import moment from 'moment'

import { AxisDomain, DateRange, getOutlierStep, isValueRange, ValueRange } from './model'

export function getDomainPoints<XDomain>(domain: AxisDomain, hasOutliers: boolean = false): XDomain[] {
  if (!domain || Array.isArray(domain)) return domain as any[]
  if (isValueRange(domain)) return getValueRangeDomainPoints(domain, hasOutliers)
  else return getDateRangeDomainPoints(domain, hasOutliers)
}

function getValueRangeDomainPoints(domain: ValueRange, hasOutliers: boolean): any[] {
  const points = []
  const step = domain.step ?? 1
  for (let i = domain.init; i <= domain.end; i += step) points.push(i)
  if (!points.includes(domain.end)) points.push(domain.end)
  if (hasOutliers) points.push(domain.end + getOutlierStep(step))
  return points
}

function getDateRangeDomainPoints(domain: DateRange, hasOutliers: boolean = false): any[] {
  const points = []
  const step = domain.step ?? { amount: 1, unit: 'day' }
  let currValue = moment(domain.init.valueOf())
  do {
    points.push(currValue.valueOf())
    currValue = currValue.add(step.amount, step.unit)
  } while (currValue.isBefore(domain.end))
  if (!points.includes(domain.end)) points.push(domain.end.valueOf())
  if (hasOutliers) points.push(domain.end.add(getOutlierStep(step.amount), step.unit).valueOf())
  return points
}

/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Theme, useTheme } from 'bold-ui'
import { Breadcrumb } from 'components/breadcrumb'
import { PageContainer } from 'components/layout/PageContainer'
import { PecSwitch } from 'components/route'
import { Fragment } from 'react'
import { Redirect, Route, useRouteMatch } from 'react-router'

import { VisualizarImovelHeader } from './components/VisualizarImovelHeader'
import { VisualizarFamiliasMoradoresView } from './VisualizarFamiliasMoradoresView'
import { VisualizarInformacoesCadastraisView } from './VisualizarInformacoesCadastraisView'
import { VisualizarUltimasVisitasView } from './VisualizarUltimasVisitasView'

export function VisualizarImovelView() {
  const theme = useTheme()
  const classes = createStyles(theme)
  const match = useRouteMatch()

  return (
    <Fragment>
      <Breadcrumb title='Visualização do imóvel' />
      <header css={classes.container}>
        <PageContainer fluid>
          <VisualizarImovelHeader />
        </PageContainer>
      </header>
      <PecSwitch>
        <Redirect exact from={`${match.url}`} to={`${match.url}/informacoes`} />
        <Route path={`${match.path}/informacoes`} component={VisualizarInformacoesCadastraisView} />
        <Route path={`${match.path}/familias`} component={VisualizarFamiliasMoradoresView} />
        <Route path={`${match.path}/visitas`} component={VisualizarUltimasVisitasView} />
      </PecSwitch>
    </Fragment>
  )
}

const createStyles = (theme: Theme) => ({
  container: css`
    border-bottom: 1px solid ${theme.pallete.divider};
  `,
  tabs: css`
    margin-top: 0.5rem;
    border-bottom-width: 0;
  `,
})

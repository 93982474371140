import { ModalMountTarget } from 'bold-ui'
import { AlertContainer } from 'components/AlertContainer'
import { BarraBrasil } from 'components/layout/BarraBrasil'
import { SiteWrapper } from 'components/layout/SiteWrapper'
import { useConfiguracoes } from 'hooks/configuracoes'
import React from 'react'

import { AlertStickyContainer } from './AlertStickyContainer'
import { AuthPortal } from './auth/AuthPortal'
import { InstalacaoRootView } from './instalacao/InstalacaoRootView'

export function InitialView() {
  const {
    configuracoes: { ativado },
  } = useConfiguracoes()

  return (
    <SiteWrapper>
      <BarraBrasil />

      <AlertStickyContainer>
        <AlertContainer />
      </AlertStickyContainer>

      {!ativado ? <InstalacaoRootView /> : <AuthPortal />}

      <ModalMountTarget />
    </SiteWrapper>
  )
}

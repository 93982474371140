import { Button, ButtonProps, Heading, HFlow, Icon, Modal, ModalBody, ModalFooter, Text, Tooltip, VFlow } from 'bold-ui'
import React, { useCallback, useState } from 'react'

import { ErroAssinaturaEletronicaEnum, erroAssinaturaEletronicaRecord } from '../model'

interface AssinaturaEletronicaAtendButtonProps extends ButtonProps {
  internetHabilitada: boolean
  setProcessoAssinaturaInProgress: (state: boolean) => void
  shouldDisableAssinarAtendimentos: boolean
}

export function AssinaturaEletronicaAtendButton(props: AssinaturaEletronicaAtendButtonProps) {
  const {
    internetHabilitada,
    shouldDisableAssinarAtendimentos,
    setProcessoAssinaturaInProgress,
    onClick,
    ...rest
  } = props
  const [modalOpen, setModalOpen] = useState(false)

  const mockTempoProcessoAssinatura = (): Promise<boolean> => {
    // TODO: (Legacy) Remover mock quando for implementado processo de assinatura
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(true)
      }, 5000)
    })
  }

  const handleSubmit = useCallback(async () => {
    // TODO: (Legacy) Remover mock quando for implementado processo de assinatura
    setProcessoAssinaturaInProgress(true)
    await mockTempoProcessoAssinatura()
    setProcessoAssinaturaInProgress(false)
  }, [setProcessoAssinaturaInProgress])

  const tooltip = 'Assinar atendimentos selecionados'

  // TODO (@Legacy): Validacoes serao implementadas na issue #14417
  const mesmoUsuarioPECGOV = false
  const contaPrataOuOuro = false

  const erroAssinatura = !internetHabilitada
    ? ErroAssinaturaEletronicaEnum.INTERNET_DESABILITADA
    : !mesmoUsuarioPECGOV
    ? ErroAssinaturaEletronicaEnum.USUARIOS_DIFERENTES
    : !contaPrataOuOuro
    ? ErroAssinaturaEletronicaEnum.CONTA_BRONZE
    : undefined

  const handleModalClose = useCallback(() => setModalOpen(false), [])
  const handleButtonClick = useCallback(
    (e) => {
      if (!internetHabilitada || !mesmoUsuarioPECGOV || !contaPrataOuOuro) {
        setModalOpen(true)
        onClick?.(e)
      }
      handleSubmit()
    },
    [contaPrataOuOuro, handleSubmit, internetHabilitada, mesmoUsuarioPECGOV, onClick]
  )

  return (
    <>
      <Modal open={modalOpen} onClose={handleModalClose} size='small' closeOnBackdropClick={false}>
        <ModalBody>
          <VFlow>
            <Heading level={2}>
              <HFlow alignItems='center'>
                <Icon icon='infoCircleOutline' size={3} fill='primary' />
                {erroAssinaturaEletronicaRecord[erroAssinatura].title}
              </HFlow>
            </Heading>
            <Text>{erroAssinaturaEletronicaRecord[erroAssinatura].message}</Text>
          </VFlow>
        </ModalBody>
        <ModalFooter>
          <HFlow justifyContent='flex-end'>
            <Button onClick={handleModalClose} kind='primary'>
              Entendi
            </Button>
          </HFlow>
        </ModalFooter>
      </Modal>
      <Tooltip text={tooltip}>
        <Button size='small' kind='primary' onClick={handleButtonClick} {...rest}>
          Assinar atendimentos
        </Button>
      </Tooltip>
    </>
  )
}

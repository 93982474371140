/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import {
  Button,
  Cell,
  Grid,
  Heading,
  HFlow,
  Icon,
  Modal,
  ModalBody,
  ModalFooter,
  Theme,
  useTheme,
  VFlow,
} from 'bold-ui'
import { Form, FormRenderProps, SubmitButton } from 'components/form'
import { StreamPlayer } from 'components/stream-player'
import { useState } from 'react'
import { metaPath } from 'util/metaPath'

import { useUserMedia } from '../hooks/useUserMedia'
import { ConfiguracoesVideochamadaModel, DispositivoMidiaSelectModel } from '../model-videochamada'
import { DispositivoMidiaSelectField } from './DispositivoMidiaSelectField'
import { MicrophoneFeedbackLabel } from './MicrophoneFeedbackLabel'

const meta = metaPath<ConfiguracoesVideochamadaModel>()

interface ConfiguracoesVideochamadaFormModalProps {
  open: boolean
  initialConfigurations?: ConfiguracoesVideochamadaModel
  onClose(): void
  onSubmit(values: ConfiguracoesVideochamadaModel): void
}

export function ConfiguracoesVideochamadaFormModal(props: ConfiguracoesVideochamadaFormModalProps) {
  const { open, initialConfigurations, onClose, onSubmit } = props

  const [audioDevice, setAudioDevice] = useState<DispositivoMidiaSelectModel>()
  const [videoDevice, setVideoDevice] = useState<DispositivoMidiaSelectModel>()

  const { stream: modalStream } = useUserMedia({
    video: open,
    audio: open,
    videoDeviceId: videoDevice?.id || initialConfigurations?.videoInput?.id,
    audioDeviceId: audioDevice?.id || initialConfigurations?.audioInput?.id,
  })

  const audioStreamInativo = !modalStream?.getAudioTracks().length
  const videoStreamInativo = !modalStream?.getVideoTracks().length

  const configuracoes = {
    audioInput: audioStreamInativo ? { id: '', nome: '' } : initialConfigurations?.audioInput,
    audioOutput: initialConfigurations?.audioOutput,
    videoInput: videoStreamInativo ? { id: '', nome: '' } : initialConfigurations?.videoInput,
  }

  const theme = useTheme()
  const styles = createStyles(theme)

  const handleSalvarConfiguracoes = (values: ConfiguracoesVideochamadaModel) => {
    onSubmit(values)
    onClose()
  }

  const handleModalClose = (formRenderProps: FormRenderProps<ConfiguracoesVideochamadaModel>) => {
    if (formRenderProps.dirty) {
      formRenderProps.form.reset()
      setVideoDevice(initialConfigurations.videoInput)
      setAudioDevice(initialConfigurations.audioInput)
    }
    onClose()
  }

  const renderForm = (formRenderProps: FormRenderProps<ConfiguracoesVideochamadaModel>) => (
    <Modal open={open} onClose={() => handleModalClose(formRenderProps)} size='large'>
      <ModalBody>
        <VFlow vSpacing={1}>
          <Heading level={1}>
            <HFlow alignItems='center'>Configurações da chamada</HFlow>
          </Heading>

          <Grid gap={2} gapVertical={1} alignItems='flex-end'>
            <Cell size={12}>
              <Heading level={2}>Áudio</Heading>
            </Cell>
            <Cell size={6}>
              <DispositivoMidiaSelectField
                name={meta.audioInput}
                label='Microfone'
                tipo='audioinput'
                onChange={(dv) => setAudioDevice(dv)}
                clearable={false}
                emptyMessage='Não existem microfones disponíveis'
              />
            </Cell>
            <Cell size={6}>
              <HFlow alignItems='center'>
                <Icon icon='microphoneOutline' size={2} />
                <MicrophoneFeedbackLabel
                  audioStreamInativo={audioStreamInativo}
                  audioTrack={modalStream?.getAudioTracks()[0]}
                />
              </HFlow>
            </Cell>
            <Cell size={6}>
              <DispositivoMidiaSelectField
                name={meta.audioOutput}
                label='Alto-falante'
                tipo='audiooutput'
                clearable={false}
                emptyMessage='Não existem alto-falantes disponíveis'
                showOnlyDefault
              />
            </Cell>
          </Grid>
          <Grid gap={2} gapVertical={1}>
            <Cell size={12}>
              <hr css={styles.divider} />
            </Cell>

            <Cell size={12}>
              <Heading level={2}>Vídeo</Heading>
            </Cell>
            <Cell size={6}>
              <DispositivoMidiaSelectField
                name={meta.videoInput}
                label='Câmera'
                tipo='videoinput'
                onChange={(dv) => setVideoDevice(dv)}
                clearable={false}
                emptyMessage='Não existem câmeras disponíveis'
              />
            </Cell>
            <Cell size={6}>
              <div css={styles.cameraContainer}>
                {videoStreamInativo ? (
                  <Heading
                    level={1}
                    style={css`
                      color: white;
                    `}
                  >
                    Sem câmera
                  </Heading>
                ) : (
                  <StreamPlayer style={styles.video} stream={modalStream} playsInline muted autoPlay />
                )}
              </div>
            </Cell>
          </Grid>
        </VFlow>
      </ModalBody>
      <ModalFooter>
        <HFlow justifyContent='flex-end'>
          <Button onClick={() => handleModalClose(formRenderProps)} style={styles.button}>
            Cancelar
          </Button>
          <SubmitButton handleSubmit={formRenderProps.handleSubmit} style={styles.button}>
            Salvar
          </SubmitButton>
        </HFlow>
      </ModalFooter>
    </Modal>
  )

  return (
    <Form<ConfiguracoesVideochamadaModel>
      render={renderForm}
      onSubmit={handleSalvarConfiguracoes}
      initialValues={
        !audioStreamInativo ? configuracoes : { ...configuracoes, audioOutput: { id: 'default', nome: '' } }
      }
    />
  )
}

const createStyles = (theme: Theme) => ({
  cameraContainer: css`
    height: 14.75rem;
    background-color: ${theme.pallete.gray.c20};
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  video: css`
    height: 100%;
    width: 100%;
    object-fit: cover;
  `,
  divider: css`
    margin: 1rem 0;
    background: ${theme.pallete.divider};
  `,
  button: css`
    width: 9rem;
  `,
})

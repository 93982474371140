import { SelectField, SelectFieldProps } from 'components/form'
import { useAsyncQuerySelect } from 'components/form/field/select/useAsyncQuerySelect'
import { ExamesPreNatalDocument } from 'graphql/hooks.generated'
import { ExamesPreNatalQuery, ExamesPreNatalQueryVariables } from 'graphql/types.generated'
import React from 'react'

export interface ExamesPreNatalSelectModel {
  id: ID
  descricao: string
}

interface ExamesPreNatalSelectProps
  extends Omit<SelectFieldProps<ExamesPreNatalSelectModel>, 'items' | 'itemToString'> {}

const itemToString = (item: ExamesPreNatalSelectModel) => item?.descricao

export function ExamesPreNatalSelectField(props: ExamesPreNatalSelectProps) {
  const { selectProps } = useAsyncQuerySelect<
    ExamesPreNatalSelectModel,
    ExamesPreNatalQuery,
    ExamesPreNatalQueryVariables
  >({
    query: ExamesPreNatalDocument,
    extractItems: (data: ExamesPreNatalQuery): ExamesPreNatalSelectModel[] => data?.examesPreNatal,
    variables: (id: string): ExamesPreNatalQueryVariables => ({
      id,
    }),
  })

  return <SelectField<ExamesPreNatalSelectModel> itemToString={itemToString} {...selectProps} {...props} />
}

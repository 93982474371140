import { Omit } from 'bold-ui'
import { SelectField, SelectFieldProps } from 'components/form/final-form'
import { TipoEscoamentoSanitarioEnum } from 'graphql/types.generated'
import React from 'react'

import { tipoEscoamentoSanitarioEnumRecord } from './condicoes-moradia-model'

export interface TipoEscoamentoSanitarioSelectFieldProps
  extends Omit<SelectFieldProps<TipoEscoamentoSanitarioEnum>, 'items' | 'itemToString'> {}

export function TipoEscoamentoSanitarioSelectField(props: TipoEscoamentoSanitarioSelectFieldProps) {
  return <SelectField<TipoEscoamentoSanitarioEnum> items={items} itemToString={itemToString} {...props} />
}

const items = Object.values(TipoEscoamentoSanitarioEnum)
const itemToString = (item: TipoEscoamentoSanitarioEnum) => item && tipoEscoamentoSanitarioEnumRecord[item]

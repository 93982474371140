import {
  TipoAbastecimentoAguaEnum,
  TipoAcessoDomicilioEnum,
  TipoDestinoLixoEnum,
  TipoDomicilioEnum,
  TipoEscoamentoSanitarioEnum,
  TipoImovelEnum,
  TipoLocalizacaoEnum,
  TipoMaterialParedeEnum,
  TipoPosseTerraEnum,
  TipoSituacaoMoradiaEnum,
  TipoTratamentoAguaEnum,
} from 'graphql/types.generated'

export const tipoAbastecimentoAguaEnumRecord: Record<TipoAbastecimentoAguaEnum, string> = {
  REDE_ENCANADA: 'Rede encanada até o domicílio - Sistema de abastecimento público (municipal ou estadual)',
  REDE_ENCANADA_ATE_O_DOMICILIO_SESAI:
    'Rede encanada até o domicílio - Sistema de abastecimento próprio da aldeia (SESAI)',
  POCO_NASCENTE_DOMICILIO: 'Poço / Nascente no domicílio',
  CISTERNA: 'Cisterna - Água de chuva',
  CARRO_PIPA: 'Carro Pipa',
  CAPTACAO_DIRETA_RIO: 'Captação direta de água do rio',
  CAPTACAO_DIRETA_POCO: 'Captação direta de poço coletivo',
  CHAFARIZ: 'Ponto de abastecimento coletivo - chafariz',
  OUTRO: 'Outro',
}

export const tipoDomicilioEnumRecord: Record<TipoDomicilioEnum, string> = {
  CASA: 'Casa',
  APARTAMENTO: 'Apartamento',
  COMODO: 'Cômodo',
  MALOCA: 'Maloca',
  IMPROVISADO: 'Improvisado',
  OUTRO: 'Outro',
}

export const tipoEscoamentoSanitarioEnumRecord: Record<TipoEscoamentoSanitarioEnum, string> = {
  REDE_COLETORA_ESGOTO_PLUVIAL: 'Rede coletora de esgoto ou pluvial',
  FOSSA_SEPTICA: 'Fossa séptica',
  FOSSA_RUDIMENTAR: 'Fossa rudimentar',
  DIRETO_PARA_RIO_LAGO_MAR: 'Direto para um rio, lago ou mar',
  CEU_ABERTO: 'Céu aberto',
  OUTRO: 'Outra forma',
}

export const tipoImovelEnumRecord: Record<TipoImovelEnum, string> = {
  DOMICILIO: 'Domicílio',
  COMERCIO: 'Comércio',
  TERRENO_BALDIO: 'Terreno baldio',
  PONTO_ESTRATEGICO:
    'Ponto Estratégico (cemitério, borracharia, ferro-velho, depósito de sucata ou materiais de construção, garagem de ônibus ou veículo de grande porte, casa de farinha)',
  ESCOLA: 'Escola',
  CRECHE: 'Creche',
  ABRIGO: 'Abrigo',
  INSTITUICAO_LONGA_PERMANENCIA_PARA_IDOSOS: 'Instituição de longa permanência para idosos',
  UNIDADE_PRISIONAL: 'Unidade prisional',
  UNIDADE_MEDIDA_SOCIOEDUCATIVA: 'Unidade de medida socioeducativa',
  DELEGACIA: 'Delegacia',
  ESTABELECIMENTO_RELIGIOSO: 'Estabelecimento religioso',
  CASAI: 'Casai',
  OUTROS: 'Outros',
}

export const tipoMaterialParedeEnumRecord: Record<TipoMaterialParedeEnum, string> = {
  ALVENARIA_COM_REVESTIMENTO: 'Alvenaria com revestimento',
  ALVENARINA_SEM_REVESTIMENTO: 'Alvenaria sem revestimento',
  TAIPA_COM_REVESTIMENTO: 'Taipa com revestimento',
  TAIPA_SEM_REVESTIMENTO: 'Taipa sem revestimento',
  MADEIRA_APARELHADA: 'Madeira aparelhada',
  MATERIAL_APROVEITADO: 'Material aproveitado',
  PALHA: 'Palha',
  OUTROS_LONA: 'Lona',
  OUTROS_MISTO_DIFERENTES_MATERIAIS: 'Misto/Diferentes materiais',
  OUTROS_CAULE_PALMEIRA: 'Caule de palmeira',
  OUTROS_SEM_PAREDE: 'Sem parede',
  OUTRO: 'Outro material',
}

export const tipoPosseTerraEnumRecord: Record<TipoPosseTerraEnum, string> = {
  PROPRIETARIO: 'Proprietário',
  PARCEIRO: 'Parceiro(a) / Meeiro(a)',
  ASSENTADO: 'Assentado(a)',
  POSSEIRO: 'Posseiro',
  ARRENDATARIO: 'Arrendatário(a)',
  COMODATARIO: 'Comodatário(a)',
  BENEFICIARIO_BANCO_TERRA: 'Beneficiário(a) do Banco da Terra',
  TERRA_INDIGENA_DEMARCADA: 'Terra indígena demarcada',
  TERRA_NAO_DEMARCADA: 'Terra não demarcada',
  NAO_SE_APLICA: 'Não se aplica',
}

export const tipoSituacaoMoradiaEnumRecord: Record<TipoSituacaoMoradiaEnum, string> = {
  PROPRIO: 'Próprio',
  FINANCIADO: 'Financiado',
  ALUGADO: 'Alugado',
  CEDIDO: 'Cedido',
  OCUPACAO: 'Ocupação',
  SITUACAO_DE_RUA: 'Situação de rua',
  ARRENDADO: 'Arrendado',
  TEMPORARIA: 'Temporária',
  OUTRO: 'Outra',
}

export const tipoTratamentoAguaEnumRecord: Record<TipoTratamentoAguaEnum, string> = {
  FILTRADA: 'Filtrada',
  FILTRACAO_BARRO: 'Filtrada com filtro de barro',
  FILTRACAO_OUTRO: 'Filtrada por outro tipo de filtro',
  FERVIDA: 'Fervida',
  CLORADA: 'Clorada',
  MINERAL: 'Mineral',
  CLORACAO_INTRADOMICILIAR: 'Clorada intradomiciliar com hipoclorito de sódio',
  SEM_TRATAMENTO: 'Sem tratamento',
}

export const tipoAcessoDomicilioEnumRecord: Record<TipoAcessoDomicilioEnum, string> = {
  PAVIMENTO: 'Pavimento',
  CHAO_BATIDO: 'Chão batido',
  FLUVIAL: 'Fluvial',
  OUTRO: 'Outro',
}

export const tipoDestinoLixoEnumRecord: Record<TipoDestinoLixoEnum, string> = {
  COLETADO: 'Coletado',
  QUEIMADO_ENTERRADO: 'Queimado/Enterrado',
  QUEIMADO: 'Queimado',
  ENTERRADO: 'Enterrado',
  CEU_ABERTO: 'Céu aberto',
  OUTRO: 'Sem tratamento',
}

export const tipoLocalizacaoEnumRecord: Record<TipoLocalizacaoEnum, string> = {
  URBANA: 'Urbana',
  RURAL: 'Rural',
  PERIURBANA: 'Periurbana',
}

import { Button, Cell, DataTable, FormControl, Grid, Icon, TableFooter, Tooltip, VFlow } from 'bold-ui'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import {
  LotacaoAndEstagioSelectField,
  LotacaoAndEstagioSelectFieldModel,
} from 'components/form/field/select/LotacaoAndEstagioSelectField/LotacaoAndEstagioSelectField'
import { TableBox } from 'components/table/TableBox'
import { usePagination } from 'components/table/usePagination'
import { css } from 'emotion'
import React from 'react'
import { MetaArray } from 'util/metaPath'
import { required } from 'util/validation/rules/required'
import { createValidator, ErrorObject } from 'util/validation/validator'
import { useEditableListField } from 'view/atendimentos/detail/components/EditableList'
import { EditableListForm, EditableListFormRenderProps } from 'view/atendimentos/detail/components/EditableListForm'

export interface LotacaoEditableTableModel {
  _id: ID
  lotacao: LotacaoAndEstagioSelectFieldModel
}

interface LotacaoEditableTableProps {
  name: MetaArray<LotacaoEditableTableModel>
  required?: boolean
}

const lotacaoEditableTableValidator = (allItems: LotacaoEditableTableModel[]) =>
  createValidator<LotacaoEditableTableModel>(
    {
      lotacao: [required],
    },
    (model: LotacaoEditableTableModel | undefined, errors: ErrorObject<LotacaoEditableTableModel>) => {
      if (model?.lotacao && allItems.find((item) => model.lotacao.id === item.lotacao.id)) {
        errors.lotacao = 'Lotação já adicionada'
      }

      return errors
    }
  )

export function LotacaoEditableTable(props: LotacaoEditableTableProps) {
  const { name, required: isRequired = false } = props
  const { acesso } = useAcessoLotacaoOrEstagio()
  const {
    handleSubmit,
    removeItem,
    input: { value },
  } = useEditableListField({ name })

  const allItems: LotacaoEditableTableModel[] = (value || []).slice().reverse()
  const { paginatedItems, tableProps } = usePagination({ items: allItems })

  const renderForm = (renderProps: EditableListFormRenderProps<LotacaoEditableTableModel>) => {
    const { name, handleSubmit } = renderProps

    return (
      <Grid gap={2} style={styles.tableHeader}>
        <Cell size={6}>
          <VFlow>
            <LotacaoAndEstagioSelectField
              name={name.lotacao}
              label='Profissional'
              placeholder='Pesquise por nome, CPF ou CNS'
              municipioId={acesso?.municipio.id}
              includeEstagios
              required={isRequired}
            />
          </VFlow>
        </Cell>

        <Cell size={2}>
          <FormControl label={<span>&nbsp;</span>}>
            <Button kind='primary' onClick={handleSubmit} size='small'>
              Adicionar
            </Button>
          </FormControl>
        </Cell>
      </Grid>
    )
  }

  const renderHeader = () => {
    return (
      <EditableListForm<LotacaoEditableTableModel>
        render={renderForm}
        onSubmit={handleSubmit}
        validate={lotacaoEditableTableValidator(allItems)}
        prefix='new'
      />
    )
  }

  const renderButtons = (row: LotacaoEditableTableModel) => {
    return (
      <Tooltip text='Excluir'>
        <Button type='button' kind='normal' skin='ghost' size='small' onClick={() => removeItem(row)}>
          <Icon icon='trashOutline' />
        </Button>
      </Tooltip>
    )
  }

  return (
    <TableBox header={renderHeader()}>
      <DataTable<LotacaoEditableTableModel>
        rows={paginatedItems}
        columns={[
          {
            name: 'nome',
            header: 'Nome',
            render: (row) => row.lotacao.profissional.nome,
            style: styles.tableRowNome,
          },
          {
            name: 'cbo',
            header: 'CBO',
            render: (row) => row.lotacao.cbo.nome.capitalize(),
            style: styles.tableRowCBO,
          },
          {
            name: 'ine',
            header: 'INE',
            render: (row) => row.lotacao.equipe?.nome ?? '-',
          },
          {
            name: 'buttons',
            render: renderButtons,
            style: styles.tableRowButtons,
          },
        ]}
      />
      <TableFooter style={styles.tableFooter} {...tableProps} />
    </TableBox>
  )
}

const styles = {
  tableHeader: css`
    padding-block: 0.5rem;
  `,
  tableFooter: css`
    border-top: 0;
  `,
  tableRowNome: css`
    width: 22rem;
  `,
  tableRowCBO: css`
    width: 20rem;
  `,
  tableRowButtons: css`
    width: 3.5rem;
    text-align: right;
  `,
}

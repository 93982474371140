import { ApolloError } from 'apollo-client'
import { isEmpty } from 'bold-ui'
import { useAlert } from 'components/alert'
import { handleError } from 'components/error'
import { Form, FormRenderProps } from 'components/form'
import { NovaSenhaModel } from 'components/form/alterar-senha/model'
import { novaSenhaInputRules } from 'components/form/alterar-senha/validator'
import { useInstalarMutation } from 'graphql/hooks.generated'
import { InstalacaoInput } from 'graphql/types.generated'
import { useConfiguracoes } from 'hooks/configuracoes'
import React, { useEffect, useState } from 'react'
import { metaPath } from 'util/metaPath'
import { composeValidators, createValidator, maxLength, required } from 'util/validation'
import {
  createEnderecoCalculator,
  createProfissionalValidator,
} from 'view/profissional/components/ProfissionalDadosForm'
import ProfissionalFormModel from 'view/profissional/components/ProfissionalFormModel'

import ConfiguracaoServidorEnderecoFormModel from '../configuracoes/instalacao/servidores/ConfiguracaoServidorEnderecoFormModel'
import { CadastroInstaladorView } from './CadastroInstaladorView'
import { convertErrors, convertToInput } from './convertModel'
import { FinalizarInstalacaoView } from './FinalizarInstalacaoView'
import { InstalacaoLayout, InstalacaoStep } from './InstalacaoLayout'
import { TipoInstalacaoView } from './TipoInstalacaoView'

export interface InstalacaoFormModel {
  step1: ConfiguracaoServidorEnderecoFormModel & Pick<InstalacaoInput, 'tipoInstalacao'>
  step2: ProfissionalFormModel & NovaSenhaModel
}

export const meta = metaPath<InstalacaoFormModel>()

const enderecoCalculator = createEnderecoCalculator(meta.step2.endereco)

export function InstalacaoRootView() {
  const [currentStep, setCurrentStep] = useState<InstalacaoStep>(InstalacaoStep.IDENTIFICAR)
  const [instalar] = useInstalarMutation()
  const alert = useAlert()
  const { update: refreshConfiguracoes } = useConfiguracoes()

  useEffect(() => window.scrollTo(0, 0), [currentStep]) // scroll to top when step changes

  const handleSubmit = (values: InstalacaoFormModel) => {
    return instalar({
      variables: { input: convertToInput(values) },
    })
      .then(() => {
        alert('success', 'Ativação da instalação foi finalizada com sucesso.')
        return refreshConfiguracoes()
      })
      .catch((error: ApolloError) => {
        const formErrors = convertErrors(handleError({ error }))

        if (!isEmpty(formErrors.step1)) {
          setCurrentStep(InstalacaoStep.IDENTIFICAR)
        } else if (!isEmpty(formErrors.step2)) {
          setCurrentStep(InstalacaoStep.CADASTRAR)
        }

        return formErrors
      })
  }

  const FormBody = (formProps: FormRenderProps<InstalacaoFormModel>) => {
    const handleAvancarTipo = () => {
      if (!formProps.errors.step1) {
        setCurrentStep(InstalacaoStep.CADASTRAR)
      } else {
        formProps.form.submit() // submete só pra forçar o estado de erro
      }
    }

    const handleVoltarCadastro = () => setCurrentStep(InstalacaoStep.IDENTIFICAR)
    const handleAvancarCadastro = () => {
      if (!formProps.errors.step2) {
        setCurrentStep(InstalacaoStep.FINALIZAR)
      } else {
        formProps.form.submit() // submete só pra forçar o estado de erro
      }
    }

    const handleVoltarFinalizar = () => setCurrentStep(InstalacaoStep.CADASTRAR)
    const handleFinalizar = () => formProps.form.submit()

    return (
      <InstalacaoLayout step={currentStep}>
        <form onSubmit={formProps.handleSubmit} noValidate>
          {currentStep === InstalacaoStep.IDENTIFICAR && <TipoInstalacaoView onAvancar={handleAvancarTipo} />}

          {currentStep === InstalacaoStep.CADASTRAR && (
            <CadastroInstaladorView
              formProps={formProps}
              onVoltar={handleVoltarCadastro}
              onAvancar={handleAvancarCadastro}
            />
          )}

          {currentStep === InstalacaoStep.FINALIZAR && (
            <FinalizarInstalacaoView onVoltar={handleVoltarFinalizar} onFinalizar={handleFinalizar} />
          )}
        </form>
      </InstalacaoLayout>
    )
  }

  return (
    <Form<InstalacaoFormModel>
      render={FormBody}
      onSubmit={handleSubmit}
      validate={validator}
      decorators={[enderecoCalculator]}
      transformResult={(result) => result} // bypass no transform default, porque o tratamento das validações é feita manualmente
    />
  )
}

const validateStep1 = createValidator<InstalacaoFormModel['step1']>({
  nomeInstalacao: [required, maxLength(100)],
  linkInstalacao: [required, maxLength(255)],
  tipoInstalacao: [required],
})

const validateSenhaStep2 = createValidator<InstalacaoFormModel['step2']>(novaSenhaInputRules)

const validator = createValidator<InstalacaoFormModel>({
  step1: [validateStep1],
  step2: composeValidators(createProfissionalValidator(false), validateSenhaStep2),
})

import { Omit } from 'bold-ui'
import { SelectField, SelectFieldProps } from 'components/form/final-form'
import { TipoOrigemEnergiaEletricaEnum } from 'graphql/types.generated'
import React from 'react'

export interface TipoEnergiaEletricaSelectFieldProps
  extends Omit<SelectFieldProps<TipoOrigemEnergiaEletricaEnum>, 'items' | 'itemToString'> {}

export function TipoEnergiaEletricaSelectField(props: TipoEnergiaEletricaSelectFieldProps) {
  return <SelectField<TipoOrigemEnergiaEletricaEnum> items={items} itemToString={itemToString} {...props} />
}

const items = Object.values(TipoOrigemEnergiaEletricaEnum)
const itemToString = (item: TipoOrigemEnergiaEletricaEnum) => item && descricaoRecord[item]

const descricaoRecord: Record<TipoOrigemEnergiaEletricaEnum, string> = {
  CONCESSIONARIA: 'Concessionária',
  GERADOR_COMUNITARIO: 'Gerador comunitário',
  GERADOR_INDIVIDUAL: 'Gerador individual',
  FOTOVOLTAICA_COMUNITARIA: 'Fotovoltaica comunitária',
  FOTOVOLTAICA_INDIVIDUAL: 'Fotovoltaica individual',
}

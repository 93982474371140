import { SelectField } from 'components/form'
import { LocalOcorrenciaSelectFieldDocument } from 'graphql/hooks.generated'
import { LocalOcorrenciaSelectFieldQuery, LocalOcorrenciaSelectFieldQueryVariables } from 'graphql/types.generated'
import React from 'react'

import { AsyncSelectFieldProps, DEFAULT_SELECT_PAGE_PARAM, useAsyncQuerySelect } from '../useAsyncQuerySelect'

export interface LocalOcorrenciaSelectFieldModel {
  id: ID
  nome?: string
}

export interface LocalOcorrenciaSelectFieldProps extends AsyncSelectFieldProps<LocalOcorrenciaSelectFieldModel> {}

const extractItems = (data: LocalOcorrenciaSelectFieldQuery) => {
  return data?.locaisOcorrencia.content
}

const handleItemToString = (item: LocalOcorrenciaSelectFieldModel) => {
  return item?.nome
}

export function LocalOcorrenciaSelectField(props: LocalOcorrenciaSelectFieldProps) {
  const variables = (query: string): LocalOcorrenciaSelectFieldQueryVariables => ({
    input: {
      query,
      pageParams: DEFAULT_SELECT_PAGE_PARAM,
    },
  })

  const { selectProps } = useAsyncQuerySelect<
    LocalOcorrenciaSelectFieldModel,
    LocalOcorrenciaSelectFieldQuery,
    LocalOcorrenciaSelectFieldQueryVariables
  >({
    query: LocalOcorrenciaSelectFieldDocument,
    extractItems,
    variables,
  })

  return <SelectField<LocalOcorrenciaSelectFieldModel> itemToString={handleItemToString} {...selectProps} {...props} />
}

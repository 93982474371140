import {
  cep,
  createValidator,
  ErrorObject,
  maxLength,
  microarea,
  numeroEndereco,
  required,
  RuleMap,
  ValidateFunction,
} from 'util/validation'

import { EnderecoFieldGroupModel } from './EnderecoFieldGroup'

export const validateEndereco = createValidator<EnderecoFieldGroupModel>(
  ruleMapFunctionEndereco(),
  validateFunctionEndereco()
)

export const validateEnderecoCompleto = createValidator<EnderecoFieldGroupModel>(
  ruleMapFunctionEndereco(),
  enderecoCompletoValidateFunction()
)

function enderecoRequired(tipoLogradouro: boolean, values: EnderecoFieldGroupModel) {
  return (
    values.cep ||
    values.uf ||
    values.municipio ||
    values.bairro ||
    values.logradouro ||
    values.numero ||
    (tipoLogradouro && values.tipoLogradouro)
  )
}

function enderecoCompletoValidateFunction(): ValidateFunction<EnderecoFieldGroupModel> {
  return (values: EnderecoFieldGroupModel, errors: ErrorObject<EnderecoFieldGroupModel>) => {
    const isRequired = enderecoRequired(true, values)

    const validateFuncion = validateFunctionEndereco()
    const errorsValidate = validateFuncion(values, errors)
    errorsValidate.pais = required(values.pais)
    errorsValidate.tipoLogradouro = isRequired && required(values.tipoLogradouro)

    if (values.pais && values.pais.nome !== 'BRASIL') {
      errorsValidate.municipioResidenciaExterior = required(values.municipioResidenciaExterior)
    }
    return errorsValidate
  }
}

export function ruleMapFunctionEndereco(): RuleMap<EnderecoFieldGroupModel> {
  return {
    cep: [cep],
    numero: [maxLength(7)],
    complemento: [maxLength(30)],
    pontoReferencia: [maxLength(40)],
    municipioResidenciaExterior: [maxLength(100)],
    area: [maxLength(3)],
    microArea: [microarea],
  }
}

export function validateFunctionEndereco(): ValidateFunction<EnderecoFieldGroupModel> {
  return (values: EnderecoFieldGroupModel, errors: ErrorObject<EnderecoFieldGroupModel>) => {
    if (values == null) {
      return errors
    }

    const isRequired = enderecoRequired(false, values)

    const isNumeroPreenchido = Boolean(values.numero || values.semNumero)
    if (isRequired) {
      errors.uf = required(values.uf)
      errors.municipio = required(values.municipio)
      errors.bairro = required(values.bairro && values.bairro.nome)
      errors.logradouro = required(values.logradouro && values.logradouro.nome)
      if (!isNumeroPreenchido) {
        errors.numero = required(values.numero)
      }
    }

    if (values.numero && !errors.numero) {
      errors.numero = numeroEndereco(values.semNumero)(values.numero)
    }

    if (values.logradouro) {
      errors.logradouro = maxLength(72)(values.logradouro.nome)
    }
    if (values.bairro) {
      errors.bairro = maxLength(72)(values.bairro.nome)
    }
    return errors
  }
}

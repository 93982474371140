import { Alert, Text } from 'bold-ui'
import React from 'react'
import { isUndefinedOrNull } from 'util/checks'

import { formatVigenciaBolsaFamilia } from '../util-bolsafamilia'

interface ImportacaoBolsaFamiliaVigenciaAlertProps {
  vigenciaMaisRecenteImportada: string
}

export function ImportacaoBolsaFamiliaVigenciaAlert(props: ImportacaoBolsaFamiliaVigenciaAlertProps) {
  const { vigenciaMaisRecenteImportada } = props

  if (isUndefinedOrNull(vigenciaMaisRecenteImportada)) {
    return null
  }

  const vigencia = formatVigenciaBolsaFamilia(vigenciaMaisRecenteImportada)

  return (
    <Alert inline type='info'>
      A vigência{' '}
      <Text color='info' fontWeight='bold'>
        {vigencia}
      </Text>{' '}
      é a vigência mais recente importada.
    </Alert>
  )
}

/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, Grid, Heading, Icon, Theme, useTheme, VFlow } from 'bold-ui'
import { PageContent } from 'components/layout/PageContent'
import { ExternalUserHeader } from 'components/user/ExternalUserHeader'
import { ReactComponent as VideoCallEndedImage } from 'images/videochamada/videochamada-encerrada.svg'
import { Fragment } from 'react'
import { useHistory, useRouteMatch } from 'react-router'

import { VideochamadaUrlParams } from './model-videochamada'

export function SairVideochamadaView() {
  const {
    params: { uuid: videochamadaUuid },
  } = useRouteMatch<VideochamadaUrlParams>()

  const history = useHistory()

  const handleVoltarClick = () => history.push(`/videochamada/${videochamadaUuid}`)

  const theme = useTheme()
  const styles = createStyles(theme)

  return (
    <Fragment>
      <ExternalUserHeader />
      <PageContent type='filled' containerStyle={styles.containerStyle} style={styles.container}>
        <Grid alignItems='center' justifyContent='space-between' direction='row' style={styles.pageContentGrid}>
          <Cell sm={5} style={styles.cellHeading}>
            <VFlow>
              <Heading level={1}>Você saiu da chamada</Heading>
              <Button kind='primary' onClick={handleVoltarClick}>
                <Icon
                  icon='arrowLeft'
                  style={css`
                    margin-right: 0.5rem;
                  `}
                />
                Voltar para sala
              </Button>
            </VFlow>
          </Cell>
          <Cell sm={7} flexGrow={1} style={styles.cellImage}>
            <VideoCallEndedImage
              title='Duas pessoas com as mãos levantadas, representando que estão se despedindo em uma chamada de vídeo.'
              css={styles.image}
            />
          </Cell>
        </Grid>
      </PageContent>
    </Fragment>
  )
}

const createStyles = (theme: Theme) => ({
  container: css`
    padding: 2rem 1rem;

    ${theme.breakpoints.up('sm')} {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  `,
  containerStyle: css`
    padding: 0;

    ${theme.breakpoints.up('sm')} {
      padding: 0 2rem;
    }
  `,
  pageContentGrid: css`
    align-items: flex-start;
    flex-direction: column;

    ${theme.breakpoints.up('sm')} {
      align-items: center;
      flex-direction: row;
    }
  `,
  cellHeading: css`
    padding-top: 0;
    ${theme.breakpoints.up('sm')} {
      padding-top: 0.5rem;
    }
  `,
  cellImage: css`
    padding-bottom: 0;
    ${theme.breakpoints.up('sm')} {
      padding-bottom: 0.5rem;
    }
  `,
  image: css`
    width: 100%;
    height: 100%;
  `,
})

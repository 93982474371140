import { createValidator, ErrorObject, maxLength, numeroEndereco, required } from 'util/validation'

import { EnderecoIndigenaFieldGroupModel } from './model-enderecoIndigena'

export const validateEnderecoIndigena = createValidator<EnderecoIndigenaFieldGroupModel>(
  {
    numero: [maxLength(7), required],
    numeroFamilia: [maxLength(7), required],
    dsei: [required],
    poloBase: [required],
    aldeia: [required],
    municipio: [required],
    uf: [required],
  },
  (
    enderecoIndigenaValues: EnderecoIndigenaFieldGroupModel,
    errors: ErrorObject<EnderecoIndigenaFieldGroupModel>
  ): ErrorObject<EnderecoIndigenaFieldGroupModel> => {
    if (enderecoIndigenaValues?.numero && !errors.numero) {
      errors.numero = numeroEndereco()(enderecoIndigenaValues.numero)
    }

    if (enderecoIndigenaValues?.numeroFamilia && !errors.numeroFamilia) {
      errors.numeroFamilia = numeroEndereco()(enderecoIndigenaValues.numeroFamilia)
    }

    return errors
  }
)

import { PagedTable } from 'bold-ui'
import { TableBox } from 'components/table'
import { usePagedTableProps } from 'components/table/usePagedTableProps'
import { useInternetHabilitadaQuery } from 'graphql/hooks.generated'
import { AtendimentosFinalizadosQuery, StatusAssinatura } from 'graphql/types.generated'
import React, { useState } from 'react'

import { convertAtendProfToAssinaturaEletronicaRowModel } from '../converter'
import { AssinaturaEletronicaAtendTableFilterModel, AssinaturaEletronicaAtendTableRowModel } from '../model'
import { AssinaturaEletronicaAtendTableHeader } from './AssinaturaEletronicaAtendTableHeader'
import {
  renderActions,
  renderCheckbox,
  renderCidadao,
  renderDataHora,
  renderEquipe,
  renderProfissional,
  renderTags,
  renderTipoAtendimento,
} from './renderAssinaturaEletronicaAtendTable'

export interface AssinaturaEletronicaAtendTableProps {
  isCoordenador: boolean
  setCurrentFilter(values: AssinaturaEletronicaAtendTableFilterModel): void
  data: AtendimentosFinalizadosQuery
  loading: boolean
}

export function AssinaturaEletronicaAtendTable(props: AssinaturaEletronicaAtendTableProps) {
  const { isCoordenador, data, loading, setCurrentFilter } = props

  const [processoAssinaturaInProgress, setProcessoAssinaturaInProgress] = useState<boolean>(false)
  const [checkedRowIdList, setCheckedRowIdList] = useState<Set<ID>>(new Set())

  const { data: internetHabilitada } = useInternetHabilitadaQuery()

  const tableProps = usePagedTableProps({
    result: data?.atendimentosFinalizados,
    loading: loading,
    onChange: setCurrentFilter,
  })

  const atendimentosFinalizados =
    data.atendimentosFinalizados?.content.map(convertAtendProfToAssinaturaEletronicaRowModel) ?? []

  const quantidadeAtendimentosPendentes = atendimentosFinalizados.filter(
    (el) => el.statusAssinatura !== StatusAssinatura.ASSINADO
  ).length

  return (
    <TableBox
      header={
        !isCoordenador && (
          <AssinaturaEletronicaAtendTableHeader
            quantidadeAtendimentosPendentes={quantidadeAtendimentosPendentes}
            checkedItems={checkedRowIdList}
            setCheckedItems={setCheckedRowIdList}
            atendimentos={atendimentosFinalizados}
            processoAssinaturaInProgress={processoAssinaturaInProgress}
            internetHabilidada={internetHabilitada?.conexao?.internet?.habilitado}
            setProcessoAssinaturaInProgress={setProcessoAssinaturaInProgress}
          />
        )
      }
    >
      <PagedTable<AssinaturaEletronicaAtendTableRowModel>
        {...tableProps}
        rows={atendimentosFinalizados}
        columns={[
          {
            name: 'checkbox',
            render: (row: AssinaturaEletronicaAtendTableRowModel) =>
              renderCheckbox(row, checkedRowIdList, setCheckedRowIdList, processoAssinaturaInProgress),
            style: { width: '56px', display: isCoordenador ? 'none' : 'table-cell' },
          },
          {
            header: 'Data de finalização',
            name: 'dataFinalizacao',
            render: renderDataHora,
            style: { width: '149px', padding: '0.5rem' },
          },
          {
            header: 'Tipo de atendimento',
            name: 'tipoAtendimento',
            render: renderTipoAtendimento,
            style: { width: '158px', display: isCoordenador ? 'none' : 'table-cell' },
          },
          {
            header: 'Profissional',
            name: 'profissional',
            render: renderProfissional,
            style: { padding: '0.5rem', display: !isCoordenador ? 'none' : 'table-cell' },
          },
          {
            header: 'Equipe',
            name: 'equipe',
            render: renderEquipe,
            style: { padding: '0.5rem', display: !isCoordenador ? 'none' : 'table-cell' },
          },
          {
            header: 'Cidadão',
            name: 'cidadao',
            render: renderCidadao,
            style: { padding: '0.5rem' },
          },
          {
            name: 'status',
            render: renderTags,
          },
          {
            name: 'icon',
            render: (row: AssinaturaEletronicaAtendTableRowModel) =>
              renderActions(row, processoAssinaturaInProgress, checkedRowIdList),
            style: { width: '129px', display: isCoordenador ? 'none' : 'table-cell' },
          },
        ]}
      />
    </TableBox>
  )
}

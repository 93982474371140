import ApolloClient from 'apollo-client'
import { EquipeSelectModel } from 'components/form'
import { LotacaoAndEstagioSelectFieldModel } from 'components/form/field/select/LotacaoAndEstagioSelectField/LotacaoAndEstagioSelectField'
import { Calculation } from 'final-form-calculate'
import { EquipeAtendimentoDocument } from 'graphql/hooks.generated'
import { isEqual } from 'lodash'
import { MetaRoot } from 'util/metaPath'

import { AssinaturaEletronicaAtendTableFilterModel } from './model'

const equipeCalculator = (
  meta: MetaRoot<AssinaturaEletronicaAtendTableFilterModel>,
  apollo: ApolloClient<object>
): Calculation => ({
  field: meta.lotacao.absolutePath(),
  updates: {
    [meta.equipe.absolutePath()]: (
      lotacao: LotacaoAndEstagioSelectFieldModel,
      allValues: AssinaturaEletronicaAtendTableFilterModel
    ) => {
      if (!!lotacao?.equipe?.id) {
        return apollo
          .query({
            query: EquipeAtendimentoDocument,
            variables: { id: lotacao.equipe.id },
          })
          .then((res) => res.data.equipe)
      } else if (!lotacao?.id) {
        return null
      }

      return allValues.equipe
    },
  },
  isEqual: (a, b) => (!a && !b) || isEqual(a, b),
})

const lotacaoCalculator = (meta: MetaRoot<AssinaturaEletronicaAtendTableFilterModel>): Calculation => ({
  field: meta.equipe.absolutePath(),
  updates: {
    [meta.lotacao.absolutePath()]: (
      equipe: EquipeSelectModel,
      _,
      allValues: AssinaturaEletronicaAtendTableFilterModel
    ) => (equipe?.id !== allValues?.lotacao?.equipe?.id ? undefined : allValues?.lotacao),
  },
})

export const createAssinaturaEletronicaAtendimentoCalculations = (
  meta: MetaRoot<AssinaturaEletronicaAtendTableFilterModel>,
  apollo: ApolloClient<object>
): Calculation[] => [lotacaoCalculator(meta), equipeCalculator(meta, apollo)]

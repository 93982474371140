import { ArquivoFormatoEnum, ArquivoIdentificadorEnum, ArquivoStatusEnum } from 'graphql/types.generated'
import {
  ACOMPANHAMENTO_CIDADAOS_VINCULADOS_ATMOSPHERE_PATH,
  ACOMPANHAMENTO_CIDADAOS_VINCULADOS_FILENAME,
} from 'view/acompanhamento-cidadaos-vinculados/model'
import { ACOMPANHAMENTO_CONDICAO_SAUDE_ATMOSPHERE_PATH } from 'view/acompanhamento-condicao-saude/model-acompanhamentoCondicaoSaude'
import { BUSCA_ATIVA_VACINACAO_PATH } from 'view/busca-ativa/vacinacao/model-buscaAtivaVacinacao'
import { IMPORTAR_CIDADAO_PATH } from 'view/importar-cidadao/model-importarCidadao'
import { EXPORTAR_CADASTROS_DUPLICADOS_PATH } from 'view/unificacaoCadastro/UnificacaoCadastroFormModel'

export interface ArquivoModel {
  id: ID
  dataInicioGerado: Instant
  status: ArquivoStatusEnum
  identificador: ArquivoIdentificadorEnum
  formato: ArquivoFormatoEnum
}

export interface RelatoriosRecentesRootModel {
  fileName: string
  atmospherePath: string
}

export const relatoriosRecentesRecord: Record<ArquivoIdentificadorEnum, RelatoriosRecentesRootModel> = {
  [ArquivoIdentificadorEnum.RELATORIO_ACOMPANHAMENTO_CIDADAOS_VINCULADOS]: {
    fileName: ACOMPANHAMENTO_CIDADAOS_VINCULADOS_FILENAME,
    atmospherePath: ACOMPANHAMENTO_CIDADAOS_VINCULADOS_ATMOSPHERE_PATH,
  },
  [ArquivoIdentificadorEnum.RELATORIO_ACOMPANHAMENTO_CONDICAO_SAUDE]: {
    fileName: ACOMPANHAMENTO_CONDICAO_SAUDE_ATMOSPHERE_PATH,
    atmospherePath: ACOMPANHAMENTO_CONDICAO_SAUDE_ATMOSPHERE_PATH,
  },
  [ArquivoIdentificadorEnum.RELATORIO_BUSCA_ATIVA_VACINACAO]: {
    fileName: BUSCA_ATIVA_VACINACAO_PATH,
    atmospherePath: BUSCA_ATIVA_VACINACAO_PATH,
  },
  [ArquivoIdentificadorEnum.RELATORIO_CADASTROS_DUPLICADOS]: {
    fileName: EXPORTAR_CADASTROS_DUPLICADOS_PATH,
    atmospherePath: EXPORTAR_CADASTROS_DUPLICADOS_PATH,
  },
  [ArquivoIdentificadorEnum.RELATORIO_IMPORTACAO_CIDADAOS]: {
    fileName: IMPORTAR_CIDADAO_PATH,
    atmospherePath: IMPORTAR_CIDADAO_PATH,
  },
}

export interface ArquivoFormato {
  arquivo: number[]
  formato: string
  arquivoId: ID
  mensagem: string
}

export interface MensagemErroArquivoMaisRecente {
  arquivoId: ID
  mensagemErro: string
}

import { Icon } from 'bold-ui'
import { useWidth } from 'hooks/useMeasure'
import React from 'react'
import { isEmpty } from 'util/validation/Util'

import { SeriesType } from './model'

export interface SeriesLabelProps<XDomain> {
  seriesType: SeriesType
  x?: number
  y?: number
  color?: string
  value?: any
  seriesName?: string
  outlierValue?: any
}

export function SeriesLabel<XDomain>(props: SeriesLabelProps<XDomain>) {
  const { seriesType, x, y, color, value, outlierValue } = props
  const [refLabel, widthLabel] = useWidth()

  switch (seriesType) {
    case SeriesType.Line:
      const iconOffset = getOutlierIconOffset(widthLabel)

      return !isEmpty(outlierValue?.value) ? (
        <>
          <Icon icon='angleUp' scale={1} x={x - iconOffset} y={y - 25} style={{ fill: 'red' }} />
          <text ref={refLabel} x={x} y={y} dy={-9} style={{ fill: color }}>
            {outlierValue.value}
          </text>
        </>
      ) : (
        <text x={x} y={y} dy={-9} style={{ fill: color }} textAnchor='middle'>
          {value}
        </text>
      )
  }
}

const getOutlierIconOffset = (widthLabel: number) => {
  const minOffset = 20
  const labelWidthOffset = widthLabel + 5
  return Math.min(labelWidthOffset, minOffset)
}

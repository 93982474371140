import { AldeiaSelectFieldModel } from 'components/form/field/select/AldeiaSelectField'
import { DseiSelectFieldModel } from 'components/form/field/select/DseiSelectField'
import { PoloBaseSelectFieldModel } from 'components/form/field/select/PoloBaseSelectField'
import createDecorator, { Calculation } from 'final-form-calculate'
import { MetaRoot } from 'util/metaPath'

import CidadaoPesquisarFormModel from './types/model-pesquisarCidadao'

const createEtniaCalculator = (meta: MetaRoot<CidadaoPesquisarFormModel>): Calculation[] => [
  {
    field: meta.cidadaoAldeado.naoIndigena.absolutePath(),
    updates: {
      [meta.cidadaoAldeado.etnia.absolutePath()]: (naoIndigena: boolean, allValues: CidadaoPesquisarFormModel) =>
        naoIndigena ? null : allValues.cidadaoAldeado?.etnia,
    },
  },
]

const createEnderecoIndigenaCalculator = (meta: MetaRoot<CidadaoPesquisarFormModel>): Calculation[] => [
  {
    field: meta.cidadaoAldeado.dsei.absolutePath(),
    updates: {
      [meta.cidadaoAldeado.poloBase.absolutePath()]: (
        dsei: DseiSelectFieldModel,
        allValues: CidadaoPesquisarFormModel
      ) => (dsei?.id !== allValues?.cidadaoAldeado?.poloBase?.dsei?.id ? null : allValues?.cidadaoAldeado?.poloBase),
    },
  },
  {
    field: meta.cidadaoAldeado.poloBase.absolutePath(),
    updates: {
      [meta.cidadaoAldeado.dsei.absolutePath()]: (
        poloBase: PoloBaseSelectFieldModel,
        allValues: CidadaoPesquisarFormModel
      ) => (!!poloBase?.dsei ? poloBase.dsei : allValues?.cidadaoAldeado?.dsei),
      [meta.cidadaoAldeado.aldeia.absolutePath()]: (
        poloBase: PoloBaseSelectFieldModel,
        allValues: CidadaoPesquisarFormModel
      ) =>
        poloBase?.id !== allValues?.cidadaoAldeado?.aldeia?.poloBase?.id ? null : allValues?.cidadaoAldeado?.aldeia,
    },
  },
  {
    field: meta.cidadaoAldeado.aldeia.absolutePath(),
    updates: {
      [meta.cidadaoAldeado.poloBase.absolutePath()]: (
        aldeia: AldeiaSelectFieldModel,
        allValues: CidadaoPesquisarFormModel
      ) => (!!aldeia?.poloBase ? aldeia.poloBase : allValues?.cidadaoAldeado?.poloBase),
      [meta.cidadaoAldeado.dsei.absolutePath()]: (
        aldeia: AldeiaSelectFieldModel,
        allValues: CidadaoPesquisarFormModel
      ) => (!!aldeia?.poloBase?.dsei ? aldeia.poloBase.dsei : allValues?.cidadaoAldeado?.dsei),
    },
  },
]

export const createCidadaoPesquisarCalculator = (meta: MetaRoot<CidadaoPesquisarFormModel>) =>
  createDecorator(...createEtniaCalculator(meta), ...createEnderecoIndigenaCalculator(meta))

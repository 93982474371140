import { Button, HFlow } from 'bold-ui'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import { useUltimoArquivoProntoEmProcessamentoQuery } from 'graphql/hooks.generated'
import { ArquivoIdentificadorEnum } from 'graphql/types.generated'
import useAtmosphere from 'hooks/useAtmosphere'
import { useServerTime } from 'hooks/useServerTime'
import { useLocalStorageState } from 'hooks/useStorage'
import React, { useEffect } from 'react'
import { Route, Switch, useHistory, useRouteMatch } from 'react-router'
import { downloadFile } from 'util/downloadFile'
import { RelatoriosRecentesModal } from 'view/arquivo/RelatoriosRecentesModal'

import { formatRelatorioFileName } from './downloadsArquivo'
import { ArquivoFormato, MensagemErroArquivoMaisRecente, relatoriosRecentesRecord } from './model-arquivo'
import UltimoRelatorioStatus from './UltimoRelatorioStatus'

export const RELATORIOS_RECENTES_URL = '/relatorios-recentes'

export interface RelatoriosRecentesProps {
  identificadorModuloArquivo: ArquivoIdentificadorEnum
  shouldRefetch: boolean
  setShouldRefetch: (boolean) => void
  setStatusRelatorio?: (ArquivoStatusEnum) => void
}

export function RelatoriosRecentes(props: RelatoriosRecentesProps) {
  const { identificadorModuloArquivo, shouldRefetch, setShouldRefetch, setStatusRelatorio } = props
  const { acesso, profissional } = useAcessoLotacaoOrEstagio()
  const { getServerTimeNow } = useServerTime()
  const now = getServerTimeNow()
  const match = useRouteMatch()
  const history = useHistory()
  const { atmospherePath, fileName } = relatoriosRecentesRecord[identificadorModuloArquivo]
  const usuarioId = profissional?.usuario?.id
  const fileNameFormatted = formatRelatorioFileName(fileName, now)

  const [mensagemErroArquivoMaisRecente, setMensagemErroArquivoMaisRecente] = useLocalStorageState<
    MensagemErroArquivoMaisRecente
  >(`${acesso.id}/${atmospherePath}/erro-relatorio`)

  const {
    data: dataUltimoArquivo,
    loading: loadingUltimoArquivo,
    refetch: refetchUltimoArquivo,
  } = useUltimoArquivoProntoEmProcessamentoQuery({
    variables: {
      input: {
        arquivoIdentificadorEnum: identificadorModuloArquivo,
      },
    },
    onCompleted: (data) => setStatusRelatorio && setStatusRelatorio(data?.ultimoArquivoProntoEmProcessamento?.status),
  })

  useEffect(() => {
    if (shouldRefetch) {
      refetchUltimoArquivo()
    }
  }, [shouldRefetch, refetchUltimoArquivo])

  useAtmosphere<ArquivoFormato>({
    topic: `${atmospherePath}/${usuarioId}`,
    onMessage: (responseBody) => {
      if (responseBody.arquivo !== null) {
        const arquivo = new Uint8Array(responseBody.arquivo)
        downloadFile(new Blob([arquivo]), `${fileNameFormatted}.${responseBody.formato.toLocaleLowerCase()}`)
      }
      setMensagemErroArquivoMaisRecente({ arquivoId: responseBody.arquivoId, mensagemErro: responseBody.mensagem })
      refetchUltimoArquivo()
      setShouldRefetch(false)
    },
  })

  const handleRelatorioClick = () => {
    history.push(`${match.url}${RELATORIOS_RECENTES_URL}`)
  }

  return (
    <>
      <HFlow alignItems='center'>
        <UltimoRelatorioStatus data={dataUltimoArquivo} loading={loadingUltimoArquivo} />
        <Button kind='primary' skin='outline' size='small' onClick={handleRelatorioClick}>
          Ver relatórios recentes
        </Button>
      </HFlow>
      <Switch>
        <Route exact path={`${match.url}${RELATORIOS_RECENTES_URL}`}>
          <RelatoriosRecentesModal
            identificadorModuloArquivo={identificadorModuloArquivo}
            fileName={fileNameFormatted}
            mensagemErroArquivoMaisRecente={mensagemErroArquivoMaisRecente}
            setShouldRefetch={setShouldRefetch}
          />
        </Route>
      </Switch>
    </>
  )
}

/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, Grid, Heading } from 'bold-ui'
import { PageContainer } from 'components/layout/PageContainer'
import { ExternalUserHeader } from 'components/user/ExternalUserHeader'
import { StatusVideochamadaEnum } from 'graphql/types.generated'
import { Fragment } from 'react'

import { CameraPreview } from './componentes/CameraPreview'
import { SalaEsperaVideochamadaUsuariosExternosForm } from './componentes/SalaEsperaVideochamadaUsuariosExternosForm'
import { StatusSolicitacaoEntradaVideochamadaEnum } from './model-videochamada'

interface SalaEsperaVideochamadaUsuariosExternosViewProps {
  nomeProfissional: string
  cboProfissional: string
  audioEnabled: boolean
  videoEnabled: boolean
  statusVideochamada: StatusVideochamadaEnum
  setAudioEnabled(value: boolean): void
  setVideoEnabled(value: boolean): void
  statusSolicitacao: StatusSolicitacaoEntradaVideochamadaEnum
  handleEntrarButtonClick(): void
}

export function SalaEsperaVideochamadaUsuariosExternosView(props: SalaEsperaVideochamadaUsuariosExternosViewProps) {
  const {
    nomeProfissional,
    cboProfissional,
    audioEnabled,
    videoEnabled,
    setAudioEnabled,
    setVideoEnabled,
    statusVideochamada,
    statusSolicitacao,
    handleEntrarButtonClick,
  } = props

  const styles = createStyles()

  return (
    <Fragment>
      <ExternalUserHeader />
      <PageContainer>
        <Grid gap={6} gapVertical={1}>
          <Cell size={12}>
            <Heading level={1} style={styles.heading}>
              Videochamada e-SUS APS
            </Heading>
          </Cell>
          <Cell lg={6} sm={12}>
            <Grid>
              <Cell size={12}>
                <Heading level={2} style={styles.headingName}>
                  Chamada com {nomeProfissional}
                  {cboProfissional ? ` | ${cboProfissional}` : ''}
                </Heading>
              </Cell>
              <Cell size={12}>
                <SalaEsperaVideochamadaUsuariosExternosForm
                  nomeProfissional={nomeProfissional}
                  statusVideochamada={statusVideochamada}
                  statusSolicitacao={statusSolicitacao}
                  handleEntrarButtonClick={handleEntrarButtonClick}
                />
              </Cell>
            </Grid>
          </Cell>
          <Cell lg={5} sm={12} xs={12}>
            <CameraPreview
              audioEnabled={audioEnabled}
              videoEnabled={videoEnabled}
              setAudioEnabled={setAudioEnabled}
              setVideoEnabled={setVideoEnabled}
            />
          </Cell>
        </Grid>
      </PageContainer>
    </Fragment>
  )
}

const createStyles = () => ({
  heading: css`
    margin-top: 2rem;
    padding-bottom: 0;
  `,
  headingName: css`
    padding: 0;
    margin-bottom: 1rem;
    margin-top: 1rem;
  `,
})

/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Alert, Cell, FormControl, Grid, Heading, Text, VFlow } from 'bold-ui'
import CheckPermission from 'components/auth/CheckPermission'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import { isCiap } from 'components/form/field/select/CiapCidSelectField/CiapCidSelectField'
import { EquipeResponsavelField } from 'components/form/field/select/EquipeResponsavelSelectField/EquipeResponsavelField'
import { MicroareaSelectField } from 'components/form/field/select/MicroareaSelectField/MicroareaSelectField'
import { FormApi } from 'final-form'
import { useFirebase } from 'hooks/firebase/useFirebase'
import { FormRenderProps } from 'react-final-form'
import Permissions from 'types/Permissions'
import { metaPath } from 'util/metaPath'
import { isEmpty } from 'util/validation/Util'

import { AcompanhamentoCondicaoSaudeTabs } from './AcompanhamentoCondicaoSaudeTabs'
import { AcompanhamentoCondicaoSaudeFormModel } from './model-acompanhamentoCondicaoSaude'
import { SearchFooter } from './SearchFooter'

const path = metaPath<AcompanhamentoCondicaoSaudeFormModel>()

const onClearField = (form: FormApi<AcompanhamentoCondicaoSaudeFormModel>, field: string) => {
  form.change(field, undefined)
  form.blur(field)
}

export function AcompanhamentoCondicaoSaudeForm(props: FormRenderProps<AcompanhamentoCondicaoSaudeFormModel>) {
  const { values, form, handleSubmit } = props
  const { acesso, hasAuthorization } = useAcessoLotacaoOrEstagio()
  const { analytics } = useFirebase()

  const isCoordenador = hasAuthorization(Permissions.acompanhamentos.condicoesDeSaude.todasAsEquipes)

  const handleEquipeResponsavelChange = () => {
    form.change(path.microareas.absolutePath(), null)
  }

  form.change('unidadeResponsavelId', acesso?.unidadeSaude?.id)
  form.change('equipeResponsavelId', acesso?.equipe?.id)
  form.change('isCoordenador', isCoordenador)

  const handleOnSubmit = () => {
    const hasPeriodoUltimoAtendimento =
      values?.geral?.periodoUltimoAtendimento?.startDate || values?.geral?.periodoUltimoAtendimento?.endDate

    const hasCiap = !isEmpty(values?.geral?.problemasCondicoes?.find((item) => isCiap(item)))
    const hasCid = !isEmpty(values?.geral?.problemasCondicoes?.find((item) => !isCiap(item)))

    !isEmpty(values.geral?.isDiabetesCheck) && analytics.logEvent('buscar_acomp_condicoes_saude_with_diabetes')
    !isEmpty(values.geral?.isHipertensaoCheck) && analytics.logEvent('buscar_acomp_condicoes_saude_with_hipertensao')
    !isEmpty(values.geral?.isObesidadeCheck) && analytics.logEvent('buscar_acomp_condicoes_saude_with_obesidade')
    !isEmpty(values.geral?.isGravidezCheck) && analytics.logEvent('buscar_acomp_condicoes_saude_with_gravidez')
    !isEmpty(values.geral?.grupoCondicao) && analytics.logEvent('buscar_acomp_condicoes_saude_with_grupo_condicao')
    hasCiap && analytics.logEvent('buscar_acomp_condicoes_saude_with_ciap')
    hasCid && analytics.logEvent('buscar_acomp_condicoes_saude_with_cid')
    hasPeriodoUltimoAtendimento && analytics.logEvent('buscar_acomp_condicoes_saude_with_periodo_ultimo_atend')

    handleSubmit()
  }

  return (
    <VFlow vSpacing={2}>
      <Grid wrap>
        <Cell size={3} style={styles.cell}>
          <FormControl label='Unidade responsável'>
            <Text>{acesso?.unidadeSaude?.nome.titleCase()}</Text>
          </FormControl>
        </Cell>
        <Cell size={isCoordenador ? 4 : 3} style={styles.cell}>
          <EquipeResponsavelField
            name={path.equipeResponsavel}
            equipe={acesso?.equipe}
            unidadeSaudeId={values?.unidadeResponsavelId}
            hasAccessToTodasEquipes={isCoordenador}
            placeholder='Selecione uma equipe'
            required={isCoordenador}
            onClear={() => onClearField(form, path.equipeResponsavel.absolutePath())}
            onChange={handleEquipeResponsavelChange}
          />
        </Cell>
        <Cell size={3} style={styles.cell}>
          <MicroareaSelectField
            name={path.microareas}
            label='Microárea'
            metaEquipe={path.equipeResponsavel}
            placeholder='Todas as microáreas'
            disabled={isCoordenador && !values?.equipeResponsavel}
          />
        </Cell>
        <CheckPermission permission={Permissions.acompanhamentos.condicoesDeSaude.problemasECondicoes}>
          <Cell size={12}>
            <Heading level={2}>Problemas e condições</Heading>
            <Alert
              inline
              type='info'
              styles={{
                wrapper: css`
                  margin-top: 0.5rem;
                `,
                container: css`
                  margin-right: 4.5rem;
                `,
              }}
            >
              A busca retorna apenas cidadãos que possuem todas as condições selecionadas e ativas na lista de problemas
              ou avaliadas no SOAP e fichas de atendimento individual.
            </Alert>
          </Cell>
        </CheckPermission>
        <AcompanhamentoCondicaoSaudeTabs path={path} formValues={values} onClick={form.reset} onChange={form.change} />
      </Grid>
      <SearchFooter
        handleSubmit={handleOnSubmit}
        onCleanFilters={form.reset}
        isCoordenador={isCoordenador}
        equipeResponsavelId={values.equipeResponsavelId}
      />
    </VFlow>
  )
}

const styles = {
  cell: css`
    margin-top: 1rem;
  `,
}

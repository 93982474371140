/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, Grid, Heading, HFlow, Modal, ModalBody, ModalFooter, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { useErrorHandler } from 'components/error'
import {
  CidadaoAtendimentoSelectField,
  CidadaoSelectField,
  Form,
  FormRenderProps,
  MonthField,
  NumberField,
  SubmitButton,
  TextField,
} from 'components/form'
import { RendaFamiliarSelectField } from 'components/form/field/select/RendaFamiliarSelectField'
import { FormApi } from 'final-form'
import { useProcessarUltimaFichaCidadaosTerritorioMutation } from 'graphql/hooks.generated'
import { useServerTime } from 'hooks/useServerTime'
import { useCallback, useMemo } from 'react'
import { isUndefinedOrNull } from 'util/checks'
import { metaPath } from 'util/metaPath'
import { CadastrarEditarFamiliaFormModel } from 'view/cadastro-imovel/model-cadastroImovel'
import { createFamiliaValidator } from 'view/cadastro-imovel/validator-cadastroImovel'

interface CadastrarFamiliaModalProps {
  open: boolean
  initialValues?: CadastrarEditarFamiliaFormModel
  hasPermission: boolean
  cidadaoCallBackUrl: string
  imovelId?: ID
  dataColeta: string
  onClose(): void
  onCadastrarFamilia(formValues: CadastrarEditarFamiliaFormModel, formApi: FormApi<object>): void
  onCadastroFamiliaSaveCache(form: CadastrarEditarFamiliaFormModel): void
  isCidadaoPresenteFamilias(cidadaoId: ID): boolean
  onEditFamilia(values: CadastrarEditarFamiliaFormModel): void
}
const meta = metaPath<CadastrarEditarFamiliaFormModel>()

export function CadastrarEditarFamiliaModal(props: CadastrarFamiliaModalProps) {
  const {
    open,
    initialValues,
    hasPermission,
    cidadaoCallBackUrl,
    dataColeta,
    onClose,
    onCadastrarFamilia,
    onCadastroFamiliaSaveCache,
    onEditFamilia,
    isCidadaoPresenteFamilias,
  } = props

  const { getServerTimeNow } = useServerTime()
  const handleRejection = useErrorHandler()

  const handleCloseModal = (resetApi: () => void) => {
    setTimeout(resetApi)
    onClose()
  }
  const [processarCidadaosTerritorio] = useProcessarUltimaFichaCidadaosTerritorioMutation()
  const alert = useAlert()

  const handleSubmit = (values: CadastrarEditarFamiliaFormModel, formApi: FormApi) => {
    processarCidadaosTerritorio({
      variables: {
        input: [values?.responsavel?.id],
      },
    })
      .then(({ data }) => {
        if (data?.processarCidadaosTerritorio) {
          alert('info', 'Cidadão automaticamente atualizado com ficha recente.')
        }
      })
      .catch(handleRejection)

    if (!!values?._id) {
      onEditFamilia(values)
    } else {
      onCadastrarFamilia(values, formApi)
    }

    handleCloseModal(formApi.reset)
  }

  const isButtonDisabled = useCallback(
    (responsavelId: ID) => {
      return isUndefinedOrNull(responsavelId) || isCidadaoPresenteFamilias(responsavelId)
    },
    [isCidadaoPresenteFamilias]
  )
  const serverTime = getServerTimeNow()
  const currentMonthYearServer = useMemo(() => ({ month: serverTime.getMonth(), year: serverTime.getFullYear() }), [
    serverTime,
  ])

  const renderForm = ({ values, form }: FormRenderProps<CadastrarEditarFamiliaFormModel>) => {
    return (
      <Modal open={open} onClose={() => handleCloseModal(form.reset)} size='auto' closeOnBackdropClick={false}>
        <ModalBody>
          <VFlow vSpacing={3}>
            <Heading level={1}>Adicionar família ao imóvel</Heading>
            <Grid
              style={css`
                width: 37rem;
                margin-bottom: 5rem;
              `}
            >
              <Cell size={12}>
                <TextField name={meta.numeroProntuarioFamiliar} label='Nº do prontuário' maxLength={30} />
              </Cell>
              <Cell size={4}>
                <RendaFamiliarSelectField name={meta.rendaFamiliar} label='Renda familiar' />
              </Cell>
              <Cell size={5}>
                <MonthField name={meta.resideDesdeMonthYear} label='Reside desde' maxMonth={currentMonthYearServer} />
              </Cell>
              <Cell size={3} />
              <Cell size={5}>
                <NumberField name={meta.numeroMembros} label='Número de membros' maxLength={2} />
              </Cell>
              <Cell size={7} />
              <Cell size={12}>
                {hasPermission ? (
                  <CidadaoAtendimentoSelectField
                    name={meta.responsavel}
                    ativo
                    obito={false}
                    label='Responsável familiar'
                    onBeforeRedirect={() => onCadastroFamiliaSaveCache(values)}
                    verificarPresenteNaListaAtendimento={false}
                    addCidadaoCallbackUrl={cidadaoCallBackUrl}
                    required
                  />
                ) : (
                  <CidadaoSelectField name={meta.responsavel} label='Responsável familiar' required ativo />
                )}
              </Cell>
            </Grid>
          </VFlow>
        </ModalBody>
        <ModalFooter>
          <HFlow justifyContent='flex-end'>
            <Button onClick={() => handleCloseModal(form.reset)} size='medium'>
              Cancelar
            </Button>
            <SubmitButton
              kind='primary'
              size='medium'
              handleSubmit={() => form.submit()}
              disabled={isButtonDisabled(values?.responsavel?.id)}
            >
              Salvar
            </SubmitButton>
          </HFlow>
        </ModalFooter>
      </Modal>
    )
  }

  return (
    <Form<CadastrarEditarFamiliaFormModel>
      render={renderForm}
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validate={createFamiliaValidator(isCidadaoPresenteFamilias, dataColeta)}
    />
  )
}

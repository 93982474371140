import { Omit } from 'bold-ui'
import { SelectField, SelectFieldProps } from 'components/form/final-form'
import { TipoMaterialParedeEnum } from 'graphql/types.generated'
import React from 'react'

import { tipoMaterialParedeEnumRecord } from './condicoes-moradia-model'

export interface TipoMaterialParedeSelectFieldProps
  extends Omit<SelectFieldProps<TipoMaterialParedeEnum>, 'items' | 'itemToString'> {}

export function TipoMaterialParedeSelectField(props: TipoMaterialParedeSelectFieldProps) {
  return <SelectField<TipoMaterialParedeEnum> items={items} itemToString={itemToString} {...props} />
}

const items = Object.values(TipoMaterialParedeEnum)
const itemToString = (item: TipoMaterialParedeEnum) => item && tipoMaterialParedeEnumRecord[item]

import { RacaCorSelectModel } from 'components/form/field/select/RacaCorSelectField'
import createCalculator from 'final-form-calculate'
import { NacionalidadeEnum, RacaCorDbEnum, TipoLocalNascimentoEnum } from 'graphql/types.generated'
import { metaPath } from 'util/metaPath'

import CidadaoFormModel from '../types/model-cidadao'

const path = metaPath<CidadaoFormModel>()

export const cidadaoCalculator = createCalculator(
  {
    field: path.racaCor.absolutePath(),
    updates: {
      [path.etnia.absolutePath()]: (value: RacaCorSelectModel, values: CidadaoFormModel) => {
        return value?.value !== RacaCorDbEnum.INDIGENA ? null : values.etnia
      },
    },
  },
  {
    field: path.nacionalidade.absolutePath(),
    updates: {
      [path.municipioNascimento.absolutePath()]: (value: NacionalidadeEnum, values: CidadaoFormModel) => {
        return value !== NacionalidadeEnum.BRASILEIRA ? null : values.municipioNascimento
      },
      [path.cidadaoAldeadoInput.aldeiaNascimento.absolutePath()]: (
        value: NacionalidadeEnum,
        values: CidadaoFormModel
      ) => {
        return value !== NacionalidadeEnum.BRASILEIRA ? null : values.cidadaoAldeadoInput?.aldeiaNascimento
      },
      [path.cidadaoAldeadoInput.localOcorrencia.absolutePath()]: (
        value: NacionalidadeEnum,
        values: CidadaoFormModel
      ) => {
        return value !== NacionalidadeEnum.BRASILEIRA ? null : values.cidadaoAldeadoInput?.localOcorrencia
      },
      [path.portariaNaturalizacao.absolutePath()]: (value: NacionalidadeEnum, values: CidadaoFormModel) => {
        return value !== NacionalidadeEnum.NATURALIZADA ? null : values.portariaNaturalizacao
      },
      [path.dataNaturalizacao.absolutePath()]: (value: NacionalidadeEnum, values: CidadaoFormModel) => {
        return value !== NacionalidadeEnum.NATURALIZADA ? null : values.dataNaturalizacao
      },
      [path.paisNascimento.absolutePath()]: (value: NacionalidadeEnum, values: CidadaoFormModel) => {
        if (value === NacionalidadeEnum.ESTRANGEIRA) {
          return values.paisNascimento
        } else {
          return null
        }
      },
      [path.dataEntradaPais.absolutePath()]: (value: NacionalidadeEnum, values: CidadaoFormModel) => {
        return value !== NacionalidadeEnum.ESTRANGEIRA ? null : values.dataEntradaPais
      },
    },
  },
  {
    field: path.cidadaoFaleceu.absolutePath(),
    updates: {
      [path.dataObito.absolutePath()]: (value: boolean, values: CidadaoFormModel) => {
        return !value ? null : values.dataObito
      },
      [path.numeroDeclaracaoObito.absolutePath()]: (value: boolean, values: CidadaoFormModel) => {
        return !value ? null : values.numeroDeclaracaoObito
      },
      [path.vinculacao.absolutePath()]: (value: boolean, values: CidadaoFormModel) => {
        return value ? null : values.vinculacao
      },
    },
  },
  {
    field: path.tipoLocalNascimento.absolutePath(),
    updates: {
      [path.cidadaoAldeadoInput.aldeiaNascimento.absolutePath()]: (
        tipoLocalNascimento: TipoLocalNascimentoEnum,
        allValues: CidadaoFormModel
      ) =>
        tipoLocalNascimento !== TipoLocalNascimentoEnum.ALDEIA ? null : allValues.cidadaoAldeadoInput?.aldeiaNascimento,
      [path.cidadaoAldeadoInput.localOcorrencia.absolutePath()]: (
        tipoLocalNascimento: TipoLocalNascimentoEnum,
        allValues: CidadaoFormModel
      ) =>
        tipoLocalNascimento !== TipoLocalNascimentoEnum.ALDEIA ? null : allValues.cidadaoAldeadoInput?.localOcorrencia,
      [path.municipioNascimento.absolutePath()]: (
        tipoLocalNascimento: TipoLocalNascimentoEnum,
        allValues: CidadaoFormModel
      ) =>
        allValues.isCidadaoAldeado && tipoLocalNascimento !== TipoLocalNascimentoEnum.MUNICIPIO
          ? null
          : allValues.municipioNascimento,
    },
  }
)

import { useOnBeforeUnload } from 'hooks/useOnBeforeUnload'
import { useOnWindowFocusOut } from 'hooks/useOnWindowFocusOut'
import { useLocalStorageState } from 'hooks/useStorage'
import { useUrlQueryParams } from 'hooks/useUrlQueryParams'

import { VisualizacaoTerritorialCacheModel } from '../model'

interface VisualizacaoQueryParams {
  redirect?: string
}

export const useVisualizacaoCache = (acessoId: ID) => {
  const { redirect } = useUrlQueryParams<VisualizacaoQueryParams>()

  const [visualizacaoCacheState, setVisualizacaoCacheState, deleteVisualizacaoCacheState] = useLocalStorageState<
    VisualizacaoTerritorialCacheModel
  >(`${acessoId}/visualizacaoTerritorial/`)

  const shouldUseCache = !!visualizacaoCacheState

  useOnBeforeUnload(() => {
    deleteVisualizacaoCacheState()
  })

  useOnWindowFocusOut(() => {
    if (shouldUseCache && !redirect) deleteVisualizacaoCacheState()
  })

  return {
    visualizacaoCacheState,
    setVisualizacaoCacheState,
    deleteVisualizacaoCacheState,
    shouldUseCache,
  }
}

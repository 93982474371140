import { msg } from '../messages'

const REGEX = '^[A-Za-z0-9áéíóúÁÉÍÓÚçÇâêôÂÊÔõãÕÃäöüÄÖÜàÀ ]*$'

export function alfaNumerico(value: string) {
  if (!new RegExp(REGEX).test(value)) {
    return msg('match', value)
  }
  return null
}

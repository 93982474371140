import { Cbo } from 'graphql/types.generated'

import {
  EquipeLotacaoResponsavel,
  LotacaoResponsavelFormModel,
  ProfissionalLotacaoResponsavel,
  UnidadeSaudeLotacaoResponsavel,
} from '../model-lotacaoResponsavel'

export const getLotacaoResponsavel = (
  profissional: ProfissionalLotacaoResponsavel,
  cbo: Cbo,
  equipe: EquipeLotacaoResponsavel,
  unidadeSaude: UnidadeSaudeLotacaoResponsavel,
  lotacaoId?: ID
): LotacaoResponsavelFormModel => ({
  lotacao: {
    id: lotacaoId,
    profissional: profissional,
    cbo: cbo,
    equipe: equipe,
    unidadeSaude: unidadeSaude,
  },
})

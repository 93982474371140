import qs from 'qs'
import { useMemo } from 'react'
import { useLocation } from 'react-router'

interface UseUrlQueryParamsProps {
  parameterLimit?: number
}

export function useUrlQueryParams<T extends Partial<Record<keyof T, string>>>(options?: UseUrlQueryParamsProps): T {
  const { search } = useLocation()

  return useMemo<T>(
    () =>
      qs.parse(search, {
        ignoreQueryPrefix: true,
        parameterLimit: options?.parameterLimit,
      }),
    [options, search]
  )
}

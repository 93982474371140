import { DateRange } from 'bold-ui'
import { endOfDay, startOfDay, subDays } from 'date-fns'
import { AtendimentosQueryInput, StatusAtendimento } from 'graphql/types.generated'
import { isUndefinedOrNull } from 'util/checks'

import {
  AtendimentosRegistroTardioFilterModel,
  REGISTRO_TARDIO_DEFAULT_DATE_DIFF,
  REGISTRO_TARDIO_INDIGENA_DATE_DIFF,
} from './filter/useListaRegistroTardioFilterDefault'

export const convertListaRegistroTardioStateToInput = (
  state: AtendimentosRegistroTardioFilterModel,
  periodoDefault: DateRange
): AtendimentosQueryInput => {
  const { dataInicio, dataFim } = determineRegistroTardioDateRange(state, periodoDefault)

  return {
    query: state.filter.query,
    ordem: state.filter.ordem,
    somenteMeusAtendimentos: state.filter.somenteMeusAtendimentos,
    statusAtendimento:
      !state.filter.statusAtendimento || state.filter.statusAtendimento.length === 0
        ? [StatusAtendimento.EM_ATENDIMENTO, StatusAtendimento.AGUARDANDO_ATENDIMENTO]
        : state.filter.statusAtendimento || [],
    dataInicio,
    dataFim,
    responsaveisIds: state.filter.responsaveis?.map((value) => value.id),
    isRegistroTardio: true,
    pageParams: state.pageParams,
  }
}

export const determineRegistroTardioDateRange = (
  state: AtendimentosRegistroTardioFilterModel,
  periodoDefault: DateRange
) => {
  if (state.filter.isSomenteNaoFinalizados) {
    return {
      dataInicio: periodoDefault?.startDate.getTime(),
      dataFim: subDays(periodoDefault?.endDate, 1).getTime(),
    }
  }

  return {
    dataInicio: getAdjustedDateInMillis(startOfDay, state.filter.periodo?.startDate),
    dataFim: getAdjustedDateInMillis(endOfDay, state.filter.periodo?.endDate),
  }
}

const getAdjustedDateInMillis = (adjustDateFunc: (date: Date) => Date, date?: Date): number | null => {
  return !isUndefinedOrNull(date) ? adjustDateFunc(new Date(date)).getTime() : null
}

export const getMinDate = (serverTime, canRegistroIndigena) => {
  return subDays(
    serverTime,
    canRegistroIndigena ? REGISTRO_TARDIO_INDIGENA_DATE_DIFF : REGISTRO_TARDIO_DEFAULT_DATE_DIFF
  )
}

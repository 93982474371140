import { SimNaoEnum } from 'components/form'
import { IvcfPerguntaEnum } from 'graphql/types.generated'
import { createValidator, empty, required } from 'util/validation'

import { IvcfModel } from '../model-ivcf'

export const ivcfValidator = createValidator<IvcfModel>({
  ALCANCE_PREENSAO_PINCA_PERGUNTA_1: required,
  ALCANCE_PREENSAO_PINCA_PERGUNTA_2: required,
  AUDICAO_PERGUNTA_1: required,
  AVD_BASICA_PERGUNTA_1: required,
  AVD_INSTRUMENTAL_PERGUNTA_1: required,
  AVD_INSTRUMENTAL_PERGUNTA_2: required,
  AVD_INSTRUMENTAL_PERGUNTA_3: required,
  CAPACIDADE_PERGUNTA_1: required,
  CAPACIDADE_PERGUNTA_2: required,
  CAPACIDADE_PERGUNTA_3: required,
  CAPACIDADE_PERGUNTA_4: required,
  CAPACIDADE_PERGUNTA_5: required,
  COGNICAO_PERGUNTA_1: required,
  COGNICAO_PERGUNTA_3: (value, allValues) =>
    allValues[IvcfPerguntaEnum.COGNICAO_PERGUNTA_1] === SimNaoEnum.SIM ? required(value) : empty(value),
  COGNICAO_PERGUNTA_2: (value, allValues) =>
    allValues[IvcfPerguntaEnum.COGNICAO_PERGUNTA_1] === SimNaoEnum.SIM ? required(value) : empty(value),
  COMORBIDADE_PERGUNTA_1: required,
  COMORBIDADE_PERGUNTA_2: required,
  COMORBIDADE_PERGUNTA_3: required,
  CONTINENCIA_PERGUNTA_1: required,
  HUMOR_PERGUNTA_1: required,
  HUMOR_PERGUNTA_2: required,
  MARCHA_PERGUNTA_1: required,
  MARCHA_PERGUNTA_2: required,
  PERCEPCAO_SAUDE_PERGUNTA_1: required,
  VISAO_PERGUNTA_1: required,
})

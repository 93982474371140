import { StatusImportacaoBolsaFamiliaEnum } from 'graphql/types.generated'
import useAtmosphere from 'hooks/useAtmosphere'
import { isUndefinedOrNull } from 'util/checks'

interface ImportacaoBolsaFamiliaListenerProps {
  municipioId: ID
  onMessage: (messageBody: StatusImportacaoBolsaFamiliaEnum) => void
}

export function ImportacaoBolsaFamiliaListener(props: ImportacaoBolsaFamiliaListenerProps) {
  const { municipioId, onMessage: handleMessage } = props

  const topic = isUndefinedOrNull(municipioId)
    ? 'importacao-bolsa-familia/adm-geral'
    : `importacao-bolsa-familia/${municipioId}`

  useAtmosphere({ topic: topic, onMessage: handleMessage })

  return null
}

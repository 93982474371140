import { DropdownItem, HFlow, Icon } from 'bold-ui'
import { DropdownButton } from 'components/dropdown'
import React, { Fragment } from 'react'
import { Link, useRouteMatch } from 'react-router-dom'

export const RegistrarAtividadeColetivaButton = () => {
  const match = useRouteMatch()

  return (
    <Fragment>
      <DropdownButton text='Registrar atividade coletiva' icon='angleDown' skin='default' kind='primary' size='large'>
        <DropdownItem component={Link} to={`${match.url}/registrar-reuniao`}>
          <HFlow alignItems='center' hSpacing={0.5}>
            <Icon icon='multipleUsersOutline' />
            Reunião de equipe
          </HFlow>
        </DropdownItem>

        <DropdownItem component={Link} to={`${match.url}/registrar-atividade-saude`}>
          <HFlow alignItems='center' hSpacing={0.5}>
            <Icon icon='heartRateFilled' />
            Atividade em saúde
          </HFlow>
        </DropdownItem>
      </DropdownButton>
    </Fragment>
  )
}

import { CidadaoTerritorioBasico, Familia, TipoDomicilioEnum, TipoImovelEnum } from 'graphql/types.generated'
import { isUndefinedOrNull } from 'util/checks'

import { domicilioTipoImovelRecord, TipoImovelListModel, tipoImovelRecord } from '../model'

export const formatNumImovelCidadao = (numImoveis: number, numCidadaos: number) => {
  return `${numImoveis ?? 0} imóve${numImoveis === 1 ? 'l' : 'is'} | ${'cidadão'.pluralizeAndConcatValue(
    numCidadaos ?? 0
  )}`
}

export const getTipoImovelIcon = (tipoImovel: TipoImovelEnum, tipoDomicilioId: string): TipoImovelListModel => {
  if (!isUndefinedOrNull(tipoImovel)) {
    if (tipoImovel !== TipoImovelEnum.DOMICILIO) {
      return tipoImovelRecord[tipoImovel]
    }
    if (!isUndefinedOrNull(tipoDomicilioId)) {
      return domicilioTipoImovelRecord[tipoDomicilioId]
    } else {
      return domicilioTipoImovelRecord[TipoDomicilioEnum.CASA]
    }
  }
  return tipoImovelRecord[TipoImovelEnum.OUTROS]
}

export const getResponsavelDependentesFamilia = (familia: Familia) => {
  return familia.cidadaos.reduce(
    (acc: { responsavel: CidadaoTerritorioBasico; dependentes: CidadaoTerritorioBasico[] }, cidadao) => {
      if (cidadao.cpf === familia.cpfCnsResponsavel || cidadao.cns === familia.cpfCnsResponsavel) {
        acc.responsavel = cidadao
      } else {
        acc.dependentes.push(cidadao)
      }

      return acc
    },
    { responsavel: null, dependentes: [] }
  )
}

import { Cell, Grid } from 'bold-ui'
import { DateField, NumberField, TextField } from 'components/form'
import moment from 'moment'
import React from 'react'
import { MetaPath } from 'util/metaPath'
import { cns, cpf, createValidator, dataNasc, ErrorObject, nome } from 'util/validation'

export const validate = createValidator<ResponsavelFieldGroupModel>(
  {
    nomeResponsavel: [nome()],
    dataNascimento: [dataNasc],
  },
  (values: ResponsavelFieldGroupModel) => {
    const errors: ErrorObject<ResponsavelFieldGroupModel> = {}
    const [lengthCpf, cpfRule] = cpf
    const [lengthCns, cnsRule] = cns

    if (values?.cpfOuCns) {
      if (lengthCpf && cpfRule(values.cpfOuCns) && lengthCns && cnsRule(values.cpfOuCns)) {
        return { cpfOuCns: 'CPF/CNS inválido.' }
      }
    }

    return errors
  }
)

export interface ResponsavelFieldGroupModel {
  nomeResponsavel?: string
  dataNascimento?: string
  cpfOuCns?: string
}

export interface ResponsavelDadosFieldGroupProps {
  name: MetaPath<ResponsavelFieldGroupModel>
}

export default function ResponsavelDadosFieldGroup(props: ResponsavelDadosFieldGroupProps) {
  const { name } = props

  return (
    <Grid wrap>
      <Cell size={6}>
        <TextField name={name.nomeResponsavel} label='Nome' maxLength={70} uppercase />
      </Cell>
      <Cell size={6} />
      <Cell size={2}>
        <DateField name={name.dataNascimento} label='Data de nascimento' maxDate={moment().toDate()} />
      </Cell>
      <Cell size={2}>
        <NumberField name={name.cpfOuCns} label='CPF/CNS' maxLength={15} />
      </Cell>
    </Grid>
  )
}

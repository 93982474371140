import { Button, ExternalStyles, HFlow, Icon, PagedTable, Spinner, Text, Theme, Tooltip, useTheme } from 'bold-ui'
import { TableColumnConfig } from 'bold-ui/lib/components/Table/DataTable/DataTable'
import useSession from 'components/auth/useSession'
import { DateTime } from 'components/date'
import { TableBox, usePagedTableProps } from 'components/table'
import { EMPTY } from 'components/table/render'
import { useFlags } from 'config/useFlagsContext'
import { StatusImportacaoBolsaFamiliaEnum, TipoAcesso } from 'graphql/types.generated'
import { useFirebase } from 'hooks/firebase/useFirebase'
import React, { Dispatch, MouseEventHandler, SetStateAction } from 'react'

import {
  ImportacaoBolsaFamiliaQueryPayload,
  ImportacaoBolsaFamiliaRowModel,
  ImportacaoBolsaFamiliaTableFilterModel,
} from '../model-bolsafamilia'
import { formatVigenciaBolsaFamilia } from '../util-bolsafamilia'

interface ImportacaoBolsaFamiliaTableProps {
  loading: boolean
  onPagedTableChange: Dispatch<SetStateAction<ImportacaoBolsaFamiliaTableFilterModel>>
  importacoes: ImportacaoBolsaFamiliaQueryPayload
}

export function ImportacaoBolsaFamiliaTable(props: ImportacaoBolsaFamiliaTableProps) {
  const { loading, onPagedTableChange: handlePagedTableChange, importacoes } = props

  const theme = useTheme()
  const { RELATORIO_BOLSA_FAMILIA_ENABLED } = useFlags()
  const { data: session } = useSession()

  const { analytics } = useFirebase()
  const handleClickDownload = () => {
    analytics.logEvent('click_relatorio_nao_encontrados_bolsa_familia')
  }

  const tableProps = usePagedTableProps<ImportacaoBolsaFamiliaQueryPayload, ImportacaoBolsaFamiliaTableFilterModel>({
    loading,
    onChange: handlePagedTableChange,
    result: importacoes,
  })

  const isInstalador = session.acesso.tipo === TipoAcesso.ADMINISTRADOR_GERAL

  return (
    <TableBox>
      <PagedTable<ImportacaoBolsaFamiliaRowModel>
        {...tableProps}
        columns={[
          {
            name: 'dataImportacaoInicio',
            header: 'Data',
            render: (item) => <DateTime value={item.dataImportacaoInicio} format='DD/MM/YYYY' />,
            sortable: true,
          },
          { name: 'responsavel', header: 'Responsável', render: (item) => item.profissionalResponsavel.nome ?? EMPTY },
          renderMunicipioColumn(isInstalador),
          {
            name: 'situacao',
            header: 'Situação',
            style: { maxWidth: '5rem' },
            render: (item) => renderSituacao(item, theme),
          },
          {
            name: 'vigencia',
            header: 'Vigência',
            style: { maxWidth: '4rem' },
            render: (item) => renderVigencia(item, theme),
            sortable: true,
          },
          renderDownloadColumn(RELATORIO_BOLSA_FAMILIA_ENABLED, handleClickDownload),
        ]}
      />
    </TableBox>
  )
}

function renderMunicipioColumn(isInstalador: boolean): TableColumnConfig<ImportacaoBolsaFamiliaRowModel> {
  return isInstalador
    ? {
        name: 'municipio',
        header: 'Município',
        render: (item) => item.municipio.nome.titleCase(),
      }
    : { name: 'municipio', render: () => null, style: { display: 'none' } }
}

function renderSituacao(item: ImportacaoBolsaFamiliaRowModel, theme: Theme) {
  switch (item.statusImportacao) {
    case StatusImportacaoBolsaFamiliaEnum.FINALIZADO:
      return (
        <HFlow hSpacing={0.25}>
          <Icon icon='checkCircleOutline' color={theme.pallete.status.success.main} size={1} />
          <Text color='success'>Importado</Text>
        </HFlow>
      )
    case StatusImportacaoBolsaFamiliaEnum.FALHA:
      return (
        <HFlow hSpacing={0.25}>
          <Icon icon='banOutline' color={theme.pallete.status.danger.main} size={1} />
          <Text color='danger'>Falha</Text>
        </HFlow>
      )
    case StatusImportacaoBolsaFamiliaEnum.EM_PROGRESSO:
      return (
        <HFlow hSpacing={0.25}>
          <Spinner size={1} style={{ color: theme.pallete.primary.main }} />
          <Text color='primary'>Iniciando</Text>
        </HFlow>
      )
    default:
      return '-'
  }
}

function renderVigencia(item: ImportacaoBolsaFamiliaRowModel, theme: Theme) {
  const vigencia = formatVigenciaBolsaFamilia(item.vigencia)
  const textStyle: ExternalStyles = item.complementar ? null : { marginLeft: '1.25rem' }
  return (
    <HFlow hSpacing={0.25}>
      {item.complementar && (
        <Tooltip text='Listagem complementar'>
          <Icon icon='infoCircleFilled' size={1} color={theme.pallete.gray.c20} />
        </Tooltip>
      )}
      <Text style={textStyle}>{vigencia}</Text>
    </HFlow>
  )
}

function renderDownloadColumn(
  isRelatorioEnabled: boolean,
  handleClickDownload: MouseEventHandler<HTMLButtonElement>
): TableColumnConfig<ImportacaoBolsaFamiliaRowModel> {
  return isRelatorioEnabled
    ? {
        name: 'relatorio',
        align: 'right',
        style: { maxWidth: '2rem', padding: '0' },
        render: (item) => renderDownloadButton(item, handleClickDownload),
      }
    : { name: 'relatorio', render: () => null, style: { display: 'none' } }
}

function renderDownloadButton(item: ImportacaoBolsaFamiliaRowModel, handleClick: MouseEventHandler<HTMLButtonElement>) {
  const isFinalizado = item.statusImportacao === StatusImportacaoBolsaFamiliaEnum.FINALIZADO

  return (
    <Button size='small' disabled={!isFinalizado} skin='ghost' onClick={handleClick}>
      <Tooltip text={isFinalizado ? 'Baixar lista de beneficiários não encontrados na instalação' : ''}>
        <Icon icon='download' />
      </Tooltip>
    </Button>
  )
}

/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, HFlow, Tooltip, VFlow } from 'bold-ui'
import { Box } from 'components/Box'
import { EquipeSelectField, SubmitButton } from 'components/form'
import DateRangeField from 'components/form/field/DateRangeField'
import { LotacaoAndEstagioSelectField } from 'components/form/field/select/LotacaoAndEstagioSelectField/LotacaoAndEstagioSelectField'
import { useServerTime } from 'hooks/useServerTime'
import { FormSpy } from 'react-final-form'

import { AssinaturaEletronicaAtendTableFilterModel, name } from '../model'

export interface AssinaturaEletronicaAtendTableFilterPopperProps {
  isCoordenador: boolean
  unidadeSaudeId: string
  onSubmit: (event?: any) => Promise<any>
  onClose: () => void
}

export default function AssinaturaEletronicaAtendTableFilterPopper(
  props: AssinaturaEletronicaAtendTableFilterPopperProps
) {
  const { unidadeSaudeId, isCoordenador, onSubmit, onClose } = props

  const { getServerTimeNow } = useServerTime()
  const serverTime = getServerTimeNow()

  const handleSubmit = (event?: React.SyntheticEvent<HTMLFormElement>) => {
    onSubmit(event)
    onClose()
  }

  return (
    <Box
      style={css`
        width: 34rem;
      `}
    >
      <VFlow>
        {isCoordenador && (
          <FormSpy<AssinaturaEletronicaAtendTableFilterModel> subscription={{ values: true }}>
            {({ values }) => {
              return (
                <VFlow>
                  <Tooltip text={!!values?.lotacao && 'Profissional selecionado.'}>
                    <EquipeSelectField
                      name={name.equipe}
                      unidadeSaudeId={unidadeSaudeId}
                      label='Equipe'
                      disabled={!!values.lotacao}
                    />
                  </Tooltip>
                  <LotacaoAndEstagioSelectField
                    label='Profissional'
                    name={name.lotacao}
                    unidadeSaudeId={unidadeSaudeId}
                    equipeId={values?.equipe?.id}
                  />
                </VFlow>
              )
            }}
          </FormSpy>
        )}
        <DateRangeField name={name.periodo} label='Período' maxDate={serverTime} />
        <HFlow hSpacing={0.5} justifyContent='flex-end'>
          <Button onClick={onClose} size='small' skin='outline' kind='normal'>
            Fechar
          </Button>
          <SubmitButton size='small' handleSubmit={handleSubmit}>
            Filtrar
          </SubmitButton>
        </HFlow>
      </VFlow>
    </Box>
  )
}

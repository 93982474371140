import {
  CdsOrigemEnum,
  IdentidadeGeneroEnum,
  NacionalidadeEnum,
  OrientacaoSexualEnum,
  RacaCorDbEnum,
  SexoEnum,
  TipoSanguineoEnum,
} from 'graphql/types.generated'
import { CidadaoAldeadoTabSectionModel } from 'view/cidadao/types/model-cidadaoTabSection'

export const EXPORTAR_CADASTROS_DUPLICADOS_PATH = 'exportar-cadastros-duplicados'

export interface CidadaoVisualizacaoModal extends CidadaoAldeadoTabSectionModel {
  id: string
  cpf?: string
  cns?: string
  nisPisPasep?: string
  nome: string
  nomeSocial?: string
  dataNascimento?: any
  dataAtualizado?: any
  dataObito?: any
  numeroDocumentoObito?: string
  sexo: SexoEnum
  nomeMae?: string
  nomePai?: string
  telefoneResidencial?: string
  telefoneCelular?: string
  telefoneContato?: string
  email?: string
  area?: string
  microArea?: string
  localidadeExterior?: string
  identidadeGeneroDbEnum?: IdentidadeGeneroEnum
  ativo?: boolean
  faleceu?: boolean
  possuiAgendamento?: boolean
  unificado?: boolean
  tipoSanguineo?: TipoSanguineoEnum
  orientacaoSexualDbEnum?: OrientacaoSexualEnum
  portariaNaturalizacao?: string
  dataNaturalizacao?: any
  dataEntradaBrasil?: any
  stCompartilhaProntuario?: boolean
  endereco?: {
    cep?: string
    bairro?: string
    logradouro?: string
    numero?: string
    semNumero?: boolean
    complemento?: string
    pontoReferencia?: string
    uf?: {
      id: string
      nome: string
    }
    municipio?: {
      id: string
      nome: string
    }
    tipoLogradouro?: {
      id: string
      nome: string
    }
  }
  etnia?: {
    id: string
    nome: string
  }
  racaCor: {
    id: string
    nome: string
    racaCorDbEnum: RacaCorDbEnum
  }
  cbo?: {
    id: string
    nome: string
  }
  escolaridade?: {
    id: string
    nome: string
  }
  localidadeNascimento?: {
    id: string
    nome: string
    uf: {
      id: string
      sigla: string
    }
  }
  prontuario?: {
    id: string
  }
  cidadaoVinculacaoEquipe?: {
    id?: string
    tpCdsOrigem?: CdsOrigemEnum
    unidadeSaude?: {
      id: string
      nome?: string
    }
    equipe?: {
      id: string
      nome: string
      ine: string
    }
  }
  estadoCivil?: { id: string; nome: string }
  paisExterior?: { id: string; nome: string }
  nacionalidade?: { id: string; nacionalidadeDbEnum: NacionalidadeEnum }
  paisNascimento?: { id: string; nome: string }
}

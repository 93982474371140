import { NacionalidadeEnum, RacaCorDbEnum, TipoLocalNascimentoEnum } from 'graphql/types.generated'
import {
  beforeEqualToday,
  cns,
  consistentPeriod,
  cpf,
  createValidator,
  dataNasc,
  ErrorObject,
  length,
  maxLength,
  nome,
  required,
  simpleName,
  validate,
} from 'util/validation'

import CidadaoFormModel from '../types/model-cidadao'

export const validator = createValidator<CidadaoFormModel>(
  {
    cpf: [cpf],
    cns: [cns],
    nome: [required, nome(), maxLength(70)],
    nomeSocial: [simpleName, maxLength(70)],
    dataNascimento: [required, dataNasc],
    sexo: [required],
    racaCor: [required],
    numeroDeclaracaoObito: [length(9)],
  },

  (values: CidadaoFormModel, errors: ErrorObject<CidadaoFormModel>) => {
    if (values == null) {
      return errors
    }
    if (values.racaCor?.value === RacaCorDbEnum.INDIGENA) {
      errors.etnia = required(values.etnia)
    }
    if (!values.desconheceNomeMae) {
      errors.nomeMae = validate(values.nomeMae, [required, nome(), maxLength(70)])
    }
    if (!values.desconheceNomePai) {
      errors.nomePai = validate(values.nomePai, [required, nome(), maxLength(70)])
    }

    if (
      NacionalidadeEnum.BRASILEIRA === values.nacionalidade &&
      (TipoLocalNascimentoEnum.MUNICIPIO === values.tipoLocalNascimento || !values.isCidadaoAldeado)
    ) {
      errors.municipioNascimento = required(values.municipioNascimento)
    } else if (NacionalidadeEnum.NATURALIZADA === values.nacionalidade) {
      errors.portariaNaturalizacao = validate(values.portariaNaturalizacao, [required, maxLength[16]])
      errors.dataNaturalizacao = validate(values.dataNaturalizacao, [required, beforeEqualToday])
    } else if (NacionalidadeEnum.ESTRANGEIRA === values.nacionalidade) {
      errors.paisNascimento = required(values.paisNascimento)
      errors.dataEntradaPais = validate(values.dataEntradaPais, [required, beforeEqualToday])
    }

    if (values.cidadaoFaleceu) {
      errors.dataObito = validate(values.dataObito, [required, beforeEqualToday])
    }

    const errorDataNascimentoEntradaPais = consistentPeriod(
      values.dataNascimento,
      values.dataEntradaPais,
      'Data de nascimento',
      'Data de entrada no Brasil'
    )
    if (errorDataNascimentoEntradaPais) {
      errors.dataNascimento = errorDataNascimentoEntradaPais.msgLblDataInicial
      errors.dataEntradaPais = errorDataNascimentoEntradaPais.msgLbDataFinal
    }
    const errorDataNascimentoNaturalizacao = consistentPeriod(
      values.dataNascimento,
      values.dataNaturalizacao,
      'Data de nascimento',
      'Data de naturalização'
    )
    if (errorDataNascimentoNaturalizacao) {
      errors.dataNascimento = errorDataNascimentoNaturalizacao.msgLblDataInicial
      errors.dataNaturalizacao = errorDataNascimentoNaturalizacao.msgLbDataFinal
    }
    const errorDataNascimentoObito = consistentPeriod(
      values.dataNascimento,
      values.dataObito,
      'Data de nascimento',
      'Data de óbito'
    )

    if (errorDataNascimentoObito) {
      errors.dataNascimento = errorDataNascimentoObito.msgLblDataInicial
      errors.dataObito = errorDataNascimentoObito.msgLbDataFinal
    }
    const errorDataEntradaPaisObito = consistentPeriod(
      values.dataEntradaPais,
      values.dataObito,
      'Data de entrada no Brasil',
      'Data de óbito'
    )
    if (errorDataEntradaPaisObito) {
      errors.dataEntradaPais = errorDataEntradaPaisObito.msgLblDataInicial
      errors.dataObito = errorDataEntradaPaisObito.msgLbDataFinal
    }
    const errorDataNaturalizacaoObito = consistentPeriod(
      values.dataNaturalizacao,
      values.dataObito,
      'Data de naturalização',
      'Data de óbito'
    )
    if (errorDataNaturalizacaoObito) {
      errors.dataNaturalizacao = errorDataNaturalizacaoObito.msgLblDataInicial
      errors.dataObito = errorDataNaturalizacaoObito.msgLbDataFinal
    }
    return errors
  }
)

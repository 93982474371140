import { Moment } from 'moment'

import createMonotoneCubicInterpolator from './createMonotoneCubicInterpolator'
import {
  ChartSeries,
  ChartSeriesDataPoint,
  DataPoint,
  getDataPointValue,
  getOutlierSeriesName,
  ReferenceAreaWithPercents,
} from './model'

export function convertSeries<XDomain>(
  series: ChartSeries<XDomain>[],
  domainPoints: XDomain[],
  refsAreas: ReferenceAreaWithPercents<XDomain>[],
  seriesHasOutliers?: (seriesIndex: number, indexData: number) => boolean,
  outlierTickValue?: number | Moment
): any[] {
  const getOutlierSeriesConfig = (seriesName: string, seriesData: ChartSeriesDataPoint<XDomain>) => {
    return {
      [seriesName]: outlierTickValue,
      [getOutlierSeriesName(seriesName)]: { value: getDataPointValue(seriesData), series: seriesName },
    }
  }

  const refs = (refsAreas ?? []).flatMap((refsAreas) => {
    return refsAreas.areaPercents.map((data, i) => {
      return {
        x: data.x,
        [refsAreas.name]: data.percent,
      }
    })
  })

  const seriesData = (series ?? [])
    .flatMap((series, seriesIndex) => {
      return (series.data as any[]).map((data: ChartSeriesDataPoint<XDomain>, dataIndex: number) => {
        const hasOutliers = seriesHasOutliers && seriesHasOutliers(seriesIndex, dataIndex)

        return {
          x: (data as DataPoint<XDomain>).x ?? domainPoints[dataIndex],
          ...(hasOutliers ? getOutlierSeriesConfig(series.name, data) : { [series.name]: getDataPointValue(data) }),
        }
      })
    })
    .concat(...refs)
    .sort((a, b) => (a.x === b.x ? 0 : a.x > b.x ? 1 : -1))
    .reduce((map, obj) => {
      map.set(obj.x, { ...map.get(obj.x), ...obj })
      return map
    }, new Map())

  /*
   * É preciso interpolar os valores pois o Recharts considera valores vazios como 0
   * e a área de referência acaba desconfigurando quando existe um X ou Y sem um valor
   * de área correspondente
   */
  const refValueNames = new Set(refs.flatMap((v) => Object.keys(v)))
  return interpolateValues(Array.from(seriesData.values()), refValueNames)
}

const asNumber = (x: any) => (isNaN(+x) ? null : +x)

function interpolateValues(orderedValues: any[], valueNames: Set<string>): any[] {
  valueNames.delete('x')
  const interpolators = getValuesInterpolators(orderedValues, valueNames)

  return orderedValues.map((v, i) => {
    valueNames.forEach((vn) => {
      if (!(vn in v)) v[vn] = interpolators.get(vn)(asNumber(v.x) ?? i)
    })
    return v
  })
}

function getValuesInterpolators(orderedValues: any[], valueNames: Set<string>): Map<string, (x: number) => number> {
  return Array.from(valueNames).reduce((interpolators, vn) => {
    const valsWithVn = orderedValues.filter((v) => vn in v)
    const xs = valsWithVn.map((v, i) => asNumber(v.x) ?? i)
    const ys = valsWithVn.map((v) => v[vn])
    interpolators.set(vn, createMonotoneCubicInterpolator(xs, ys))
    return interpolators
  }, new Map<string, (x: number) => number>())
}

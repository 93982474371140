/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, Grid, Heading, Icon, Text, Theme, useTheme, VFlow } from 'bold-ui'
import { PageContent } from 'components/layout/PageContent'
import { ExternalUserHeader } from 'components/user/ExternalUserHeader'
import { useUrlQueryParams } from 'hooks/useUrlQueryParams'
import { ReactComponent as LostConnectionImage } from 'images/videochamada/videochamada-perda-conexao.svg'
import { Fragment } from 'react'
import { useHistory, useRouteMatch } from 'react-router'

import { VideochamadaUrlParams } from './model-videochamada'

interface VideochamadaPerdaConexaoQueryParams {
  owner?: string
}

export function VideochamadaPerdaConexaoView() {
  const { owner } = useUrlQueryParams<VideochamadaPerdaConexaoQueryParams>()
  const isOwner = owner === 'true'

  const {
    params: { uuid: videochamadaId },
  } = useRouteMatch<VideochamadaUrlParams>()

  const history = useHistory()

  const handleVoltarClick = () => navigator.onLine && history.push(`/videochamada/${videochamadaId}`)

  const theme = useTheme()
  const styles = createStyles(theme)

  return (
    <Fragment>
      <ExternalUserHeader />
      <PageContent type='filled' containerStyle={styles.containerStyle} style={styles.container}>
        <Grid alignItems='center' justifyContent='space-between' direction='row' style={styles.pageContentGrid}>
          <Cell sm={5} style={styles.cellHeading}>
            <VFlow vSpacing={1}>
              <Heading level={1}>
                Você {isOwner ? 'encerrou a' : 'saiu da'} chamada porque a conexão foi perdida
              </Heading>
              <Text>
                Verifique sua conexão com a internet e
                {isOwner ? ' crie uma nova chamada no módulo de Videochamadas.' : ' tente novamente.'}
              </Text>
              {!isOwner && (
                <Button kind='primary' onClick={handleVoltarClick}>
                  <Icon
                    icon='arrowLeft'
                    style={css`
                      margin-right: 0.5rem;
                    `}
                  />
                  Voltar para sala
                </Button>
              )}
            </VFlow>
          </Cell>
          <Cell sm={7} flexGrow={1} style={styles.cellImage}>
            <LostConnectionImage
              title='Tomada desconectada, representando que a chamada de vídeo foi interrompida.'
              css={styles.image}
            />
          </Cell>
        </Grid>
      </PageContent>
    </Fragment>
  )
}

const createStyles = (theme: Theme) => ({
  container: css`
    padding: 2rem 1rem;

    ${theme.breakpoints.up('sm')} {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  `,
  containerStyle: css`
    padding: 0;

    ${theme.breakpoints.up('sm')} {
      padding: 0 2rem;
    }
  `,
  pageContentGrid: css`
    align-items: flex-start;
    flex-direction: column;

    ${theme.breakpoints.up('sm')} {
      align-items: center;
      flex-direction: row;
    }
  `,
  cellHeading: css`
    padding-top: 0;
    ${theme.breakpoints.up('sm')} {
      padding-top: 0.5rem;
    }
  `,
  cellImage: css`
    padding-bottom: 0;
    ${theme.breakpoints.up('sm')} {
      padding-bottom: 0.5rem;
    }
  `,
  image: css`
    width: 100%;
    height: 100%;
  `,
})

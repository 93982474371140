import { SelectField, SelectFieldProps } from 'components/form'
import { TemaReuniaoEnum } from 'graphql/types.generated'
import React from 'react'

export interface TemaReuniaoSelectFieldModel {
  value: TemaReuniaoEnum
  label: string
}

type TemaReuniaoSelectFieldProps = Omit<SelectFieldProps<TemaReuniaoSelectFieldModel>, 'items' | 'itemToString'>

const temaReuniaoItems: TemaReuniaoSelectFieldModel[] = [
  {
    value: TemaReuniaoEnum.QUESTOES_ADMINISTRATIVAS_FUNCIONAMENTO,
    label: 'Questões administrativas / Funcionamento',
  },
  {
    value: TemaReuniaoEnum.PROCESSOS_DE_TRABALHO,
    label: 'Processos de trabalho',
  },
  {
    value: TemaReuniaoEnum.DIAGNOSTICO_DO_TERRITORIO_MONITORAMENTO_DO_TERRITORIO,
    label: 'Diagnóstico do território / Monitoramento do território',
  },
  {
    value: TemaReuniaoEnum.PLANEJAMENTO_MONITORAMENTO_DAS_ACOES_DA_EQUIPE,
    label: 'Planejamento / Monitoramento das ações da equipe',
  },
  {
    value: TemaReuniaoEnum.DISCUSSAO_DE_CASO_PROJETO_TERAPEUTICO_SINGULAR,
    label: 'Discussão de caso / Projeto terapêutico singular',
  },
  {
    value: TemaReuniaoEnum.EDUCACAO_PERMANENTE,
    label: 'Educação permanente',
  },
  {
    value: TemaReuniaoEnum.OUTROS,
    label: 'Outros',
  },
]

const itemToString = (item: TemaReuniaoSelectFieldModel): string => item?.label

export function TemaReuniaoSelectField(props: TemaReuniaoSelectFieldProps) {
  return <SelectField<TemaReuniaoSelectFieldModel> items={temaReuniaoItems} itemToString={itemToString} {...props} />
}

import { Button, ButtonProps, Icon, Tooltip } from 'bold-ui'
import { Color } from 'csstype'
import { useCallback, useState } from 'react'
import React from 'react'
import { HistoricoDetalhesModel } from 'view/atendimentos/detail/historico/model/model-historico'

import { DadosCidadaoVisualizarAtendimentoModel } from './model'
import { VisualizarAtendimentoModal } from './VisualizarAtendimentoModal'

interface VisualizarAtendimentoButtonProps extends ButtonProps {
  modalTitle: string
  dataOrTurno: string
  modalTitleColor: Color
  historicoAtendimento: HistoricoDetalhesModel
  cidadao?: DadosCidadaoVisualizarAtendimentoModel
  tooltip: string
  isAtendimentoAssinado?: boolean
}

export function VisualizarAtendimentoButton(props: VisualizarAtendimentoButtonProps) {
  const {
    dataOrTurno,
    tooltip,
    cidadao,
    modalTitleColor,
    historicoAtendimento,
    modalTitle,
    onClick,
    isAtendimentoAssinado = false,
    ...rest
  } = props
  const [modalOpen, setModalOpen] = useState(false)

  const handleModalClose = useCallback(() => setModalOpen(false), [])
  const handleButtonClick = useCallback(
    (e) => {
      setModalOpen(true)
      onClick?.(e)
    },
    [onClick]
  )

  return (
    <>
      <VisualizarAtendimentoModal
        title={`${modalTitle} ${dataOrTurno ? `| ${dataOrTurno}` : ''}`}
        titleColor={modalTitleColor}
        historicoAtendimento={historicoAtendimento}
        cidadao={cidadao}
        open={modalOpen}
        onClose={handleModalClose}
        isAtendimentoAssinado={isAtendimentoAssinado}
      />

      <Tooltip text={tooltip}>
        <Button size='small' skin='ghost' onClick={handleButtonClick} {...rest}>
          <Icon icon='zoomOutline' />
        </Button>
      </Tooltip>
    </>
  )
}

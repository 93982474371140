/** @jsx jsx * */
import { css, jsx } from '@emotion/core'
import { ExternalStyles, HFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { usePecField } from 'components/form/final-form/hooks/useField'
import { useFlags } from 'config/useFlagsContext'
import { useConfiguracoes } from 'hooks/configuracoes'
import { useFirebase } from 'hooks/firebase/useFirebase'
import { Dispatch, memo, SetStateAction, useEffect, useState } from 'react'
import { Meta } from 'util/metaPath'
import { isEmpty } from 'util/validation/Util'

import { EmailUpdateForm } from '../../components/EmailUpdateForm'
import { AtestadoAssinaturaDigitalGerarCheckbox } from './AtestadoAssinaturaDigitalGerarCheckbox'

interface AtestadoAssinaturaDigitalViewProps {
  name: Meta<boolean>
  cidadaoId: ID
  cidadaoEmail: string
  onEmailCidadaoChange: Dispatch<SetStateAction<boolean>>
  hasCid10?: boolean
  style?: ExternalStyles
  onDirtyChange: (dirty: boolean) => void
  emailCanceled?: boolean
  onEmailCancel?: (canceled: boolean) => void
}

export const AtestadoAssinaturaDigitalView = memo((props: AtestadoAssinaturaDigitalViewProps) => {
  const {
    name,
    cidadaoId,
    cidadaoEmail,
    onEmailCidadaoChange,
    hasCid10 = false,
    style = styles.externalStylesDefault,
    onDirtyChange,
    emailCanceled = false,
    onEmailCancel,
  } = props

  const {
    input: { value: isAtestadoDigitalChecked, onChange: onChangeAtestadoDigitalChecked },
  } = usePecField({ name: name, subscription: { value: true } })

  const alert = useAlert()
  const isCidadaoEmailEmpty = isEmpty(cidadaoEmail)
  const { PILOTO_ATESTADO_DIGITAL_ENABLED } = useFlags()
  const {
    configuracoes: { isPrescricaoDigitalEnabled },
  } = useConfiguracoes()
  const { analytics } = useFirebase()
  const [isEmailCidadaoEditing, setIsEmailCidadaoEditing] = useState(false)

  useEffect(() => {
    onEmailCidadaoChange(isAtestadoDigitalChecked && isCidadaoEmailEmpty && PILOTO_ATESTADO_DIGITAL_ENABLED)

    if (hasCid10) {
      onChangeAtestadoDigitalChecked(false)
      onEmailCancel?.(true)
    } else if (!isAtestadoDigitalChecked) {
      onEmailCancel?.(true)
    } else {
      onEmailCancel?.(false)
    }
  }, [
    PILOTO_ATESTADO_DIGITAL_ENABLED,
    hasCid10,
    isAtestadoDigitalChecked,
    isCidadaoEmailEmpty,
    onChangeAtestadoDigitalChecked,
    onEmailCidadaoChange,
    onEmailCancel,
  ])

  if (!PILOTO_ATESTADO_DIGITAL_ENABLED || !isPrescricaoDigitalEnabled) {
    return null
  }

  const handleEmailChange = () => {
    alert('success', 'E-mail atualizado com sucesso.')
    analytics.logEvent('click_atualizar_email_atestado_digital')
  }

  const handleEmailEdit = () => analytics.logEvent('click_editar_email_atestado_digital')

  return (
    <HFlow style={[styles.container, style]}>
      <AtestadoAssinaturaDigitalGerarCheckbox name={name} hasCid10={hasCid10} emailEditing={isEmailCidadaoEditing} />
      <EmailUpdateForm
        cidadaoId={cidadaoId}
        cidadaoEmail={cidadaoEmail}
        onSubmitSuccess={handleEmailChange}
        disabled={!isAtestadoDigitalChecked}
        initialValues={{ email: cidadaoEmail }}
        onDirtyChange={onDirtyChange}
        editing={isEmailCidadaoEditing}
        onEditingChange={setIsEmailCidadaoEditing}
        onEditarClick={handleEmailEdit}
        canceled={emailCanceled}
        onCancel={onEmailCancel}
      />
    </HFlow>
  )
})

const styles = {
  container: css`
    width: 100%;
  `,
  externalStylesDefault: css`
    padding-left: 0rem;
  `,
}

import { useCallback, useState } from 'react'

interface RenderMicroareaControlModel {
  previusCachedMicroarea: string
  renderMicroareaCache: boolean
}

export const useMicroareasVisualizacao = (
  cachedMicroarea: string,
  shouldUseCache: boolean,
  resetExpandedItems: () => void
) => {
  const [currentMicroareaTab, setCurrentMicroareaTab] = useState<string>(shouldUseCache ? cachedMicroarea : undefined)
  const [renderMicroareaControl, setRenderMicroareaControl] = useState<RenderMicroareaControlModel>({
    previusCachedMicroarea: cachedMicroarea,
    renderMicroareaCache: shouldUseCache ? true : false,
  })

  const handleMicroareaTabClick = useCallback(
    (microarea: string) => {
      resetExpandedItems()
      setCurrentMicroareaTab(microarea)

      if (renderMicroareaControl.renderMicroareaCache === true) {
        setRenderMicroareaControl((state) => ({ ...state, renderMicroareaCache: null }))
      } else if (renderMicroareaControl.renderMicroareaCache === null) {
        setRenderMicroareaControl((state) => ({ ...state, renderMicroareaCache: false }))
      }
    },
    [renderMicroareaControl.renderMicroareaCache, resetExpandedItems]
  )

  return {
    currentMicroareaTab,
    handleMicroareaTabClick,
    setRenderMicroareaControl,
    setCurrentMicroareaTab,
  }
}

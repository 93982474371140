/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { SerializedStyles } from '@emotion/serialize'
import { Icon, Theme, useTheme } from 'bold-ui'
import { keyframes } from 'emotion'
import { useCallback, useEffect, useMemo, useRef } from 'react'

const SENSIBILIDADE_MIN = 100
const BAR_HEIGHTS = { default: 2.5, min: 5, middle: 7.5, max: 10 }
const VOLUME_THRESHOLDS = { min: 150, middle: 200, max: 250 }

type MicrophoneFeedbackProps = {
  audioTrack: MediaStreamTrack
  audioEnabled?: boolean
  styles?: SerializedStyles
  alwaysVisible?: boolean
}

export function MicrophoneFeedback(props: MicrophoneFeedbackProps) {
  const { audioTrack, audioEnabled = false, styles: externalStyles, alwaysVisible = false } = props

  const theme = useTheme()
  const styles = createStyles(theme)

  const containerRef = useRef<HTMLDivElement>()
  const volumeBar1 = useRef<SVGRectElement>()
  const volumeBar2 = useRef<SVGRectElement>()
  const volumeBar3 = useRef<SVGRectElement>()

  const volumeBarRefs = useMemo(() => [volumeBar1, volumeBar2, volumeBar3], [volumeBar1, volumeBar2, volumeBar3])

  const setVisible = (visible: boolean) => {
    if (containerRef.current) {
      containerRef.current.style.display = visible ? null : 'none'
    }
  }

  const updateVolumeBars = useCallback(
    (volume: number) => {
      const { height, y } = calculateVolumeBarAttributes(volume)
      volumeBarRefs.forEach((volumeBarRef) => {
        if (volumeBarRef.current) {
          volumeBarRef.current.setAttribute('height', height.toString())
          volumeBarRef.current.setAttribute('y', y.toString())
          volumeBarRef.current.style.animationPlayState = height < 5 ? 'paused' : 'running'
        }
      })
    },
    [volumeBarRefs]
  )

  useEffect(() => {
    if (audioEnabled && audioTrack) {
      setVisible(false)

      const audioContext = new AudioContext()
      const analyser = audioContext.createAnalyser()
      const mic = audioContext.createMediaStreamSource(new MediaStream([audioTrack]))

      mic.connect(analyser)

      const frequency = new Uint8Array(analyser.frequencyBinCount)

      const updateVolume = () => {
        if (audioContext.state === 'closed') {
          return
        }

        analyser.getByteFrequencyData(frequency)

        const maxVolume = Math.max(...frequency)
        const visible = maxVolume > SENSIBILIDADE_MIN

        setVisible(alwaysVisible || visible)

        if (visible) updateVolumeBars(maxVolume)

        requestAnimationFrame(updateVolume)
      }

      updateVolume()

      return () => {
        mic.disconnect(analyser)
        audioContext.close()
        setVisible(true)
      }
    }
  }, [alwaysVisible, audioEnabled, audioTrack, updateVolumeBars])

  return (
    <div ref={containerRef} css={[externalStyles, styles.micContainer, audioEnabled ? styles.micOn : styles.micOff]}>
      {audioEnabled ? (
        <svg width='16' height='16' viewBox='0 0 15 15' fill='none'>
          {volumeBarRefs.map((ref, idx) => (
            <rect
              key={idx}
              ref={ref}
              x={1.25 + idx * 5}
              y='2.5'
              width='2.5'
              height='10'
              rx='1.25'
              fill='white'
              css={idx % 2 === 0 ? styles.edgeVolumeBar : styles.middleVolumeBar}
            />
          ))}
        </svg>
      ) : (
        <Icon size={1} icon='microphoneOffFilled' color='white' />
      )}
    </div>
  )
}

const volumeBarJiggle = keyframes`
  0% {
    transform: scaleY(.75)
  }
  50% {
    transform: scaleY(1)
  }
  100% {
    transform: scaleY(.75)
  }
`

const calculateVolumeBarAttributes = (volume: number) => {
  let height = BAR_HEIGHTS.default

  if (volume >= VOLUME_THRESHOLDS.max) {
    height = BAR_HEIGHTS.max
  } else if (volume >= VOLUME_THRESHOLDS.middle) {
    height = BAR_HEIGHTS.middle
  } else if (volume >= VOLUME_THRESHOLDS.min) {
    height = BAR_HEIGHTS.min
  }

  return { height, y: BAR_HEIGHTS.middle - height / 2 }
}

const createStyles = (theme: Theme) => ({
  micContainer: css`
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  `,
  micOff: css`
    background-color: ${theme.pallete.gray.c40};
  `,
  micOn: css`
    background-color: ${theme.pallete.primary.c40};
  `,
  edgeVolumeBar: css`
    animation: ${volumeBarJiggle} 0.6s steps(9, jump-none) 0.1s infinite;
    transform-origin: center;
  `,
  middleVolumeBar: css`
    animation: ${volumeBarJiggle} 0.4s steps(9, jump-none) 0.1s infinite;
    transform-origin: center;
  `,
})

import { Alert, Button, HeadingSection, HFlow, Icon, Link, Text, Tooltip, useStyles, VFlow } from 'bold-ui'
import useSession from 'components/auth/useSession'
import { Box } from 'components/Box'
import { CheckboxField, Form, FormRenderProps, SubmitButton } from 'components/form'
import { TextAreaField } from 'components/form/final-form/TextAreaField/TextAreaField'
import { LoadingIndicator } from 'components/loading/LoadingIndicator'
import { GrauSatisfacaoUsuario, TipoRespostaPesquisa } from 'graphql/types.generated'
import { useConfiguracoes } from 'hooks/configuracoes'
import React, { CSSProperties } from 'react'
import { metaPath } from 'util/metaPath'
import { createValidator, maxLength, required } from 'util/validation'

import { ButtonContainer } from './PesquisaSatisfacaoButtonContainer'

interface PesquisaSatisfacaoBoxProps {
  onClose(value: TipoRespostaPesquisa): void
  handleOnSubmit(values: PesquisaSatisfacaoBoxFormModel): void
}

export interface PesquisaSatisfacaoBoxFormModel {
  grauSatisfacao?: GrauSatisfacaoUsuario
  observacao?: string
  tipoResposta?: TipoRespostaPesquisa
  enviaMunicipio?: boolean
  municipio?: string
}

export const validate = createValidator<PesquisaSatisfacaoBoxFormModel>({
  grauSatisfacao: [required],
  observacao: [maxLength(500)],
})

const path = metaPath<PesquisaSatisfacaoBoxFormModel>()

export const PesquisaSatisfacaoBox = (props: PesquisaSatisfacaoBoxProps) => {
  const { classes } = useStyles(createStyles)

  const handleOnCloseClicked = () => {
    props.onClose(TipoRespostaPesquisa.FECHADO)
  }

  const handleOnNaoResponderClicked = () => {
    props.onClose(TipoRespostaPesquisa.NAO_RESPONDIDO)
  }

  const {
    configuracoes: { versao },
  } = useConfiguracoes()

  const { data, loading } = useSession()

  if (loading || !versao) {
    return <LoadingIndicator />
  }

  const acessoHasMunicipio = !!data.acesso?.municipio

  const renderForm = (formProps: FormRenderProps) => {
    return (
      <Box style={classes.container}>
        <HFlow hSpacing={3} alignItems='flex-start'>
          <div>
            <HeadingSection title='Ajude-nos a melhorar o e-SUS APS PEC' level={1} />
          </div>
          <Button skin='ghost' size='small' onClick={handleOnCloseClicked}>
            <Icon icon='timesDefault' />
          </Button>
        </HFlow>
        <VFlow>
          <Text fontWeight='bold'>Quão satisfeito você está com a nova versão {versao}?</Text>
          <HFlow hSpacing={1.8}>
            <ButtonContainer name={path.grauSatisfacao} />
          </HFlow>
          <HFlow justifyContent='space-between' style={{ width: 340 }}>
            <Text>Muito insatisfeito</Text>
            <Text>Muito satisfeito</Text>
          </HFlow>
          <TextAreaField
            label='Você tem críticas ou sugestões sobre alguma tela ou funcionalidade do PEC? Conte para a gente:'
            name={path.observacao}
            maxLength={500}
            disabled={!formProps.values.grauSatisfacao}
            style={classes.textArea}
          />
          {acessoHasMunicipio && (
            <HFlow alignItems='center' hSpacing={0.5}>
              <CheckboxField name={path.enviaMunicipio} label='Enviar o nome do município' />
              <Tooltip
                style={{ maxWidth: '290px' }}
                text='Coletamos o nome do seu município para facilitar a análise das respostas e para entrar em contato com o administrador municipal, se necessário.'
              >
                <Icon icon='infoCircleFilled' size={1} />
              </Tooltip>
            </HFlow>
          )}
          <Alert type='info' inline>
            Para assistência ou esclarecimento de dúvidas entre em contato através do nosso{' '}
            <Link target='_blank' href='http://esusaps.bridge.ufsc.br/'>
              portal de suporte
            </Link>
          </Alert>
          <HFlow justifyContent='flex-end'>
            <Button kind='normal' skin='outline' size='small' onClick={handleOnNaoResponderClicked}>
              Não responder
            </Button>
            <SubmitButton
              kind='primary'
              skin='default'
              size='small'
              disabled={!formProps.values.grauSatisfacao}
              handleSubmit={formProps.handleSubmit}
            >
              Enviar
            </SubmitButton>
          </HFlow>
        </VFlow>
      </Box>
    )
  }
  return (
    <Form<PesquisaSatisfacaoBoxFormModel>
      render={renderForm}
      initialValues={{ enviaMunicipio: acessoHasMunicipio }}
      onSubmit={props.handleOnSubmit}
      validate={validate}
    />
  )
}

const createStyles = () => ({
  container: {
    width: 402,
    height: 550,
    position: 'fixed',
    left: 109,
    bottom: 20,
    zIndex: 9,
  } as CSSProperties,
  textArea: {
    resize: 'none',
  } as CSSProperties,
})

/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, HFlow, Icon, PagedTable, PagedTableProps, Text, Tooltip, VFlow } from 'bold-ui'
import { DateTime } from 'components/date'
import { Cns, Cpf, Telefone } from 'components/label'
import { TableBox } from 'components/table/TableBox'
import { Fragment } from 'react'
import { useHistory } from 'react-router'

import { AcompanhamentoCondicaoSaudeDto } from './model-acompanhamentoCondicaoSaude'

export interface AcompanhamentoCondicaoSaudeListingProps
  extends Omit<PagedTableProps<AcompanhamentoCondicaoSaudeDto>, 'columns'> {}

export function AcompanhamentoCondicaoSaudeListing(props: AcompanhamentoCondicaoSaudeListingProps) {
  const { ...tableProps } = props
  const history = useHistory()

  const renderCidadao = (row: AcompanhamentoCondicaoSaudeDto) => {
    return (
      <VFlow vSpacing={0}>
        <Text fontWeight='bold'>{row.nomeSocialCidadao?.titleCase() ?? row.nomeCidadao?.titleCase()}</Text>
        {row.cpfCidadao ? (
          <HFlow hSpacing={0.25} alignItems='center'>
            <Text fontWeight='bold'>CPF</Text>
            <Cpf value={row.cpfCidadao} />
          </HFlow>
        ) : row.cnsCidadao ? (
          <HFlow hSpacing={0.25} alignItems='center'>
            <Text fontWeight='bold'>CNS</Text>
            <Cns value={row.cnsCidadao} />
          </HFlow>
        ) : (
          <Text>Cidadão sem CNS e CPF</Text>
        )}
      </VFlow>
    )
  }

  const renderTelefone = (row: AcompanhamentoCondicaoSaudeDto) => {
    return !row?.telefoneCelular && !row?.telefoneContato && !row?.telefoneResidencial ? (
      <Text>-</Text>
    ) : (
      <span>
        {row.telefoneResidencial && (
          <Fragment>
            <Telefone value={row.telefoneResidencial} /> <br />
          </Fragment>
        )}
        {row.telefoneCelular && (
          <Fragment>
            <Telefone value={row.telefoneCelular} /> <br />
          </Fragment>
        )}
        {row.telefoneContato && (
          <Fragment>
            <Telefone value={row.telefoneContato} /> <br />
          </Fragment>
        )}
      </span>
    )
  }

  const renderUltimoAtend = (row: AcompanhamentoCondicaoSaudeDto) => {
    return !row?.dataUltimoAtendimento ? (
      <Text>-</Text>
    ) : (
      <Fragment>
        {' '}
        {row.dataUltimoAtendimento && <DateTime value={row.dataUltimoAtendimento} format='DD/MM/YYYY' />}{' '}
      </Fragment>
    )
  }

  return (
    <TableBox>
      <PagedTable<AcompanhamentoCondicaoSaudeDto>
        {...tableProps}
        columns={[
          {
            name: 'cidadao',
            header: 'Cidadão',
            sortable: true,
            render: renderCidadao,
            style: css`
              width: 18rem;
              padding: 1rem;
            `,
          },
          {
            name: 'endereco',
            header: 'Endereço',
            sortable: true,
            render: (row: AcompanhamentoCondicaoSaudeDto) => row.endereco.titleCase(),
            style: css`
              width: 26rem;
            `,
          },
          {
            name: 'telefone',
            header: 'Telefone',
            sortable: false,
            render: renderTelefone,
            style: css`
              width: 9rem;
            `,
          },
          {
            name: 'dataAtendimento',
            header: 'Último atendimento',
            sortable: true,
            render: renderUltimoAtend,
            style: css`
              width: 4.65rem;
            `,
          },
          {
            name: 'visualizarCidadao',
            header: '',
            render: (row: AcompanhamentoCondicaoSaudeDto) => {
              return (
                <Tooltip text='Visualizar cidadão'>
                  <Button
                    type='button'
                    skin='ghost'
                    size='small'
                    onClick={() => {
                      history.push(`/cidadao/${row.idCidadao}/informacoes`)
                    }}
                  >
                    <Icon icon='zoomOutline' />
                  </Button>
                </Tooltip>
              )
            },
            style: { width: '4rem' },
          },
        ]}
      />
    </TableBox>
  )
}

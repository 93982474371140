import { formatNumberRemovingDots, getNumberPrecision } from 'util/number'

import { ValidationMessages } from './index'
import { MSG_ERRO_PREENCHIMENTO_OBRIGATORIO } from './model'

export const messages: ValidationMessages = {
  arrayMaxLength: (value, ...args) => `Deve possuir no máximo ${args[0]} itens.`,
  beforeEqual130Years: (value, ...args) => `Deve ser anterior ou igual a 130 anos.`,
  beforeEqualToday: (value, ...args) => `Deve ser anterior ou igual à data atual.`,
  afterEqualToday: (value, ...args) => `Deve ser posterior ou igual à data atual.`,
  beforeEqualTo: (value, ...args) => `Deve ser anterior ou igual à ${args[0]}.`,
  afterEqualTo: (value, ...args) => `Deve ser posterior ou igual à ${args[0]}.`,
  beforeTo: (value, ...args) => `Deve ser anterior à ${args[0]}.`,
  afterTo: (value, ...args) => `Deve ser posterior à ${args[0]}.`,
  afterBirthdate: (value, ...args) => `Deve ser posterior à data de nascimento.`,
  empty: (value, ...args) => `Não deve ser preenchido.`,
  invalid: (value, ...args) => `Campo inválido.`,
  length: (value, ...args) => `Deve possuir ${args[0]} caracteres.`,
  minLength: (value, ...args) => `Deve possuir ao menos ${args[0]} caractere${args[0] !== 1 ? 's' : ''}.`,
  maxLength: (value, ...args) => `Deve possuir no máximo ${args[0]} caractere${args[0] !== 1 ? 's' : ''}.`,
  match: (value, ...args) => `Um ou mais caracteres informados não são permitidos para esse campo.`,
  microarea: (value, ...args) => 'Deve ter valor entre 01 e 99.',
  nome: (value, ...args) => `Informe nome e sobrenome.`,
  simpleName: (value, ...args) => `Deve conter apenas letras (acentuadas ou não).`,
  nomeOneLetter: (value, ...args) => `Nome não pode ter somente uma letra.`,
  nomeTwoLetters: (value, ...args) => `Nome e sobrenome não podem ter apenas duas letras.`,
  range: (value, ...args) =>
    `Deve ter valor entre ${formatNumberToValidationMessage(args[0])} e ${formatNumberToValidationMessage(args[1])}.`,
  minRange: (value, ...args) => `Deve ter valor igual ou acima de ${formatNumberToValidationMessage(args[0])}.`,
  maxRange: (value, ...args) => `Deve ter valor igual ou abaixo de ${formatNumberToValidationMessage(args[0])}.`,
  required: (value, ...args) => MSG_ERRO_PREENCHIMENTO_OBRIGATORIO,
  senha: (value, ...args) => `Deve possuir ao menos uma letra e um número.`,
  igualA: (value, ...args) => `Os campos ${args[0]} e ${args[1]} devem ser iguais.`,
  oneRequired: (value, ...args) => `Pelo menos um dos campos é de preenchimento obrigatório.`,
  nonWhitespaces: (value, ...args) => `Não deve conter espaços.`,
  maxYearsPeriod: (value) =>
    `O período não deve ser superior a ${'ano'.pluralizeAndConcatValue(value)}. Selecione outro período`,
  maxWeeksPeriod: (value) =>
    `O período não deve ser superior a ${'semana'.pluralizeAndConcatValue(value)}. Selecione outro período`,
  addressNumberPattern: (value, ...args) => 'Deve conter apenas números e letras não acentuadas.',
}

const formatNumberToValidationMessage = (value: number): string => {
  const precision = getNumberPrecision(value)
  return formatNumberRemovingDots(value, precision)
}

/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Alert, Button, Heading, HFlow, Text, Theme, useTheme, VFlow } from 'bold-ui'
import { Form, SubmitButton } from 'components/form'
import { getFieldError } from 'components/form/final-form/util'
import { FormApi } from 'final-form'
import { IvcfPerguntaEnum } from 'graphql/types.generated'
import { MutableRefObject, useMemo } from 'react'
import { useField } from 'react-final-form'
import { calculateAge } from 'util/date/calculateAge'
import { humanizeAge } from 'util/date/humanize-age'
import { MetaPath, metaPath } from 'util/metaPath'

import { AtendimentoIvcfModel, IvcfGrupoEnum, IvcfModel, IvcfSubGrupoEnum } from '../model-ivcf'
import { calculateIvcf } from '../util-ivcf'
import { ivcfDecorator } from './calculator-ivcf'
import { IvcfGroupHeader } from './IvcfGroupHeader'
import { IvcfPerguntaField } from './IvcfPerguntaField'
import { IvcfSubGroupSection } from './IvcfSubGroupSection'
import { ivcfValidator } from './validator-ivcf'

interface IvcfFormProps {
  name: MetaPath<AtendimentoIvcfModel>
  cidadaoDataNascimento: string
  dataInicioAtendimento: string
  rootRef: MutableRefObject<HTMLDivElement>
  initialValues?: IvcfModel
  hideHeader?: boolean
  hideBorder?: boolean
  readOnly?: boolean
  isEditing?: boolean
  onSubmit(values: AtendimentoIvcfModel): void
  onCancelEdit?(): void
}

const formPath = metaPath<IvcfModel>()

export function IvcfForm(props: IvcfFormProps) {
  const {
    name,
    cidadaoDataNascimento,
    dataInicioAtendimento,
    rootRef,
    initialValues,
    hideHeader,
    hideBorder,
    readOnly,
    isEditing,
    onSubmit,
    onCancelEdit,
  } = props

  const theme = useTheme()
  const styles = useMemo(() => createStyles(theme, isEditing), [isEditing, theme])
  const { meta } = useField<AtendimentoIvcfModel>(name.absolutePath())

  const { years: idadeCidadaoEmAnos } = calculateAge(cidadaoDataNascimento, dataInicioAtendimento)
  const humanizedIdadeCidadao = humanizeAge(cidadaoDataNascimento, dataInicioAtendimento)
  const submitDoAtendimentoFalhouDevidoAoNaoPreenchimentoDoIvcf = getFieldError(meta)
  const isReadOnly = readOnly && !isEditing
  const scrollTo = rootRef.current?.offsetTop - (isEditing ? 500 : 220)

  const handleSubmit = (values: IvcfModel, formApi: FormApi<IvcfModel>) => {
    window.scrollTo({ behavior: 'smooth', top: scrollTo })
    onSubmit(calculateIvcf(values, idadeCidadaoEmAnos))
    setTimeout(formApi.reset)
  }

  const handleCancelEdit = (formApi: FormApi<IvcfModel>) => {
    onCancelEdit?.()
    setTimeout(formApi.reset)
  }

  return (
    <Form<IvcfModel>
      onSubmit={handleSubmit}
      validate={ivcfValidator}
      decorators={[ivcfDecorator]}
      initialValues={initialValues}
      render={(renderProps) => {
        const reset = () => {
          renderProps.form.reset()
          renderProps.form.getRegisteredFields().forEach((field) => renderProps.form.resetFieldState(field))
        }

        const hasErrors =
          submitDoAtendimentoFalhouDevidoAoNaoPreenchimentoDoIvcf ||
          (renderProps.submitFailed && renderProps.hasValidationErrors)

        return (
          <VFlow>
            {hasErrors && (
              <Alert type='danger' inline>
                É necessário responder todas as perguntas do formulário para realizar o cálculo.
              </Alert>
            )}
            <div css={styles.box(hasErrors, hideBorder)}>
              {!hideHeader && (
                <HFlow hSpacing={0.25} alignItems='center' style={styles.header}>
                  <Heading level={4}>Perguntas do instrumento</Heading>
                  <Heading level={4} color='danger'>
                    *
                  </Heading>
                </HFlow>
              )}

              <VFlow vSpacing={0}>
                <IvcfGroupHeader grupo={IvcfGrupoEnum.IDADE} isEditing={isEditing} />
                <HFlow alignItems='center' style={styles.question}>
                  <Text fontWeight='bold'>{humanizedIdadeCidadao}.</Text>
                </HFlow>

                <IvcfGroupHeader grupo={IvcfGrupoEnum.PERCEPCAO_SAUDE} isEditing={isEditing} />
                <IvcfPerguntaField
                  ivcfPath={formPath}
                  pergunta={IvcfPerguntaEnum.PERCEPCAO_SAUDE_PERGUNTA_1}
                  isEditing={isEditing}
                  readOnly={isReadOnly}
                />

                <IvcfGroupHeader grupo={IvcfGrupoEnum.AVD} isEditing={isEditing} />
                <IvcfSubGroupSection
                  ivcfPath={formPath}
                  subgrupo={IvcfSubGrupoEnum.AVD_INSTRUMENTAL}
                  isEditing={isEditing}
                  readOnly={isReadOnly}
                />
                <IvcfSubGroupSection
                  ivcfPath={formPath}
                  subgrupo={IvcfSubGrupoEnum.AVD_BASICA}
                  isEditing={isEditing}
                  readOnly={isReadOnly}
                />

                <IvcfGroupHeader grupo={IvcfGrupoEnum.COGNICAO} isEditing={isEditing} />
                <IvcfPerguntaField
                  ivcfPath={formPath}
                  pergunta={IvcfPerguntaEnum.COGNICAO_PERGUNTA_1}
                  isEditing={isEditing}
                  readOnly={isReadOnly}
                />
                <IvcfPerguntaField
                  ivcfPath={formPath}
                  pergunta={IvcfPerguntaEnum.COGNICAO_PERGUNTA_2}
                  isEditing={isEditing}
                  readOnly={isReadOnly}
                />
                <IvcfPerguntaField
                  ivcfPath={formPath}
                  pergunta={IvcfPerguntaEnum.COGNICAO_PERGUNTA_3}
                  isEditing={isEditing}
                  readOnly={isReadOnly}
                />

                <IvcfGroupHeader grupo={IvcfGrupoEnum.HUMOR} isEditing={isEditing} />
                <IvcfPerguntaField
                  ivcfPath={formPath}
                  pergunta={IvcfPerguntaEnum.HUMOR_PERGUNTA_1}
                  isEditing={isEditing}
                  readOnly={isReadOnly}
                />
                <IvcfPerguntaField
                  ivcfPath={formPath}
                  pergunta={IvcfPerguntaEnum.HUMOR_PERGUNTA_2}
                  isEditing={isEditing}
                  readOnly={isReadOnly}
                />

                <IvcfGroupHeader grupo={IvcfGrupoEnum.MOBILIDADE} isEditing={isEditing} />
                <IvcfSubGroupSection
                  ivcfPath={formPath}
                  subgrupo={IvcfSubGrupoEnum.ALCANCE_PREENSAO_PINCA}
                  isEditing={isEditing}
                  readOnly={isReadOnly}
                />
                <IvcfSubGroupSection
                  ivcfPath={formPath}
                  subgrupo={IvcfSubGrupoEnum.CAPACIDADE}
                  isEditing={isEditing}
                  readOnly={isReadOnly}
                />
                <IvcfSubGroupSection
                  ivcfPath={formPath}
                  subgrupo={IvcfSubGrupoEnum.MARCHA}
                  isEditing={isEditing}
                  readOnly={isReadOnly}
                />
                <IvcfSubGroupSection
                  ivcfPath={formPath}
                  subgrupo={IvcfSubGrupoEnum.CONTINENCIA}
                  isEditing={isEditing}
                  readOnly={isReadOnly}
                />

                <IvcfGroupHeader grupo={IvcfGrupoEnum.COMUNICACAO} isEditing={isEditing} />
                <IvcfSubGroupSection
                  ivcfPath={formPath}
                  subgrupo={IvcfSubGrupoEnum.VISAO}
                  isEditing={isEditing}
                  readOnly={isReadOnly}
                />
                <IvcfSubGroupSection
                  ivcfPath={formPath}
                  subgrupo={IvcfSubGrupoEnum.AUDICAO}
                  isEditing={isEditing}
                  readOnly={isReadOnly}
                />

                <IvcfGroupHeader grupo={IvcfGrupoEnum.COMORBIDADE_MULTIPLA} isEditing={isEditing} />
                <IvcfPerguntaField
                  ivcfPath={formPath}
                  pergunta={IvcfPerguntaEnum.COMORBIDADE_PERGUNTA_1}
                  isEditing={isEditing}
                  readOnly={isReadOnly}
                />
                <IvcfPerguntaField
                  ivcfPath={formPath}
                  pergunta={IvcfPerguntaEnum.COMORBIDADE_PERGUNTA_2}
                  isEditing={isEditing}
                  readOnly={isReadOnly}
                />
                <IvcfPerguntaField
                  ivcfPath={formPath}
                  pergunta={IvcfPerguntaEnum.COMORBIDADE_PERGUNTA_3}
                  isEditing={isEditing}
                  readOnly={isReadOnly}
                  hideBorderBottom
                />

                {isEditing && (
                  <HFlow justifyContent='flex-end' hSpacing={0.5} style={styles.editingFooter}>
                    <Button size='small' onClick={() => handleCancelEdit(renderProps.form)}>
                      Cancelar
                    </Button>
                    <SubmitButton handleSubmit={renderProps.handleSubmit} size='small'>
                      Salvar
                    </SubmitButton>
                  </HFlow>
                )}
              </VFlow>
            </div>

            {!readOnly && (
              <HFlow justifyContent='flex-end' hSpacing={0.5}>
                <Button size='small' onClick={reset}>
                  Limpar campos
                </Button>
                <SubmitButton handleSubmit={renderProps.handleSubmit} size='small'>
                  Calcular índice
                </SubmitButton>
              </HFlow>
            )}
          </VFlow>
        )
      }}
    />
  )
}

const createStyles = (theme: Theme, isEditing: boolean) => ({
  box: (hasErrors: boolean, hideBorder: boolean) => css`
    border: 1px solid ${hasErrors ? theme.pallete.status.danger.main : theme.pallete.gray.c80};
    border-width: ${hideBorder && !hasErrors ? 0 : 1};
    border-radius: 2px;
    overflow: hidden;
  `,
  header: css`
    display: flex;
    align-items: center;
    height: 2.5rem;
    padding: 0 1rem;
    background: ${theme.pallete.surface.main};
    border-bottom: 1px solid ${theme.pallete.gray.c80};
  `,
  question: css`
    padding: 0.5rem 1rem;
    border-bottom: 1px solid ${theme.pallete.gray.c80};
    background: ${isEditing ? theme.pallete.primary.c90 : theme.pallete.surface.main};
  `,
  editingFooter: css`
    padding: 1rem;
    background: ${theme.pallete.primary.c90};
    border-top: 1px solid ${theme.pallete.gray.c80};
  `,
})

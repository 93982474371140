/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Modal, ModalBody, Theme, useTheme } from 'bold-ui'
import { useEffect, useRef } from 'react'

import { ChatMessage, SUPPORTS_VIRTUAL_KEYBOARD } from '../model-videochamada'
import { ChatVideochamada, ChatVideochamadaProps } from './ChatVideochamada'

declare global {
  interface VirtualKeyboard {
    overlaysContent: boolean
  }

  interface Navigator {
    readonly virtualKeyboard?: VirtualKeyboard
  }
}

interface ChatVideochamadaMobileModalProps extends ChatVideochamadaProps {
  open: boolean
  messages: ChatMessage[]
  onClose?(): void
  onSendMessage(message: string): void
  messageInput: string
  onChangeInput(message: string): void
}

export function ChatVideochamadaMobileModal(props: ChatVideochamadaMobileModalProps) {
  const { open, messages, onClose, onSendMessage, messageInput, onChangeInput, ...rest } = props

  const theme = useTheme()
  const styles = createStyles(theme)
  const modalRef = useRef<HTMLDivElement>()

  useEffect(() => {
    //TODO RNG: rever esta manipulação da DOM para estilização do modal quando for encontrada solução mais genérica para mobile. #19068
    const containerElement = modalRef.current?.parentNode as Element
    const backdrop = containerElement.nextElementSibling as Element

    if (containerElement) {
      const parentChatModalMobileStyle = css(styles.parentChatModalMobile)
      const backdropChatModalMobileStyle = css(styles.backdropChatModalMobile)

      containerElement.setAttribute('style', parentChatModalMobileStyle.styles)
      backdrop.setAttribute('style', backdropChatModalMobileStyle.styles)
    }
  }, [styles.parentChatModalMobile, styles.backdropChatModalMobile])

  useEffect(() => {
    if (SUPPORTS_VIRTUAL_KEYBOARD) navigator.virtualKeyboard.overlaysContent = true

    return () => {
      if (SUPPORTS_VIRTUAL_KEYBOARD) navigator.virtualKeyboard.overlaysContent = false
    }
  }, [])

  return (
    <Modal
      open={open}
      onClose={onClose}
      size='large'
      style={styles.chatModalMobile}
      hasCloseIcon={false}
      containerRef={modalRef}
    >
      <ModalBody style={styles.chatModalBodyMobile}>
        <ChatVideochamada
          open={open}
          messages={messages}
          onClose={onClose}
          onSendMessage={onSendMessage}
          isMobile
          messageInput={messageInput}
          onChangeInput={onChangeInput}
          {...rest}
        />
      </ModalBody>
    </Modal>
  )
}

const createStyles = (theme: Theme) => ({
  parentChatModalMobile: css`
    padding: 0;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows:
      1fr
      env(keyboard-inset-height, 0px);
  `,
  backdropChatModalMobile: css`
    background-color: ${theme.pallete.gray.c10};
  `,
  chatModalMobile: css`
    background-color: ${theme.pallete.gray.c10};
    height: 100%;
    max-height: 100vh;
    width: 100vw;
    min-width: 0;
    border: 0;
    border-radius: 0 !important;
  `,
  chatModalBodyMobile: css`
    height: 100%;
    padding: 1rem 0;
  `,
})

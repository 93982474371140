import { HFlow } from 'bold-ui'
import { HLabel } from 'components/HLabel'
import React from 'react'
import { formatDate } from 'util/date/formatDate'

import { AssinaturaEletronicaAtendTableFilterModel } from '../model'

export interface AssinaturaEletronicaInfoProps
  extends Omit<AssinaturaEletronicaAtendTableFilterModel, 'criterioExibicao' | 'pageParams'> {
  isCoordenador: boolean
}

export function AssinaturaEletronicaAtendInfo(props: AssinaturaEletronicaInfoProps) {
  const { equipe, lotacao, periodo, isCoordenador } = props

  return (
    <HFlow>
      {isCoordenador && equipe && <HLabel title='Equipe:'> {equipe.nome} </HLabel>}
      {isCoordenador && lotacao && <HLabel title='Profissional:'> {lotacao.profissional?.nome} </HLabel>}
      {(periodo?.startDate || periodo?.endDate) && (
        <HLabel title='Período:'>
          {formatDate(periodo?.startDate)} até {formatDate(periodo.endDate)}
        </HLabel>
      )}
    </HFlow>
  )
}

import { Omit, Text } from 'bold-ui'
import { SelectField, SelectFieldProps } from 'components/form/final-form'
import { useMicroareaImovelSelectFieldQuery } from 'graphql/hooks.generated'
import { MicroareaImovelSelectFieldQuery, TipoEnderecoEnum } from 'graphql/types.generated'
import React from 'react'

type MicroareaImovelSelectModel = MicroareaImovelSelectFieldQuery['microareasImoveis'][0]

interface MicroareaImovelSelectFieldProps extends Omit<SelectFieldProps<String>, 'items' | 'itemToString'> {
  cnes: string
  ine: string
  tipoEndereco: TipoEnderecoEnum
}

const SEM_EQUIPE_ITEM = {
  abreviatura: 'FA',
  extenso: 'Fora de Área',
}

const itemToString = (item: MicroareaImovelSelectModel) =>
  item === SEM_EQUIPE_ITEM.abreviatura ? SEM_EQUIPE_ITEM.extenso : item

const renderItem = (item: MicroareaImovelSelectModel) =>
  item === SEM_EQUIPE_ITEM.abreviatura ? renderItemForaDeArea(item) : renderItemMicroarea(item)

const renderItemForaDeArea = (item: MicroareaImovelSelectModel) => (
  <Text fontWeight='bold'>{SEM_EQUIPE_ITEM.extenso}</Text>
)

const renderItemMicroarea = (item: MicroareaImovelSelectModel) => <Text fontWeight='bold'>{item}</Text>

export function MicroareaImovelSelectField(props: MicroareaImovelSelectFieldProps) {
  const { cnes, ine, tipoEndereco, ...rest } = props

  const { data } = useMicroareaImovelSelectFieldQuery({
    variables: { input: { cnes, ine, tipoEndereco } },
    skip: !cnes || !ine,
  })

  return (
    <SelectField<String> itemToString={itemToString} renderItem={renderItem} items={data.microareasImoveis} {...rest} />
  )
}

import { Button, Heading, HFlow, Modal, ModalBody, Text, VFlow } from 'bold-ui'
import abaMicroarea from 'images/visualizacao-territorial/aba-microarea.png'
import botaoAdicionarImovel from 'images/visualizacao-territorial/botao-adicionar-imovel.png'
import cabecalhoImovel from 'images/visualizacao-territorial/cabecalho-do-imovel.png'
import selectFieldCidadao from 'images/visualizacao-territorial/select-field-adicionar-cidadaos.png'
import React from 'react'

interface ModalNovidadesAcompanhamentoTerritorioProps {
  isModalOpen: boolean
  onClose(): void
}

export function ModalNovidadesAcompanhamentoTerritorio(props: ModalNovidadesAcompanhamentoTerritorioProps) {
  const { isModalOpen, onClose } = props

  return (
    <Modal size='large' open={isModalOpen} closeOnBackdropClick={true} onClose={onClose}>
      <ModalBody>
        <VFlow vSpacing={1}>
          <Heading level={2}>Novidades cadastro de imóveis e famílias</Heading>
          <Text>
            A funcionalidade de Acompanhamento do Território, no menu lateral, foi evoluída para permitir cadastrar e
            atualizar os imóveis e famílias, condições de moradia e responsabilidades de acompanhamento, tudo isso em
            uma única interface moderna e intuitiva, sem a necessidade de gerar várias Fichas CDS manualmente.
          </Text>
          <VFlow>
            <Heading level={4}>Organização por microáreas</Heading>
            <Text>
              Visualizar somente as informações mais atualizadas do imóvel e seus núcleos familiares, organizados em sua
              própria unidade, equipe, microárea e logradouro.
            </Text>
            <img src={abaMicroarea} alt='Abas das microáreas da Visualização do território' />
          </VFlow>
          <VFlow>
            <Heading level={4}>Cadastro do imóvel</Heading>
            <Text>
              Caso não encontre o imóvel listado, inicie um novo cadastro através do botão “Adicionar imóvel”. Nele você
              poderá preencher todos os campos de forma mais moderna, segura e simplificada.
            </Text>
            <img src={botaoAdicionarImovel} alt='Botão de adicionar imóvel no Cadastro do Imóvel' />
          </VFlow>
          <VFlow>
            <Heading level={4}>Cabeçalho do imóvel</Heading>
            <Text>
              A nova funcionalidade permite registrar todo o antigo cabeçalho da ficha simplesmente selecionando uma
              lotação ativa no campo "Responsabilidade de acompanhamento".
            </Text>
            <img src={cabecalhoImovel} alt='Cabeçalho do imóvel no Cadastro do imóvel' />
          </VFlow>
          <VFlow>
            <Heading level={4}>Adicionando família</Heading>
            <Text>
              Cadastre e atualize famílias buscando e selecionando facilmente os cidadãos cadastrados, sem precisar se
              preocupar em inserir numeros de CPF/CNS ou criar novas fichas para os membros das famílias.
            </Text>
            <img src={selectFieldCidadao} alt='Campo para adicionar e atualizar famílias' />
          </VFlow>
        </VFlow>
        <HFlow justifyContent='flex-end'>
          <Button onClick={onClose} kind='primary'>
            Entendi
          </Button>
        </HFlow>
      </ModalBody>
    </Modal>
  )
}

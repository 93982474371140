import { HFlow, Text } from 'bold-ui'
import { DateTime } from 'components/date'
import { Age } from 'components/date/Age'
import { Cpf } from 'components/label'
import React from 'react'

import { CidadaoListingFormModel } from '../types/model-pesquisarCidadao'

interface CidadaoCamposComunsProps {
  data: CidadaoListingFormModel
}

export const CidadaoCamposComuns = (props: CidadaoCamposComunsProps) => {
  const { data } = props

  return (
    <>
      <HFlow>
        <HFlow alignItems='center' hSpacing={0.5}>
          <Text fontWeight='bold'>CPF</Text>
          {data.cpf ? <Cpf value={data.cpf} /> : '-'}
        </HFlow>
        <HFlow hSpacing={0.5}>
          <Text fontWeight='bold'>CNS</Text>
          {data.cns ? <Text>{data.cns}</Text> : '-'}
        </HFlow>
      </HFlow>
      <HFlow alignItems='center' hSpacing={0.5}>
        <Text fontWeight='bold'>Data de nascimento</Text>
        {data.dataNascimento ? (
          <>
            <DateTime format='DD/MM/YYYY' value={data.dataNascimento} />
            <Age dateOfBirth={data.dataNascimento} />
          </>
        ) : (
          '-'
        )}
      </HFlow>
    </>
  )
}

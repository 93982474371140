import { Button, HFlow, Modal, ModalBody, ModalFooter, VFlow } from 'bold-ui'
import { LoadingIndicator } from 'components/loading'
import React from 'react'
import { CidadaoNomeSection } from 'view/cidadao/visualizacao/CidadaoCabecalhoSection'
import { CidadaoInformacoesView } from 'view/cidadao/visualizacao/CidadaoInformacoesView'
import { CidadaoVisualizacaoModal } from 'view/unificacaoCadastro/UnificacaoCadastroFormModel'

interface CidadaoDadosModalProps {
  closeModal(): void
  cidadao: CidadaoVisualizacaoModal
  openModal: boolean
}

export function CidadaoDadosModal(props: CidadaoDadosModalProps) {
  const { cidadao, openModal, closeModal } = props

  return (
    <Modal open={openModal} onClose={closeModal} closeOnBackdropClick={false} style={{ width: '61rem' }}>
      <ModalBody>
        {!cidadao ? (
          <LoadingIndicator />
        ) : (
          <>
            <VFlow vSpacing={0}>
              <CidadaoNomeSection cidadao={cidadao} />
            </VFlow>
            <CidadaoInformacoesView cidadao={cidadao} mostrarProntuario={false} />
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <HFlow justifyContent='flex-end'>
          <Button kind='primary' skin='default' size='medium' data-cy='FecharButton' onClick={closeModal}>
            Fechar
          </Button>
        </HFlow>
      </ModalFooter>
    </Modal>
  )
}

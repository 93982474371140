import { Omit } from 'bold-ui'
import { SelectField, SelectFieldProps } from 'components/form/final-form'
import { TipoDomicilioEnum } from 'graphql/types.generated'
import React from 'react'

import { tipoDomicilioEnumRecord } from './condicoes-moradia-model'

export interface TipoDomicilioSelectFieldProps
  extends Omit<SelectFieldProps<TipoDomicilioEnum>, 'items' | 'itemToString'> {}

export function TipoDomicilioSelectField(props: TipoDomicilioSelectFieldProps) {
  return <SelectField<TipoDomicilioEnum> items={items} itemToString={itemToString} {...props} />
}

const items = Object.values(TipoDomicilioEnum)
const itemToString = (item: TipoDomicilioEnum) => item && tipoDomicilioEnumRecord[item]

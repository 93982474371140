import useSession from 'components/auth/useSession'
import { useFlags } from 'config/useFlagsContext'
import { OrdemListaAtendimentoEnum, StatusAtendimento } from 'graphql/types.generated'
import { useServerTime } from 'hooks/useServerTime'
import Permissions from 'types/Permissions'
import { AtendimentosFilterModel } from 'view/atendimentos/list/filter/model'

import { getMinDate } from '../utils-registroTardio'
import { ListaRegistroTardioFilterModel } from './ListaRegistroTardioFilter'

export interface AtendimentosRegistroTardioFilterModel extends Omit<AtendimentosFilterModel, 'filter'> {
  filter: ListaRegistroTardioFilterModel
}

export const REGISTRO_TARDIO_DEFAULT_DATE_DIFF = 7
export const REGISTRO_TARDIO_INDIGENA_DATE_DIFF = 30

export function useListaRegistroTardioFilterDefault(): AtendimentosRegistroTardioFilterModel {
  const { getServerTimeNow } = useServerTime()
  const serverTime = getServerTimeNow().getTime()
  const { SAUDE_INDIGENA_ENABLED } = useFlags()
  const { hasAuthorization } = useSession()

  const lotacaoPodeAtender = hasAuthorization(Permissions.visualizarListaDeAtendimento.registrarAtendimento)

  // TODO [DeLorean]: Aguardando CNES da parte do Sesai para definir como será validado as opções especificas da saúde indígena (#19881)
  const canRegistroIndigena = SAUDE_INDIGENA_ENABLED

  return {
    filter: {
      periodo: {
        startDate: getMinDate(serverTime, canRegistroIndigena),
        endDate: new Date(serverTime),
      },
      ordem: OrdemListaAtendimentoEnum.ORDEM_CHEGADA_DECRESCENTE,
      statusAtendimento: [StatusAtendimento.AGUARDANDO_ATENDIMENTO, StatusAtendimento.EM_ATENDIMENTO],
      responsaveis: [],
      somenteMeusAtendimentos: lotacaoPodeAtender,
    },
    lastUpdate: serverTime,
    lastDailyUpdate: serverTime,
    pageParams: {},
  }
}

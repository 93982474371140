import { Omit } from 'bold-ui'
import { SelectField, SelectFieldProps } from 'components/form/final-form'
import { AnimaisDomicilioEnum } from 'graphql/types.generated'
import React from 'react'

export interface AnimaisDomicilioSelectFieldProps
  extends Omit<SelectFieldProps<AnimaisDomicilioEnum>, 'items' | 'itemToString'> {}

export function AnimaisDomicilioSelectField(props: AnimaisDomicilioSelectFieldProps) {
  return <SelectField<AnimaisDomicilioEnum> items={items} itemToString={itemToString} {...props} />
}

const items = Object.values(AnimaisDomicilioEnum)
const itemToString = (item: AnimaisDomicilioEnum) => item && descricaoRecord[item]

const descricaoRecord: Partial<Record<AnimaisDomicilioEnum, string>> = {
  GATO: 'Gato',
  CACHORRO: 'Cachorro',
  PASSARO: 'Pássaro',
  MACACO: 'Macaco',
  GALINHA: 'Galinha',
  PORCO: 'Porco',
  REPTEIS: 'Répteis',
  OUTRO: 'Outro',
}

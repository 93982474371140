import { VFlow } from 'bold-ui'
import CheckPermission from 'components/auth/CheckPermission'
import { BoxGroup } from 'components/BoxGroup'
import { LoadingIndicator } from 'components/loading'
import { useConexaoQuery } from 'graphql/hooks.generated'
import { useConfiguracoes } from 'hooks/configuracoes'
import React, { useCallback } from 'react'
import Permissions from 'types/Permissions'

import { ConfiguracoesInternetBox } from './internet/ConfiguracoesInternetBox'

export const ConexaoView = () => {
  const { data, refetch } = useConexaoQuery()

  const {
    configuracoes: { smtpConfigurado },
    update,
  } = useConfiguracoes()
  const refetchConfiguracoes = useCallback(() => {
    update()
    refetch()
  }, [refetch, update])

  if (!data?.conexao) {
    return <LoadingIndicator />
  }

  const { internet, cadsus, horus, videochamadas, agendaOnline, prescricaoDigital } = data.conexao

  return (
    <VFlow vSpacing={2}>
      <CheckPermission permission={Permissions.configuracaoDeInstalacao.configurarConexaoComInternet}>
        <BoxGroup>
          <ConfiguracoesInternetBox
            internet={internet}
            cadsus={cadsus}
            horus={horus}
            videochamada={videochamadas}
            agendaOnline={agendaOnline}
            prescricaoDigital={prescricaoDigital}
            smtpConfigurado={smtpConfigurado}
            refetch={refetchConfiguracoes}
          />
        </BoxGroup>
      </CheckPermission>
    </VFlow>
  )
}

/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Theme, useTheme } from 'bold-ui'
import { forwardRef, RefObject, TextareaHTMLAttributes, useEffect } from 'react'

interface ChatTextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  onPressEnter?: () => void
  isMobile?: boolean
}

export const ChatTextArea = forwardRef((props: ChatTextAreaProps, ref: RefObject<HTMLTextAreaElement>) => {
  const { onPressEnter, value, onChange, isMobile = false } = props
  const theme = useTheme()
  const styles = createStyles(theme, isMobile)

  useEffect(() => {
    if (ref.current) {
      ref.current.style.height = '1rem'
      if (value !== '') ref.current.style.height = ref.current.scrollHeight + 'px'
    }
  }, [ref, value])

  const handleInputKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !!onPressEnter) {
      e.preventDefault()
      ref.current.focus()
      onPressEnter()
    }
  }

  return (
    <textarea
      css={styles.textArea}
      onChange={onChange}
      onKeyDown={handleInputKeyPress}
      placeholder='Enviar mensagem'
      maxLength={2000}
      ref={ref}
      rows={1}
      value={value}
    />
  )
})

const createStyles = (theme: Theme, isMobile: boolean) => ({
  textArea: css`
    display: flex;
    max-height: 3.5rem;
    resize: none;
    overflow: auto;
    border: none;
    outline: none;
    margin: 0;
    padding: 0 0.25rem 0 0.5rem;
    width: inherit;
    background-color: ${isMobile && theme.pallete.gray.c10};
    color: ${isMobile && theme.pallete.gray.c100};

    ::-webkit-scrollbar {
      width: 0.3735rem;
    }

    ::-webkit-scrollbar-track {
      -webkit-box-shadow: transparent;
      -webkit-border-radius: 10px;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
      -webkit-border-radius: 10px;
      border-radius: 10px;
      background: ${theme.pallete.gray.c70};
    }
  `,
})

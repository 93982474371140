import { Omit } from 'bold-ui'
import { SelectField, SelectFieldProps } from 'components/form/final-form'
import { TipoSituacaoMoradiaEnum } from 'graphql/types.generated'
import React from 'react'

import { tipoSituacaoMoradiaEnumRecord } from './condicoes-moradia-model'

export interface TipoSituacaoMoradiaSelectFieldProps
  extends Omit<SelectFieldProps<TipoSituacaoMoradiaEnum>, 'items' | 'itemToString'> {}

export function TipoSituacaoMoradiaSelectField(props: TipoSituacaoMoradiaSelectFieldProps) {
  return <SelectField<TipoSituacaoMoradiaEnum> items={items} itemToString={itemToString} {...props} />
}

const items = Object.values(TipoSituacaoMoradiaEnum)
const itemToString = (item: TipoSituacaoMoradiaEnum) => item && tipoSituacaoMoradiaEnumRecord[item]

import { Heading, HFlow, Tag, Text, Tooltip, VFlow } from 'bold-ui'
import { Box } from 'components/Box'
import { DateTime } from 'components/date'
import { Telefone } from 'components/label'
import React from 'react'
import { identidadeGenero, sexo } from 'types/enums'

import { CidadaoListingFormModel } from '../types/model-pesquisarCidadao'
import { CidadaoCamposComuns } from './CidadaoCamposComuns'
import { CidadaoListButtons } from './CidadaoListButtons'

interface CidadaoAldeadoListingItemViewProps {
  data: CidadaoListingFormModel
  telefone: string
  externalAccess: boolean
  hasAnyPermission: boolean
  cadastrarEEditar: boolean
  inativar: boolean
  excluir: boolean
  visualizarAgenda: boolean
  onExcluirClick(): void
  onVisualizarClick(): void
  onEditarClick(): void
  onReativarClick(): void
  handleVerAgendamentosClick(): void
  navigateToCidadaoCallbackUrl(cidadaoId: any): void
  modalInformarLoginGovBrOpen: boolean
  setModalInformarLoginGovBrOpen(value: boolean): void
  continueEditarCidadao(): void
}

export const CidadaoAldeadoListingItemView = (props: CidadaoAldeadoListingItemViewProps) => {
  const {
    data,
    telefone,
    externalAccess,
    hasAnyPermission,
    cadastrarEEditar,
    inativar,
    excluir,
    visualizarAgenda,
    onExcluirClick,
    onVisualizarClick,
    onEditarClick,
    onReativarClick,
    handleVerAgendamentosClick,
    navigateToCidadaoCallbackUrl,
    modalInformarLoginGovBrOpen,
    setModalInformarLoginGovBrOpen,
    continueEditarCidadao,
  } = props

  const nomeTradicional = data.cidadaoAldeado.nomeTradicional
  const nomeSocial = data.nomeSocial
  const enderecoIndigena = data.enderecoIndigena

  return (
    <>
      <Box style={{ minHeight: 136, textAlign: 'left' }}>
        <HFlow justifyContent='space-between'>
          <VFlow vSpacing={0}>
            <HFlow hSpacing={0.5}>
              {data.faleceu && <Tag>Óbito</Tag>}
              {!data.ativo && <Tag>Inativo</Tag>}
              {data.unificado && <Tag>Cadastro unificado</Tag>}
              {data.unificacaoBase && (
                <Tooltip text={`Cadastro originado após unificação da Base ${data.unificacaoBase}`}>
                  <Tag>Base {data.unificacaoBase.titleCase()}</Tag>
                </Tooltip>
              )}
            </HFlow>
            <HFlow hSpacing={0.5} alignItems='baseline'>
              <Heading level={3}>{(nomeSocial || data.nome)?.titleCase()}</Heading>
              {nomeSocial && (
                <>
                  <Text fontWeight='bold'>(Nome social)</Text>
                  <Text>{'| ' + data.nome?.titleCase()}</Text>
                </>
              )}
              {nomeTradicional && (
                <>
                  <Text>{'| ' + nomeTradicional.titleCase()}</Text>
                  <Text>(Nome tradicional)</Text>
                </>
              )}
            </HFlow>
            <HFlow hSpacing={2}>
              <VFlow vSpacing={0} style={{ width: 350 }}>
                <CidadaoCamposComuns data={data} />
                <HFlow hSpacing={0.5}>
                  <Text fontWeight='bold'>Sexo</Text>
                  <Text>{sexo[data.sexo]}</Text>
                  <Text fontWeight='bold'>Id. de gênero</Text>
                  <Text>{data.identidadeGeneroDbEnum ? identidadeGenero[data.identidadeGeneroDbEnum] : '-'}</Text>
                </HFlow>
                <HFlow alignItems='center' hSpacing={0.5}>
                  <Text fontWeight='bold'>Telefone</Text>
                  {telefone ? <Telefone value={telefone}></Telefone> : <Text>-</Text>}
                </HFlow>
                <HFlow alignItems='center' hSpacing={0.5}>
                  <Text fontWeight='bold'>Última atualização</Text>
                  {data.dataAtualizado ? <DateTime format='DD/MM/YYYY' value={data.dataAtualizado} /> : '-'}
                </HFlow>
              </VFlow>
              <VFlow vSpacing={0}>
                <HFlow hSpacing={0.5}>
                  <Text fontWeight='bold'>Nome da mãe</Text>
                  <Text>{data.nomeMae ? data.nomeMae.titleCase() : '-'}</Text>
                </HFlow>
                <HFlow alignItems='center' hSpacing={0.5}>
                  <Text fontWeight='bold'>DSEI</Text>
                  <Text>{enderecoIndigena?.dseiResidencia?.nome.titleCase() || '-'}</Text>
                </HFlow>
                <HFlow hSpacing={0.5}>
                  <Text fontWeight='bold'>Polo base</Text>
                  <Text>{enderecoIndigena?.poloBaseResidencia?.nome.titleCase() || '-'}</Text>
                </HFlow>
                <HFlow hSpacing={0.5}>
                  <Text fontWeight='bold'>Aldeia</Text>
                  <Text>{enderecoIndigena?.aldeiaResidencia?.nome.titleCase() || '-'}</Text>
                </HFlow>
              </VFlow>
            </HFlow>
          </VFlow>
          <CidadaoListButtons
            data={data}
            externalAccess={externalAccess}
            hasAnyPermission={hasAnyPermission}
            cadastrarEEditar={cadastrarEEditar}
            inativar={inativar}
            excluir={excluir}
            visualizarAgenda={visualizarAgenda}
            onExcluirClick={onExcluirClick}
            onVisualizarClick={onVisualizarClick}
            onEditarClick={onEditarClick}
            onReativarClick={onReativarClick}
            handleVerAgendamentosClick={handleVerAgendamentosClick}
            navigateToCidadaoCallbackUrl={navigateToCidadaoCallbackUrl}
            modalInformarLoginGovBrOpen={modalInformarLoginGovBrOpen}
            setModalInformarLoginGovBrOpen={setModalInformarLoginGovBrOpen}
            continueEditarCidadao={continueEditarCidadao}
          />
        </HFlow>
      </Box>
    </>
  )
}

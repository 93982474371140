import { alert } from 'components/alert'
import { confirm } from 'components/modals/confirm'
import { UnregisterCallback } from 'history'
import { useConfiguracoes } from 'hooks/configuracoes'
import qs from 'qs'
import { useEffect } from 'react'
import { useHistory } from 'react-router'

import { useEsusViewState } from './EsusViewContext'
import { parseMessage } from './EsusViewMountTarget'

let historyUnblock: UnregisterCallback

export const useEsusNavigationController = () => {
  const sendMessage = (message: any) => window.postMessage({ type: 'sendToIframe', message }, '*')

  const history = useHistory()
  const { setUrl, baseUrl, uri } = useEsusViewState()
  const {
    configuracoes: { oldPecConnected },
  } = useConfiguracoes()

  const block = () => {
    if (!historyUnblock && oldPecConnected) {
      historyUnblock = history.block((location) => {
        return location.pathname !== history.location.pathname ? 'esus' : undefined
      })
    }
  }

  const unblock = () => {
    if (historyUnblock) {
      historyUnblock()
      historyUnblock = undefined
    }
  }

  useEffect(() => unblock, [])

  // TODO: testar se é possível colocar isso dentro de um useEffect(..., [uri])
  // ao logar e acessar um módulo inicial que é no antigo e-SUS o block acontecia antes de carregar o módulo
  setTimeout(() => {
    if (uri === '/pec/user') {
      unblock()
    } else if (!historyUnblock) {
      block()
    }
  })

  useEffect(() => {
    const messageListener = (e: MessageEvent) => {
      setTimeout(() => {
        const messageData = parseMessage(e)
        const baseUrlWithoutParameters = baseUrl.split('?')[0]
        if (
          messageData.type === 'URL' &&
          // só recebe mensagens do módulo base atual
          (messageData.to.startsWith(baseUrlWithoutParameters) ||
            // só interpreta mensagem de force se a navegação anterior já estiver concluída
            (uri.includes('?') && messageData.force))
        ) {
          unblock()
          setUrl(messageData.to)
          history.push({
            search: qs.stringify({
              iframeUrl: messageData.to,
            }),
          })
        } else if (messageData.type === 'confirmLeaving') {
          confirm({
            title: 'Deseja sair sem salvar?',
            body: 'As alterações realizadas serão perdidas.',
            onClose: () => sendMessage({ type: 'leavingConfirmation', response: 'no' }),
            onCancel: () => sendMessage({ type: 'leavingConfirmation', response: 'no' }),
            onConfirm: () => sendMessage({ type: 'leavingConfirmation', response: 'yes' }),
            confirmLabel: 'Sim, sair da tela',
            cancelLabel: 'Não, continuar aqui',
          })()
        } else if (messageData.type === 'forcePecGo') history.push(messageData.url)
        else if (messageData.type === 'showPecNotification')
          alert(messageData.alertType, messageData.message, messageData.timeout)
      })
    }
    window.addEventListener('message', messageListener)

    return () => window.removeEventListener('message', messageListener)
  }, [history, setUrl, baseUrl, uri])
}

import { SimNaoEnum } from 'components/form'
import createDecorator from 'final-form-calculate'
import { metaPath } from 'util/metaPath'

import { IvcfModel } from '../model-ivcf'

const meta = metaPath<IvcfModel>()

export const ivcfDecorator = createDecorator({
  field: meta.COGNICAO_PERGUNTA_1.absolutePath(),
  updates: {
    [meta.COGNICAO_PERGUNTA_2.absolutePath()]: (value: SimNaoEnum, allValues: IvcfModel) => {
      if (value === SimNaoEnum.SIM) return allValues.COGNICAO_PERGUNTA_2
    },
    [meta.COGNICAO_PERGUNTA_3.absolutePath()]: (value: SimNaoEnum, allValues: IvcfModel) => {
      if (value === SimNaoEnum.SIM) return allValues.COGNICAO_PERGUNTA_3
    },
  },
})

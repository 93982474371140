/** @jsx jsx */
import { css, Global, jsx } from '@emotion/core'
import { Button, Cell, Grid, Heading, Icon, Theme, Tooltip, useTheme } from 'bold-ui'
import { useAlert } from 'components/alert'
import { useErrorHandler } from 'components/error'
import { PageContent } from 'components/layout/PageContent'
import { confirm } from 'components/modals/confirm'
import { HideOnScreenSize } from 'components/responsive'
import { ExternalUserHeader, TOTAL_HEADER_HEIGHT } from 'components/user/ExternalUserHeader'
import { keyframes } from 'emotion'
import {
  useEncerrarVideochamadaMutation,
  useNotificarConexaoVideochamadaFalhouMutation,
  useNotificarSaidaDonoVideochamadaMutation,
} from 'graphql/hooks.generated'
import { useFirebase } from 'hooks/firebase/useFirebase'
import { useHeight } from 'hooks/useMeasure'
import { useOrientationDetection } from 'hooks/useOrientationDetection'
import { useViewportType } from 'hooks/useViewportType'
import { Fragment, useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react'
import { Route, useHistory, useLocation, useRouteMatch } from 'react-router'

import { ChatVideochamada } from './componentes/ChatVideochamada'
import { ChatVideochamadaMobileModal } from './componentes/ChatVideochamadaMobileModal'
import { NewMessagesPreview } from './componentes/NewMessagesPreview'
import { SolicitacaoEntrarVideochamadaListener } from './componentes/SolicitacaoEntrarVideochamadaListener'
import { StreamPlayersVideochamadaLayout } from './componentes/StreamPlayersVideochamadaLayout'
import { VideoChamadaFooter } from './componentes/VideochamadaFooter'
import { useConfiguracoesVideochamada } from './hooks/useConfiguracoesVideochamada'
import { useMediaDevicesConfiguration } from './hooks/useMediaDevicesConfiguration'
import { useMediaSession } from './hooks/useMediaSession'
import { useScreenShare } from './hooks/useScreenShare'
import { useUserMedia } from './hooks/useUserMedia'
import { useWebRtc, WEBRTC_CONNECTION_FAILURE_MESSAGE, WEBRTC_ICE_CONNECTION_FAILURE_MESSAGE } from './hooks/useWebRtc'
import {
  ChatMessage,
  ConfiguracoesVideochamadaModel,
  LocalVideocallParticipant,
  MediaStreamTrackKind,
  VideocallStream,
  VideoOrientationEnum,
} from './model-videochamada'
import { getVideocallStreams } from './utils-videochamada'

const MAX_MESSAGE_HEIGHT = 114
const VIDEOCHAMADA_NETWORK_TRESHOLD = 500

interface VideochamadaViewProps {
  selfData: LocalVideocallParticipant
  isOwner: boolean
  videochamadaUuid: ID
  audioEnabled: boolean
  videoEnabled: boolean
  setAudioEnabled(value: boolean): void
  setVideoEnabled(value: boolean): void
}

declare global {
  interface NetworkInformation extends EventTarget {
    readonly downlink: number
  }

  interface Navigator extends NetworkInformation {
    connection?: NetworkInformation
  }
}

export function VideochamadaView(props: VideochamadaViewProps) {
  const { videochamadaUuid, selfData, audioEnabled, videoEnabled, setAudioEnabled, setVideoEnabled, isOwner } = props
  const { analytics } = useFirebase()
  const history = useHistory()
  const match = useRouteMatch()
  const location = useLocation()
  const { isMobile, isTablet } = useViewportType()
  const orientation = useOrientationDetection()

  const selfId = selfData?.id
  const [chatOpen, setChatOpen] = useState(false)
  const [isChatTransitioning, setIsChatTransitioning] = useState(false)
  const [pipActive, setPipActive] = useState(false)
  const [slowConnection, setSlowConnection] = useState(false)
  const [hasNewMessages, setHasNewMessages] = useState(false)
  const [newMessages, dispatchNewMessages] = useReducer(newMessagesReducer, [])
  const [messageInput, setMessageInput] = useState('')
  const [hasChatScrolledToLastMessage, setHasChatScrolledToLastMessage] = useState(false)
  const [chatScrolledToLastMessage, setChatScrolledToLastMessage] = useState(false)

  const messagesPreviewContainerRef = useRef<HTMLDivElement>()
  const messagesPreviewRef = useRef<HTMLDivElement>()

  const [videoContainerRef, videoContainerHeight] = useHeight()
  const [videoContainerWidth, setVideoContainerWidth] = useState(0)

  const openChat = useCallback(() => {
    !chatOpen && setChatOpen(true)
    isMobile && !history.location.pathname.endsWith('/chat') && history.push(`${match.url}/chat`)
  }, [chatOpen, history, isMobile, match.url])
  const closeChat = useCallback(() => {
    chatOpen && setChatOpen(false)
    isMobile && history.replace(history.location.pathname.replace('/chat', ''))
  }, [chatOpen, history, isMobile])

  useEffect(() => {
    setIsChatTransitioning(true)
    dispatchNewMessages({ type: 'remove_all' })
    setHasNewMessages(false)
  }, [chatOpen])

  const alert = useAlert()
  const theme = useTheme()

  const styles = createStyles(
    theme,
    videoContainerWidth,
    orientation === VideoOrientationEnum.LANDSCAPE && window.screen.height < theme.breakpoints.size['sm']
  )

  const [configuracoesVideochamada, setConfiguracoesVideochamada] = useConfiguracoesVideochamada()

  const { stream: localStream, audioDeviceAvailable, videoDeviceAvailable, mediaDevices } = useUserMedia({
    video: videoEnabled,
    audio: audioEnabled,
    videoDeviceId: configuracoesVideochamada ? configuracoesVideochamada.videoInput?.id : 'default',
    audioDeviceId: configuracoesVideochamada ? configuracoesVideochamada.audioInput?.id : 'default',
  })

  const { mediaDevicesConfiguration, setStoredAudioDevice, setStoredVideoDevice } = useMediaDevicesConfiguration({
    mediaDevices,
    audioEnabled,
    videoEnabled,
  })

  const handleSetConfiguracoesVideochamada = (config: ConfiguracoesVideochamadaModel) => {
    setConfiguracoesVideochamada(config)
    setStoredAudioDevice(config.audioInput)
    setStoredVideoDevice(config.videoInput)
  }

  const handleCameraToggle = useCallback(() => setVideoEnabled(!videoEnabled), [setVideoEnabled, videoEnabled])
  const handleMicrophoneToggle = useCallback(() => setAudioEnabled(!audioEnabled), [audioEnabled, setAudioEnabled])

  useMediaSession({
    isCameraActive: videoEnabled,
    isMicrophoneActive: audioEnabled,
    onCameraToggle: handleCameraToggle,
    onMicrophoneToggle: handleMicrophoneToggle,
  })

  const { shareScreen, stopSharingScreen, sharingScreen, displayStream } = useScreenShare()

  const handlePeerDisconnected = useCallback(
    (peerId: ID) =>
      !isOwner &&
      peerId === selfId &&
      navigator.onLine &&
      !window.location.pathname.endsWith('/sair') &&
      history.push('/videochamada/encerrada'),
    [isOwner, selfId, history]
  )

  const handleNewMessage = useCallback(
    (newMessage: ChatMessage) => {
      const containerHeight = messagesPreviewContainerRef.current ? messagesPreviewContainerRef.current.clientHeight : 0
      const messagesHeight = messagesPreviewRef.current ? messagesPreviewRef.current.clientHeight : 0

      if (!chatOpen && containerHeight - messagesHeight > MAX_MESSAGE_HEIGHT) {
        dispatchNewMessages({ type: 'add', message: newMessage })
        setHasNewMessages(true)

        setTimeout(() => {
          dispatchNewMessages({ type: 'remove_last' })
        }, 5000)
      }
    },
    [chatOpen]
  )

  const [notificarConexaoVideochamadaFalhou] = useNotificarConexaoVideochamadaFalhouMutation()

  const handleConnectionFail = useCallback(
    async (error: Error) => {
      if (
        isOwner &&
        (error?.message === WEBRTC_CONNECTION_FAILURE_MESSAGE ||
          error?.message === WEBRTC_ICE_CONNECTION_FAILURE_MESSAGE)
      ) {
        await notificarConexaoVideochamadaFalhou({ variables: { videochamadaUuid } })
        analytics.logEvent('TELE_ERR_videochamadas_conexao_falhou')
      }
    },
    [analytics, isOwner, notificarConexaoVideochamadaFalhou, videochamadaUuid]
  )

  const {
    remoteParticipants,
    sendMessage,
    messages,
    addPresentingStreamId,
    removePresentingStreamId,
    presentingStreamsIds,
  } = useWebRtc({
    selfData,
    roomId: videochamadaUuid,
    audioEnabled: audioEnabled,
    videoEnabled: videoEnabled,
    localStream: localStream,
    presentationStream: displayStream,
    onPeerDisconnected: handlePeerDisconnected,
    onNewRemoteMessage: handleNewMessage,
    onConnectionFail: handleConnectionFail,
  })

  const handleConnectionChange = useCallback(
    () => !navigator.onLine && history.push(`${match.url}/perda-conexao?owner=${isOwner}`),
    [history, match.url, isOwner]
  )
  const handleRejection = useErrorHandler()

  const sairVideochamada = useCallback(() => window.location.replace(`${match.url}/sair`), [match])

  const [encerrarVideoChamada] = useEncerrarVideochamadaMutation()
  const [notificarSaidaDonoVideochamada] = useNotificarSaidaDonoVideochamadaMutation()

  const encerrarVideochamadaConfirm = useCallback(
    () =>
      confirm({
        title: `Encerrar a chamada ou apenas sair?`,
        body: 'Ao selecionar “Encerrar chamada”, a chamada será encerrada para todos os participantes.',
        type: 'danger',
        confirmLabel: 'Encerrar chamada',
        cancelLabel: 'Sair da chamada',
        onConfirm: () =>
          encerrarVideoChamada({ variables: { videochamadaUuid } })
            .then(() => {
              history.push('/videochamada/encerrada?owner=true')
            })
            .catch(handleRejection),
        onCancel: () => {
          sairVideochamada()
          notificarSaidaDonoVideochamada({
            variables: {
              videochamadaUuid,
            },
          })
        },
      })(),
    [encerrarVideoChamada, videochamadaUuid, handleRejection, history, sairVideochamada, notificarSaidaDonoVideochamada]
  )

  const handleClickSairVideochamada = isOwner ? encerrarVideochamadaConfirm : sairVideochamada

  useEffect(() => {
    window.addEventListener('offline', handleConnectionChange)

    return () => {
      window.removeEventListener('offline', handleConnectionChange)
    }
  }, [handleConnectionChange])

  useEffect(() => {
    if (displayStream) addPresentingStreamId(displayStream.id)
    return () => {
      displayStream && removePresentingStreamId(displayStream.id)
    }
  }, [addPresentingStreamId, displayStream, removePresentingStreamId])

  const videocallStreams: VideocallStream[] = useMemo(
    () => getVideocallStreams(selfId, localStream, remoteParticipants, displayStream, isOwner, presentingStreamsIds),
    [displayStream, isOwner, localStream, presentingStreamsIds, remoteParticipants, selfId]
  )

  const [remoteVideoEnabled, setRemoteVideoEnabled] = useState(false)
  useEffect(() => {
    const remoteStreams = videocallStreams
      .filter((stream) => stream.remote && !!stream.stream)
      .map((stream) => stream.stream)

    const handleChangeTracks = (e: CustomEvent | MediaStreamTrack) => {
      const track = e instanceof CustomEvent ? e.detail?.track : (e as MediaStreamTrack)
      if (track?.kind === MediaStreamTrackKind.VIDEO) setRemoteVideoEnabled(track?.enabled)
    }

    remoteStreams.flatMap((stream) => stream.getTracks()).forEach((track) => handleChangeTracks(track))

    remoteStreams.forEach((stream) => {
      stream.addEventListener('enable', (e: CustomEvent) => handleChangeTracks(e))
    })

    return () => {
      remoteStreams.forEach((stream) => stream.removeEventListener('enable', (e: CustomEvent) => handleChangeTracks(e)))
    }
  }, [videocallStreams])

  useEffect(() => {
    if (isMobile) {
      const isUrlChat = location.pathname.includes('/chat')
      if (isUrlChat !== chatOpen) setChatOpen(isUrlChat)
    }
  }, [chatOpen, isMobile, location.pathname])

  useEffect(() => {
    if (`/videochamada/${videochamadaUuid}` === location.pathname) {
      const handleNavigation = () => {
        window.location.replace(`/videochamada/${videochamadaUuid}/sair`)
      }

      window.addEventListener('popstate', handleNavigation)

      return () => {
        window.removeEventListener('popstate', handleNavigation)
      }
    }
  }, [location.pathname, videochamadaUuid])

  useEffect(() => {
    if (navigator.connection?.downlink) {
      const checkSlowConnection = () => {
        const bitrate = navigator.connection.downlink * 1000
        const isSlowConnection = bitrate < VIDEOCHAMADA_NETWORK_TRESHOLD
        setSlowConnection(isSlowConnection)
      }
      checkSlowConnection()
      const intervalCheckSlowConnection = setInterval(checkSlowConnection, 10000)

      return () => clearInterval(intervalCheckSlowConnection)
    }
  }, [])

  useEffect(() => {
    if (slowConnection) alert('warning', 'A conexão pode estar prejudicada devido à lentidão na rede.')
  }, [alert, slowConnection])

  useEffect(() => {
    const width = (videoContainerHeight * 16) / 9
    width && setVideoContainerWidth(width)
  }, [videoContainerHeight])

  return (
    <Fragment>
      <Global styles={styles.global} />
      <ExternalUserHeader primary />

      <PageContent style={styles.pageContent} containerStyle={styles.pageContainer} fluid>
        <Grid
          direction='column'
          justifyContent='center'
          alignItems='stretch'
          style={styles.pageContentGrid}
          gapVertical={0.5}
        >
          <Cell size={12} style={[styles.mobileHeaderWrapper, styles.cells]}>
            <HideOnScreenSize up='sm'>
              <Grid direction='row' alignItems='center'>
                <Cell size={8}>
                  <Heading style={styles.mobileHeading} level={3}>
                    Videochamada e-SUS APS
                  </Heading>
                </Cell>
                <Cell size={4}>
                  <div css={styles.sairButtonWrapper}>
                    <Tooltip text='Sair da chamada'>
                      <Button kind='danger' size='small' style={styles.button} onClick={handleClickSairVideochamada}>
                        <Icon icon='phoneFilled' color='white' style={styles.buttonIcon} />
                        Sair
                      </Button>
                    </Tooltip>
                  </div>
                </Cell>
              </Grid>
            </HideOnScreenSize>
          </Cell>
          <Cell flexGrow={1} style={[styles.gridContent, styles.cells]}>
            <Grid wrap={false} gap={0.5} style={styles.content}>
              <Cell
                size={chatOpen && isTablet ? 8 : chatOpen && !isMobile ? 9 : 12}
                style={styles.messagesPreviewAndPlayersContainer}
              >
                <div ref={videoContainerRef} css={styles.videoContainerRef}>
                  <StreamPlayersVideochamadaLayout
                    streams={videocallStreams}
                    pipActive={pipActive}
                    setPipActive={setPipActive}
                  />
                </div>
                {!chatOpen && (
                  <NewMessagesPreview
                    newMessages={newMessages}
                    containerRef={messagesPreviewContainerRef}
                    messagesRef={messagesPreviewRef}
                  />
                )}
              </Cell>
              {isMobile ? (
                <Route path={`${match.url}/chat`}>
                  <ChatVideochamadaMobileModal
                    open
                    messages={messages}
                    onClose={() => {
                      closeChat()
                    }}
                    onSendMessage={sendMessage}
                    messageInput={messageInput}
                    onChangeInput={setMessageInput}
                    style={styles.chat}
                    hasScrolledToLastMessage={hasChatScrolledToLastMessage}
                    onHasScrolledToLastMessage={setHasChatScrolledToLastMessage}
                    scrolledToLastMessage={chatScrolledToLastMessage}
                    onScrolledToLastMessage={setChatScrolledToLastMessage}
                  />
                </Route>
              ) : (
                chatOpen && (
                  <Cell style={styles.chatContainer}>
                    <ChatVideochamada
                      open={isChatTransitioning || chatOpen}
                      messages={messages}
                      onClose={() => closeChat()}
                      onSendMessage={sendMessage}
                      animated
                      onAnimationEnd={() => setIsChatTransitioning(false)}
                      style={styles.chat}
                      messageInput={messageInput}
                      onChangeInput={setMessageInput}
                      hasScrolledToLastMessage={hasChatScrolledToLastMessage}
                      onHasScrolledToLastMessage={setHasChatScrolledToLastMessage}
                      scrolledToLastMessage={chatScrolledToLastMessage}
                      onScrolledToLastMessage={setChatScrolledToLastMessage}
                    />
                  </Cell>
                )
              )}
            </Grid>
          </Cell>
          <Cell style={[styles.cells, styles.cellFooter]}>
            <VideoChamadaFooter
              isOwner={isOwner}
              audioDeviceAvailable={audioDeviceAvailable}
              audioEnabled={audioEnabled}
              setAudioEnabled={setAudioEnabled}
              videoDeviceAvailable={videoDeviceAvailable}
              videoEnabled={videoEnabled}
              setVideoEnabled={setVideoEnabled}
              onConfiguracoesChange={handleSetConfiguracoesVideochamada}
              configuracoes={mediaDevicesConfiguration}
              chatOpen={chatOpen}
              setChatOpen={(open) => (open ? openChat() : closeChat())}
              sharingScreen={sharingScreen}
              onShareScreenClick={sharingScreen ? stopSharingScreen : shareScreen}
              hasRemoteParticipant={videocallStreams.some((stream) => stream.remote)}
              hasRemoteVideo={remoteVideoEnabled}
              pipActive={pipActive}
              setPipActive={setPipActive}
              hasNewMessages={hasNewMessages}
              onClickSairVideochamada={handleClickSairVideochamada}
            />
          </Cell>
        </Grid>
        {isOwner && (
          <SolicitacaoEntrarVideochamadaListener
            videochamadaUuid={videochamadaUuid}
            numeroParticipantes={remoteParticipants.length + 1}
          />
        )}
      </PageContent>
    </Fragment>
  )
}

const newMessagesReducer = (
  prevNewMessages: ChatMessage[],
  action: { type: 'add' | 'remove_last' | 'remove_all'; message?: ChatMessage }
) => {
  switch (action.type) {
    case 'add':
      return [...prevNewMessages, action.message]
    case 'remove_last':
      return prevNewMessages.slice(1)
    case 'remove_all':
      return []
    default:
      return prevNewMessages
  }
}

const animationConfig = '0.5s ease'
const createStyles = (theme: Theme, videoContainerWidth: number, isMobileLandscape: boolean) => ({
  global: css`
    body {
      background-color: ${theme.pallete.gray.c10};
      #root {
        > div {
          min-height: 100dvh;
          height: calc(100dvh + ${isMobileLandscape ? TOTAL_HEADER_HEIGHT + 'rem' : '0px'});
        }
      }
    }
  `,
  pageContent: css`
    padding: 1rem 1rem 1.5rem 1rem;
    flex-grow: 1;
    display: flex;
    align-items: stretch;
    justify-content: center;
    background-color: ${theme.pallete.gray.c10};
    overflow: hidden;

    ${theme.breakpoints.up('sm')} {
      padding: 2rem 2rem 2.5rem 2rem;
    }

    ${theme.breakpoints.up('md')} {
      padding: 2rem 2.5rem 2.5rem 2.5rem;
    }
  `,
  pageContainer: css`
    display: flex;
    max-width: 100%;
    width: 100%;
    padding: 0;
    overflow: hidden;

    ${theme.breakpoints.up('md')} {
      width: ${isMobileLandscape ? '100%' : videoContainerWidth + 'px'};
    }
  `,
  chatContainer: css`
    overflow: hidden;
    width: 0;
    animation: ${chatContainerScrollIn} ${animationConfig} forwards;
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0.5rem;
  `,
  chat: css`
    animation: ${chatFadeIn} ${animationConfig} forwards;
  `,
  messagesPreviewAndPlayersContainer: css`
    padding: 0;
    height: 100%;
    transition: ${animationConfig};
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
  `,
  content: css`
    margin: 0;
    height: 100%;
  `,
  gridContent: css`
    height: 0;
  `,
  videoContainerRef: css`
    height: 100%;
  `,
  button: css`
    padding: 0.5rem 0.75rem;
    align-items: baseline;
    justify-content: space-between;
  `,
  buttonIcon: css`
    margin-right: 0.625rem;
  `,
  sairButtonWrapper: css`
    display: flex;
    justify-content: flex-end;
  `,
  mobileHeaderWrapper: css`
    margin-bottom: 1.5rem;
    ${theme.breakpoints.up('sm')} {
      display: none;
    }
  `,
  mobileHeading: css`
    color: white;
  `,
  pageContentGrid: css`
    margin: 0 !important;
    flex-grow: 1;
  `,
  cells: css`
    padding: 0 !important;
  `,
  cellFooter: css`
    padding-top: 1.5rem !important;

    ${theme.breakpoints.up('sm')} {
      padding-top: 2rem !important;
    }
  `,
})

const chatFadeIn = keyframes`
  0% { 
    opacity: 0;
  }
  99% { 
    opacity: 0;
  }
  100% { 
    opacity: 1;
  }
`

const chatContainerScrollIn = keyframes`
  0% {
    flex-grow: 0.001;
    opacity: 0; 
  }
  100% { 
    flex-grow: 1; 
    opacity: 1; 
  }
`

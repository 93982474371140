import { Cell, Grid, HeadingSection, InfoLabel, VFlow } from 'bold-ui'
import { DateTime } from 'components/date'
import { Cpf } from 'components/label'
import { BeneficioDbEnum, NacionalidadeEnum, PeriodoAusencia, TipoLocalNascimentoEnum } from 'graphql/types.generated'
import React from 'react'
import { beneficioRecord, sexo } from 'types/enums'
import { tipoEnderecoIndigena } from 'view/cadastro-imovel/model-cadastroImovel'

import { PeriodoAusenciaTable } from '../create/PeriodoAusenciaTable'
import { CidadaoAldeadoSectionModel } from '../types/model-cidadaoTabSection'
import {
  CompartilhamentoProntuarioSection,
  ContatosSection,
  EnderecoExteriorSection,
  EnderecoSection,
  EquipeResponsavelSection,
  GrupoObito,
  InformacoesSociodemograficasSection,
} from './CidadaoInformacoesSections'
import { CidadaoInformacoesViewProps } from './CidadaoInformacoesView'

interface PeriodoAusenciaSectionProps {
  periodoAusenciaList?: PeriodoAusencia[]
}

interface RaniSectionProps {
  cidadaoAldeado?: CidadaoAldeadoSectionModel
}

export const CidadaoAldeadoInformacoesView = ({ cidadao, mostrarProntuario }: CidadaoInformacoesViewProps) => {
  return (
    <VFlow vSpacing={2} style={{ paddingTop: '0.9375rem' }}>
      {cidadao?.faleceu && <GrupoObito cidadao={cidadao} />}
      <DadosPessoaisSection cidadao={cidadao} />
      <EquipeResponsavelSection cidadao={cidadao} isCidadaoAldeado />
      <NaturalidadeSection cidadao={cidadao} />
      {tipoEnderecoIndigena.includes(cidadao?.tipoEndereco) ? (
        <EnderecoAldeadoSection cidadao={cidadao} />
      ) : !cidadao?.paisExterior ? (
        <EnderecoSection cidadao={cidadao} isCidadaoAldeado />
      ) : (
        <EnderecoExteriorSection cidadao={cidadao} isCidadaoAldeado />
      )}
      <RaniSection cidadaoAldeado={cidadao?.cidadaoAldeado} />
      <PeriodoAusenciaSection periodoAusenciaList={cidadao?.periodoAusenciaList} />
      <ContatosSection cidadao={cidadao} isCidadaoAldeado />
      <InformacoesSociodemograficasSection cidadao={cidadao} isCidadaoAldeado />
      {mostrarProntuario && <CompartilhamentoProntuarioSection cidadao={cidadao} />}
    </VFlow>
  )
}

const DadosPessoaisSection = ({ cidadao: { cidadaoAldeado, ...cidadao } }: CidadaoInformacoesViewProps) => {
  return (
    <HeadingSection level={2} title='Dados pessoais'>
      <Grid wrap>
        <Cell size={3} data-cy='CidadaoAldeadoInformacoesGerais.cpf'>
          <InfoLabel title='CPF'>
            <Cpf value={cidadao?.cpf} />
          </InfoLabel>
        </Cell>
        <Cell size={3} data-cy='CidadaoAldeadoInformacoesGerais.cns'>
          <InfoLabel placeholder='-' title='CNS'>
            {cidadao?.cns}
          </InfoLabel>
        </Cell>
        <Cell size={3} data-cy='CidadaoAldeadoInformacoesGerais.dataNascimento'>
          <InfoLabel placeholder='-' title='Data de nascimento'>
            <DateTime format='DD/MM/YYYY' value={cidadao?.dataNascimento} />
          </InfoLabel>
        </Cell>
        <Cell size={3} data-cy='CidadaoAldeadoInformacoesGerais.dataReconhecimento'>
          <InfoLabel placeholder='-' title='Data de reconhecimento'>
            <DateTime format='DD/MM/YYYY' value={cidadaoAldeado?.dtReconhecimento} />
          </InfoLabel>
        </Cell>
        <Cell size={3} data-cy='CidadaoAldeadoInformacoesGerais.sexo'>
          <InfoLabel placeholder='-' title='Sexo'>
            {sexo[cidadao?.sexo]}
          </InfoLabel>
        </Cell>
        <Cell size={3} data-cy='CidadaoAldeadoInformacoesGerais.racaCor'>
          <InfoLabel placeholder='-' title='Raça/cor'>
            {cidadao?.racaCor?.nome?.titleCase()}
          </InfoLabel>
        </Cell>
        <Cell size={6} data-cy='CidadaoAldeadoInformacoesGerais.etnia'>
          <InfoLabel placeholder='-' title='Etnia'>
            {cidadao?.etnia?.nome?.titleCase()}
          </InfoLabel>
        </Cell>
        <Cell size={3} data-cy='CidadaoAldeadoInformacoesGerais.funcaoSocial'>
          <InfoLabel placeholder='-' title='Função social'>
            {cidadaoAldeado?.funcaoSocial?.nome?.titleCase()}
          </InfoLabel>
        </Cell>
        <Cell size={9} data-cy='CidadaoAldeadoInformacoesGerais.chefeFamilia'>
          <InfoLabel placeholder='-' title='Chefe de família'>
            {cidadaoAldeado?.stChefeFamilia ? 'Sim' : 'Não'}
          </InfoLabel>
        </Cell>
        <Cell size={3} data-cy='CidadaoAldeadoInformacoesGerais.nomeMae'>
          <InfoLabel placeholder='-' title='Nome da mãe'>
            {cidadao?.nomeMae?.titleCase()}
          </InfoLabel>
        </Cell>
        <Cell size={3} data-cy='CidadaoAldeadoInformacoesGerais.nomePai'>
          <InfoLabel placeholder='-' title='Nome do pai'>
            {cidadao?.nomePai?.titleCase()}
          </InfoLabel>
        </Cell>
        <Cell size={6} data-cy='CidadaoAldeadoInformacoesGerais.responsavelLegal'>
          <InfoLabel placeholder='-' title='Responsável legal'>
            {cidadaoAldeado?.responsavelLegal?.titleCase()}
          </InfoLabel>
        </Cell>
        <Cell size={12} data-cy='CidadaoAldeadoInformacoesGerais.beneficios'>
          <InfoLabel placeholder='-' title='Benefícios do governo'>
            {formatBeneficios(cidadaoAldeado?.beneficios)}
          </InfoLabel>
        </Cell>
      </Grid>
    </HeadingSection>
  )
}

const NaturalidadeSection = ({ cidadao: { cidadaoAldeado, ...cidadao } }: CidadaoInformacoesViewProps) => {
  const isEstrangeiro = cidadao?.nacionalidade?.nacionalidadeDbEnum === NacionalidadeEnum.ESTRANGEIRA
  const isNaturalizado = cidadao?.nacionalidade?.nacionalidadeDbEnum === NacionalidadeEnum.NATURALIZADA
  const isAldeia = cidadao?.tipoLocalNascimento === TipoLocalNascimentoEnum.ALDEIA

  return (
    <HeadingSection level={2} title='Naturalidade'>
      <Grid wrap>
        <Cell size={3} data-cy='CidadaoAldeadoInformacoesGerais.nacionalidade'>
          <InfoLabel placeholder='-' title='Nacionalidade'>
            {cidadao?.nacionalidade?.nacionalidadeDbEnum?.titleCase()}
          </InfoLabel>
        </Cell>
        {isEstrangeiro ? (
          <>
            <Cell size={3} data-cy='CidadaoAldeadoInformacoesGerais.paisNascimento'>
              <InfoLabel placeholder='-' title='País de nascimento'>
                {cidadao?.paisNascimento?.nome?.titleCase()}
              </InfoLabel>
            </Cell>
            <Cell size={3} data-cy='CidadaoAldeadoInformacoesGerais.dataEntradaBrasil'>
              <InfoLabel placeholder='-' title='Data de entrada no Brasil'>
                <DateTime format='DD/MM/YYYY' value={cidadao?.dataEntradaBrasil} />
              </InfoLabel>
            </Cell>
          </>
        ) : isNaturalizado ? (
          <>
            <Cell size={3} data-cy='CidadaoAldeadoInformacoesGerais.portariaNaturalizacao'>
              <InfoLabel placeholder='-' title='Portaria de naturalização'>
                {cidadao?.portariaNaturalizacao?.titleCase()}
              </InfoLabel>
            </Cell>
            <Cell size={3} data-cy='CidadaoAldeadoInformacoesGerais.dataNaturalizacao'>
              <InfoLabel placeholder='-' title='Data de naturalização'>
                <DateTime format='DD/MM/YYYY' value={cidadao?.dataNaturalizacao} />
              </InfoLabel>
            </Cell>
          </>
        ) : isAldeia ? (
          <>
            <Cell size={3} data-cy='CidadaoAldeadoInformacoesGerais.localNascimento'>
              <InfoLabel placeholder='-' title='Local de nascimento'>
                Aldeia
              </InfoLabel>
            </Cell>
            <Cell size={3} data-cy='CidadaoAldeadoInformacoesGerais.aldeiaNascimento'>
              <InfoLabel placeholder='-' title='Aldeia'>
                {cidadaoAldeado?.aldeiaNascimento?.nome?.titleCase()}
              </InfoLabel>
            </Cell>
            <Cell size={3} data-cy='CidadaoAldeadoInformacoesGerais.localOcorrenciaNascimento'>
              <InfoLabel placeholder='-' title='Local de ocorrência do nascimento'>
                {cidadaoAldeado?.localOcorrencia?.nome?.titleCase()}
              </InfoLabel>
            </Cell>
          </>
        ) : (
          <>
            <Cell size={3} data-cy='CidadaoAldeadoInformacoesGerais.localNascimento'>
              <InfoLabel placeholder='-' title='Local de nascimento'>
                Município
              </InfoLabel>
            </Cell>
            <Cell size={3} data-cy='CidadaoAldeadoInformacoesGerais.municipioNascimento'>
              <InfoLabel placeholder='-' title='Município'>
                {cidadao?.localidadeNascimento?.nome?.titleCase()}
              </InfoLabel>
            </Cell>
          </>
        )}
      </Grid>
    </HeadingSection>
  )
}

const EnderecoAldeadoSection = ({ cidadao: { enderecoIndigena, ...cidadao } }: CidadaoInformacoesViewProps) => {
  return (
    <HeadingSection level={2} title='Residência'>
      <Grid wrap>
        <Cell size={3} data-cy='CidadaoAldeadoInformacoesGerais.localidade'>
          <InfoLabel placeholder='-' title='Localidade'>
            {cidadao?.tipoEndereco?.titleCase()}
          </InfoLabel>
        </Cell>
        <Cell size={3} data-cy='CidadaoAldeadoInformacoesGerais.dseiResidencia'>
          <InfoLabel placeholder='-' title='DSEI'>
            {enderecoIndigena?.dseiResidencia?.nome?.titleCase()}
          </InfoLabel>
        </Cell>
        <Cell size={3} data-cy='CidadaoAldeadoInformacoesGerais.poloBaseResidencia'>
          <InfoLabel placeholder='-' title='Polo Base'>
            {enderecoIndigena?.poloBaseResidencia?.nome?.titleCase()}
          </InfoLabel>
        </Cell>
        <Cell size={3} data-cy='CidadaoAldeadoInformacoesGerais.aldeiaResidencia'>
          <InfoLabel placeholder='-' title='Aldeia'>
            {enderecoIndigena?.aldeiaResidencia?.nome?.titleCase()}
          </InfoLabel>
        </Cell>
        <Cell size={3} data-cy='CidadaoAldeadoInformacoesGerais.municipioAldeiaResidencia'>
          <InfoLabel placeholder='-' title='Município da Aldeia'>
            {enderecoIndigena?.municipio?.nome?.titleCase()}
          </InfoLabel>
        </Cell>
        <Cell size={3} data-cy='CidadaoAldeadoInformacoesGerais.ufResidencia'>
          <InfoLabel placeholder='-' title='UF'>
            {enderecoIndigena?.uf?.sigla?.toUpperCase()}
          </InfoLabel>
        </Cell>
        <Cell size={3} data-cy='CidadaoAldeadoInformacoesGerais.numeroResidencia'>
          <InfoLabel placeholder='-' title='Número da residência'>
            {enderecoIndigena?.numero}
          </InfoLabel>
        </Cell>
        <Cell size={3} data-cy='CidadaoAldeadoInformacoesGerais.numeroFamilia'>
          <InfoLabel placeholder='-' title='Número da família'>
            {cidadao?.numeroFamilia}
          </InfoLabel>
        </Cell>
      </Grid>
    </HeadingSection>
  )
}

const RaniSection = ({ cidadaoAldeado }: RaniSectionProps) => {
  return (
    <HeadingSection level={2} title='RANI - Registro Administrativo de Nascimento Indígena'>
      <Grid wrap>
        <Cell size={3} data-cy='CidadaoAldeadoInformacoesGerais.unidadeFunai'>
          <InfoLabel placeholder='-' title='Unidade da FUNAI'>
            {cidadaoAldeado?.unidadeFunai}
          </InfoLabel>
        </Cell>
        <Cell size={3} data-cy='CidadaoAldeadoInformacoesGerais.livro'>
          <InfoLabel placeholder='-' title='Livro'>
            {cidadaoAldeado?.livro}
          </InfoLabel>
        </Cell>
        <Cell size={6} data-cy='CidadaoAldeadoInformacoesGerais.folha'>
          <InfoLabel placeholder='-' title='Folha'>
            {cidadaoAldeado?.folha}
          </InfoLabel>
        </Cell>
        <Cell size={3} data-cy='CidadaoAldeadoInformacoesGerais.cadastroUnico'>
          <InfoLabel placeholder='-' title='Nº Cadastro Único'>
            {cidadaoAldeado?.cadastroUnico}
          </InfoLabel>
        </Cell>
        <Cell size={3} data-cy='CidadaoAldeadoInformacoesGerais.dataEmissao'>
          <InfoLabel placeholder='-' title='Data da emissão'>
            <DateTime format='DD/MM/YYYY' value={cidadaoAldeado?.dtEmissao} />
          </InfoLabel>
        </Cell>
        <Cell size={6} data-cy='CidadaoAldeadoInformacoesGerais.ufNascimento'>
          <InfoLabel placeholder='-' title='UF'>
            {cidadaoAldeado?.ufNascimento?.sigla}
          </InfoLabel>
        </Cell>
      </Grid>
    </HeadingSection>
  )
}

const PeriodoAusenciaSection = ({ periodoAusenciaList }: PeriodoAusenciaSectionProps) => {
  return (
    <HeadingSection level={2} title='Período de ausência'>
      <Grid wrap>
        <Cell size={6} data-cy='CidadaoAldeadoInformacoesGerais.periodoAusencia'>
          <PeriodoAusenciaTable periodoAusenciaList={periodoAusenciaList} onlyTable />
        </Cell>
      </Grid>
    </HeadingSection>
  )
}

const formatBeneficios = (beneficios: BeneficioDbEnum[]) =>
  beneficios
    ? `${beneficios
        ?.map((item) => beneficioRecord[item])
        .join(', ')
        .replaceLastOccurrence(', ', ' e ')}`
    : null

/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, Grid, Heading, HFlow, Tag, Text, Tooltip, VFlow } from 'bold-ui'
import { DateTime } from 'components/date'
import { HLabel } from 'components/HLabel'
import { Cpf } from 'components/label'
import { useAtendimentoHeaderCidadaoQuery } from 'graphql/hooks.generated'
import { identidadeGenero, sexo } from 'types/enums'
import { humanizeAge } from 'util/date/humanize-age'

export interface AtendimentoHeaderCidadaoSectionProps {
  cidadaoId: ID
  dataAtendimento?: number
}

export const AtendimentoHeaderCidadaoSection = (props: AtendimentoHeaderCidadaoSectionProps) => {
  const { cidadaoId, dataAtendimento } = props

  const { data: { cidadao } = {} } = useAtendimentoHeaderCidadaoQuery({ variables: { cidadaoId } })

  if (!cidadao) return null

  return (
    <VFlow vSpacing={0}>
      <Grid alignItems='baseline' gap={0.5} gapVertical={0}>
        <Cell>
          <Heading level={2}>{(cidadao.nomeSocial ?? cidadao.nome).titleCase()}</Heading>
        </Cell>
        {cidadao.nomeSocial && (
          <Cell>
            <Text fontSize={1} fontWeight='bold'>
              (Nome social)
            </Text>
          </Cell>
        )}
        <Cell>
          <Text fontSize={0.9}>
            {cidadao.nomeSocial ? `${cidadao.nome.titleCase()} ` : ''}{' '}
            {cidadao.nomeTradicional && `| ${cidadao.nomeTradicional.titleCase()} `}| &nbsp;
            {sexo[cidadao.sexo]}{' '}
          </Text>
          {cidadao.identidadeGeneroDbEnum && (
            <Text fontSize={0.9}>({identidadeGenero[cidadao.identidadeGeneroDbEnum]})</Text>
          )}
        </Cell>
        {cidadao.faleceu && (
          <Cell>
            <Tag style={styles.tag}>Óbito</Tag>
          </Cell>
        )}
        {!cidadao.ativo && (
          <Cell>
            <Tag style={styles.tag}>Inativo</Tag>
          </Cell>
        )}
        {cidadao.unificado && (
          <Cell>
            <Tag style={styles.tag}>Cadastro unificado</Tag>
          </Cell>
        )}
        {cidadao?.unificacaoBase && (
          <Cell>
            <Tooltip text={`Cadastro originado após unificação da Base ${cidadao.unificacaoBase}`}>
              <Tag style={styles.tag}>Base {cidadao.unificacaoBase.titleCase()}</Tag>
            </Tooltip>
          </Cell>
        )}
      </Grid>

      <HFlow hSpacing={1}>
        {cidadao.dataNascimento && (
          <Text>
            {humanizeAge(cidadao.dataNascimento, dataAtendimento)} | Nasceu em{' '}
            <DateTime format='DD/MM/YYYY' value={cidadao.dataNascimento} />
          </Text>
        )}
        <HLabel title='CPF'>
          <Cpf value={cidadao.cpf} />
        </HLabel>
        <HLabel title='CNS'>{cidadao.cns}</HLabel>
        <HLabel title='Nome da mãe'>{cidadao.nomeMae?.titleCase()}</HLabel>
      </HFlow>
    </VFlow>
  )
}

const styles = {
  tag: css`
    &:not(:last-of-type) {
      margin-right: 0.5rem;
      margin-bottom: 0.5rem;
    }
  `,
}

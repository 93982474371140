import { Button, Icon, Theme, useTheme } from 'bold-ui'
import { css } from 'emotion'
import React from 'react'
import { useHistory, useLocation } from 'react-router'

interface VisualizacaoCidadaoButtonProps {
  cidadaoId: ID
}

export const VisualizarCidadaoButton = ({ cidadaoId }: VisualizacaoCidadaoButtonProps) => {
  const location = useLocation()
  const history = useHistory()

  const theme = useTheme()
  const styles = createStyles(theme)

  const handleVisualizarClick = () => {
    history.push(`/cidadao/${cidadaoId}${location.search}`)
  }

  return (
    <Button skin='ghost' kind='primary' onClick={handleVisualizarClick} style={styles.button}>
      <Icon icon='zoomOutline' />
    </Button>
  )
}

const createStyles = (theme: Theme) => {
  return {
    button: css`
      color: ${theme.pallete.gray.c20};
      padding: 0.5rem;
      margin-left: 3rem;
    `,
  }
}

import { useOnBeforeUnload } from 'hooks/useOnBeforeUnload'
import { useLocalStorageState } from 'hooks/useStorage'
import { useMemo } from 'react'

import { CadastroImovelCacheState } from '../model-cadastroImovel'

export const useCadastroImovelCache = (acessoId: string, redirect?: string, cidadaoId?: ID, endereco?: string) => {
  const [cacheState, setCacheState, deleteCacheState] = useLocalStorageState<CadastroImovelCacheState>(
    `${acessoId}/cadastro-imovel/`
  )
  // TODO NP - Refatorar useLocalStorage quando for integrado o pr da issue #17870

  const { atualizacaoCidadao, cadastroCidadaoModal, cadastroFamiliaModal } = cacheState?.familiaCidadaoModalState || {}

  const shouldRenderCache = useMemo(
    () =>
      (!!atualizacaoCidadao ||
        !!cadastroCidadaoModal?.modalOpen ||
        !!cadastroFamiliaModal?.modalOpen ||
        !!cacheState?.listaLogradouroState?.fillEnderecoFields) &&
      (cidadaoId !== undefined || redirect === 'true' || endereco === 'true'),
    [atualizacaoCidadao, cacheState, cadastroCidadaoModal, cadastroFamiliaModal, cidadaoId, endereco, redirect]
  )

  useOnBeforeUnload(() => deleteCacheState())

  return {
    cacheState,
    shouldRenderCache,
    setCacheState,
    deleteCacheState,
  }
}

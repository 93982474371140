import { SelectField } from 'components/form'
import { FuncaoSocialSelectFieldDocument } from 'graphql/hooks.generated'
import { FuncaoSocialSelectFieldQuery, FuncaoSocialSelectFieldQueryVariables } from 'graphql/types.generated'
import React from 'react'

import { AsyncSelectFieldProps, DEFAULT_SELECT_PAGE_PARAM, useAsyncQuerySelect } from '../useAsyncQuerySelect'

export interface FuncaoSocialSelectModel {
  id: ID
  nome: string
}

export interface FuncaoSocialSelectFieldProps extends AsyncSelectFieldProps<FuncaoSocialSelectModel> {}

const extractItems = (data: FuncaoSocialSelectFieldQuery) => {
  return data?.funcoesSociais.content
}

const handleItemToString = (item: FuncaoSocialSelectModel) => {
  return item?.nome
}

export function FuncaoSocialSelectField(props: FuncaoSocialSelectFieldProps) {
  const variables = (query: string): FuncaoSocialSelectFieldQueryVariables => ({
    input: {
      query,
      pageParams: DEFAULT_SELECT_PAGE_PARAM,
    },
  })

  const { selectProps } = useAsyncQuerySelect<
    FuncaoSocialSelectModel,
    FuncaoSocialSelectFieldQuery,
    FuncaoSocialSelectFieldQueryVariables
  >({
    query: FuncaoSocialSelectFieldDocument,
    extractItems,
    variables,
  })

  return <SelectField<FuncaoSocialSelectModel> itemToString={handleItemToString} {...selectProps} {...props} />
}

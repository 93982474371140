/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, Grid, Heading, HeadingSection, HFlow, VFlow } from 'bold-ui'
import CheckPermission from 'components/auth/CheckPermission'
import { CheckboxField, RadioField } from 'components/form'
import { AgeRangeField } from 'components/form/field/AgeRangeField'
import DateRangeField from 'components/form/field/DateRangeField'
import {
  CiapCidSelectField,
  CiapCidSelectModel,
} from 'components/form/field/select/CiapCidSelectField/CiapCidSelectField'
import {
  GrupoCondicaoSelectField,
  GrupoCondicaoSelectModel,
} from 'components/form/field/select/GrupoCondicaoSelectField'
import { AgeRangeType } from 'graphql/types.generated'
import { useCallback } from 'react'
import Permissions from 'types/Permissions'
import { isEmpty } from 'util/validation/Util'

import { AcompanhamentoCondicaoSaudeTabsProps } from './AcompanhamentoCondicaoSaudeTabs'
import { GrupoCondicaoBuscaRapida } from './components/GrupoCondicaoBuscaRapida'
import { SexoIdentidadeGeneroButtonGroup } from './components/SexoIdentidadeGeneroButtonGroup'
import { AgeRangeFilter, condicaoToFormRecord } from './model-acompanhamentoCondicaoSaude'

export function AcompanhamentoCondicaoSaudeGeralTabContent(props: AcompanhamentoCondicaoSaudeTabsProps) {
  const { path, formValues, onChange } = props

  const canCheckApenasProblemasCondicoesAtivas =
    isEmpty(formValues.geral?.grupoCondicao) && isEmpty(formValues.geral?.problemasCondicoes)

  const handleClearCheckbox = useCallback(
    (numberOfGroups: number) => {
      numberOfGroups === 0 &&
        canCheckApenasProblemasCondicoesAtivas &&
        onChange(path.geral.ativoListaProblema.absolutePath(), false)
    },
    [canCheckApenasProblemasCondicoesAtivas, onChange, path.geral.ativoListaProblema]
  )

  const handleOnChangeGrupoCond = (grupos: GrupoCondicaoSelectModel[]) => {
    if (!grupos?.length && !formValues.geral.problemasCondicoes?.length) {
      onChange(path.geral.ativoListaProblema.absolutePath(), false)
    }
    Object.entries(condicaoToFormRecord).forEach(([condicao, formKey]) => {
      const isConditionPresent = grupos.some((item) => item.grupoCondicao.toString() === condicao)
      if (isConditionPresent !== formValues.geral?.[formKey]) {
        onChange(path.geral[formKey].absolutePath(), isConditionPresent)
      }
    })
  }

  const handleChangeCiapCid = (ciapCidList: CiapCidSelectModel[]) => {
    if (isEmpty(ciapCidList) && isEmpty(formValues.geral.grupoCondicao)) {
      onChange(path.geral.ativoListaProblema.absolutePath(), false)
    }
  }

  const handleAgeRangeChange = () => {
    onChange(path.faixaEtaria.absolutePath(), undefined)
  }

  return (
    <Grid>
      <CheckPermission permission={Permissions.acompanhamentos.condicoesDeSaude.problemasECondicoes}>
        <Cell size={12}>
          <GrupoCondicaoBuscaRapida
            path={path.geral}
            value={formValues}
            onChange={onChange}
            handleClearCheckbox={handleClearCheckbox}
          />
        </Cell>
        <Cell
          size={6}
          style={css`
            margin-top: 0.5rem;
          `}
        >
          <GrupoCondicaoSelectField
            label='Grupos de condições prioritários'
            name={path.geral.grupoCondicao.absolutePath()}
            multiple
            placeholder='Selecione grupos de condições prioritários'
            onChange={handleOnChangeGrupoCond}
          />
        </Cell>
        <Cell
          size={6}
          style={css`
            margin-top: 0.5rem;
          `}
        >
          <CiapCidSelectField
            label='CIAP2 e CID10'
            name={path.geral.problemasCondicoes.absolutePath()}
            multiple
            placeholder='Selecione outros CIAP2 e CID10'
            onChange={handleChangeCiapCid}
          />
        </Cell>
        <Cell size={12}>
          <CheckboxField
            name={path.geral.ativoListaProblema.absolutePath()}
            label='Buscar apenas problemas / condições ativas na lista de problemas e condições.'
            disabled={canCheckApenasProblemasCondicoesAtivas}
          />
        </Cell>
      </CheckPermission>
      <Cell size={12}>
        <Heading level={2}>Informações do cidadão</Heading>
      </Cell>
      <Cell size={6}>
        <SexoIdentidadeGeneroButtonGroup onChange={onChange} meta={path} />
      </Cell>
      <Cell size={6} />
      <Cell size={4}>
        <DateRangeField
          name={path.geral.periodoUltimoAtendimento.absolutePath()}
          label='Período do último atendimento individual'
          startPlaceholder='Data inicial'
          finalPlaceholder='Data final'
          maxDate={new Date()}
        />
      </Cell>
      <Cell size={8} />
      <Cell
        size={12}
        style={css`
          padding-bottom: 0;
        `}
      >
        <HeadingSection title='Faixa etária' level={5} />
      </Cell>
      <Cell
        size={4}
        style={css`
          padding-top: 0;
        `}
      >
        <VFlow vSpacing={0.5}>
          <RadioField
            name={path.faixaEtariaFilter}
            value={AgeRangeFilter.TODAS_FAIXAS}
            label='Todas as faixas'
            onChange={handleAgeRangeChange}
          />
          <RadioField
            name={path.faixaEtariaFilter}
            value={AgeRangeFilter.CRIANCA}
            label='Criança (0 a 10 anos)'
            onChange={handleAgeRangeChange}
          />
          <RadioField
            name={path.faixaEtariaFilter}
            value={AgeRangeFilter.ADOLESCENTE}
            label='Adolescente (11 a 19 anos)'
            onChange={handleAgeRangeChange}
          />
        </VFlow>
      </Cell>
      <Cell
        size={8}
        style={css`
          padding-top: 0;
        `}
      >
        <VFlow vSpacing={0.5}>
          <RadioField
            name={path.faixaEtariaFilter}
            value={AgeRangeFilter.ADULTO}
            label='Adulto (20 a 59 anos)'
            onChange={handleAgeRangeChange}
          />
          <RadioField
            name={path.faixaEtariaFilter}
            value={AgeRangeFilter.IDOSO}
            label='Idoso (60 anos ou mais)'
            onChange={handleAgeRangeChange}
          />
          <HFlow
            style={css`
              max-width: 28rem;
            `}
          >
            <div>
              <RadioField
                name={path.faixaEtariaFilter}
                value={AgeRangeFilter.OUTRA}
                label='Outra'
                onChange={handleAgeRangeChange}
              />
            </div>
            <AgeRangeField
              name={path.faixaEtaria}
              disabled={formValues?.faixaEtariaFilter !== AgeRangeFilter.OUTRA}
              rangeTypeOptions={[AgeRangeType.ANOS, AgeRangeType.MESES, AgeRangeType.DIAS]}
            />
          </HFlow>
        </VFlow>
      </Cell>
    </Grid>
  )
}

/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Alert, HFlow, Text, VFlow } from 'bold-ui'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import { Breadcrumb } from 'components/breadcrumb/Breadcrumb'
import { Form, FormRenderProps } from 'components/form'
import { PageContent } from 'components/layout/PageContent'
import { TitleGroup } from 'components/TitleGroup'
import {
  useAcompanhamentoCidadaosVinculadosLazyQuery,
  useAcompanhamentoCidadaosVinculadosProcessamentoQuery,
} from 'graphql/hooks.generated'
import { ArquivoIdentificadorEnum, ArquivoStatusEnum, ProcessamentoStatusEnum } from 'graphql/types.generated'
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import Permissions from 'types/Permissions'
import { isUndefinedOrNull } from 'util/checks'
import { RelatoriosRecentes } from 'view/arquivo/RelatoriosRecentes'

import {
  AcompanhamentoCidadaosVinculadosFilter,
  AcompanhamentoCidadaosVinculadosFilterModel,
} from './components/AcompanhamentoCidadaosVinculadosFilter'
import { ProcessamentoPendenteModal } from './components/ProcessamentoPendenteModal'
import { AcompanhamentoCidadaosVinculadosTableView } from './components/table/AcompanhamentoCidadaosVinculadosTableView'
import { convertAcompanhamentoCidadaosVinculadosFilterModelToFilterInput } from './converter'
import {
  downloadCSVAcompanhamentoCidadaosVinculados,
  downloadImpressaoAcompanhamentoCidadaosVinculados,
} from './downloadsAcompanhamentoCidadaosVinculados'
import { TITULO_ACOMPANHAMENTO_CIDADAOS_VINCULADOS } from './model'

export default function AcompanhamentoCidadaosVinculadosView() {
  const { acesso, hasAuthorization } = useAcessoLotacaoOrEstagio()

  const hasAccessToTodasEquipes = hasAuthorization(Permissions.acompanhamentos.cidadaosVinculados.todasAsEquipes)
  const hasEquipeOrAccessToTodasEquipes = !!acesso?.equipe || hasAccessToTodasEquipes

  const [filter, setFilter] = useState<AcompanhamentoCidadaosVinculadosFilterModel>()
  const [isProcessamentoPendenteModalOpen, setIsProcessamentoPendenteModalOpen] = useState(false)
  const [shouldRefetch, setShouldRefetch] = useState(false)
  const [statusRelatorio, setStatusRelatorio] = useState<ArquivoStatusEnum>()

  const identificadorModuloArquivo = ArquivoIdentificadorEnum.RELATORIO_ACOMPANHAMENTO_CIDADAOS_VINCULADOS

  const {
    data: { processamentoStatus },
  } = useAcompanhamentoCidadaosVinculadosProcessamentoQuery()
  const [executeQuery, { data, loading }] = useAcompanhamentoCidadaosVinculadosLazyQuery()

  const afterSubmit = () => {
    setShouldRefetch(true)
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const updateQuery = useCallback(executeQuery, [])

  const input = useMemo(() => {
    if (isUndefinedOrNull(filter)) return null
    return { ...convertAcompanhamentoCidadaosVinculadosFilterModelToFilterInput(filter) }
  }, [filter])

  useEffect(() => {
    const deveBuscarDados =
      isUndefinedOrNull(processamentoStatus) || processamentoStatus === ProcessamentoStatusEnum.PROCESSADO
    if (isUndefinedOrNull(input)) return
    if (deveBuscarDados) {
      updateQuery({
        variables: {
          input,
        },
      })
    } else if (!isUndefinedOrNull(processamentoStatus)) {
      setIsProcessamentoPendenteModalOpen(true)
    }
  }, [input, processamentoStatus, updateQuery])

  const handleFilterChange = (filter: AcompanhamentoCidadaosVinculadosFilterModel) => setFilter(filter)

  const handleImprimirClick = () => {
    downloadImpressaoAcompanhamentoCidadaosVinculados(input)
    afterSubmit()
  }

  const handleExportarCSVClick = () => {
    downloadCSVAcompanhamentoCidadaosVinculados(input)
    afterSubmit()
  }

  const renderForm = (formProps: FormRenderProps<AcompanhamentoCidadaosVinculadosFilterModel>) => {
    return (
      <Fragment>
        <Breadcrumb title={TITULO_ACOMPANHAMENTO_CIDADAOS_VINCULADOS} />
        <PageContent type='filled' style={styles.formContainer}>
          <VFlow style={styles.titleContent}>
            <HFlow justifyContent='space-between'>
              <TitleGroup title={TITULO_ACOMPANHAMENTO_CIDADAOS_VINCULADOS} />
              <RelatoriosRecentes
                shouldRefetch={shouldRefetch}
                setShouldRefetch={setShouldRefetch}
                identificadorModuloArquivo={identificadorModuloArquivo}
                setStatusRelatorio={setStatusRelatorio}
              />
            </HFlow>
            <Alert inline type='info' styles={{ container: styles.alertInfoIcon, wrapper: styles.alertInfoWrapper }}>
              São apresentados apenas os cidadãos vinculados a alguma equipe/INE. A origem das informações são as Fichas
              de cadastro individual, Fichas de cadastro domiciliar e territorial do CDS e os cadastros do módulo
              cidadão do PEC.
            </Alert>
            <AcompanhamentoCidadaosVinculadosFilter
              unidadeSaude={acesso?.unidadeSaude}
              equipe={acesso?.equipe}
              loading={loading}
              handleSubmit={formProps.handleSubmit}
              hasAccessToTodasEquipes={hasAccessToTodasEquipes}
              hasEquipeOrAccessToTodasEquipes={hasEquipeOrAccessToTodasEquipes}
            />
          </VFlow>
        </PageContent>
        {hasEquipeOrAccessToTodasEquipes ? (
          <AcompanhamentoCidadaosVinculadosTableView
            equipe={formProps.values?.equipe}
            data={data}
            loading={loading}
            shouldRefetch={shouldRefetch}
            statusRelatorio={statusRelatorio}
            handleExportarCSVClick={handleExportarCSVClick}
            handleImprimirClick={handleImprimirClick}
            onChange={handleFilterChange}
          />
        ) : (
          <PageContent style={styles.textResult}>
            <Text fontWeight='bold' variant='h3'>
              Seu perfil não possui acesso aos resultados. Para buscar os dados é necessário estar vinculado a uma
              equipe.
            </Text>
          </PageContent>
        )}
        <ProcessamentoPendenteModal
          status={processamentoStatus}
          isOpen={isProcessamentoPendenteModalOpen}
          setIsOpen={setIsProcessamentoPendenteModalOpen}
        />
      </Fragment>
    )
  }

  return <Form<AcompanhamentoCidadaosVinculadosFilterModel> render={renderForm} onSubmit={handleFilterChange} />
}

const styles = {
  formContainer: css`
    border-bottom: 1px solid lightgray;
    padding-bottom: 2rem;
    flex-grow: 0;
  `,
  alertInfoIcon: {
    svg: css`
      align-self: center;
    `,
  },
  alertInfoWrapper: css`
    padding: 0 1rem 0 0.5rem;
  `,
  titleContent: css`
    margin: 1rem 0;
  `,
  textResult: css`
    text-align: center;
  `,
}

import { Heading } from 'bold-ui'
import { useAlert } from 'components/alert'
import useSession from 'components/auth/useSession'
import { useErrorHandler } from 'components/error'
import { PageContainer } from 'components/layout/PageContainer'
import { PageContent } from 'components/layout/PageContent'
import { differenceInMinutes } from 'date-fns'
import {
  useBuscaCidadaoCadsusLazyQuery,
  useBuscaCidadaoCadsusPollingQuery,
  useCidadaoListingLazyQuery,
  useConfiguracaoCadsusQuery,
} from 'graphql/hooks.generated'
import { CidadaoListingQuery, SincronizacaoCadsusResultEnum } from 'graphql/types.generated'
import useAtmosphere from 'hooks/useAtmosphere'
import { useServerTime } from 'hooks/useServerTime'
import qs from 'qs'
import React, { useCallback, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { v4 as uuidv4 } from 'uuid'

import { useSaudeIndigenaAuthorization } from '../authorization/useSaudeIndigenaAuthorization'
import { CadsusAtmosphereResponse } from '../create/CidadaoCreateView'
import { CidadaoCadsusItemType } from '../types/CadsusModel'
import { CidadaoUrlParams } from '../types/CidadaoUrlParams'
import CidadaoPesquisarFormModel from '../types/model-pesquisarCidadao'
import { CidadaoAldeadoPesquisarForm } from './CidadaoAldeadoPesquisarForm'
import { CidadaoListItemView } from './CidadaoListItemView'
import { CidadaoPesquisarForm } from './CidadaoPesquisarForm'

export type CidadaoItemType = CidadaoListingQuery['cidadaos']['content'][0]

let pollingCadsus = false

export function CidadaoListView() {
  sessionStorage.removeItem('cidadaoCadsus')
  const alert = useAlert()
  const location = useLocation()
  const history = useHistory()
  const handleRejection = useErrorHandler()

  const [cidadaoUrlParamsState] = useState<CidadaoUrlParams>(
    qs.parse(location.search, {
      ignoreQueryPrefix: true,
    })
  )

  const [hasCadsusCalled, setHasCadsusCalled] = useState(false)
  const [isCadsusLoading, setIsCadsusLoading] = useState<boolean>(false)
  const { data: configCadsus } = useConfiguracaoCadsusQuery()
  const [buscaListaCadsusLazyQuery] = useBuscaCidadaoCadsusLazyQuery()
  const [hashBuscaCadsus, setHashBuscaCadsus] = useState<string>()
  const { data: session } = useSession()
  const { getServerTimeNow } = useServerTime()
  const now = getServerTimeNow()

  const { hasCadastrarEditarAldeadoPermission } = useSaudeIndigenaAuthorization()

  const alertaUsuario = useCallback(
    (message: string) => {
      alert('warning', message)
    },
    [alert]
  )

  const processResultCadsus = useCallback(
    (data) => {
      pollingCadsus = false
      setIsCadsusLoading(false)
      setCadsusDataState(data.cidadaos)
      if (SincronizacaoCadsusResultEnum.SUCESSO === data.resultCadsus) {
        if (!data?.cidadaos?.length) {
          alertaUsuario(
            'Não foram encontrados resultados com os filtros selecionados. Refine a busca incluindo mais informações.'
          )
        } else if (data?.cidadaos?.length === 20) {
          alert(
            'info',
            'Foram encontrados muitos cadastros com os dados informados. Refine a busca incluindo mais informações.'
          )
        }
      } else if (SincronizacaoCadsusResultEnum.ERRO_SEM_CONEXAO === data.resultCadsus) {
        alertaUsuario(
          'Não foi possível buscar na base nacional, pois a instalação está offline.' +
            ' Entre em contato com o responsável pela instalação para mais informações.'
        )
      } else if (SincronizacaoCadsusResultEnum.ERRO_CADSUS_DESABILITADO === data.resultCadsus) {
        alertaUsuario(
          'Não foi possível buscar na base nacional, pois a conexão com o serviço está desabilitada.' +
            ' Entre em contato com o responsável pela instalação para mais informações.'
        )
      } else if (SincronizacaoCadsusResultEnum.ERRO === data.resultCadsus) {
        alertaUsuario('Não foi possível se conectar à base nacional do CADSUS.')
      } else if (SincronizacaoCadsusResultEnum.ERRO_AUTENTICACAO === data.resultCadsus) {
        alertaUsuario(
          'Erro na autenticação com o CADSUS. Entre em contato com o Administrador da Instalação para verificar as configurações do certificado.'
        )
      } else if (SincronizacaoCadsusResultEnum.LENTO === data.resultCadsus) {
        alertaUsuario('O serviço de consulta está momentaneamente sobrecarregado, tente novamente em alguns instantes.')
      } else if (SincronizacaoCadsusResultEnum.VALIDACAO_APENAS_NOME === data.resultCadsus) {
        alertaUsuario(
          'Não é possível buscar na base nacional apenas o nome. É necessário incluir mais alguma informação.'
        )
      }
      setHasCadsusCalled(true)
    },
    [alert, alertaUsuario]
  )

  const { refetch: refetchPolling } = useBuscaCidadaoCadsusPollingQuery({ skip: true })

  useEffect(() => {
    let shouldUpdate = true
    const scheduleCadsusPolling = setInterval(() => {
      if (!!hashBuscaCadsus && pollingCadsus && shouldUpdate) {
        shouldUpdate = false
        refetchPolling({ uuid: hashBuscaCadsus })
          .then(
            ({ data }) =>
              !!data.buscaCidadaosCadsusCompletoPolling &&
              data.buscaCidadaosCadsusCompletoPolling.uuid === hashBuscaCadsus &&
              pollingCadsus &&
              processResultCadsus(data.buscaCidadaosCadsusCompletoPolling)
          )
          .finally(() => (shouldUpdate = true))
          .catch(handleRejection)
      }
    }, 1 * 1000)

    return () => clearInterval(scheduleCadsusPolling)
  }, [handleRejection, hashBuscaCadsus, processResultCadsus, refetchPolling])

  const [pesquisaCadsusState, setPesquisacadsusState] = useState<CidadaoPesquisarFormModel>({})
  const [cadsusDataState, setCadsusDataState] = useState<CidadaoCadsusItemType[]>()

  const buscaCidadaoCadsus = useCallback(() => {
    if (configCadsus.conexao.internet.habilitado) {
      if (configCadsus.conexao.cadsus.habilitado) {
        if (
          configCadsus.configuracaoCertificadoHabilitado?.cadsusHabilitado &&
          differenceInMinutes(new Date(session.expDateAccessTokenGovBr), now) > 0
        ) {
          const hash = uuidv4()
          setHashBuscaCadsus(hash)
          setIsCadsusLoading(true)
          pollingCadsus = true
          buscaListaCadsusLazyQuery({
            variables: {
              filter: {
                query: {
                  query: pesquisaCadsusState.nomeCpfCns,
                  dataNascimento: pesquisaCadsusState.dataNascimento,
                  nomeMae: pesquisaCadsusState.nomeMae,
                  ibgeMunicipioNascimento: pesquisaCadsusState.municipio?.ibge,
                  pageParams: {},
                },
                uuid: hash,
              },
            },
          })
        } else {
          alertaUsuario(
            'Não foi possível se conectar à base nacional do CADSUS. Verifique se o certificado está configurado e se o seu login no gov.br está válido.'
          )
        }
      } else {
        alertaUsuario(
          'Não foi possível buscar na base nacional, pois a conexão com o serviço está desabilitada.' +
            ' Entre em contato com o responsável pela instalação para mais informações.'
        )
      }
    } else {
      alertaUsuario(
        'Não foi possível buscar na base nacional, pois a instalação está offline.' +
          ' Entre em contato com o responsável pela instalação para mais informações.'
      )
    }
  }, [
    alertaUsuario,
    buscaListaCadsusLazyQuery,
    configCadsus,
    now,
    pesquisaCadsusState.dataNascimento,
    pesquisaCadsusState.municipio,
    pesquisaCadsusState.nomeCpfCns,
    pesquisaCadsusState.nomeMae,
    session.expDateAccessTokenGovBr,
  ])

  const [executeQuery, { data, refetch }] = useCidadaoListingLazyQuery({
    onCompleted: (dataCidadaos) => {
      if (dataCidadaos?.cidadaos?.content.length === 0) {
        alert('warning', 'Nenhum resultado encontrado na base local.')
        buscaCidadaoCadsus()
      }
      if (dataCidadaos?.cidadaos?.content.length > 50) {
        alert(
          'info',
          'Foram encontrados muitos cadastros com os dados informados. Refine a busca incluindo mais informações.'
        )
      }
    },
  })

  const handleSubmitPesquisa = (form: CidadaoPesquisarFormModel) => {
    if (
      !form.nomeCpfCns &&
      !form.dataNascimento &&
      !form.nomeMae &&
      !form.municipio &&
      !form.cidadaoAldeado?.dsei &&
      !form.cidadaoAldeado?.etnia &&
      !form.cidadaoAldeado?.naoIndigena
    ) {
      alert('info', 'Insira algum filtro para realizar a busca.')
    } else {
      pesquisar(form)
      history.push({
        search: qs.stringify({
          callbackUrl: cidadaoUrlParamsState.callbackUrl,
          callbackParams: cidadaoUrlParamsState.callbackParams,
          form: form,
        }),
      })
    }
  }

  const pesquisar = useCallback(
    (form: CidadaoPesquisarFormModel | undefined) => {
      setCadsusDataState(null)
      setPesquisacadsusState({
        nomeCpfCns: form?.nomeCpfCns,
        dataNascimento: form?.dataNascimento,
        municipio: form?.municipio,
        nomeMae: form?.nomeMae,
      })
      setHasCadsusCalled(false)
      executeQuery({
        variables: {
          filtro: {
            pageParams: {
              size: 51,
              fetchPageInfo: false,
            },
            query: form?.nomeCpfCns,
            dataNascimento: form?.dataNascimento,
            nomeMae: form?.nomeMae,
            municipioNascimento: form?.municipio?.id,
            cidadaoAldeado: hasCadastrarEditarAldeadoPermission
              ? {
                  naoIndigena: form?.cidadaoAldeado?.naoIndigena,
                  etnia: form?.cidadaoAldeado?.naoIndigena ? null : form?.cidadaoAldeado?.etnia?.id,
                  dsei: form?.cidadaoAldeado?.dsei?.id,
                  poloBase: form?.cidadaoAldeado?.poloBase?.id,
                  aldeia: form?.cidadaoAldeado?.aldeia?.id,
                }
              : null,
            ativo: cidadaoUrlParamsState.callbackUrl ? true : null,
            obito: cidadaoUrlParamsState.callbackUrl ? false : null,
          },
        },
      })
    },
    [cidadaoUrlParamsState.callbackUrl, executeQuery, hasCadastrarEditarAldeadoPermission]
  )

  const fetchCadsusResult = useCallback(
    (responseBody: CadsusAtmosphereResponse) => {
      if (!!hashBuscaCadsus && pollingCadsus && responseBody.uuid === hashBuscaCadsus) {
        refetchPolling({ uuid: hashBuscaCadsus })
          .then(
            ({ data }) =>
              !!data.buscaCidadaosCadsusCompletoPolling &&
              data.buscaCidadaosCadsusCompletoPolling.uuid === hashBuscaCadsus &&
              pollingCadsus &&
              processResultCadsus(data.buscaCidadaosCadsusCompletoPolling)
          )
          .catch(handleRejection)
      }
    },
    [handleRejection, hashBuscaCadsus, processResultCadsus, refetchPolling]
  )

  useAtmosphere<CadsusAtmosphereResponse>({
    topic: `cadsus-lista/${session.profissional.usuario.id}`,
    onMessage: fetchCadsusResult,
  })

  useEffect(() => {
    cidadaoUrlParamsState.form && pesquisar(cidadaoUrlParamsState.form)
  }, [cidadaoUrlParamsState, pesquisar])

  const refetchLocal = () => {
    refetch()
  }

  return (
    <>
      <PageContainer>
        <Heading style={{ padding: '2rem 0rem' }} level={1}>
          Cidadão
        </Heading>
        {hasCadastrarEditarAldeadoPermission ? (
          <CidadaoAldeadoPesquisarForm
            handleSubmitPesquisa={handleSubmitPesquisa}
            initialValue={cidadaoUrlParamsState.form}
          />
        ) : (
          <CidadaoPesquisarForm handleSubmitPesquisa={handleSubmitPesquisa} initialValue={cidadaoUrlParamsState.form} />
        )}
      </PageContainer>
      <PageContent type='transparent' style={{ textAlign: 'center' }}>
        <CidadaoListItemView
          result={data}
          resultCadsus={cadsusDataState}
          loadingCadsus={isCadsusLoading}
          refetchLocal={refetchLocal}
          onBuscarClicked={buscaCidadaoCadsus}
          hasCadsusCalled={hasCadsusCalled}
          hasCadsusConnection={configCadsus.conexao?.cadsus?.habilitado}
          hasInternetConnection={configCadsus.conexao?.internet?.habilitado}
          hasCertificadoCadsus={configCadsus.configuracaoCertificadoHabilitado?.cadsusHabilitado}
          cidadaoUrlParams={cidadaoUrlParamsState}
        />
      </PageContent>
    </>
  )
}

import { AtendimentoProfissional, AtendimentosFinalizadosInput, StatusAssinatura } from 'graphql/types.generated'
import { OrigemAtendimento } from 'graphql/types.generated'
import { HistoricoDetalhesModel } from 'view/atendimentos/detail/historico/model/model-historico'

import {
  AssinaturaEletronicaAtendTableFilterModel,
  AssinaturaEletronicaAtendTableRowModel,
  CriterioExibicaoAtendimento,
} from './model'

export const convertAssinaturaFilterModelToInput = (
  form: AssinaturaEletronicaAtendTableFilterModel
): AtendimentosFinalizadosInput =>
  form && {
    hasAssinatura:
      form.criterioExibicao === CriterioExibicaoAtendimento.ATENDIMENTOS_COM_ASSINATURA
        ? true
        : form.criterioExibicao === CriterioExibicaoAtendimento.ATENDIMENTOS_SEM_ASSINATURA
        ? false
        : undefined,
    equipeId: form.equipe?.id,
    lotacaoId: form.lotacao?.id,
    periodo: form.periodo,
    pageParams: form.pageParams,
  }

export const convertAssinaturaModelToHistoricoModel = (
  detalhesAtendimento: AssinaturaEletronicaAtendTableRowModel
): HistoricoDetalhesModel => ({
  origemAtendimento: OrigemAtendimento.PEC,
  tipoAtendProf: detalhesAtendimento.tipoAtendimento,
  codigoUnicoRegistro: detalhesAtendimento.codigoUnicoRegistro,
  cpfCnsCidadao: detalhesAtendimento.cidadao.cpf ?? detalhesAtendimento.cidadao.cns,
  isAtendRecente: detalhesAtendimento.isAtendimentoRecente,
  idAtendRecente: detalhesAtendimento.id,
  fichasConcatenadas: null,
  dataAtendimento: detalhesAtendimento.dataAtendimento,
})

export const convertAtendProfToAssinaturaEletronicaRowModel = (
  atendProf: AtendimentoProfissional
): AssinaturaEletronicaAtendTableRowModel => ({
  id: atendProf.id,
  dataAtendimento: atendProf.iniciadoEm,
  tipoAtendimento: atendProf.tipo,
  codigoUnicoRegistro: atendProf.codigoUnicoRegistro,
  isAtendimentoRecente: atendProf.isAtendRecente,
  cidadao: {
    nomeSocial: atendProf.atendimento.cidadao?.nomeSocial,
    nome: atendProf.atendimento.cidadao?.nome,
    cpf: atendProf.atendimento.cidadao?.cpf,
    cns: atendProf.atendimento.cidadao?.cns,
    dataNascimento: atendProf.atendimento.cidadao?.dataNascimento,
  },
  nomeProfissional: atendProf.lotacao.profissional.nome,
  cboProfissional: atendProf.lotacao.cbo.nome,
  nomeEquipe: atendProf.lotacao.equipe?.nome,
  ineEquipe: atendProf.lotacao.equipe?.ine,
  horarioAssinatura: atendProf.assinaturaEletronicaAtend?.dtAssinatura,
  statusAssinatura: atendProf.assinaturaEletronicaAtend?.status,
  isAtendimentoAssinado: atendProf.assinaturaEletronicaAtend?.status === StatusAssinatura.ASSINADO,
})

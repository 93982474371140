import { format } from 'date-fns'
import { OrigemAtendimento } from 'graphql/types.generated'

import { IdadeOrigemAtendimento } from './model/model-historico'

export const convertIdadeOrigemAtendimento = (
  dataNascimentoCidadao: LocalDate,
  dataAtendimento?: Instant,
  origemAtendimento?: OrigemAtendimento
): IdadeOrigemAtendimento => ({
  dataAtendimento: format(dataAtendimento, 'yyyy-MM-dd'),
  dataNascimentoCidadao,
  origemAtendimento: origemAtendimento,
})

/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, Grid, Tooltip, useTheme } from 'bold-ui'
import { ReactComponent as SvgChatSend } from 'images/videochamada/mail-send-filled.svg'
import { useRef } from 'react'

import { ChatTextArea } from './ChatTextArea'

interface ChatTextInputProps {
  onSend(text: string): void
  isMobile?: boolean
  value: string
  onChange(message: string): void
}

export function ChatTextInput(props: ChatTextInputProps) {
  const { onSend, isMobile = false, value, onChange } = props

  const textAreaRef = useRef<HTMLTextAreaElement>(null)
  const theme = useTheme()

  const handleClick = () => {
    textAreaRef.current?.focus()
    if (!!value) {
      onSend(value)
      onChange('')
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange(e.target.value)
  }

  return (
    <Grid
      alignItems='center'
      justifyContent='space-between'
      gap={0}
      style={css`
        border: 1px solid gray;
      `}
    >
      <Cell size={11}>
        <ChatTextArea
          ref={textAreaRef}
          value={value}
          isMobile={isMobile}
          onChange={handleChange}
          onPressEnter={handleClick}
        />
      </Cell>
      <Cell size={1}>
        <Tooltip text='Enviar mensagem'>
          <Button size='small' onClick={handleClick} style={styles.icon} disabled={!value}>
            <SvgChatSend fill={!value ? theme.pallete.gray.c60 : theme.pallete.primary.c40} fontSize='1.5rem' />
          </Button>
        </Tooltip>
      </Cell>
    </Grid>
  )
}

const styles = {
  icon: css`
    display: flex;
    align-items: center;
    width: inherit;
    padding: 0;
    border: none;
    &:focus {
      box-shadow: none;
    }
    ,
    &:not(:disabled):hover {
      background-color: transparent;
    }
  `,
}

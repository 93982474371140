import { Button, DropdownDivider, DropdownItem, HFlow, Icon, Tooltip } from 'bold-ui'
import { DropdownButton } from 'components/dropdown'
import React from 'react'

import { useSaudeIndigenaAuthorization } from '../authorization/useSaudeIndigenaAuthorization'
import { ModalInformarLoginGovBr } from '../create/ModalInformarLoginGovBr'
import { CidadaoListingFormModel } from '../types/model-pesquisarCidadao'

interface CidadaoListButtonsProps {
  data: CidadaoListingFormModel
  externalAccess: boolean
  hasAnyPermission: boolean
  cadastrarEEditar: boolean
  inativar: boolean
  excluir: boolean
  visualizarAgenda: boolean
  onExcluirClick(): void
  onVisualizarClick(): void
  onEditarClick(): void
  onReativarClick(): void
  handleVerAgendamentosClick(): void
  navigateToCidadaoCallbackUrl(cidadaoId: any): void
  modalInformarLoginGovBrOpen: boolean
  setModalInformarLoginGovBrOpen(value: boolean): void
  continueEditarCidadao(): void
}

export const CidadaoListButtons = (props: CidadaoListButtonsProps) => {
  const {
    data,
    externalAccess,
    hasAnyPermission,
    cadastrarEEditar,
    inativar,
    excluir,
    visualizarAgenda,
    onExcluirClick,
    onVisualizarClick,
    onEditarClick,
    onReativarClick,
    handleVerAgendamentosClick,
    navigateToCidadaoCallbackUrl,
    modalInformarLoginGovBrOpen,
    setModalInformarLoginGovBrOpen,
    continueEditarCidadao,
  } = props

  const { hasCadastrarEditarAldeadoPermission } = useSaudeIndigenaAuthorization()

  const disableEditAldeado = data?.cidadaoAldeado && !hasCadastrarEditarAldeadoPermission

  const tooltipEdit = !data?.ativo
    ? 'Este cadastro não pode ser atualizado pois está inativo'
    : disableEditAldeado && 'É necessário permissão para editar cidadão aldeado'

  return (
    <HFlow alignItems='center' hSpacing={0.5}>
      {externalAccess ? (
        <Button kind='normal' skin='outline' size='small' onClick={() => navigateToCidadaoCallbackUrl(data.id)}>
          <Icon icon='plus' style={{ marginRight: '0.5rem' }} />
          Selecionar e voltar
        </Button>
      ) : (
        <Button kind='normal' skin='outline' size='small' onClick={onVisualizarClick}>
          <Icon icon='zoomOutline' style={{ marginRight: '0.5rem' }} />
          Visualizar
        </Button>
      )}
      {hasAnyPermission && (
        <DropdownButton>
          {externalAccess && <DropdownItem onClick={onVisualizarClick}>Visualizar</DropdownItem>}

          {cadastrarEEditar && (
            <Tooltip text={tooltipEdit}>
              <DropdownItem disabled={!data.ativo || disableEditAldeado} onClick={onEditarClick}>
                Atualizar cadastro
              </DropdownItem>
            </Tooltip>
          )}

          {!data.ativo && inativar && !externalAccess && (
            <DropdownItem onClick={onReativarClick}>Reativar cadastro</DropdownItem>
          )}

          {visualizarAgenda && !externalAccess && (
            <Tooltip text={!data.possuiAgendamento && 'Não há agendamentos registrados para o cidadão'}>
              <DropdownItem onClick={handleVerAgendamentosClick} disabled={!data.possuiAgendamento}>
                Ver agendamentos
              </DropdownItem>
            </Tooltip>
          )}

          {excluir && !externalAccess && (
            <>
              <DropdownDivider />
              <DropdownItem
                type='danger'
                style={{ paddingTop: '0.25rem', paddingBottom: '0.25rem', display: 'flex', alignItems: 'center' }}
                onClick={onExcluirClick}
              >
                <Icon icon='trashOutline' style={{ marginRight: '0.5rem' }} /> Excluir
              </DropdownItem>
            </>
          )}
        </DropdownButton>
      )}
      <ModalInformarLoginGovBr
        open={modalInformarLoginGovBrOpen}
        onClose={() => setModalInformarLoginGovBrOpen(false)}
        onConfirm={continueEditarCidadao}
      />
    </HFlow>
  )
}

import { useAlert } from 'components/alert'
import useSession from 'components/auth/useSession'
import { isLotacaoOrEstagio } from 'components/auth/useSessionUtils'
import { useErrorHandler } from 'components/error'
import { Form, FormRenderProps } from 'components/form'
import { LoadingIndicator } from 'components/loading'
import { information } from 'components/modals/information'
import { HideOnScreenSize } from 'components/responsive'
import { TermoModal } from 'components/TermoModal'
import { useVideochamadaViewQuery } from 'graphql/hooks.generated'
import { StatusVideochamadaEnum } from 'graphql/types.generated'
import { useUrlQueryParams } from 'hooks/useUrlQueryParams'
import { capitalize } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { Route, useHistory, useRouteMatch } from 'react-router'
import { isUndefinedOrNull } from 'util/checks'

import { CriarVideochamadaView } from './CriarVideochamadaView'
import { useSolicitarEntradaVideochamada } from './hooks/useSolicitarEntradaVideochamada'
import { useVideochamadaStatus } from './hooks/useVideochamadaStatus'
import {
  AtorVideochamada,
  LocalVideocallParticipant,
  MotivoEncerramentoVideochamadaEnum,
  SalaEsperaFormModel,
  StatusSolicitacaoEntradaVideochamadaEnum,
  VideochamadaUrlParams,
} from './model-videochamada'
import { SalaEsperaVideochamadaUsuariosExternosView } from './SalaEsperaVideochamadaUsuariosExternosView'
import { SalaEsperaVideochamadaUsuariosExternosViewMobile } from './SalaEsperaVideochamadaUsuariosExternosViewMobile'
import { termosVideochamadaProfissionalVisitante } from './termos'
import { isVideochamadaIniciada } from './utils-videochamada'
import { profParticipanteValidator } from './validator-videochamada'
import { VideochamadaCanceladaView } from './VideochamadaCanceladaView'
import { VideochamadaEncerradaView } from './VideochamadaEncerradaView'
import { VideochamadaView } from './VideochamadaView'

export interface VideochamadaQueryParams {
  audio?: string
  video?: string
  cidadaoPresente?: string
}

export function VideochamadaRootView() {
  const { data: session, loading: isSessionLoading, refresh: refreshSession } = useSession()
  const history = useHistory()
  const alert = useAlert()
  const handleRejection = useErrorHandler()

  const queryParams = useUrlQueryParams<VideochamadaQueryParams>()

  const {
    params: { uuid: videochamadaUuid },
    url: matchUrl,
  } = useRouteMatch<VideochamadaUrlParams>()

  const {
    loading,
    data: { videochamada },
  } = useVideochamadaViewQuery({ variables: { uuid: videochamadaUuid } })

  const idAcesso = session?.acesso?.id
  const owner = videochamada?.owner

  const isOwner = !isUndefinedOrNull(idAcesso) && idAcesso === owner?.id
  const ownerName = owner?.profissional.nome
  const ownerCpf = owner?.profissional.cpf
  const cboProfissional =
    videochamada && owner ? (isLotacaoOrEstagio(owner) ? capitalize(owner?.cbo.nome) : null) : null

  const [selfData, setSelfData] = useState<LocalVideocallParticipant>()
  const [audioEnabled, setAudioEnabled] = useState(queryParams.audio !== 'false')
  const [videoEnabled, setVideoEnabled] = useState(queryParams.video !== 'false')

  const openTermosResponsabilidadeModal = useCallback(() => {
    history.push(`${matchUrl}/termosResponsabilidade`)
  }, [history, matchUrl])
  const closeTermosResponsabilidadeModal = useCallback(() => {
    history.replace(history.location.pathname.replace('/termosResponsabilidade', ''))
  }, [history])

  const { solicitarEntradaVideochamada, statusSolicitacao } = useSolicitarEntradaVideochamada({
    videochamadaUuid,
    ownerCpf,
    onEntrar: setSelfData,
    isOwner,
  })

  const { loading: isStatusVideochamadaLoading, status, motivoEncerramento } = useVideochamadaStatus(
    videochamadaUuid,
    true
  )

  useEffect(() => {
    switch (statusSolicitacao) {
      case StatusSolicitacaoEntradaVideochamadaEnum.NEGADA:
        alert('danger', `Sua participação na videochamada foi negada por ${ownerName}.`)
        break
      case StatusSolicitacaoEntradaVideochamadaEnum.TIMEOUT:
        alert('danger', `Sua solicitação para entrar na chamada não foi respondida por ${ownerName}, tente novamente.`)
        break
      case StatusSolicitacaoEntradaVideochamadaEnum.VIDEOCHAMADA_LOTADA:
        information({
          title: 'Número máximo de participantes excedido',
          body: 'No momento, as videochamadas e-SUS APS podem ter apenas dois participantes simultâneos.',
          showCloseButton: true,
        })()
        break
    }
  }, [alert, ownerName, statusSolicitacao])

  useEffect(() => {
    if (status === StatusVideochamadaEnum.NAO_ENCONTRADA) history.push('/404')
    if (status === StatusVideochamadaEnum.ENCERRADA) {
      switch (motivoEncerramento) {
        case MotivoEncerramentoVideochamadaEnum.ENCERRADA_POR_CRIADOR:
          history.push(`/videochamada/encerrada?owner=${isOwner}`)
          break
        case MotivoEncerramentoVideochamadaEnum.TIMEOUT:
          history.push('/videochamada/timeout')
          break
        case MotivoEncerramentoVideochamadaEnum.CONEXAO_FALHOU:
          showConnectionFailModal(isOwner, () => history.push(`/videochamada/encerrada?owner=${isOwner}`))
          break
        default:
          history.push('/videochamada/encerrada')
          break
      }
    }
  }, [history, isOwner, motivoEncerramento, status])

  // Se é o owner e a videochamada já foi iniciada, entra sem passar pela sala de espera
  useEffect(() => {
    if (isOwner && !selfData && isVideochamadaIniciada(status))
      solicitarEntradaVideochamada(ownerName, ownerCpf, true).catch(handleRejection)
  }, [solicitarEntradaVideochamada, isOwner, ownerCpf, ownerName, selfData, handleRejection, status])

  const handleEntrarButtonClick = () => {
    refreshSession() // Garante que o usuário esteja logado/deslogado ao tentar iniciar a videochamada
    openTermosResponsabilidadeModal()
  }

  const handleSolicitarEntrada = useCallback(
    async (values: SalaEsperaFormModel, autorizacoes: Record<AtorVideochamada.PROFISSIONAL_EXTERNO, boolean>) => {
      if (!autorizacoes[AtorVideochamada.PROFISSIONAL_EXTERNO])
        alert('danger', 'O profissional precisa autorizar a videochamada.')

      closeTermosResponsabilidadeModal()

      solicitarEntradaVideochamada(
        values.nomeParticipante,
        values.cpf,
        autorizacoes[AtorVideochamada.PROFISSIONAL_EXTERNO]
      )
    },
    [alert, closeTermosResponsabilidadeModal, solicitarEntradaVideochamada]
  )

  const renderForm = ({ values, handleSubmit }: FormRenderProps<SalaEsperaFormModel>) => (
    <form onSubmit={handleSubmit} noValidate>
      <Route path={`${matchUrl}/termosResponsabilidade`}>
        <TermoModal
          onAccept={(autorizacoes) => handleSolicitarEntrada(values, autorizacoes)}
          open
          onClose={() => closeTermosResponsabilidadeModal()}
          termos={termosVideochamadaProfissionalVisitante}
          confirmText='Iniciar chamada'
        />
      </Route>
      <HideOnScreenSize up='md'>
        <SalaEsperaVideochamadaUsuariosExternosViewMobile
          nomeProfissional={ownerName}
          cboProfissional={cboProfissional}
          audioEnabled={audioEnabled}
          videoEnabled={videoEnabled}
          setAudioEnabled={setAudioEnabled}
          setVideoEnabled={setVideoEnabled}
          statusVideochamada={status}
          statusSolicitacao={statusSolicitacao}
          handleEntrarButtonClick={handleEntrarButtonClick}
        />
      </HideOnScreenSize>
      <HideOnScreenSize down='md'>
        <SalaEsperaVideochamadaUsuariosExternosView
          nomeProfissional={ownerName}
          cboProfissional={cboProfissional}
          audioEnabled={audioEnabled}
          videoEnabled={videoEnabled}
          setAudioEnabled={setAudioEnabled}
          setVideoEnabled={setVideoEnabled}
          statusVideochamada={status}
          statusSolicitacao={statusSolicitacao}
          handleEntrarButtonClick={handleEntrarButtonClick}
        />
      </HideOnScreenSize>
    </form>
  )

  return loading ||
    isSessionLoading ||
    isStatusVideochamadaLoading ||
    status === null ||
    status === StatusVideochamadaEnum.NAO_ENCONTRADA ||
    (isOwner && !selfData && status === StatusVideochamadaEnum.ATIVA) ? (
    <LoadingIndicator />
  ) : status === StatusVideochamadaEnum.CANCELADA ? (
    <VideochamadaCanceladaView />
  ) : status === StatusVideochamadaEnum.AGUARDANDO_INICIO && isOwner ? (
    <CriarVideochamadaView horarioInicioVideochamada={videochamada?.agendamento?.horarioInicial} />
  ) : status === StatusVideochamadaEnum.ENCERRADA ? (
    <VideochamadaEncerradaView />
  ) : selfData ? (
    <VideochamadaView
      selfData={selfData}
      isOwner={isOwner}
      videochamadaUuid={videochamadaUuid}
      audioEnabled={audioEnabled}
      videoEnabled={videoEnabled}
      setAudioEnabled={setAudioEnabled}
      setVideoEnabled={setVideoEnabled}
    />
  ) : (
    <Form<SalaEsperaFormModel>
      render={renderForm}
      onSubmit={handleEntrarButtonClick}
      validate={profParticipanteValidator}
      suppressNotificationError
    />
  )
}

const showConnectionFailModal = (isOwner: boolean, onClose: () => void) =>
  information({
    title: 'Conexão falhou',
    body:
      'Não foi possível estabelecer uma conexão com o outro participante. Verifique suas configurações ou entre em contato com o administrador da rede.',
    iconColor: 'alert',
    showCloseButton: true,
    closeLabel: isOwner ? 'Encerrar chamada' : 'Voltar',
    onClose,
  })()

import { afterEqualDate, beforeEqualToday, createValidator, ErrorObject, hora, required } from 'util/validation'

import { localAtendimentoComJustificativa, RegistroTardioFormModel } from './model-registroTardio'

export const validator = (minDate: Date, canAtender: boolean) =>
  createValidator<RegistroTardioFormModel>(
    {
      cidadao: required,
      dataAtendimento: [required, beforeEqualToday, afterEqualDate(minDate)],
      horaAtendimento: [required, hora],
      lotacao: !canAtender && required,
      localAtendimento: required,
    },
    (
      values: RegistroTardioFormModel,
      errors: ErrorObject<RegistroTardioFormModel>
    ): ErrorObject<RegistroTardioFormModel> => {
      if (values.localAtendimento && isLocalAtendimentoComJustificativa(values.localAtendimento.id)) {
        errors.justificativa = required(values.justificativa)
      }
      return errors
    }
  )

export const isLocalAtendimentoComJustificativa = (id: ID) => {
  return localAtendimentoComJustificativa.has(id)
}

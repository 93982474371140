/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Heading, HFlow, Icon, VFlow } from 'bold-ui'
import { Form, FormRenderProps, RadioGroupField } from 'components/form'
import { PopperButton, PopperControls } from 'components/popper'
import createDecorator from 'final-form-calculate'
import { useApolloClient } from 'graphql/hooks'
import { useMemo } from 'react'
import { metaPath } from 'util/metaPath'

import { createAssinaturaEletronicaAtendimentoCalculations } from '../calculator'
import { AssinaturaEletronicaAtendTableFilterModel, CriterioExibicaoAtendimento, name } from '../model'
import AssinaturaEletronicaAtendTableFilterPopper from './AssinaturaEletronicaAtendTableFilterPopper'

const criterioExibicaoAtendimentoOptions = [
  {
    value: CriterioExibicaoAtendimento.TODOS_OS_ATENDIMENTOS,
    label: 'Todos os atendimentos',
  },
  {
    value: CriterioExibicaoAtendimento.ATENDIMENTOS_SEM_ASSINATURA,
    label: 'Sem assinatura',
  },
  {
    value: CriterioExibicaoAtendimento.ATENDIMENTOS_COM_ASSINATURA,
    label: 'Com assinatura',
  },
]

export interface AssinaturaEletronicaAtendTableFilterProps {
  initialValues?: AssinaturaEletronicaAtendTableFilterModel
  onChange(values: AssinaturaEletronicaAtendTableFilterModel): void
  unidadeSaudeId?: string
  isCoordenador: boolean
}

const path = metaPath<AssinaturaEletronicaAtendTableFilterModel>()

export function AssinaturaEletronicaAtendTableFilter(props: AssinaturaEletronicaAtendTableFilterProps) {
  const { initialValues, onChange, unidadeSaudeId, isCoordenador } = props

  const apollo = useApolloClient()
  const decorator = useMemo(
    () => [createDecorator(...createAssinaturaEletronicaAtendimentoCalculations(path, apollo))],
    [apollo]
  )

  const renderForm = ({ handleSubmit }: FormRenderProps<AssinaturaEletronicaAtendTableFilterModel>) => (
    <HFlow justifyContent='space-between' alignItems='flex-end'>
      <VFlow vSpacing={0.5}>
        <Heading level={5}> Exibir </Heading>
        <RadioGroupField
          name={name.criterioExibicao}
          options={criterioExibicaoAtendimentoOptions}
          onChange={handleSubmit}
        />
      </VFlow>

      <PopperButton
        kind='primary'
        size='small'
        skin='default'
        placement='bottom-end'
        focusOnOpen={false}
        renderPopper={(controls) => renderPopper(controls, handleSubmit, isCoordenador, unidadeSaudeId)}
      >
        <Icon icon='filterOutline' />
        Filtros
      </PopperButton>
    </HFlow>
  )

  const renderPopper = (
    controls: PopperControls,
    handleSubmit: (event: any) => Promise<any>,
    isCoordenador: boolean,
    unidadeSaudeId: string
  ) => {
    return (
      <AssinaturaEletronicaAtendTableFilterPopper
        onClose={controls.close}
        onSubmit={handleSubmit}
        isCoordenador={isCoordenador}
        unidadeSaudeId={unidadeSaudeId}
      />
    )
  }

  return (
    <Form<AssinaturaEletronicaAtendTableFilterModel>
      onSubmit={onChange}
      initialValues={initialValues}
      render={renderForm}
      decorators={decorator}
    />
  )
}

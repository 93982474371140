/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, Text, Theme, useTheme } from 'bold-ui'
import { useMemo } from 'react'

import { IVCF_RESULTADOS, IvcfResultadoEnum } from '../model-ivcf'

interface IvcfResultadoLineProps {
  line: IvcfResultadoEnum
}

export function IvcfResultadoLine(props: IvcfResultadoLineProps) {
  const { line } = props

  const theme = useTheme()
  const styles = useMemo(() => createStyles(theme), [theme])

  const lineDiv = createLine(props.line, styles)
  const lineTitle = IVCF_RESULTADOS[line].shortTitle
  const lineRange = line === IvcfResultadoEnum.BAIXO ? '0-6' : line === IvcfResultadoEnum.MODERADO ? '7-15' : '16-40'

  return (
    <div css={styles.div}>
      <HFlow css={styles.tableHeader}>
        <Text style={styles.tableHeaderPrimaryText}>{lineTitle}</Text>
        <Text style={styles.tableHeaderSecondaryText}>({lineRange})</Text>
      </HFlow>
      {lineDiv}
    </div>
  )
}

const createLine = (type: IvcfResultadoEnum, styles) => {
  const styleMap = {
    [IvcfResultadoEnum.BAIXO]: styles.lineBaixo,
    [IvcfResultadoEnum.MODERADO]: styles.lineMedio,
    [IvcfResultadoEnum.ALTO]: styles.lineAlto,
  }

  return <div css={[styles.line, styleMap[type]]} />
}

const createStyles = (theme: Theme) => ({
  div: css`
    padding: 0;
    margin: 0;
  `,
  tableHeader: css`
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 0.25rem;
    background: ${theme.pallete.gray.c90};
    height: 1.5rem;
    margin-bottom: 1.125rem;
  `,
  tableHeaderPrimaryText: css`
    color: ${theme.pallete.gray.c10};
  `,
  tableHeaderSecondaryText: css`
    color: ${theme.pallete.gray.c40};
  `,
  line: css`
    height: 0.25rem;
  `,
  lineBaixo: css`
    background: ${IVCF_RESULTADOS.BAIXO.lineBgColor};
  `,
  lineMedio: css`
    background: ${IVCF_RESULTADOS.MODERADO.lineBgColor};
  `,
  lineAlto: css`
    background: ${IVCF_RESULTADOS.ALTO.lineBgColor};
  `,
})

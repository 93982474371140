import useSession from 'components/auth/useSession'
import { differenceInMinutes } from 'date-fns'

import { useConfiguracoes } from './configuracoes'
import { useServerTime } from './useServerTime'

export function useLoginGovBr() {
  const { getServerTimeNow } = useServerTime()
  const now = getServerTimeNow()
  const { data: session } = useSession()
  const {
    configuracoes: { govBREnabled },
  } = useConfiguracoes()

  return {
    isGovBrEnabled: govBREnabled,
    isAccessTokenGovBrAlive: govBREnabled && differenceInMinutes(new Date(session.expDateAccessTokenGovBr), now) > 0,
  }
}

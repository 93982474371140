import {
  Button,
  colors,
  Dropdown,
  DropdownItem,
  ExternalStyles,
  focusBoxShadow,
  Icon,
  InputProps,
  Omit,
  Text,
  TextInput,
  Theme,
  useStyles,
} from 'bold-ui'
import { AgeRangeType } from 'graphql/types.generated'
import { isNaN, isNumber } from 'lodash'
import React, { CSSProperties, FocusEvent, KeyboardEvent, useRef, useState } from 'react'

export interface AgeRangeValue {
  tipoFaixaEtaria: AgeRangeType
  firstValue: number
  secondValue: number
}
export interface AgeRangeProps extends Omit<InputProps, 'onChange' | 'value' | 'maxLength' | 'style' | 'placeholder'> {
  value?: AgeRangeValue
  invalid?: boolean
  maxLength?: number
  firstInputPlaceholder?: string
  secondInputPlaceholder?: string
  style?: ExternalStyles
  rangeTypeOptions?: AgeRangeType[]
  onChange?(item: AgeRangeValue): void
}

export const stringToEnumAgeRangeTypeMap: Record<string, AgeRangeType> = {
  Anos: AgeRangeType.ANOS,
  Meses: AgeRangeType.MESES,
  Semanas: AgeRangeType.SEMANAS,
  Dias: AgeRangeType.DIAS,
}

export const enumToStringAgeRangeTypeMap: Record<AgeRangeType, string> = {
  [AgeRangeType.ANOS]: 'Anos',
  [AgeRangeType.MESES]: 'Meses',
  [AgeRangeType.SEMANAS]: 'Semanas',
  [AgeRangeType.DIAS]: 'Dias',
}

export const getSingleAgeRangeValue = (faixaEtaria: AgeRangeValue) =>
  faixaEtaria?.secondValue ?? faixaEtaria?.firstValue

export function AgeRange(props: AgeRangeProps) {
  const {
    onChange,
    value,
    invalid,
    firstInputPlaceholder = 'Idade mínima',
    secondInputPlaceholder = 'Idade máxima',
    maxLength = 3,
    rangeTypeOptions = Object.values(AgeRangeType),
    onBlur,
    ...rest
  } = props
  const firstInputRef = useRef<HTMLInputElement>()
  const secondInputRef = useRef<HTMLInputElement>()
  const [anchorRef, setAnchorRef] = useState<HTMLButtonElement>()
  const [open, setOpen] = useState(false)

  const options = rangeTypeOptions.map((option: AgeRangeType) => enumToStringAgeRangeTypeMap[option])
  const { classes, css } = useStyles(createStyles, props.disabled)
  const className = css(classes.mainDiv, invalid && classes.invalid, props.style)

  const handleChangePeriodo = (selectedOption) => {
    const ageRangeValues = {
      tipoFaixaEtaria: stringToEnumAgeRangeTypeMap[selectedOption],
      firstValue: null,
      secondValue: null,
    } as AgeRangeValue
    return () => {
      onChange && onChange(ageRangeValues)
    }
  }

  const handleChangeFirstValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const firstValueStr = e.currentTarget?.value
    const firstValueInt = parseInt(firstValueStr)
    const firstValue = !isNaN(firstValueInt) ? firstValueInt : undefined
    const ageRangeValues = {
      tipoFaixaEtaria: value?.tipoFaixaEtaria ?? AgeRangeType.ANOS,
      firstValue,
      secondValue: value?.secondValue,
    } as AgeRangeValue
    onChange && onChange(ageRangeValues)
  }

  const handleChangeSecondValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const secondValueStr = e.currentTarget?.value
    const secondValue = !isNaN(parseInt(secondValueStr)) ? parseInt(secondValueStr) : undefined
    const ageRangeValues = {
      tipoFaixaEtaria: value?.tipoFaixaEtaria ?? AgeRangeType.ANOS,
      firstValue: value?.firstValue,
      secondValue,
    } as AgeRangeValue
    onChange && onChange(ageRangeValues)
  }

  const handleClearFirstValue = () => {
    const ageRangeValues = {
      tipoFaixaEtaria: value?.tipoFaixaEtaria,
      firstValue: undefined,
      secondValue: value?.secondValue,
    } as AgeRangeValue
    onChange && onChange(ageRangeValues)
    firstInputRef.current.focus()
  }

  const handleClearSecondValue = () => {
    const ageRangeValues = {
      tipoFaixaEtaria: value?.tipoFaixaEtaria,
      firstValue: value?.firstValue,
      secondValue: undefined,
    } as AgeRangeValue
    onChange && onChange(ageRangeValues)
    secondInputRef.current.focus()
  }
  const handleClick = () => setOpen((state) => !state)

  const handleClose = () => {
    setOpen(false)
    anchorRef.focus()
  }

  const maskTextInput = (e: KeyboardEvent<HTMLInputElement>) => {
    if (!(e.charCode >= 48 && e.charCode <= 57)) {
      e.preventDefault()
    }
  }

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    if (isNumber(value.firstValue) && isNumber(value.secondValue) && value.firstValue > value.secondValue) {
      const ageRangeValues = {
        tipoFaixaEtaria: value?.tipoFaixaEtaria,
        firstValue: value.secondValue,
        secondValue: value.firstValue,
      } as AgeRangeValue
      onChange && onChange(ageRangeValues)
    }
    onBlur && onBlur(e)
  }

  return (
    <div className={className}>
      <div className={classes.internalWrapper}>
        <div className={classes.inputwrapper}>
          <TextInput
            clearable
            inputRef={firstInputRef}
            name='firstInput'
            style={classes.textField}
            maxLength={maxLength}
            value={isNumber(value?.firstValue) && !isNaN(value?.firstValue) ? value.firstValue.toString() : ''}
            onChange={handleChangeFirstValue}
            onClear={handleClearFirstValue}
            onKeyPress={(event) => maskTextInput(event)}
            placeholder={firstInputPlaceholder}
            onBlur={handleBlur}
            {...rest}
          />
        </div>
        <span className={classes.arrowIconWrapper}>
          <strong>até</strong>
        </span>
        <div className={classes.inputwrapper}>
          <TextInput
            clearable
            inputRef={secondInputRef}
            name='secondInput'
            style={classes.textField}
            maxLength={maxLength}
            value={isNumber(value?.secondValue) && !isNaN(value?.secondValue) ? value.secondValue.toString() : ''}
            onChange={handleChangeSecondValue}
            onClear={handleClearSecondValue}
            onKeyPress={(event) => maskTextInput(event)}
            placeholder={secondInputPlaceholder}
            onBlur={handleBlur}
            {...rest}
          />
        </div>
      </div>
      {options.length > 1 ? (
        <Button
          skin='ghost'
          size='small'
          tabIndex={-1}
          disabled={props.disabled}
          innerRef={setAnchorRef}
          onClick={handleClick}
          style={classes.button}
        >
          <Text style={classes.buttonText}>
            {enumToStringAgeRangeTypeMap[value?.tipoFaixaEtaria ?? AgeRangeType.ANOS]}
          </Text>
          <Icon style={{ marginLeft: '0.5rem' }} icon={open ? 'angleUp' : 'angleDown'} />
          <Dropdown anchorRef={anchorRef} open={open} onClose={handleClose} style={classes.dropdown}>
            {options.map((op) => (
              <DropdownItem key={op} onClick={handleChangePeriodo(op)}>
                {op}
              </DropdownItem>
            ))}
          </Dropdown>
        </Button>
      ) : (
        <Text fontWeight='bold' style={classes.button}>
          {options[0]}
        </Text>
      )}
    </div>
  )
}

const createStyles = (theme: Theme, disabled: boolean) => {
  const mainDivStyle = createMainDivStyle(theme)

  return {
    mainDiv: {
      ...mainDivStyle.base,
      ':not(:disabled):hover': mainDivStyle.hover,
      ':not(:disabled):active': mainDivStyle.active,
      ':focus-within': {
        ':not(:disabled)': mainDivStyle.focus,
      },
      background: disabled && theme.pallete.surface.background,
    } as CSSProperties,
    invalid: mainDivStyle.invalid,
    button: {
      borderRadius: 0,
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      width: '5.8rem',
      padding: '0.25rem',
      backgroundColor: colors.gray.c90,
      '&:focus': {
        boxShadow: 'none !important',
      },
    } as CSSProperties,
    buttonText: {
      marginLeft: '0.5rem',
      width: '2.5rem',
      marginBottom: '0.15rem',
    },
    dropdown: {
      '&:focus': {
        transition: 'box-shadow .2s ease',
      },
    } as CSSProperties,
    internalWrapper: {
      flex: 1,
      alignItems: 'center',
      marginRight: '0.2rem',
      display: 'flex',
    } as CSSProperties,
    arrowIconWrapper: {
      background: 'transparent',
      color: disabled && theme.pallete.text.disabled,
      cursor: 'default',
      display: 'flex',
    } as CSSProperties,
    inputwrapper: {
      flex: 1,
    } as CSSProperties,
    textField: {
      padding: 0,
      textAlign: 'center',
      border: 'none',
      '::placeholder': {
        color: theme.pallete.text.disabled,
      },
      '&:focus': {
        boxShadow: 'none !important',
      },
    } as CSSProperties,
  }
}

const createMainDivStyle = (theme: Theme) => ({
  base: {
    display: 'flex',
    height: '2rem',
    border: '1px solid' + theme.pallete.gray.c70,
    borderRadius: theme.radius.input,
    position: 'relative',
    transition: 'box-shadow .2s ease',
    '&:required': {
      boxShadow: 'none',
    },
  } as CSSProperties,
  active: {
    borderColor: theme.pallete.primary.main,
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.09)',
  } as CSSProperties,
  focus: {
    boxShadow: focusBoxShadow(theme),
    outline: 'none',
  } as CSSProperties,
  hover: {
    borderColor: theme.pallete.gray.c60,
  } as CSSProperties,
  invalid: {
    border: 'solid 1px ' + theme.pallete.status.danger.main,
    '&:focus-within': {
      ':not(:disabled)': {
        border: 'solid 1px ' + theme.pallete.gray.c80,
        boxShadow: focusBoxShadow(theme, 'danger'),
      },
    },
  } as CSSProperties,
})

import { Cell, Grid, Heading, VFlow } from 'bold-ui'
import { Box } from 'components/Box'
import { TextField, UnidadeSaudeSelectField, UnidadeSaudeSelectModel } from 'components/form'
import React from 'react'
import { MetaRoot } from 'util/metaPath'

export interface LocalAtividadeFieldModel {
  unidadeSaudeLocalAtividade?: UnidadeSaudeSelectModel
  outraLocalidade?: string
}

interface LocalAtividadeFieldProps {
  name: MetaRoot<LocalAtividadeFieldModel>
}

export function LocalAtividadeField({ name }: LocalAtividadeFieldProps) {
  return (
    <VFlow vSpacing={0.5}>
      <Heading level={5}>Local da atividade</Heading>
      <Box>
        <Grid gap={2}>
          <Cell size={6}>
            <UnidadeSaudeSelectField
              name={name.unidadeSaudeLocalAtividade}
              label='CNES'
              title='CNES'
              placeholder='Busque por um CNES'
            />
          </Cell>

          <Cell size={6}>
            <TextField
              name={name.outraLocalidade}
              label='Outro'
              title='Outro'
              placeholder='Informe o local da atividade'
              maxLength={250}
            />
          </Cell>
        </Grid>
      </Box>
    </VFlow>
  )
}

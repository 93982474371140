/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, Text, Theme, useTheme, VFlow } from 'bold-ui'
import { useMemo } from 'react'

import { IVCF_SUBGRUPOS, IvcfSubGrupoEnum } from '../model-ivcf'
import { IvcfPerguntaField } from './IvcfPerguntaField'

interface IvcfSubGroupSectionProps {
  ivcfPath: any
  subgrupo: IvcfSubGrupoEnum
  isEditing: boolean
  readOnly?: boolean
}

export function IvcfSubGroupSection(props: IvcfSubGroupSectionProps) {
  const { ivcfPath, subgrupo, isEditing, readOnly } = props

  const theme = useTheme()
  const styles = useMemo(() => createStyles(theme, isEditing), [isEditing, theme])

  const { label, subtitle, perguntas } = IVCF_SUBGRUPOS[subgrupo]

  return (
    <VFlow vSpacing={0}>
      <HFlow alignItems='center' style={styles.header}>
        <Text color='primary'>{label}</Text>
      </HFlow>
      {subtitle && (
        <HFlow alignItems='center' style={styles.subtitle}>
          <Text>{subtitle}</Text>
        </HFlow>
      )}
      {perguntas.map((pergunta) => (
        <IvcfPerguntaField ivcfPath={ivcfPath} pergunta={pergunta} isEditing={isEditing} readOnly={readOnly} />
      ))}
    </VFlow>
  )
}

const createStyles = (theme: Theme, isEditing: boolean) => ({
  header: css`
    font-weight: bold;
    height: 2.5rem;
    padding: 0 1rem;
    background: ${isEditing ? theme.pallete.primary.c90 : theme.pallete.surface.main};
    border-bottom: 1px solid ${theme.pallete.gray.c80};
  `,
  subtitle: css`
    font-weight: bold;
    height: 2rem;
    padding: 0 1rem;
    background: ${isEditing ? theme.pallete.primary.c90 : theme.pallete.surface.main};
    border-bottom: 1px solid ${theme.pallete.gray.c80};
  `,
})

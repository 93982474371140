import { Alert, HeadingSection, VFlow } from 'bold-ui'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import { Breadcrumb } from 'components/breadcrumb'
import { PageContent } from 'components/layout/PageContent'
import { getLotacaoResponsavel } from 'components/lotacao-responsavel/utils/utils-lotacaoResponsavel'
import { format } from 'date-fns'
import { TipoEnderecoEnum } from 'graphql/types.generated'
import { useServerTime } from 'hooks/useServerTime'
import React, { useMemo } from 'react'
import { useSaudeIndigenaAuthorization } from 'view/cidadao/authorization/useSaudeIndigenaAuthorization'

import { ImovelForm } from './components/ImovelForm'
import { ImovelFormModel } from './model-cadastroImovel'

export default function CadastrarImovelView() {
  const { acesso, profissional } = useAcessoLotacaoOrEstagio()
  const { getServerTimeNow } = useServerTime()
  const { hasTerritorioIndigenaPermission } = useSaudeIndigenaAuthorization()
  const dataAtual = format(getServerTimeNow(), 'yyyy-MM-dd')

  const initialValues = useMemo<ImovelFormModel>(
    () => ({
      responsabilidade: getLotacaoResponsavel(profissional, acesso.cbo, acesso.equipe, acesso.unidadeSaude, acesso.id),
      tipoEndereco: hasTerritorioIndigenaPermission ? TipoEnderecoEnum.ALDEIA : TipoEnderecoEnum.LOGRADOURO,
      dataColeta: dataAtual,
      isDomicilio: null,
      possuiFamiliasSalvas: false,
    }),
    [
      hasTerritorioIndigenaPermission,
      acesso.cbo,
      acesso.equipe,
      acesso.id,
      acesso.unidadeSaude,
      dataAtual,
      profissional,
    ]
  )

  return (
    <PageContent type='filled'>
      <Breadcrumb title='Cadastro do imóvel' />
      <HeadingSection level={1} title='Cadastro do imóvel' />
      <VFlow>
        <Alert type='info'>Salvar este cadastro atualizará automaticamente o cadastro dos moradores envolvidos.</Alert>
        <ImovelForm initialValues={initialValues} />
      </VFlow>
    </PageContent>
  )
}

import { useStyles } from 'bold-ui'
import React, { CSSProperties } from 'react'

import { ChartTooltip } from './ChartTooltip'
import { CustomDot } from './CustomDot'
import { AxisDomain, DotShape, getDataPointValue, getOutlierSeriesName, Outlier, TooltipRenderer } from './model'

export interface LineDotProps<XDomain> {
  cx?: number
  cy?: number
  value?: number | number[]
  stroke?: string
  showTooltip?: boolean
  dataKey?: string
  payload?: { [x: string]: any }
  xDomain?: AxisDomain
  dotShape?: DotShape
  tooltipRenderer?: TooltipRenderer<XDomain>
}

export function LineDot<XDomain>(props: LineDotProps<XDomain>) {
  const { cx, cy, showTooltip, stroke, payload, value, xDomain, dataKey: seriesName, dotShape, tooltipRenderer } = props
  const { classes } = useStyles(createStyles)
  const tooltipValue = getTooltipValue(payload[getOutlierSeriesName(seriesName)], seriesName, value)

  return (
    <ChartTooltip
      showTooltip={showTooltip}
      seriesName={seriesName}
      label={payload.x}
      value={tooltipValue}
      labelDomain={xDomain}
      renderer={tooltipRenderer}
    >
      <g className={classes.container}>
        <CustomDot cx={cx} cy={cy} stroke={stroke} shape={dotShape} />
      </g>
    </ChartTooltip>
  )
}

const getTooltipValue = (outlier: Outlier, seriesName: string, value: number | number[]) =>
  outlier && outlier.series === seriesName
    ? getDataPointValue(outlier.value)
    : Array.isArray(value)
    ? value[value.length - 1]
    : value

const createStyles = () => ({
  container: {
    '.hover-circle': {
      visibility: 'hidden',
    },
    '&:hover .hover-circle': {
      visibility: 'visible',
    },
  } as CSSProperties,
})

/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Heading, HFlow, Icon, Modal, ModalBody, ModalFooter, ModalProps, Text, VFlow } from 'bold-ui'
import { FooterButton } from 'components/footer-button'
import { formatDate } from 'util/date/formatDate'
import { UltimoAtendimentoProfissionalCidadaoModel } from 'view/registro-tardio/model-registroTardio'

/** TODO (RoyalFlush) Receber obrigatoriamente informações do respectivo atendimento e renderizá-las */

interface RegistroTardioAtendimentoPosteriorModalProps extends ModalProps {
  atendimento: UltimoAtendimentoProfissionalCidadaoModel
}

export function RegistroTardioAtendimentoPosteriorModal(props: RegistroTardioAtendimentoPosteriorModalProps) {
  const { atendimento, ...modalProps } = props

  return (
    <Modal {...modalProps} size='small'>
      <ModalBody>
        <VFlow>
          <HFlow alignItems='center'>
            <Icon icon='infoCircleOutline' size={3} fill='info' />
            <Heading level={1}>Atenção</Heading>
          </HFlow>
          <VFlow
            vSpacing={1}
            style={css`
              margin-left: 4rem;
            `}
          >
            <Text>
              Não é possível adicionar o registro para o cidadão, pois existe um atendimento realizado com data
              posterior à data do atendimento do registro tardio.
            </Text>
            <VFlow vSpacing={0}>
              <Text>Informações sobre o atendimento posterior mais recente:</Text>
              <Text>Data: {formatDate(atendimento.finalizadoEm)}</Text>
              <Text>Especialidade: {atendimento.lotacao.cbo.nome.titleCase()}</Text>
              <Text>Profissional: {atendimento.lotacao.profissional.nome}</Text>
            </VFlow>
          </VFlow>
        </VFlow>
      </ModalBody>
      <ModalFooter>
        <HFlow justifyContent='flex-end'>
          <FooterButton kind='primary' size='medium' onClick={modalProps.onClose}>
            Fechar
          </FooterButton>
        </HFlow>
      </ModalFooter>
    </Modal>
  )
}

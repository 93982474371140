/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { ExternalStyles, Heading, HeadingProps, Icon, IconProps, VFlow } from 'bold-ui'
import { IconColor } from 'bold-ui/lib/components/Icon/Icon'

export interface ModalBodyProps {
  title: React.ReactNode
  titleLevel?: HeadingProps['level']
  type: IconColor
  icon?: IconProps['icon']
  iconSize?: number
  iconStyle?: ExternalStyles
  children?: React.ReactNode
  style?: ExternalStyles
}

export function ModalBody(props: ModalBodyProps) {
  const { title, titleLevel = 3, type, icon, iconSize = 3, iconStyle, children, style } = props

  return (
    <VFlow style={style}>
      <Heading level={titleLevel}>
        <div css={styles.titleWrapper}>
          <Icon icon={icon} fill={type} size={iconSize} style={[styles.icon, iconStyle]} />
          {title}
        </div>
      </Heading>

      {children}
    </VFlow>
  )
}

ModalBody.defaultProps = {
  icon: 'questionMarkOutline',
} as ModalBodyProps

const styles = {
  titleWrapper: css`
    display: flex;
    align-items: center;
  `,
  icon: css`
    margin-right: 1rem;
  `,
}

import { SimNaoEnum } from 'components/form'
import { IvcfPerguntaEnum } from 'graphql/types.generated'

import { AtendimentoIvcfModel, IvcfModel, IvcfResultadoEnum, IvcfSubGrupoEnum } from './model-ivcf'

export function calculateIvcf(respostas: IvcfModel, idadeCidadaoEmAnos: number): AtendimentoIvcfModel {
  let totalPontos: number = 0
  let resultado: IvcfResultadoEnum = null
  let dimensoesAlteradas: IvcfSubGrupoEnum[] = []

  // ID: 1 - Idade
  if (idadeCidadaoEmAnos >= 75 && idadeCidadaoEmAnos <= 85) {
    totalPontos++
    dimensoesAlteradas.push(IvcfSubGrupoEnum.IDADE)
  } else if (idadeCidadaoEmAnos >= 85) {
    totalPontos += 3
    dimensoesAlteradas.push(IvcfSubGrupoEnum.IDADE)
  }

  // ID: 2 - Percepção da Saúde
  if (respostas[IvcfPerguntaEnum.PERCEPCAO_SAUDE_PERGUNTA_1] === SimNaoEnum.NAO) {
    totalPontos++
    dimensoesAlteradas.push(IvcfSubGrupoEnum.PERCEPCAO)
  }

  // ID: 3, 4 e 5 - AVD INSTRUMENTAL
  if (
    respostas[IvcfPerguntaEnum.AVD_INSTRUMENTAL_PERGUNTA_1] === SimNaoEnum.SIM ||
    respostas[IvcfPerguntaEnum.AVD_INSTRUMENTAL_PERGUNTA_2] === SimNaoEnum.SIM ||
    respostas[IvcfPerguntaEnum.AVD_INSTRUMENTAL_PERGUNTA_3] === SimNaoEnum.SIM
  ) {
    totalPontos += 4
    dimensoesAlteradas.push(IvcfSubGrupoEnum.AVD_INSTRUMENTAL)
  }

  // ID: 6 - AVD BASICA
  if (respostas[IvcfPerguntaEnum.AVD_BASICA_PERGUNTA_1] === SimNaoEnum.SIM) {
    totalPontos += 6
    dimensoesAlteradas.push(IvcfSubGrupoEnum.AVD_BASICA)
  }

  // ID: 7, 8 e 9 - Cognição
  if (respostas[IvcfPerguntaEnum.COGNICAO_PERGUNTA_1] === SimNaoEnum.SIM) {
    totalPontos++
  }
  if (respostas[IvcfPerguntaEnum.COGNICAO_PERGUNTA_2] === SimNaoEnum.SIM) {
    totalPontos++
  }
  if (respostas[IvcfPerguntaEnum.COGNICAO_PERGUNTA_3] === SimNaoEnum.SIM) {
    totalPontos += 2
  }
  if (
    [
      respostas[IvcfPerguntaEnum.COGNICAO_PERGUNTA_1],
      respostas[IvcfPerguntaEnum.COGNICAO_PERGUNTA_2],
      respostas[IvcfPerguntaEnum.COGNICAO_PERGUNTA_3],
    ].includes(SimNaoEnum.SIM)
  ) {
    dimensoesAlteradas.push(IvcfSubGrupoEnum.COGNICAO)
  }

  // ID: 10 e 11 - Humor
  if (respostas[IvcfPerguntaEnum.HUMOR_PERGUNTA_1] === SimNaoEnum.SIM) {
    totalPontos += 2
  }
  if (respostas[IvcfPerguntaEnum.HUMOR_PERGUNTA_2] === SimNaoEnum.SIM) {
    totalPontos += 2
  }

  if (
    [respostas[IvcfPerguntaEnum.HUMOR_PERGUNTA_1], respostas[IvcfPerguntaEnum.HUMOR_PERGUNTA_2]].includes(
      SimNaoEnum.SIM
    )
  ) {
    dimensoesAlteradas.push(IvcfSubGrupoEnum.HUMOR)
  }

  // ID: 12 e 13 - Alcance, preensao e pinca
  if (respostas[IvcfPerguntaEnum.ALCANCE_PREENSAO_PINCA_PERGUNTA_1] === SimNaoEnum.SIM) {
    totalPontos++
  }
  if (respostas[IvcfPerguntaEnum.ALCANCE_PREENSAO_PINCA_PERGUNTA_2] === SimNaoEnum.SIM) {
    totalPontos++
  }

  if (
    [
      respostas[IvcfPerguntaEnum.ALCANCE_PREENSAO_PINCA_PERGUNTA_1],
      respostas[IvcfPerguntaEnum.ALCANCE_PREENSAO_PINCA_PERGUNTA_2],
    ].includes(SimNaoEnum.SIM)
  ) {
    dimensoesAlteradas.push(IvcfSubGrupoEnum.ALCANCE_PREENSAO_PINCA)
  }

  // ID: 14.1.1, 14.1.2, 14.2, 14.3, 14.4 - Capacidade aerobica e/ou muscular
  if (
    respostas[IvcfPerguntaEnum.CAPACIDADE_PERGUNTA_1] === SimNaoEnum.SIM ||
    respostas[IvcfPerguntaEnum.CAPACIDADE_PERGUNTA_2] === SimNaoEnum.SIM ||
    respostas[IvcfPerguntaEnum.CAPACIDADE_PERGUNTA_3] === SimNaoEnum.SIM ||
    respostas[IvcfPerguntaEnum.CAPACIDADE_PERGUNTA_4] === SimNaoEnum.SIM ||
    respostas[IvcfPerguntaEnum.CAPACIDADE_PERGUNTA_5] === SimNaoEnum.SIM
  ) {
    totalPontos += 2
    dimensoesAlteradas.push(IvcfSubGrupoEnum.CAPACIDADE)
  }

  // ID: 15 - Marcha
  if (respostas[IvcfPerguntaEnum.MARCHA_PERGUNTA_1] === SimNaoEnum.SIM) {
    totalPontos += 2
  }
  // ID: 16 - Marcha
  if (respostas[IvcfPerguntaEnum.MARCHA_PERGUNTA_2] === SimNaoEnum.SIM) {
    totalPontos += 2
  }
  if (
    [respostas[IvcfPerguntaEnum.MARCHA_PERGUNTA_1], respostas[IvcfPerguntaEnum.MARCHA_PERGUNTA_2]].includes(
      SimNaoEnum.SIM
    )
  ) {
    dimensoesAlteradas.push(IvcfSubGrupoEnum.MARCHA)
  }

  // ID: 17 - Continencia esfincteriana
  if (respostas[IvcfPerguntaEnum.CONTINENCIA_PERGUNTA_1] === SimNaoEnum.SIM) {
    totalPontos += 2
    dimensoesAlteradas.push(IvcfSubGrupoEnum.CONTINENCIA)
  }

  // ID: 18 - Visao
  if (respostas[IvcfPerguntaEnum.VISAO_PERGUNTA_1] === SimNaoEnum.SIM) {
    totalPontos += 2
    dimensoesAlteradas.push(IvcfSubGrupoEnum.VISAO)
  }

  // ID: 19 - Audicao
  if (respostas[IvcfPerguntaEnum.AUDICAO_PERGUNTA_1] === SimNaoEnum.SIM) {
    totalPontos += 2
    dimensoesAlteradas.push(IvcfSubGrupoEnum.AUDICAO)
  }

  // ID: 20.1, 20.2 e 20.3 - Comorbidade múltipla
  if (
    respostas[IvcfPerguntaEnum.COMORBIDADE_PERGUNTA_1] === SimNaoEnum.SIM ||
    respostas[IvcfPerguntaEnum.COMORBIDADE_PERGUNTA_2] === SimNaoEnum.SIM ||
    respostas[IvcfPerguntaEnum.COMORBIDADE_PERGUNTA_3] === SimNaoEnum.SIM
  ) {
    totalPontos += 4
    dimensoesAlteradas.push(IvcfSubGrupoEnum.COMORBIDADE)
  }

  if (totalPontos < 7) {
    resultado = IvcfResultadoEnum.BAIXO
  } else if (totalPontos < 15) {
    resultado = IvcfResultadoEnum.MODERADO
  } else {
    resultado = IvcfResultadoEnum.ALTO
  }

  return {
    respostas,
    totalPontos,
    resultado,
    dimensoesAlteradas,
  }
}

/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Heading, HFlow, Icon, Text, Theme, useTheme } from 'bold-ui'
import { MutableRefObject } from 'react'

import { ChatMessage } from '../model-videochamada'

interface NewMessagesPreviewProps {
  newMessages: ChatMessage[]
  containerRef: MutableRefObject<HTMLDivElement>
  messagesRef: MutableRefObject<HTMLDivElement>
}

export function NewMessagesPreview(props: NewMessagesPreviewProps) {
  const { newMessages, containerRef, messagesRef } = props

  const theme = useTheme()
  const styles = createStyles(theme)

  return (
    <div css={styles.messagesPreviewContainer} ref={containerRef}>
      <div ref={messagesRef}>
        {newMessages.map(({ sender, time, content }) => (
          <div css={styles.messagePreviewContainer} key={sender + '-' + time}>
            <HFlow hSpacing={0.5}>
              <Icon size={1} style={styles.senderIcon} icon='chatFilled' />
              <Heading level={5} style={styles.sender} fontWeight='bold'>
                {sender}
              </Heading>
            </HFlow>
            <Text style={styles.message}>{content}</Text>
          </div>
        ))}
      </div>
    </div>
  )
}

const createStyles = (theme: Theme) => ({
  messagesPreviewContainer: css`
    width: 18rem;
    height: 100%;
    z-index: 4;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: absolute;
    right: 0.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;

    ${theme.breakpoints.up('md')} {
      right: 1rem;
    }
  `,
  messagePreviewContainer: css`
    background-color: ${theme.pallete.gray.c20};
    box-shadow: ${theme.shadows.outer[40]};
    margin-bottom: 0.5rem;
    border-radius: 0.25rem;
    padding: 0.5rem;
    overflow-wrap: break-word;

    &:last-child {
      margin-bottom: 0;
    }
  `,
  senderIcon: css`
    color: ${theme.pallete.primary.c80};
  `,
  sender: css`
    max-width: 15rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${theme.pallete.gray.c100};
  `,
  message: css`
    color: ${theme.pallete.gray.c100};
    overflow-wrap: break-word;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    display: -webkit-box;
  `,
})

import { useEffect, useState } from 'react'
import { VideoOrientationEnum } from 'view/videochamada/model-videochamada'

export function useOrientationDetection(): VideoOrientationEnum {
  const [orientation, setOrientation] = useState<VideoOrientationEnum>(VideoOrientationEnum.PORTRAIT)

  useEffect(() => {
    const handleOrientationChange = () => {
      const { orientation } = window.screen
      if (orientation.type.startsWith('portrait')) {
        setOrientation(VideoOrientationEnum.PORTRAIT)
      } else if (orientation.type.startsWith('landscape')) {
        setOrientation(VideoOrientationEnum.LANDSCAPE)
      }
    }

    handleOrientationChange()

    window.addEventListener('orientationchange', handleOrientationChange)

    return () => {
      window.removeEventListener('orientationchange', handleOrientationChange)
    }
  }, [])

  return orientation
}

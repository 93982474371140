import { validateEnderecoCompleto } from 'components/form'
import { validateEnderecoIndigena } from 'components/form/field/enderecoindigena/validator-enderecoIndigena'
import { NacionalidadeEnum, TipoEnderecoEnum } from 'graphql/types.generated'
import { createValidator, ErrorObject, required, ValidateFunction } from 'util/validation'
import { tipoEnderecoIndigena } from 'view/cadastro-imovel/model-cadastroImovel'

import CidadaoFormModel, { ContatosFormModel } from '../types/model-cidadao'
import { validate as validateContatos } from './ContatosFieldGroup'
import { validate as validateInformacoesComplementares } from './InformacoesComplementaresFieldGroup'
import { validator as validateCidadao } from './validator-cidadao'
import { validateCidadaoAldeado } from './validator-cidadaoAldeado'

export const validator = createValidator<CidadaoFormModel>(
  {
    contato: validateContatos,
    informacoesComplementares: validateInformacoesComplementares,
  },
  (values: CidadaoFormModel, errors: ErrorObject<CidadaoFormModel>) => {
    const errorsCidadao: ValidateFunction<CidadaoFormModel> = validateCidadao
    const errorsValidate = errorsCidadao(values, errors)

    if (TipoEnderecoEnum.LOGRADOURO === values.tipoEndereco) {
      errors.endereco = validateEnderecoCompleto(values.endereco)
    } else if (values.isCidadaoAldeado && tipoEnderecoIndigena.includes(values.tipoEndereco)) {
      errors.enderecoIndigena = validateEnderecoIndigena(values.enderecoIndigena)
    }

    if (
      !values.isCidadaoAldeado &&
      NacionalidadeEnum.ESTRANGEIRA !== values.nacionalidade &&
      !Boolean(
        values.contato?.telefoneResidencial || values.contato?.telefoneCelular || values.contato?.telefoneContato
      )
    ) {
      const contatoError = errors.contato as ErrorObject<ContatosFormModel>
      errors.contato = {
        ...contatoError,
        telefones: required(
          values?.contato?.telefoneResidencial || values.contato?.telefoneCelular || values.contato?.telefoneContato
        ),
      }
    }

    if (values.isCidadaoAldeado && values.cidadaoAldeadoInput) {
      errors.cidadaoAldeadoInput = validateCidadaoAldeado(values)(values.cidadaoAldeadoInput)
    }

    return errorsValidate
  }
)

/** @jsx jsx */
import { css, Global, jsx } from '@emotion/core'
import { Cell, Grid, Heading, Theme, useTheme } from 'bold-ui'
import { PageContainer } from 'components/layout/PageContainer'
import { ExternalUserHeader, TOTAL_HEADER_HEIGHT } from 'components/user/ExternalUserHeader'
import { StatusVideochamadaEnum } from 'graphql/types.generated'
import { useViewportType } from 'hooks/useViewportType'
import { Fragment } from 'react'

import { CameraPreview } from './componentes/CameraPreview'
import { SalaEsperaVideochamadaUsuariosExternosForm } from './componentes/SalaEsperaVideochamadaUsuariosExternosForm'
import { StatusSolicitacaoEntradaVideochamadaEnum } from './model-videochamada'

interface SalaEsperaVideochamadaUsuariosExternosViewMobileProps {
  nomeProfissional: string
  cboProfissional: string
  audioEnabled: boolean
  videoEnabled: boolean
  statusVideochamada: StatusVideochamadaEnum
  setAudioEnabled(value: boolean): void
  setVideoEnabled(value: boolean): void
  statusSolicitacao: StatusSolicitacaoEntradaVideochamadaEnum
  handleEntrarButtonClick(): void
}

export function SalaEsperaVideochamadaUsuariosExternosViewMobile(
  props: SalaEsperaVideochamadaUsuariosExternosViewMobileProps
) {
  const {
    nomeProfissional,
    cboProfissional,
    audioEnabled,
    videoEnabled,
    setAudioEnabled,
    setVideoEnabled,
    statusVideochamada,
    statusSolicitacao,
    handleEntrarButtonClick,
  } = props

  const { isMobile } = useViewportType()
  const theme = useTheme()
  const styles = createStyles(theme, isMobile)

  return (
    <Fragment>
      <Global styles={styles.global} />
      <ExternalUserHeader />
      <PageContainer style={styles.container}>
        <Grid direction='column' wrap={false} style={styles.containerGrid}>
          <Cell size={12} style={styles.scrollableSection}>
            <Grid direction='column' wrap={false} style={styles.innerScrollableGrid}>
              <Cell size={12}>
                <Heading level={3}>Videochamada e-SUS APS</Heading>
                <Heading level={5}>
                  Chamada com {nomeProfissional}
                  {cboProfissional ? ` | ${cboProfissional}` : ''}
                </Heading>
              </Cell>
              <Cell size={12} flexGrow={1}>
                <CameraPreview
                  audioEnabled={audioEnabled}
                  videoEnabled={videoEnabled}
                  setAudioEnabled={setAudioEnabled}
                  setVideoEnabled={setVideoEnabled}
                  isMobile
                />
              </Cell>
            </Grid>
          </Cell>
          <Cell size={12} style={styles.noScrollableSection}>
            <span css={styles.divider} />
            <SalaEsperaVideochamadaUsuariosExternosForm
              nomeProfissional={nomeProfissional}
              statusVideochamada={statusVideochamada}
              statusSolicitacao={statusSolicitacao}
              handleEntrarButtonClick={handleEntrarButtonClick}
              isMobile
            />
          </Cell>
        </Grid>
      </PageContainer>
    </Fragment>
  )
}

const createStyles = (theme: Theme, isMobile: boolean) => ({
  global: css`
    body > div#root {
      overflow-x: clip;
    }

    body > div#root > div {
      min-height: ${isMobile ? 'auto' : '100vh'};
    }
  `,
  container: css`
    height: calc(${window.innerHeight}px - ${TOTAL_HEADER_HEIGHT}rem);
    padding: 0 0.5rem;
  `,
  containerGrid: css`
    height: 100%;
    margin: 0;
  `,
  scrollableSection: css`
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100%;
  `,
  innerScrollableGrid: css`
    height: 100%;
    margin-top: 0;
    padding-bottom: 0;
  `,
  noScrollableSection: css`
    margin-top: auto;
    padding-top: 1rem;
    padding-bottom: 1rem;
    position: relative;
  `,
  divider: css`
    position: absolute;
    top: 0;
    left: -0.5rem;
    width: 100vw;
    height: 1px;
    background-color: ${theme.pallete.divider};
    z-index: 1;
  `,
})

import { Cell, Grid, HeadingSection, InfoLabel, VFlow } from 'bold-ui'
import { DateTime } from 'components/date'
import { Cpf } from 'components/label'
import { NacionalidadeEnum, RacaCorDbEnum } from 'graphql/types.generated'
import React from 'react'
import { nacionalidade, sexo } from 'types/enums'
import { titleCase } from 'util/strConversor'

import { CidadaoTabSectionModel } from '../types/model-cidadaoTabSection'
import {
  CompartilhamentoProntuarioSection,
  ContatosSection,
  EnderecoExteriorSection,
  EnderecoSection,
  EquipeResponsavelSection,
  GrupoObito,
  InformacoesSociodemograficasSection,
} from './CidadaoInformacoesSections'

export interface CidadaoInformacoesViewProps {
  cidadao: CidadaoTabSectionModel
  mostrarProntuario?: boolean
  renderTipoLogradouro?: boolean
  isCidadaoAldeado?: boolean
}

export const CidadaoInformacoesView = (props: CidadaoInformacoesViewProps) => {
  const { cidadao, mostrarProntuario } = props
  return (
    <VFlow vSpacing={2} style={{ paddingTop: '0.9375rem' }}>
      {cidadao?.faleceu && <GrupoObito cidadao={cidadao} />}
      <DadosPessoaisSection cidadao={cidadao} />
      <EquipeResponsavelSection cidadao={cidadao} />
      {!cidadao?.paisExterior && <EnderecoSection cidadao={cidadao} />}
      {cidadao?.paisExterior && <EnderecoExteriorSection cidadao={cidadao} />}
      <ContatosSection cidadao={cidadao} />
      <InformacoesSociodemograficasSection cidadao={cidadao} />
      {mostrarProntuario && <CompartilhamentoProntuarioSection cidadao={cidadao} />}
    </VFlow>
  )
}

const DadosPessoaisSection = (props: CidadaoInformacoesViewProps) => {
  const { cidadao } = props
  return (
    <HeadingSection level={2} title='Dados pessoais'>
      <Grid wrap>
        <Cell size={4} data-cy='CidadaoInformacoesGerais.cpf'>
          <InfoLabel title='CPF'>
            <Cpf value={cidadao?.cpf} />
          </InfoLabel>
        </Cell>
        <Cell size={4} data-cy='CidadaoInformacoesGerais.cns'>
          <InfoLabel placeholder='-' title='CNS'>
            {cidadao?.cns}
          </InfoLabel>
        </Cell>
        <Cell size={4} data-cy='CidadaoInformacoesGerais.dataNascimento'>
          <InfoLabel placeholder='-' title='Data de nascimento'>
            <DateTime format='DD/MM/YYYY' value={cidadao?.dataNascimento} />
          </InfoLabel>
        </Cell>
        <Cell size={4} data-cy='CidadaoInformacoesGerais.sexo'>
          <InfoLabel placeholder='-' title='Sexo'>
            {sexo[cidadao?.sexo]}
          </InfoLabel>
        </Cell>
        <Cell size={4} data-cy='CidadaoInformacoesGerais.racaCor'>
          <InfoLabel placeholder='-' title='Raça/cor'>
            {titleCase(cidadao?.racaCor?.nome)}
          </InfoLabel>
        </Cell>
        {cidadao?.racaCor?.racaCorDbEnum === RacaCorDbEnum.INDIGENA ? (
          <Cell size={4} data-cy='CidadaoInformacoesGerais.etnia'>
            <InfoLabel placeholder='-' title='Etnia'>
              {titleCase(cidadao?.etnia?.nome)}
            </InfoLabel>
          </Cell>
        ) : (
          <Cell size={4} />
        )}
        <Cell size={4} data-cy='CidadaoInformacoesGerais.nomeMae'>
          <InfoLabel placeholder='-' title='Nome da mãe'>
            {titleCase(cidadao?.nomeMae)}
          </InfoLabel>
        </Cell>
        <Cell size={8} data-cy='CidadaoInformacoesGerais.nomePai'>
          <InfoLabel placeholder='-' title='Nome do pai'>
            {titleCase(cidadao?.nomePai)}
          </InfoLabel>
        </Cell>
        <Cell size={4} data-cy='CidadaoInformacoesGerais.nacionalidade'>
          <InfoLabel placeholder='-' title='Nacionalidade'>
            {nacionalidade[cidadao?.nacionalidade?.nacionalidadeDbEnum]}
          </InfoLabel>
        </Cell>
        {cidadao?.nacionalidade?.nacionalidadeDbEnum === NacionalidadeEnum.BRASILEIRA && (
          <Cell size={8} data-cy='CidadaoInformacoesGerais.municipioNascimento'>
            <InfoLabel placeholder='-' title='Município de nascimento'>
              {cidadao?.localidadeNascimento
                ? titleCase(cidadao?.localidadeNascimento?.nome) + ' - ' + cidadao?.localidadeNascimento?.uf.sigla
                : '-'}
            </InfoLabel>
          </Cell>
        )}
        {cidadao?.nacionalidade?.nacionalidadeDbEnum === NacionalidadeEnum.NATURALIZADA && (
          <>
            <Cell size={4} data-cy='CidadaoInformacoesGerais.portariaNaturalizacao'>
              <InfoLabel placeholder='-' title='Portaria de naturalização'>
                {titleCase(cidadao?.portariaNaturalizacao)}
              </InfoLabel>
            </Cell>

            <Cell size={4} data-cy='CidadaoInformacoesGerais.dataNaturalizacao'>
              <InfoLabel placeholder='-' title='Data de naturalização'>
                <DateTime format='DD/MM/YYYY' value={cidadao?.dataNaturalizacao} />
              </InfoLabel>
            </Cell>
          </>
        )}
        {cidadao?.nacionalidade?.nacionalidadeDbEnum === NacionalidadeEnum.ESTRANGEIRA && (
          <>
            <Cell size={4} data-cy='CidadaoInformacoesGerais.paisNascimento'>
              <InfoLabel placeholder='-' title='País de nascimento'>
                {titleCase(cidadao?.paisNascimento?.nome)}
              </InfoLabel>
            </Cell>

            <Cell size={4} data-cy='CidadaoInformacoesGerais.dataEntradaBrasil'>
              <InfoLabel placeholder='-' title='Data de entrada no Brasil'>
                <DateTime format='DD/MM/YYYY' value={cidadao?.dataEntradaBrasil} />
              </InfoLabel>
            </Cell>
          </>
        )}
      </Grid>
    </HeadingSection>
  )
}

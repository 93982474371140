import { colors, TagProps } from 'bold-ui'
import { DadosCidadaoVisualizarAtendimentoModel } from 'components/atendimento/model'
import { EquipeSelectModel } from 'components/form'
import { LotacaoAndEstagioSelectFieldModel } from 'components/form/field/select/LotacaoAndEstagioSelectField/LotacaoAndEstagioSelectField'
import { PageParams, Period, StatusAssinatura, TipoAtendimentoProfissional } from 'graphql/types.generated'
import { formatDateAndHoursMinutes } from 'util/date/formatDate'
import { metaPath } from 'util/metaPath'

// TODO: (Legacy) estabelecer valor definitivo para constante de maximo de atendimentos (#13641)
export const MAXIMO_ATENDIMENTOS_PAGINA = 10
export const LIMITE_ATENDIMENTOS_ASSINATURA = 10

export enum ValidacaoAssinaturaEnum {
  SEM_ATENDIMENTOS_SELECIONADOS,
  SEM_ATENDIMENTOS_PENDENTES,
  ASSINATURA_EM_ANDAMENTO,
  LIMITE_ATENDIMENTOS_SELECIONADOS_EXCEDIDO,
  LIMITE_ATENDIMENTO_SELECIONADOS_PARA_ASSINATURA_EXCEDIDO,
  ATENDIMENTO_JA_ASSINADO,
}

export const getMensagemValidacao: Record<ValidacaoAssinaturaEnum, string> = {
  [ValidacaoAssinaturaEnum.SEM_ATENDIMENTOS_SELECIONADOS]: 'Selecione pelo menos um atendimento para assinar.',
  [ValidacaoAssinaturaEnum.SEM_ATENDIMENTOS_PENDENTES]:
    'Não existem atendimentos pendentes de assinatura nesta página.',
  [ValidacaoAssinaturaEnum.ASSINATURA_EM_ANDAMENTO]: 'Assinatura em andamento.',
  [ValidacaoAssinaturaEnum.LIMITE_ATENDIMENTOS_SELECIONADOS_EXCEDIDO]: `Não é possível selecionar mais de ${LIMITE_ATENDIMENTOS_ASSINATURA} atendimentos.`,
  [ValidacaoAssinaturaEnum.LIMITE_ATENDIMENTO_SELECIONADOS_PARA_ASSINATURA_EXCEDIDO]: `Não é possível assinar mais de ${LIMITE_ATENDIMENTOS_ASSINATURA} atendimentos.`,
  [ValidacaoAssinaturaEnum.ATENDIMENTO_JA_ASSINADO]: 'O atendimento não pode ser assinado novamente.',
}

export interface AssinaturaEletronicaAtendTableRowModel {
  id: ID
  dataAtendimento: number
  tipoAtendimento: TipoAtendimentoProfissional
  cidadao: DadosCidadaoVisualizarAtendimentoModel
  nomeProfissional: string
  cboProfissional: string
  nomeEquipe: string
  ineEquipe: string
  horarioAssinatura?: number
  statusAssinatura?: StatusAssinatura
  codigoUnicoRegistro: string
  isAtendimentoRecente: boolean
  isAtendimentoAssinado?: boolean
}

export enum CriterioExibicaoAtendimento {
  TODOS_OS_ATENDIMENTOS,
  ATENDIMENTOS_SEM_ASSINATURA,
  ATENDIMENTOS_COM_ASSINATURA,
}

export interface AssinaturaEletronicaAtendTableFilterModel {
  periodo?: Period
  equipe?: EquipeSelectModel
  lotacao?: LotacaoAndEstagioSelectFieldModel
  criterioExibicao?: CriterioExibicaoAtendimento
  pageParams: PageParams
}

interface StatusAssinaturaTagModel extends Pick<TagProps, 'type' | 'icon'> {
  descricao: string
  color: string
  backgroundColor: string
}

export const statusAssinaturaAtendimentoRecord = (
  dataHoraAssinatura: number
): Record<StatusAssinatura & 'NAO_ASSINADO', StatusAssinaturaTagModel> => {
  return {
    [StatusAssinatura.ASSINADO]: {
      type: 'success',
      icon: 'checkCircleFilled',
      color: colors.green.c40,
      backgroundColor: colors.green.c90,
      descricao: `Assinado em ${formatDateAndHoursMinutes(dataHoraAssinatura)}`,
    },
    [StatusAssinatura.ERRO_ASSINATURA]: {
      type: 'danger',
      icon: 'banOutline',
      color: colors.red.c40,
      backgroundColor: colors.red.c90,
      descricao: 'Erro na assinatura',
    },
    [StatusAssinatura.TENTATIVA_NAO_CONCLUIDA]: {
      type: 'danger',
      icon: 'banOutline',
      color: colors.red.c40,
      backgroundColor: colors.red.c90,
      descricao: 'Tentativa de assinatura não concluída',
    },
    [StatusAssinatura.ASSINATURA_INVALIDA]: {
      type: 'alert',
      icon: 'exclamationTriangleFilled',
      color: colors.orange.c40,
      backgroundColor: colors.orange.c90,
      descricao: 'Aguardando assinatura',
    },
    NAO_ASSINADO: {
      type: 'alert',
      icon: 'exclamationTriangleFilled',
      color: colors.orange.c40,
      backgroundColor: colors.orange.c90,
      descricao: 'Aguardando assinatura',
    },
  }
}

export enum ErroAssinaturaEletronicaEnum {
  INTERNET_DESABILITADA,
  USUARIOS_DIFERENTES,
  CONTA_BRONZE,
}

export const erroAssinaturaEletronicaRecord: Record<
  ErroAssinaturaEletronicaEnum,
  { title: string; message: string }
> = {
  [ErroAssinaturaEletronicaEnum.INTERNET_DESABILITADA]: {
    title: 'O PEC está offline',
    message:
      'Não é possível realizar a assinatura pois a sua instalação do PEC está offline. Tente novamente após a conexão ser reestabelecida.',
  },
  [ErroAssinaturaEletronicaEnum.USUARIOS_DIFERENTES]: {
    title: 'A conta do Gov.br não corresponde ao usuário do PEC',
    message:
      'A conta utilizada para realizar login no Gov.br possui um CPF diferente do usuário que você está logado no PEC. Verifique se a conta correta foi utilizada.',
  },
  [ErroAssinaturaEletronicaEnum.CONTA_BRONZE]: {
    title: 'Você não possui conta prata ou ouro no Gov.br',
    message:
      'Para conseguir realizar uma assinatura eletrônica é necessário possuir uma conta prata ou ouro. Aumente o nível da sua conta e tente novamente. Para mais informações sobre os níveis de conta, clique aqui.',
  },
}

export const name = metaPath<AssinaturaEletronicaAtendTableFilterModel>()

import { convertLogradouro } from 'components/form'
import { items as identidadeGeneroValidas } from 'components/form/field/select/IdentidadeGeneroSelectField'
import { items as orientacoesSexuaisValidas } from 'components/form/field/select/OrientacaoSexualSelectField'
import { SimNaoEnum } from 'components/form/field/SimNaoRadioGroupField'
import { CidadaoCadsus, CidadaoInput, TipoEnderecoEnum } from 'graphql/types.generated'
import { tipoSanguineo } from 'types/enums'
import { tipoEnderecoIndigena } from 'view/cadastro-imovel/model-cadastroImovel'

import { CadsusCidadaoLocalType } from './CadsusModel'
import CidadaoFormModel from './model-cidadao'

export default (model: CidadaoFormModel): CidadaoInput => {
  const { contato, endereco, enderecoIndigena, informacoesComplementares, vinculacao, cidadaoAldeadoInput } = model
  const isTipoEnderecoIndigena = tipoEnderecoIndigena.includes(model.tipoEndereco)

  return {
    id: model.id,
    cns: model.cns,
    cpf: model.cpf,
    nome: model.nome,
    nomeSocial: model.nomeSocial,
    dataNascimento: model.dataNascimento,
    sexo: model.sexo,
    racaCor: model.racaCor?.value,
    etnia: model.etnia?.id,
    nomeMae: model.nomeMae,
    desconheceNomeMae: model.desconheceNomeMae,
    nomePai: model.nomePai,
    desconheceNomePai: model.desconheceNomePai,
    nacionalidade: model.nacionalidade,
    municipioNascimento: model.municipioNascimento?.id,
    portariaNaturalizacao: model.portariaNaturalizacao,
    dataNaturalizacao: model.dataNaturalizacao,
    paisNascimento: model.paisNascimento?.id,
    dataEntradaPais: model.dataEntradaPais,
    cidadaoFaleceu: model.cidadaoFaleceu,
    dataObito: model.dataObito,
    numeroDocumentoObito: model.numeroDeclaracaoObito,
    telefoneResidencial: contato?.telefoneResidencial,
    telefoneCelular: contato?.telefoneCelular,
    telefoneContato: contato?.telefoneContato,
    email: contato?.email,
    endereco:
      endereco && !isTipoEnderecoIndigena
        ? {
            bairro: endereco.bairro?.nome,
            cep: endereco.cep,
            complemento: endereco.complemento,
            logradouro: convertLogradouro(endereco.logradouro),
            municipio: endereco.municipio?.id,
            numero: endereco.numero,
            pontoReferencia: endereco.pontoReferencia,
            semNumero: endereco.semNumero,
            tipoLogradouro: endereco.tipoLogradouro?.id,
            uf: endereco.uf?.id,
          }
        : { semNumero: false },
    enderecoIndigenaInput:
      enderecoIndigena && isTipoEnderecoIndigena
        ? {
            dseiResidenciaId: enderecoIndigena?.dsei?.id,
            poloBaseResidenciaId: enderecoIndigena?.poloBase?.id,
            aldeiaResidenciaId: enderecoIndigena?.aldeia?.id,
            municipioId: enderecoIndigena?.municipio?.id,
            ufId: enderecoIndigena?.uf?.id,
            numero: enderecoIndigena?.numero,
          }
        : null,
    numeroFamilia: isTipoEnderecoIndigena ? enderecoIndigena?.numeroFamilia : endereco?.numeroFamilia,
    tipoEndereco: model.tipoEndereco ?? TipoEnderecoEnum.LOGRADOURO,
    tipoLocalNascimento: model.tipoLocalNascimento,
    paisResidencia: endereco?.pais?.id,
    municipioResidenciaExterior: endereco.municipioResidenciaExterior,
    area: endereco?.area,
    microArea: endereco?.microArea,
    statusForaArea: endereco?.foraArea ?? false,
    nisPisPasep: informacoesComplementares?.nis,
    estadoCivil: informacoesComplementares?.estadoCivil?.id,
    tipoSanguineo: informacoesComplementares?.tipoSanguineo?.identificador,
    ocupacao: informacoesComplementares?.ocupacao?.id,
    escolaridade: informacoesComplementares?.escolaridade?.id,
    orientacaoSexualDbEnum: informacoesComplementares?.orientacaoSexual,
    desejaInformarOrientacaoSexual:
      informacoesComplementares?.desejaInformarOrientacaoSexual != null
        ? informacoesComplementares.desejaInformarOrientacaoSexual === SimNaoEnum.SIM
        : null,
    desejaInformarIdentidadeGenero:
      informacoesComplementares?.desejaInformarIdentidadeGenero != null
        ? informacoesComplementares.desejaInformarIdentidadeGenero === SimNaoEnum.SIM
        : null,
    identidadeGeneroDbEnum: informacoesComplementares?.identidadeGenero,
    desativarCompartilhamentoProntuario: model.desativarCompartilhamentoProntuario,
    vinculacao: vinculacao && {
      ine: vinculacao.equipe?.ine,
      utilizarFichaCadastroIndividual: vinculacao.cadastroIndividualCheck,
      cnes: vinculacao.unidadeSaude?.cnes,
    },
    uuidUltimaFicha: model.uuidFicha,
    isCidadaoAldeado: model.isCidadaoAldeado ?? false,
    cidadaoAldeadoInput: cidadaoAldeadoInput && {
      nomeTradicional: cidadaoAldeadoInput.nomeTradicional,
      funcaoSocial: cidadaoAldeadoInput.funcaoSocial?.id,
      aldeiaNascimento: cidadaoAldeadoInput.aldeiaNascimento?.id,
      localOcorrencia: cidadaoAldeadoInput.localOcorrencia?.id,
      stChefeFamilia: cidadaoAldeadoInput.stChefeFamilia === SimNaoEnum.SIM,
      responsavelLegal: cidadaoAldeadoInput.responsavelLegal,
      dtReconhecimento: cidadaoAldeadoInput.dtReconhecimento,
      beneficios: cidadaoAldeadoInput.beneficios || [],
      unidadeFunai: cidadaoAldeadoInput.unidadeFunai,
      livro: cidadaoAldeadoInput.livro,
      folha: cidadaoAldeadoInput.folha,
      cadastroUnico: cidadaoAldeadoInput.cadastroUnico,
      dtEmissao: cidadaoAldeadoInput.dtEmissao,
      ufNascimento: cidadaoAldeadoInput.ufNascimento?.id,
      dtAusencia: cidadaoAldeadoInput.dtAusencia,
      dtRetorno: cidadaoAldeadoInput.dtRetorno,
    },
  } as CidadaoInput
}

export function convertToForm(input: CadsusCidadaoLocalType): CidadaoFormModel {
  return {
    id: input.id,
    cpf: input.cpf,
    cns: input.cns,
    nome: input.nome,
    nomeSocial: input.nomeSocial,
    dataNascimento: input.dataNascimento,
    sexo: input.sexo,
    racaCor: input.racaCor && {
      id: input.racaCor?.id,
      nome: input.racaCor?.nome,
      value: input.racaCor?.racaCorDbEnum,
    },
    etnia: input.etnia && {
      id: input.etnia?.id,
      nome: input.etnia?.nome,
    },
    nomeMae: input.nomeMae,
    desconheceNomeMae: input.desconheceNomeMae,
    nomePai: input.nomePai,
    desconheceNomePai: input.desconheceNomePai,
    municipioNascimento: input.localidadeNascimento && {
      id: input.localidadeNascimento?.id,
      nome: input.localidadeNascimento?.nome,
      uf: input.localidadeNascimento?.uf && {
        id: input.localidadeNascimento?.uf?.id,
        nome: input.localidadeNascimento?.uf?.nome,
        sigla: input.localidadeNascimento?.uf?.sigla,
      },
    },
    portariaNaturalizacao: input.portariaNaturalizacao,
    dataNaturalizacao: input.dataNaturalizacao,
    nacionalidade: input.nacionalidade?.nacionalidadeDbEnum,
    paisNascimento: input.paisNascimento && {
      id: input.paisNascimento?.id,
      nome: input.paisNascimento?.nome,
    },
    dataEntradaPais: input.dataEntradaBrasil,
    cidadaoFaleceu: input.faleceu,
    dataObito: input.dataObito,
    numeroDeclaracaoObito: input.numeroDocumentoObito,
    contato: {
      telefoneResidencial: input.telefoneResidencial,
      telefoneCelular: input.telefoneCelular,
      telefoneContato: input.telefoneContato,
      email: input.email,
    },
    endereco:
      input.endereco || (input.localidadeExterior && input.paisExterior)
        ? {
            ...input?.endereco,
            bairro: input?.endereco?.bairro && { nome: input?.endereco?.bairro },
            logradouro: input?.endereco?.logradouro && { nome: input?.endereco?.logradouro },
            pais: {
              id: input.paisExterior?.id ?? '31',
              nome: input.paisExterior?.nome ?? 'BRASIL',
            },
            municipioResidenciaExterior: input.localidadeExterior,
            area: input.area,
            microArea: input.microArea,
            foraArea: input.statusForaArea,
            numeroFamilia: TipoEnderecoEnum.LOGRADOURO === input.tipoEndereco ? input.numeroFamilia : null,
          }
        : {
            pais: {
              id: '31',
              nome: 'BRASIL',
            },
            logradouro: null,
          },
    informacoesComplementares: {
      nis: input.nisPisPasep,
      estadoCivil: input.estadoCivil && {
        id: input.estadoCivil?.id,
        nome: input.estadoCivil?.nome,
      },
      tipoSanguineo: input.tipoSanguineo && {
        nome: tipoSanguineo[input.tipoSanguineo],
        identificador: input.tipoSanguineo,
      },
      ocupacao: input.cbo && {
        id: input.cbo?.id,
        nome: input.cbo?.nome,
        cbo2002: input.cbo?.cbo2002,
      },
      escolaridade: input.escolaridade && {
        id: input.escolaridade?.id,
        nome: input.escolaridade?.nome,
      },
      desejaInformarOrientacaoSexual:
        input.desejaInformarOrientacaoSexual != null
          ? input.desejaInformarOrientacaoSexual
            ? SimNaoEnum.SIM
            : SimNaoEnum.NAO
          : null,
      orientacaoSexual:
        input.orientacaoSexualDbEnum && orientacoesSexuaisValidas.includes(input.orientacaoSexualDbEnum)
          ? input.orientacaoSexualDbEnum
          : null,
      identidadeGenero:
        input.identidadeGeneroDbEnum && identidadeGeneroValidas.includes(input.identidadeGeneroDbEnum)
          ? input.identidadeGeneroDbEnum
          : null,
      desejaInformarIdentidadeGenero:
        input.desejaInformarIdentidadeGenero != null
          ? input.desejaInformarIdentidadeGenero
            ? SimNaoEnum.SIM
            : SimNaoEnum.NAO
          : null,
    },
    desativarCompartilhamentoProntuario: !input.stCompartilhaProntuario,
    uuidFicha: input.uuidUltimaFicha,
    vinculacao: !input.faleceu &&
      (input?.cidadaoVinculacaoEquipe?.utilizarCadastroIndividual || input?.cidadaoVinculacaoEquipe?.unidadeSaude) && {
        id: input.cidadaoVinculacaoEquipe?.id,
        cadastroIndividualCheck: input.cidadaoVinculacaoEquipe?.utilizarCadastroIndividual,
        unidadeSaude: input.cidadaoVinculacaoEquipe?.unidadeSaude,
        equipe: input.cidadaoVinculacaoEquipe.equipe && {
          id: input.cidadaoVinculacaoEquipe?.equipe?.id,
          nome: input.cidadaoVinculacaoEquipe?.equipe?.nome,
          ine: input.cidadaoVinculacaoEquipe?.equipe?.ine,
          tipoEquipe: input.cidadaoVinculacaoEquipe?.equipe?.tipoEquipe,
        },
      },
    periodoAusenciaList: input.periodoAusenciaList,
    isCidadaoAldeado: input.cidadaoAldeado ? true : false,
    cidadaoAldeadoInput: {
      funcaoSocial: input.cidadaoAldeado?.funcaoSocial,
      aldeiaNascimento: input.cidadaoAldeado?.aldeiaNascimento,
      localOcorrencia: input.cidadaoAldeado?.localOcorrencia,
      nomeTradicional: input.cidadaoAldeado?.nomeTradicional,
      responsavelLegal: input.cidadaoAldeado?.responsavelLegal,
      stChefeFamilia:
        input.cidadaoAldeado?.stChefeFamilia != null
          ? input.cidadaoAldeado?.stChefeFamilia
            ? SimNaoEnum.SIM
            : SimNaoEnum.NAO
          : null,
      beneficios: input.cidadaoAldeado?.beneficios,
      unidadeFunai: input.cidadaoAldeado?.unidadeFunai,
      livro: input.cidadaoAldeado?.livro,
      folha: input.cidadaoAldeado?.folha,
      cadastroUnico: input.cidadaoAldeado?.cadastroUnico,
      dtEmissao: input.cidadaoAldeado?.dtEmissao,
      ufNascimento: input.cidadaoAldeado?.ufNascimento,
      dtReconhecimento: input.cidadaoAldeado?.dtReconhecimento,
    },
    enderecoIndigena: {
      dsei: input.enderecoIndigena?.dseiResidencia,
      poloBase: input.enderecoIndigena?.poloBaseResidencia,
      aldeia: input.enderecoIndigena?.aldeiaResidencia,
      municipio: input.enderecoIndigena?.municipio,
      uf: input.enderecoIndigena?.uf,
      numero: input.enderecoIndigena?.numero,
      numeroFamilia: tipoEnderecoIndigena.includes(input.tipoEndereco) ? input.numeroFamilia : null,
    },
    tipoEndereco: input.tipoEndereco ?? TipoEnderecoEnum.LOGRADOURO,
    tipoLocalNascimento: input.tipoLocalNascimento,
  }
}

export function convertCadsusToFormModel(cidadao: CidadaoCadsus): CidadaoFormModel {
  return {
    cpf: cidadao.cpf,
    cns: cidadao.cns,
    nome: cidadao.nome?.toUpperCase(),
    nomeSocial: cidadao.nomeSocial !== 'SEM INFORMAÇÃO' ? cidadao.nomeSocial?.toUpperCase() : null,
    dataNascimento: cidadao.dataNascimento,
    sexo: cidadao.sexo,
    racaCor: {
      id: cidadao.racaCor?.id,
      nome: cidadao.racaCor?.nome,
      value: cidadao.racaCor?.racaCorDbEnum,
    },
    etnia: cidadao.etnia && {
      id: cidadao.etnia?.id,
      nome: cidadao.etnia?.nome,
    },
    nomeMae: cidadao.nomeMae?.toUpperCase(),
    desconheceNomeMae: !cidadao.nomeMae || cidadao.nomeMae === 'SEM INFORMAÇÃO',
    nomePai: cidadao.nomePai?.toUpperCase(),
    desconheceNomePai: !cidadao.nomePai || cidadao.nomePai === 'SEM INFORMAÇÃO',
    municipioNascimento: {
      id: cidadao.municipioNascimento?.id,
      nome: cidadao.municipioNascimento?.nome,
      uf: {
        id: cidadao.municipioNascimento?.uf?.id,
        nome: cidadao.municipioNascimento?.uf?.nome,
        sigla: cidadao.municipioNascimento?.uf?.sigla,
      },
    },
    portariaNaturalizacao: cidadao.portariaNaturalizacao,
    dataNaturalizacao: cidadao.dataNaturalizacao,
    nacionalidade: cidadao.nacionalidade?.nacionalidadeDbEnum,
    paisNascimento: {
      id: cidadao.paisNascimento?.id,
      nome: cidadao.paisNascimento?.nome,
    },
    dataEntradaPais: cidadao.dataEntradaBrasil,
    cidadaoFaleceu: cidadao.dataObito,
    dataObito: cidadao.dataObito,
    numeroDeclaracaoObito: null,
    contato: {
      telefoneResidencial: cidadao.telefoneResidencial[0],
      telefoneCelular: cidadao.telefoneCelular[0],
      telefoneContato: cidadao.telefoneContato[0],
      email: cidadao.email,
    },
    endereco: {
      ...cidadao?.endereco,
      bairro: cidadao?.endereco?.bairro && { nome: cidadao?.endereco?.bairro },
      logradouro: cidadao?.endereco?.logradouro && { nome: cidadao?.endereco?.logradouro },
      pais: {
        id: cidadao.paisResidencia?.id ?? '31',
        nome: cidadao.paisResidencia?.nome ?? 'BRASIL',
      },
      municipioResidenciaExterior: cidadao.municipioResidenciaExterior,
    },
    informacoesComplementares: {
      nis: cidadao.numeroPisPasep,
    },
  }
}

/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, FormControl, Grid, Icon, Tooltip } from 'bold-ui'
import { useAlert } from 'components/alert'
import { VideochamadaUrlTextInput } from 'components/videochamada'
import { useCallback } from 'react'
import { isHttpsConnection } from 'util/https'
import { v4 as uuidv4 } from 'uuid'

export interface InserirVideochamadaAgendamentoSectionProps {
  videochamadaUuid: string
  onVideochamadaUuidChange(value: string): void
  onRemoverVideochamadaClick(): void
  disabled?: boolean
  tooltipTextDisabled?: string
}

export const InserirVideochamadaAgendamentoSection = (props: InserirVideochamadaAgendamentoSectionProps) => {
  const {
    videochamadaUuid,
    onVideochamadaUuidChange,
    onRemoverVideochamadaClick,
    disabled = false,
    tooltipTextDisabled,
  } = props

  const alert = useAlert()

  const handleAdicionarVideochamadaClick = useCallback(async () => {
    if (!isHttpsConnection) {
      alert('danger', 'Não é possível iniciar uma videochamada em um acesso sem HTTPS.')
    } else {
      const videochamadaUuid = uuidv4()

      onVideochamadaUuidChange(videochamadaUuid)
    }
  }, [alert, onVideochamadaUuidChange])

  return (
    <FormControl label='Consulta via videochamada e-SUS APS'>
      {!videochamadaUuid ? (
        <Tooltip text={disabled && tooltipTextDisabled} placement='auto'>
          <Button
            size='small'
            kind='primary'
            skin='outline'
            onClick={handleAdicionarVideochamadaClick}
            disabled={disabled}
          >
            <Icon icon='videoFilled' style={styles.videochamadaIcon} /> Adicionar videochamada
          </Button>
        </Tooltip>
      ) : (
        <Grid alignItems='center' gap={0.5}>
          <Cell flexGrow={1}>
            <VideochamadaUrlTextInput videochamadaUuid={videochamadaUuid} />
          </Cell>
          <Cell>
            <Tooltip text='Remover videochamada'>
              <Button kind='normal' skin='ghost' size='small' onClick={onRemoverVideochamadaClick}>
                <Icon icon='timesDefault' />
              </Button>
            </Tooltip>
          </Cell>
        </Grid>
      )}
    </FormControl>
  )
}

const styles = {
  videochamadaIcon: css`
    margin-right: 0.5rem;
  `,
}

/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Heading, HFlow, Icon, Link, Text, Theme, useTheme, VFlow } from 'bold-ui'
import { useViewportType } from 'hooks/useViewportType'
import { ReactComponent as CookieIcon } from 'images/cookie/cookie.svg'
import { useCallback, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router'

import { CookiePreferencesModal } from './CookiePreferencesModal'
import { CookiePreferencesModalMobile } from './CookiePreferencesModalMobile'

interface CookieBannerViewProps {
  onClickAceitarNecessarios: () => void
  onClickAceitarTodos: () => void
}

export const CookieBannerView = (props: CookieBannerViewProps) => {
  const { onClickAceitarNecessarios, onClickAceitarTodos } = props
  const theme = useTheme()
  const styles = createStyles(theme)
  const location = useLocation()
  const history = useHistory()
  const { isMobile, isTablet } = useViewportType()
  const [isPreferencesModalOpen, setIsPreferencesModalOpen] = useState(false)

  const handleClickTermosUso = useCallback(() => {
    setIsPreferencesModalOpen(false)
    location.pathname !== '/termosUso' && history.push('/termosUso')
  }, [location.pathname, history])

  const LayoutComponent = useMemo(() => (isMobile || isTablet ? VFlow : HFlow), [isMobile, isTablet])

  return (
    <LayoutComponent>
      {!isMobile && !isTablet && <CookieIcon css={styles.cookie} aria-label='Ícone de cookie' />}
      <HFlow>
        {(isMobile || isTablet) && <CookieIcon css={styles.cookie} aria-label='Ícone de cookie' />}
        <VFlow vSpacing={0.1}>
          <Heading level={4} style={styles.title}>
            Utilizamos cookies para melhorar a sua experiência no sistema e prover serviços personalizados
          </Heading>
          <Text>
            Ao aceitar todos, você terá acesso a todas as funcionalidades do sistema. Ao clicar em "Aceitar apenas os
            necessários", os cookies que não forem estritamente necessários serão desativados. Para escolher quais quer
            autorizar, clique em "Configurar cookies".
          </Text>
          <Text>
            Saiba mais em nossos <Link onClick={handleClickTermosUso}>Termos de uso e Políticas de privacidade.</Link>
          </Text>
        </VFlow>
      </HFlow>
      <VFlow vSpacing={0.5} style={styles.buttonsArea}>
        <Button kind='primary' size='small' onClick={onClickAceitarTodos} style={styles.buttonAceitarTodos}>
          Aceitar todos
        </Button>
        <Button kind='normal' size='small' onClick={onClickAceitarNecessarios} style={styles.buttonAceitarNecessarios}>
          Aceitar apenas os necessários
        </Button>
        <Button
          kind='normal'
          skin='ghost'
          size='small'
          style={styles.buttonConfigurar}
          onClick={() => setIsPreferencesModalOpen(true)}
        >
          <HFlow hSpacing={0.5}>
            <Icon icon='gearFilled' />
            Configurar cookies
          </HFlow>
        </Button>
      </VFlow>
      {isPreferencesModalOpen &&
        (isMobile ? (
          <CookiePreferencesModalMobile
            onClose={() => setIsPreferencesModalOpen(false)}
            onClickAceitarTodos={onClickAceitarTodos}
            onClickAceitarNecessarios={onClickAceitarNecessarios}
            onOpenTermosUso={handleClickTermosUso}
          />
        ) : (
          <CookiePreferencesModal
            onClose={() => setIsPreferencesModalOpen(false)}
            onClickAceitarTodos={onClickAceitarTodos}
            onClickAceitarNecessarios={onClickAceitarNecessarios}
            onOpenTermosUso={handleClickTermosUso}
          />
        ))}
    </LayoutComponent>
  )
}

const createStyles = (theme: Theme) => ({
  cookie: css`
    fill: ${theme.pallete.primary.c40};
    width: 2rem;
    height: 2rem;
  `,
  buttonsArea: css`
    justify-content: center;
    ${theme.breakpoints.down('sm')} {
      align-items: center;
      margin-top: 2rem;
    }
    ${theme.breakpoints.down('lg')} {
      align-items: center;
    }
  `,
  buttonAceitarTodos: css`
    align-items: center;
    width: 14rem;
    ${theme.breakpoints.down('sm')} {
      width: 20.5rem;
      height: 3rem;
    }
    ${theme.breakpoints.down('lg')} {
      width: 20rem;
      height: 3rem;
      margin-bottom: 0.5rem;
    }
  `,
  buttonAceitarNecessarios: css`
    align-items: center;
    width: 14rem;
    color: ${theme.pallete.primary.c40};
    border-color: ${theme.pallete.primary.c40};
    ${theme.breakpoints.down('sm')} {
      width: 20.5rem;
      height: 3rem;
    }
    ${theme.breakpoints.down('lg')} {
      width: 20rem;
      height: 3rem;
      margin-bottom: 0.5rem;
    }
  `,
  buttonConfigurar: css`
    align-items: center;
    width: 14rem;
    color: ${theme.pallete.primary.c40};
    ${theme.breakpoints.down('sm')} {
      width: 20.5rem;
      height: 3rem;
    }
    ${theme.breakpoints.down('lg')} {
      width: 20rem;
      height: 3rem;
    }
  `,
  title: css`
    color: ${theme.pallete.primary.c40};
  `,
})

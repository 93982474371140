import { useEffect } from 'react'

export function useOnWindowFocusOut(onFocusOut: () => void) {
  useEffect(() => {
    window.addEventListener('focusout', onFocusOut)

    return () => {
      window.removeEventListener('focusout', onFocusOut)
    }
  }, [onFocusOut])
}

/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, Text, Theme, useTheme, VFlow } from 'bold-ui'
import { RadioGroupField, SimNaoEnum } from 'components/form'
import { IvcfPerguntaEnum } from 'graphql/types.generated'
import { useMemo } from 'react'
import { useForm } from 'react-final-form'
import { MetaRoot } from 'util/metaPath'

import { IVCF_PERGUNTAS, IvcfModel } from '../model-ivcf'

interface IvcfPerguntaFieldProps {
  ivcfPath: MetaRoot<IvcfModel>
  pergunta: IvcfPerguntaEnum
  isEditing: boolean
  hideBorderBottom?: boolean
  readOnly?: boolean
}

const PERGUNTAS_COGNICAO_CONDICIONAIS = [IvcfPerguntaEnum.COGNICAO_PERGUNTA_2, IvcfPerguntaEnum.COGNICAO_PERGUNTA_3]

export function IvcfPerguntaField(props: IvcfPerguntaFieldProps) {
  const { ivcfPath, pergunta, hideBorderBottom, readOnly, isEditing } = props

  const theme = useTheme()
  const styles = useMemo(() => createStyles(theme, hideBorderBottom, isEditing), [hideBorderBottom, isEditing, theme])
  const { getState } = useForm()

  const name = ivcfPath[pergunta]
  const { values } = getState()
  const { layout, label, options } = IVCF_PERGUNTAS[pergunta]

  const isPerguntaCognicao1Checked = values[IvcfPerguntaEnum.COGNICAO_PERGUNTA_1] === SimNaoEnum.SIM
  const isDisabled = !isPerguntaCognicao1Checked && PERGUNTAS_COGNICAO_CONDICIONAIS.includes(pergunta)

  const content = (
    <RadioGroupField
      name={name}
      options={Object.entries(options).map(([value, label]) => ({
        value,
        label,
      }))}
      showErrorField={false}
      disabled={readOnly || isDisabled}
    />
  )

  return (
    <div css={styles.question}>
      {layout === 'horizontal' ? (
        <HFlow hSpacing={2} alignItems='center' justifyContent='space-between'>
          <Text>{label}</Text>
          {content}
        </HFlow>
      ) : (
        <VFlow vSpacing={0.5}>
          <Text>{label}</Text>
          {content}
        </VFlow>
      )}
    </div>
  )
}

const createStyles = (theme: Theme, hideBorderBottom, isEditing: boolean) => ({
  question: css`
    padding: 0.5rem 1rem;
    border-bottom: ${hideBorderBottom ? 0 : 1}px solid ${theme.pallete.gray.c80};
    background: ${isEditing ? theme.pallete.primary.c90 : theme.pallete.surface.main};
  `,
})

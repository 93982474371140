import { formatDate } from 'util/date/formatDate'

import { VisualizarVisitaListItemBodyPanelProps } from './components/VisualizarVisitaListItemBodyPanel'
import { ImovelVisitaResult } from './model-visualizacao-imovel'

export const convertVistasRowToListItemBodyPanel = (
  row: ImovelVisitaResult
): VisualizarVisitaListItemBodyPanelProps => {
  return {
    anotacoes: row?.anotacoes,
    motivosVisita: row?.motivoVisita,
    desfecho: row?.dimensaoDesfechoVisita?.descricao,
    turno: row?.turno?.descricao,
    dataVisita: formatDate(row?.dataVisita),
    acompanhamento: row?.acompanhamento,
    buscaAtiva: row?.buscaAtiva,
    controleAmbiental: row?.controleAmbiental,
    altura: row?.altura,
    peso: row?.peso,
    glicemiaCapilar: row?.medicaoGlicemia,
    momentoColetaGlicemia: row?.dimensaoTipoGlicemia?.descricao,
    pressaoArterial: row?.medicaoPressaoArterial,
    temperatura: row?.temperatura,
    isCompartilhada: row?.stVisitaCompartilhada,

    dataNascimentoCidadao: row?.cidadaoPec ? row?.cidadaoPec?.dataNascimento : row?.dataNascimentoCidadao,
    sexoCidadao: row?.cidadaoPec ? row?.cidadaoPec?.dimensaoSexo?.descricao : row?.dimensaoSexo?.descricao,
    cpfCidadao: row?.cidadaoPec ? row.cidadaoPec?.cpf : row?.cpfCidadao,
    cnsCidadao: row?.cidadaoPec ? row.cidadaoPec?.cns : row?.cnsCidadao,
    nomeCidadao: row?.cidadaoPec?.nome,
    nomeSocialCidadao: row?.cidadaoPec?.nomeSocial,
    geolocalizacao: row?.latitude && row?.longitude ? [row.latitude, row.longitude] : null,
  }
}

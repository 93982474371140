import { PageContent } from 'components/layout/PageContent'
import { PageHeaderSection } from 'components/PageHeaderSection'
import { AtividadeColetivaQueryInput } from 'graphql/types.generated'
import React, { Fragment, useState } from 'react'

import { AtividadeColetivaTable } from './componentes/AtividadeColetivaTable'
import { RegistrarAtividadeColetivaButton } from './componentes/RegistrarAtividadeColetivaButton'

export function AtividadeColetivaView() {
  const [filter, setFilter] = useState<AtividadeColetivaQueryInput>({
    pageParams: {
      page: 0,
      size: 10,
    },
  })

  return (
    <Fragment>
      <PageHeaderSection title='Atividade coletiva' action={<RegistrarAtividadeColetivaButton />} />

      <PageContent type='transparent'>
        <AtividadeColetivaTable filter={filter} onChangeFilter={setFilter} />
      </PageContent>
    </Fragment>
  )
}

import { AxiosRequestConfig } from 'axios'
import { Link, Text, Tooltip, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import useSession from 'components/auth/useSession'
import { Breadcrumb } from 'components/breadcrumb'
import { PageContent } from 'components/layout/PageContent'
import { PageHeaderSection } from 'components/PageHeaderSection'
import { FileUploaderComponent } from 'components/upload/FileUploaderComponent'
import { useImportacoesBolsaFamiliaLazyQuery, useVigenciaMaisRecenteBolsaFamiliaQuery } from 'graphql/hooks.generated'
import { StatusImportacaoBolsaFamiliaEnum } from 'graphql/types.generated'
import { useFirebase } from 'hooks/firebase/useFirebase'
import React, { useEffect, useState } from 'react'
import { DownloadInfo } from 'view/cnes/ImportacaoCnesView'

import { ImportacaoBolsaFamiliaListener } from './components/ImportacaoBolsaFamiliaListener'
import { ImportacaoBolsaFamiliaTable } from './components/ImportacaoBolsaFamiliaTable'
import { ImportacaoBolsaFamiliaVigenciaAlert } from './components/ImportacaoBolsaFamiliaVigenciaAlert'
import { importarBeneficiariosBolsaFamilia } from './importarBeneficiariosBolsaFamilia'
import { ImportacaoBolsaFamiliaTableFilterModel } from './model-bolsafamilia'

const TITLE = 'Importar beneficiários do bolsa família'

export function ImportacaoBolsaFamiliaView() {
  const alert = useAlert()

  const { data: session } = useSession()

  const handleUpload = (formData: FormData, config: AxiosRequestConfig) => {
    return importarBeneficiariosBolsaFamilia(formData, config).catch((error) => {
      const errorMessage =
        error.response.data.message ??
        error.response.data ??
        'Não é possível importar esse arquivo. Verifique se o arquivo selecionado está correto.'
      alert('danger', errorMessage)
      throw error
    })
  }

  const [filter, setFilter] = useState<ImportacaoBolsaFamiliaTableFilterModel>({
    pageParams: {
      size: 10,
      sort: ['-dataImportacaoInicio'],
    },
  })

  const { data, refetch } = useVigenciaMaisRecenteBolsaFamiliaQuery()

  const [statusImportacao, setStatusImportacao] = useState<StatusImportacaoBolsaFamiliaEnum>(null)

  const [executeQuery, { data: importacoes, loading }] = useImportacoesBolsaFamiliaLazyQuery()

  const { analytics } = useFirebase()

  useEffect(() => {
    executeQuery({
      variables: {
        input: {
          ...filter,
        },
      },
    })
  }, [executeQuery, filter])

  useEffect(() => {
    if (statusImportacao) {
      executeQuery({
        variables: {
          input: {
            ...filter,
          },
        },
      })

      refetch()
      setStatusImportacao(null)
    }
  }, [executeQuery, filter, refetch, statusImportacao])

  useEffect(() => {
    analytics.logEvent('acesso_importar_bolsa_familia')
  }, [analytics])

  const handleOnMessageListener = (messageBody: StatusImportacaoBolsaFamiliaEnum) => {
    setStatusImportacao(messageBody)
    if (messageBody !== StatusImportacaoBolsaFamiliaEnum.EM_PROGRESSO) {
      analytics.logEvent('registro_importacao_bolsa_familia')
    }
  }

  const hasImportacaoEmAndamento =
    importacoes?.importacoesBolsaFamilia?.content[0]?.statusImportacao === StatusImportacaoBolsaFamiliaEnum.EM_PROGRESSO

  return (
    <>
      <Breadcrumb title={TITLE} />
      <PageHeaderSection title={TITLE} />
      <PageContent>
        <VFlow vSpacing={1.5}>
          <Tooltip text={hasImportacaoEmAndamento ? 'Já existe um arquivo do bolsa família sendo importado.' : ''}>
            <div>
              <FileUploaderComponent
                uploadRequest={handleUpload}
                text='Clique para importar ou arraste o arquivo'
                disabled={hasImportacaoEmAndamento}
              />
              <DownloadInfo text={DonwloadInfoText} />
            </div>
          </Tooltip>
          <ImportacaoBolsaFamiliaVigenciaAlert vigenciaMaisRecenteImportada={data?.vigenciaMaisRecente?.vigencia} />
          <ImportacaoBolsaFamiliaListener
            municipioId={session.acesso?.municipio?.id}
            onMessage={handleOnMessageListener}
          />
          <ImportacaoBolsaFamiliaTable
            loading={loading}
            onPagedTableChange={setFilter}
            importacoes={importacoes?.importacoesBolsaFamilia}
          />
        </VFlow>
      </PageContent>
    </>
  )
}

const DonwloadInfoText = (
  <Text>
    Os cidadãos listados no arquivo importado serão marcados como beneficiários do bolsa família na vigência
    correspondente. Busque o arquivo JSON da vigência desejada no{' '}
    <Link href='https://egestorab.saude.gov.br/' target='_blank'>
      e-Gestor
    </Link>
    .
  </Text>
)

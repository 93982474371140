/** @jsx jsx */
import { jsx } from '@emotion/core'
import { LoadingIndicator } from 'components/loading'
import { useHistoricoPuerperioPecQuery } from 'graphql/hooks.generated'
import {
  EncaminhamentoExternoEspecializado,
  ReceitaMedicamento,
  RegistroVacinacao,
  SolicitacaoExame,
} from 'graphql/types.generated'
import { memo } from 'react'
import HistoricoSection from 'view/atendimentos/detail/historico/components/HistoricoSection'
import { HistoricoPecPanelProps } from 'view/atendimentos/detail/historico/model/model-historico'
import {
  convertMedicoesToHistoricoMedicaoModel,
  convertToEvolucaoProblema,
  mountInfosAdministrativasAtendimentosRecentes,
} from 'view/atendimentos/detail/historico/utils/utils-historico'
import { tipoParticipacaoAtendimentoRecord } from 'view/atendimentos/detail/soap/finalizacao/model-finalizacao'

import HistoricoDesfechoPanel from '../../HistoricoDesfechoPecPanel'
import HistoricoGapPanel from '../../HistoricoGapPanel'
import HistoricoNoDetailAvailable from '../../HistoricoNoDetailAvailable'
import HistoricoRegistrosAnterioresPanel from '../../HistoricoRegistrosAnterioresPanel'
import HistoricoAntecedenteSection from '../consulta/HistoricoAntecedenteSection'
import HistoricoAvaliacaoPecPanel from '../consulta/HistoricoAvaliacaoPecPanel'
import HistoricoObjetivoPecPanel from '../consulta/HistoricoObjetivoPecPanel'
import HistoricoPlanoPecPanel from '../consulta/HistoricoPlanoPecPanel'
import HistoricoSubjetivoPecPanel from '../consulta/HistoricoSubjetivoPecPanel'

interface HistoricoPuerperioPecProps extends HistoricoPecPanelProps {}

function HistoricoPuerperioPec(props: HistoricoPuerperioPecProps) {
  const { idAtend, idadeOrigemAtendimento, dataNascimento, isAtendObservacao } = props
  const {
    data: { historicoAtendimentoPec: historico },
    loading,
  } = useHistoricoPuerperioPecQuery({
    variables: {
      input: {
        idAtend,
        isOrigemPec: true,
      },
    },
  })

  if (loading) return <LoadingIndicator />

  if (!historico) return <HistoricoNoDetailAvailable />

  const tipoParticipacaoCidadao = tipoParticipacaoAtendimentoRecord[historico.tipoParticipacaoCidadao]

  return (
    <HistoricoGapPanel
      idadeOrigemAtendimento={idadeOrigemAtendimento}
      informacoesAdministrativas={mountInfosAdministrativasAtendimentosRecentes(historico, true)}
      isAtendObservacao={isAtendObservacao}
      tipoParticipacaoCidadao={tipoParticipacaoCidadao}
    >
      <HistoricoSection section='S'>
        <HistoricoSubjetivoPecPanel evolucaoSubjetivo={historico.evolucaoSubjetivo} />
      </HistoricoSection>
      <HistoricoSection section='O'>
        <HistoricoObjetivoPecPanel
          evolucaoObjetivo={historico.evolucaoObjetivo}
          medicoes={convertMedicoesToHistoricoMedicaoModel(historico.medicoes)}
          examesResultados={historico.examesResultados}
          marcadorConsumoAlimentar={historico.marcadorConsumoAlimentar}
          isAtendObservacao={isAtendObservacao}
        />
      </HistoricoSection>
      <HistoricoSection section='A'>
        <HistoricoAvaliacaoPecPanel
          evolucaoAvaliacao={historico.evolucaoAvaliacao}
          evolucaoAlergia={historico.alergiaEvolucao}
          evolucaoProblema={convertToEvolucaoProblema(historico.evolucaoProblema)}
        />
      </HistoricoSection>
      <HistoricoSection section='P'>
        <HistoricoPlanoPecPanel
          evolucaoPlano={historico.evolucaoPlano}
          quantidadeAtestados={historico.quantidadeAtestados}
          quantidadeOrientacoes={historico.quantidadeOrientacoes}
          encaminhamentos={historico.encaminhamentos}
          encaminhamentosEspecializados={
            historico.encaminhamentosEspecializados as EncaminhamentoExternoEspecializado[]
          }
          prescricaoMedicamentos={historico.receitaMedicamento as ReceitaMedicamento[]}
          examesSolicitados={historico.requisicoesExames as SolicitacaoExame[]}
          planosDeCuidado={historico.planosDeCuidado}
          isAtendObservacao={isAtendObservacao}
          cuidadosCompartilhados={historico.cuidadosCompartilhados}
        />
      </HistoricoSection>
      <HistoricoAntecedenteSection
        antecedentes={historico.antecedentes}
        cirurgiasInternacoes={historico.cirurgiasInternacoes}
        dataNascimento={dataNascimento}
      />
      <HistoricoSection section='RA'>
        <HistoricoRegistrosAnterioresPanel
          medicoes={convertMedicoesToHistoricoMedicaoModel(historico.medicoes)}
          registrosVacinacao={historico.vacinacao?.registrosVacinacao as RegistroVacinacao[]}
          dataNascimentoCidadao={idadeOrigemAtendimento.dataNascimentoCidadao}
        />
      </HistoricoSection>
      <HistoricoSection section='D'>
        <HistoricoDesfechoPanel
          condutas={historico.condutas}
          procedimentosAdministrativos={historico.procedimentosAtendimento?.map((proced) => proced.procedimento)}
          notificacoesCasoSuspeito={historico.notificacoesCasoSuspeito}
          racionalidadeEmSaude={historico.racionalidadeSaude}
        />
      </HistoricoSection>
    </HistoricoGapPanel>
  )
}

export default memo(HistoricoPuerperioPec)

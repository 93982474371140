/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, Grid, Heading } from 'bold-ui'
import { AccordionCompositeItem } from 'components/accordion/AccordionCompositeItem'
import { PeriodoAusencia } from 'graphql/types.generated'
import { Accordion } from 'react-accessible-accordion'

import { PeriodoAusenciaTable } from './PeriodoAusenciaTable'

interface PeriodoAusenciaHistoryProps {
  periodoAusenciaList?: PeriodoAusencia[]
}

export function PeriodoAusenciaHistory({ periodoAusenciaList }: PeriodoAusenciaHistoryProps) {
  return (
    <Grid>
      <Cell size={6}>
        <Accordion allowZeroExpanded>
          <AccordionCompositeItem
            header={
              <Heading level={4} style={styles.heading}>
                Histórico de datas
              </Heading>
            }
            body={<PeriodoAusenciaTable periodoAusenciaList={periodoAusenciaList} />}
          />
        </Accordion>
      </Cell>
    </Grid>
  )
}

const styles = {
  heading: css`
    padding: 0.5rem 0;
  `,
}

import { SelectMenuComponents } from 'bold-ui/lib/components/Select/SelectSingle/SelectDownshiftMenu'
import { SelectFieldWithAddButton } from 'components/form/final-form/SelectField/SelectFieldWithAddButton'
import React from 'react'
import { useHistory } from 'react-router-dom'

import { CidadaoSelectFieldItem } from '../CidadaoSelectField'
import { AsyncQuerySelectProps, AsyncSelectFieldProps } from '../useAsyncQuerySelect'
import { CidadaoAtendimentoSelectFieldModel } from './CidadaoAtendimentoSelectField'

interface CidadaoAtendimentoSelectFieldCommonProps extends AsyncSelectFieldProps<CidadaoAtendimentoSelectFieldModel> {
  addCidadaoCallbackUrl: string
  placeholder: string
  components: Partial<SelectMenuComponents<CidadaoAtendimentoSelectFieldModel>>
  selectProps: AsyncQuerySelectProps<CidadaoAtendimentoSelectFieldModel>
  verificarPresenteNaListaAtendimento?: boolean
  onBeforeRedirect?: () => void
}

export function CidadaoAtendimentoSelectFieldCommon(props: CidadaoAtendimentoSelectFieldCommonProps) {
  const {
    addCidadaoCallbackUrl,
    placeholder,
    components,
    selectProps,
    verificarPresenteNaListaAtendimento = true,
    onBeforeRedirect,
    ...rest
  } = props

  const history = useHistory()

  const handleClickCadastrarButton = () => {
    onBeforeRedirect?.()
    setTimeout(() => history.push(`/cidadao?callbackUrl=${addCidadaoCallbackUrl}`))
  }

  return (
    <SelectFieldWithAddButton<CidadaoAtendimentoSelectFieldModel>
      itemToString={itemToString}
      renderItem={renderItem}
      itemIsEqual={verificarPresenteNaListaAtendimento && isPresente}
      menuMinWidth={500}
      popperProps={popperProps}
      addText='Cadastre um cidadão somente se não o encontrar na busca.'
      addButtonText='Cadastrar cidadão'
      onClickAddButton={handleClickCadastrarButton}
      placeholder={placeholder}
      components={components}
      {...selectProps}
      {...rest}
    />
  )
}

const isPresente = (cidadao: CidadaoAtendimentoSelectFieldModel) => cidadao.presenteListaAtendimento

const itemToString = (item: CidadaoAtendimentoSelectFieldModel) => item?.nomeSocial || item?.nome

const renderItem = (item: CidadaoAtendimentoSelectFieldModel) => {
  return item && <CidadaoSelectFieldItem item={item} />
}

const popperProps = { modifiers: [{ name: 'preventOverflow', enabled: false }] }

/**
 * Retorna uma representação de strings a partir de uma
 * lista de strings no formato 'palavra1, palavra2, palavra3 + N (número de itens restantes)'
 * (Caso a lista possua mais de três itens, os três primeiros itens serão exibidos
 * e o resto será agrupado em um número, caso contrário os itens só são separados por vírgula)
 *
 * @param strings uma lista de strings a serem agrupadas
 */

export const stringToCountList = (...strings: string[]): string | null => {
  const filtered: string[] = strings.filter((i) => i != null)
  if (filtered.length <= 0) return null
  if (filtered.length < 4) return filtered.splice(0, filtered.length).join(', ')

  return [`${filtered.slice(0, 3).join(', ')}`, `+${filtered.slice(3).length}`].join(' ')
}

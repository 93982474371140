import { SelectField, SelectFieldProps } from 'components/form'
import { TipoAtividadeEnum } from 'graphql/types.generated'
import React from 'react'

export interface TipoAtividadeSelectFieldModel {
  value: TipoAtividadeEnum
  label: string
  isReuniao: boolean
}

interface TipoAtividadeSelectFieldProps
  extends Omit<SelectFieldProps<TipoAtividadeSelectFieldModel>, 'items' | 'itemToString'> {
  isReuniao?: boolean
}

const tipoAtividadeItems: TipoAtividadeSelectFieldModel[] = [
  {
    value: TipoAtividadeEnum.REUNIAO_DE_EQUIPE,
    label: 'Reunião de equipe',
    isReuniao: true,
  },
  {
    value: TipoAtividadeEnum.REUNIAO_COM_OUTRAS_EQUIPES_DE_SAUDE,
    label: 'Reunião com outras equipes de saúde',
    isReuniao: true,
  },
  {
    value: TipoAtividadeEnum.REUNIAO_INTERSETORIAL_CONSELHO_LOCAL_DE_SAUDE_CONTROLE_SOCIAL,
    label: 'Reunião intersetorial / Conselho local de saúde / Controle social',
    isReuniao: true,
  },
  {
    value: TipoAtividadeEnum.EDUCACAO_EM_SAUDE,
    label: 'Educação em saúde',
    isReuniao: false,
  },
  {
    value: TipoAtividadeEnum.ATENDIMENTO_EM_GRUPO,
    label: 'Atendimento em grupo',
    isReuniao: false,
  },
  {
    value: TipoAtividadeEnum.AVALIACAO_PROCEDIMENTO_COLETIVO,
    label: 'Avaliação / Procedimento coletivo',
    isReuniao: false,
  },
  {
    value: TipoAtividadeEnum.MOBILIZACAO_SOCIAL,
    label: 'Mobilização social',
    isReuniao: false,
  },
]

const itemToString = (item: TipoAtividadeSelectFieldModel) => item?.label

export function TipoAtividadeSelectField({ isReuniao, ...rest }: TipoAtividadeSelectFieldProps) {
  const items = tipoAtividadeItems.filter((item) => item.isReuniao === !!isReuniao)

  return <SelectField<TipoAtividadeSelectFieldModel> items={items} itemToString={itemToString} {...rest} />
}

/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Link } from 'bold-ui'
import React from 'react'

interface MapLinkProps {
  latitude: number
  longitude: number
}

export const MapLink = React.memo(({ latitude, longitude }: MapLinkProps) => {
  const handleOpenMap = (latitude: number, longitude: number) => {
    window.open(`https://www.openstreetmap.org/?mlat=${latitude}&mlon=${longitude}#map=19/${latitude}/${longitude}`)
  }

  return <Link onClick={() => handleOpenMap(latitude, longitude)}>{`${latitude}, ${longitude}`}</Link>
})

import { Cell, FormControl, Grid, HFlow, VFlow } from 'bold-ui'
import { BeneficioDbEnum } from 'graphql/types.generated'
import React from 'react'
import { MetaPath } from 'util/metaPath'

import { CheckboxField, ErrorField } from '../final-form'

export interface BeneficiosCheckboxGroupFieldProps {
  name: MetaPath<BeneficioDbEnum[]>
  values?: BeneficioDbEnum[]
}

export function BeneficiosCheckboxGroupField({ name, values }: BeneficiosCheckboxGroupFieldProps) {
  const disabled = values && values.includes(BeneficioDbEnum.NAO_RECEBE_BENEFICIO_SOCIAL)

  return (
    <VFlow>
      <FormControl label='Benefícios do governo'>
        <VFlow vSpacing={0.5}>
          <ErrorField name={name} />

          <HFlow style={{ gridAutoColumns: 'auto', marginLeft: '-0.25rem' }}>
            <Grid alignItems='baseline' gapVertical={0.5}>
              <Cell size={3}>
                <CheckboxField name={name} label='Aposentado' value={BeneficioDbEnum.APOSENTADO} disabled={disabled} />
              </Cell>

              <Cell size={3}>
                <CheckboxField
                  name={name}
                  label='Bolsa família'
                  value={BeneficioDbEnum.BOLSA_FAMILIA}
                  disabled={disabled}
                />
              </Cell>

              <Cell size={3}>
                <CheckboxField
                  name={name}
                  label='Leite não humano'
                  value={BeneficioDbEnum.LEITE_NAO_HUMANO}
                  disabled={disabled}
                />
              </Cell>

              <Cell size={3}>
                <CheckboxField
                  name={name}
                  label='Outros benefícios'
                  value={BeneficioDbEnum.OUTROS_BENEFICIOS}
                  disabled={disabled}
                />
              </Cell>

              <Cell size={3}>
                <CheckboxField
                  name={name}
                  label='Benefício de prestação continuada'
                  value={BeneficioDbEnum.BENEFICIO_DE_PRESTACAO_CONTINUADA}
                  disabled={disabled}
                />
              </Cell>

              <Cell size={3}>
                <CheckboxField
                  name={name}
                  label='Cesta de alimento'
                  value={BeneficioDbEnum.CESTA_DE_ALIMENTO}
                  disabled={disabled}
                />
              </Cell>

              <Cell size={3}>
                <CheckboxField
                  name={name}
                  label='Não recebe benefício social'
                  value={BeneficioDbEnum.NAO_RECEBE_BENEFICIO_SOCIAL}
                  disabled={
                    values && values.length > 0 && !values.includes(BeneficioDbEnum.NAO_RECEBE_BENEFICIO_SOCIAL)
                  }
                />
              </Cell>
            </Grid>
          </HFlow>
        </VFlow>
      </FormControl>
    </VFlow>
  )
}

import { HFlow, Text, VFlow } from 'bold-ui'
import { SelectField } from 'components/form/final-form'
import { AldeiaSelectFieldDocument } from 'graphql/hooks.generated'
import { AldeiaSelectFieldQuery, AldeiaSelectFieldQueryVariables } from 'graphql/types.generated'
import React from 'react'

import { AsyncSelectFieldProps, DEFAULT_SELECT_PAGE_PARAM, useAsyncQuerySelect } from '../useAsyncQuerySelect'

export type AldeiaSelectFieldModel = Partial<AldeiaSelectFieldQuery['aldeias']['content'][0]>

export interface AldeiaSelectFieldProps extends AsyncSelectFieldProps<AldeiaSelectFieldModel> {
  dseiId?: ID
  poloBaseId?: ID
}

export function AldeiaSelectField(props: AldeiaSelectFieldProps) {
  const { dseiId, poloBaseId, ...rest } = props

  const {
    selectProps: { loading, ...asyncProps },
  } = useAsyncQuerySelect<AldeiaSelectFieldModel, AldeiaSelectFieldQuery, AldeiaSelectFieldQueryVariables>({
    query: AldeiaSelectFieldDocument,
    extractItems: (data) => data && data.aldeias && data.aldeias.content,
    variables: (inputQuery: string) => ({
      input: {
        dseiId,
        poloBaseId,
        query: inputQuery,
        pageParams: {
          ...DEFAULT_SELECT_PAGE_PARAM,
          sort: ['nome'],
        },
      },
    }),
  })

  const itemToString = (item: AldeiaSelectFieldModel) => item && item.nome

  const renderItem = (item: AldeiaSelectFieldModel) => {
    const municipiosList: string[] = []

    item.municipio.forEach((element) => {
      municipiosList.push(`${element.nome} - ${element.uf.sigla}`)
    })
    return (
      <VFlow vSpacing={0}>
        <Text fontWeight='bold'>{item.nome}</Text>
        <HFlow hSpacing={0.5} style={{ whiteSpace: 'pre-wrap' }}>
          {municipiosList.join('\n')}
        </HFlow>
      </VFlow>
    )
  }

  return (
    <SelectField<AldeiaSelectFieldModel>
      itemToString={itemToString}
      renderItem={renderItem}
      {...asyncProps}
      {...rest}
    />
  )
}

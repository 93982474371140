import { SelectField } from 'components/form/final-form'
import { PoloBaseSelectFieldDocument } from 'graphql/hooks.generated'
import { PoloBaseSelectFieldQuery, PoloBaseSelectFieldQueryVariables } from 'graphql/types.generated'
import React from 'react'

import { AsyncSelectFieldProps, DEFAULT_SELECT_PAGE_PARAM, useAsyncQuerySelect } from '../useAsyncQuerySelect'

export type PoloBaseSelectFieldModel = Partial<PoloBaseSelectFieldQuery['polosBases']['content'][0]>

export interface PoloBaseSelectFieldProps extends AsyncSelectFieldProps<PoloBaseSelectFieldModel> {
  dseiId?: ID
  aldeiaId?: ID
}

export function PoloBaseSelectField(props: PoloBaseSelectFieldProps) {
  const { dseiId, aldeiaId, ...rest } = props

  const {
    selectProps: { loading, ...asyncProps },
  } = useAsyncQuerySelect<PoloBaseSelectFieldModel, PoloBaseSelectFieldQuery, PoloBaseSelectFieldQueryVariables>({
    query: PoloBaseSelectFieldDocument,
    extractItems: (data) => data && data.polosBases && data.polosBases.content,
    variables: (inputQuery: string) => ({
      input: {
        dseiId,
        aldeiaId,
        query: inputQuery,
        pageParams: {
          ...DEFAULT_SELECT_PAGE_PARAM,
          sort: ['nome'],
        },
      },
    }),
  })

  const itemToString = (item: PoloBaseSelectFieldModel) => item && item.nome

  return <SelectField<PoloBaseSelectFieldModel> itemToString={itemToString} {...asyncProps} {...rest} />
}

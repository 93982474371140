import { useAlert } from 'components/alert'
import useSession from 'components/auth/useSession'
import { useErrorHandler } from 'components/error'
import { confirm } from 'components/modals/confirm'
import { useExcluirCidadaoMutation, useReativarCidadaoMutation } from 'graphql/hooks.generated'
import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import Permissions from 'types/Permissions'

import { CidadaoUrlParams } from '../types/CidadaoUrlParams'
import { CidadaoListingFormModel } from '../types/model-pesquisarCidadao'
import { useNavigateToCidadaoCallbackUrl } from '../useNavigateToCidadaoCallbackUrl'
import { useNeedLoginGovBrToCadsus } from '../useNeedLoginGovBrToCadsus'
import { CidadaoAldeadoListingItemView } from './CidadaoAldeadoListingItemView'
import { CidadaoListingItemView } from './CidadaoListingItemView'

interface CidadaoListingItemProps {
  data: CidadaoListingFormModel
  refetchLocal(): void
  cidadaoUrlParams: CidadaoUrlParams
}

export const CidadaoListingItem = (props: CidadaoListingItemProps) => {
  const {
    data,
    cidadaoUrlParams: { callbackUrl },
    refetchLocal,
  } = props

  const { hasAuthorization } = useSession()
  const [excluirCidadao] = useExcluirCidadaoMutation()
  const [reativarCidadao] = useReativarCidadaoMutation()
  const externalAccess = !!callbackUrl

  const alert = useAlert()
  const handleRejection = useErrorHandler()
  const location = useLocation()
  const history = useHistory()

  const navigateToCidadaoCallbackUrl = useNavigateToCidadaoCallbackUrl()

  const telefone = data.telefoneCelular
    ? data.telefoneCelular
    : data.telefoneResidencial
    ? data.telefoneResidencial
    : null

  const cadastrarEEditar = hasAuthorization(Permissions.gestaoDeCadastrosDeCidadao.visualizarCidadao.cadastrarEEditar)
  const excluir = hasAuthorization(Permissions.gestaoDeCadastrosDeCidadao.visualizarCidadao.excluir)
  const inativar = hasAuthorization(Permissions.gestaoDeCadastrosDeCidadao.visualizarCidadao.inativar)
  const visualizarAgenda = hasAuthorization(Permissions.visualizarAgenda)

  const hasAnyPermission = cadastrarEEditar || excluir || inativar || visualizarAgenda

  const onExcluirClick = () => {
    confirm({
      title: `Deseja excluir o cadastro do cidadão?`,
      confirmLabel: 'Excluir',
      type: 'danger',
      onConfirm: () => {
        return excluirCidadao({ variables: { cidadaoId: data.id } })
          .then(() => {
            alert('success', 'Cadastro do cidadão excluído.')
            refetchLocal()
          })
          .catch(handleRejection)
      },
    })()
  }

  const onVisualizarClick = () => {
    history.push(`/cidadao/${data.id}${location.search}`)
  }

  const { needLoginGovBr: deveAparecerModalGovBr } = useNeedLoginGovBrToCadsus()

  const [modalInformarLoginGovBrOpen, setModalInformarLoginGovBrOpen] = useState(false)

  const continueEditarCidadao = () => {
    history.push(`/cidadao/${data.id}/edit${location.search}`)
  }

  const onEditarClick = () => {
    if (deveAparecerModalGovBr) {
      setModalInformarLoginGovBrOpen(true)
    } else {
      continueEditarCidadao()
    }
  }

  const onReativarClick = () => {
    confirm({
      title: `Deseja reativar o cadastro do cidadão?`,
      confirmLabel: 'Reativar',
      type: 'primary',
      onConfirm: () => {
        return reativarCidadao({ variables: { cidadaoId: data.id } })
          .then(() => {
            alert('success', 'Cadastro do cidadão reativado.')
            refetchLocal()
          })
          .catch(handleRejection)
      },
    })()
  }

  const handleVerAgendamentosClick = () => {
    history.push(`/cidadao/${data.id}/agendamentos`)
  }

  return data.cidadaoAldeado ? (
    <CidadaoAldeadoListingItemView
      data={data}
      telefone={telefone}
      externalAccess={externalAccess}
      hasAnyPermission={hasAnyPermission}
      cadastrarEEditar={cadastrarEEditar}
      inativar={inativar}
      excluir={excluir}
      visualizarAgenda={visualizarAgenda}
      onExcluirClick={onExcluirClick}
      onVisualizarClick={onVisualizarClick}
      onEditarClick={onEditarClick}
      onReativarClick={onReativarClick}
      handleVerAgendamentosClick={handleVerAgendamentosClick}
      navigateToCidadaoCallbackUrl={navigateToCidadaoCallbackUrl}
      modalInformarLoginGovBrOpen={modalInformarLoginGovBrOpen}
      setModalInformarLoginGovBrOpen={setModalInformarLoginGovBrOpen}
      continueEditarCidadao={continueEditarCidadao}
    />
  ) : (
    <CidadaoListingItemView
      data={data}
      telefone={telefone}
      externalAccess={externalAccess}
      hasAnyPermission={hasAnyPermission}
      cadastrarEEditar={cadastrarEEditar}
      inativar={inativar}
      excluir={excluir}
      visualizarAgenda={visualizarAgenda}
      onExcluirClick={onExcluirClick}
      onVisualizarClick={onVisualizarClick}
      onEditarClick={onEditarClick}
      onReativarClick={onReativarClick}
      handleVerAgendamentosClick={handleVerAgendamentosClick}
      navigateToCidadaoCallbackUrl={navigateToCidadaoCallbackUrl}
      modalInformarLoginGovBrOpen={modalInformarLoginGovBrOpen}
      setModalInformarLoginGovBrOpen={setModalInformarLoginGovBrOpen}
      continueEditarCidadao={continueEditarCidadao}
    />
  )
}

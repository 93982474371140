import { VFlow } from 'bold-ui'
import CheckPermission from 'components/auth/CheckPermission'
import { useDadosServidoresQuery } from 'graphql/hooks.generated'
import { useConfiguracoes } from 'hooks/configuracoes/useConfiguracoes'
import React, { useCallback } from 'react'
import Permissions from 'types/Permissions'

import { ServidoresBox } from './ServidoresBox'

export const ServidoresView = () => {
  const { data, refetch } = useDadosServidoresQuery()

  const { update } = useConfiguracoes()
  const refetchConfiguracoes = useCallback(() => {
    update()
    refetch()
  }, [refetch, update])

  return (
    <>
      {data && data.servidores && (
        <VFlow vSpacing={2}>
          <CheckPermission permission={Permissions.configuracaoDeInstalacao.configurarServidores}>
            <ServidoresBox
              internetConnection={data.servidores.internet.habilitado}
              dadosServidor={data.servidores.dadosServidor}
              servidorSMTP={data.servidores.servidorSMTP}
              load={refetchConfiguracoes}
            />
          </CheckPermission>
        </VFlow>
      )}
    </>
  )
}

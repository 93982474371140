import { HFlow, Icon, VFlow } from 'bold-ui'
import { Form, FormRenderProps } from 'components/form'
import { PopperButton, PopperControls } from 'components/popper'
import { TipoEnderecoEnum } from 'graphql/types.generated'
import React from 'react'
import { convertToInitialValuesMicroareasFilter } from 'view/visualizacaoTerritorial/converter'

import { VisualizacaoMicroareasFilterModel, VisualizacaoTerritorialMicroareasFilterModel } from '../../model'
import { MicroareasFilterPopper } from './MicroareasFilterPopper'
import { MicroareasFilterTags } from './MicroareasFilterTags'

interface MicroareasFilterProps {
  onSubmit(values: VisualizacaoTerritorialMicroareasFilterModel): void
  microareasFilter?: VisualizacaoMicroareasFilterModel
  tipoEndereco: TipoEnderecoEnum
}

export function MicroareasFilter(props: MicroareasFilterProps) {
  const { onSubmit, microareasFilter, tipoEndereco } = props

  const handleLimparCampos = () => {
    onSubmit(null)
  }

  const renderForm = ({ handleSubmit }: FormRenderProps<VisualizacaoTerritorialMicroareasFilterModel>) => {
    const renderPopper = (ctrl: PopperControls) => (
      <MicroareasFilterPopper
        tipoEndereco={tipoEndereco}
        onSubmit={handleSubmit}
        onLimparCampos={handleLimparCampos}
        close
        {...ctrl}
      />
    )

    return (
      <HFlow alignItems='center' justifyContent='space-between'>
        <MicroareasFilterTags microareasFilter={microareasFilter} />
        <PopperButton kind='primary' placement='bottom-end' size='small' renderPopper={renderPopper}>
          <Icon icon='filterOutline' />
          Filtros
        </PopperButton>
      </HFlow>
    )
  }

  return (
    <VFlow>
      <Form<VisualizacaoTerritorialMicroareasFilterModel>
        render={renderForm}
        onSubmit={onSubmit}
        initialValues={convertToInitialValuesMicroareasFilter(microareasFilter)}
      />
    </VFlow>
  )
}

import { useCallback, useEffect, useRef, useState } from 'react'

import { ConfiguracoesVideochamadaModel } from '../model-videochamada'

interface UseUserMediaOptions {
  video: boolean
  audio: boolean
  videoDeviceId?: string
  audioDeviceId?: string
}

export const enableTrackStreamEvent = (track?: MediaStreamTrack) => new CustomEvent('enable', { detail: { track } })

const getUserMedia = async (audioDeviceId: string, videoDeviceId: string) => {
  return await navigator.mediaDevices?.getUserMedia({
    audio: audioDeviceId ? { deviceId: { ideal: audioDeviceId } } : true,
    video: videoDeviceId ? { deviceId: { ideal: videoDeviceId } } : true,
  })
}
export function useUserMedia(options: UseUserMediaOptions) {
  const { audio, audioDeviceId, video, videoDeviceId } = options
  const [stream, setStream] = useState<MediaStream>()
  const [audioDeviceAvailable, setAudioDeviceAvailable] = useState(false)
  const [videoDeviceAvailable, setVideoDeviceAvailable] = useState(false)
  const [mediaDevices, setMediaDevices] = useState<ConfiguracoesVideochamadaModel>()

  const lastAudioTrackEnabled = useRef<boolean>()
  const lastVideoTrackEnabled = useRef<boolean>()

  const updateTracks = useCallback((stream: MediaStream, audioEnabled: boolean, videoEnabled: boolean) => {
    const audioChanged = lastAudioTrackEnabled.current !== audioEnabled
    const videoChanged = lastVideoTrackEnabled.current !== videoEnabled

    if (audioChanged) updateAudioTracks(stream, audioEnabled)
    if (videoChanged) updateVideoTracks(stream, videoEnabled)

    lastAudioTrackEnabled.current = audioEnabled
    lastVideoTrackEnabled.current = videoEnabled
  }, [])

  const updateAudioTracks = (stream: MediaStream, audioEnabled: boolean) => {
    stream.getAudioTracks().forEach((track) => {
      track.enabled = audioEnabled
      stream.dispatchEvent(enableTrackStreamEvent(track))
    })
  }

  const updateVideoTracks = (stream: MediaStream, videoEnabled: boolean) => {
    stream.getVideoTracks().forEach((track) => {
      track.enabled = videoEnabled
      stream.dispatchEvent(enableTrackStreamEvent(track))
    })
  }

  const rawStream = useRef<MediaStream>()

  const checkDevicesStatusAndGetStreams = useCallback(
    async ({ audio, audioDeviceId, video, videoDeviceId }: UseUserMediaOptions) => {
      if (!stream) {
        try {
          const streamCandidate = await getUserMedia(audioDeviceId, videoDeviceId)
          if (streamCandidate) {
            const videoTracks = streamCandidate.getVideoTracks()
            setVideoDeviceAvailable(!!videoTracks.length)
            videoTracks.forEach((track) => (track.enabled = video))

            const audioTracks = streamCandidate.getAudioTracks()
            setAudioDeviceAvailable(!!audioTracks.length)
            audioTracks.forEach((track) => (track.enabled = audio))

            if (!!rawStream.current) {
              setStream(rawStream.current)
            } else if (streamCandidate) {
              setStream(streamCandidate)
              rawStream.current = streamCandidate
            }

            const audioTrack = streamCandidate?.getAudioTracks()[0]
            const videoTrack = streamCandidate?.getVideoTracks()[0]

            const audioDeviceId = audioTrack?.getSettings()?.deviceId
            const audioDeviceLabel = audioTrack?.label

            const videoDeviceId = videoTrack?.getSettings()?.deviceId
            const videoDeviceLabel = videoTrack?.label

            setMediaDevices({
              audioInput: { id: audioDeviceId ?? '', nome: audioDeviceLabel ?? '' },
              audioOutput: { id: 'default', nome: 'Padrão' },
              videoInput: { id: videoDeviceId ?? '', nome: videoDeviceLabel ?? '' },
            })
            lastAudioTrackEnabled.current = audio
            lastVideoTrackEnabled.current = video
          }
        } catch (error) {
          setVideoDeviceAvailable(false)
          setAudioDeviceAvailable(false)
        }
      } else updateTracks(stream, audio, video)
    },
    [stream, updateTracks]
  )

  useEffect(() => {
    checkDevicesStatusAndGetStreams({ audio, audioDeviceId, video, videoDeviceId })
    const handleDeviceChange = () => checkDevicesStatusAndGetStreams({ audio, audioDeviceId, video, videoDeviceId })
    navigator.mediaDevices.addEventListener('devicechange', handleDeviceChange)

    return () => navigator.mediaDevices.removeEventListener('devicechange', handleDeviceChange)
  }, [checkDevicesStatusAndGetStreams, audio, audioDeviceId, video, videoDeviceId])

  //Para de usar os dispositivos de mídia ao destruir o componente
  useEffect(() => () => stream?.getTracks().forEach((track) => track.stop()), [stream])

  return { stream, audioDeviceAvailable, videoDeviceAvailable, mediaDevices }
}

import { isEmpty, Text, VFlow } from 'bold-ui'
import {
  AtendimentoProfissionalPreNatal,
  AtendimentoProfissionalPuericultura,
  EvolucaoObjetivo,
  MarcadorConsumoAlimentar,
  ResultadoExame,
} from 'graphql/types.generated'
import React from 'react'
import { filterByRegistroAnterior } from 'view/atendimentos/detail/historico/utils/filterByRegistroAnterior'
import {
  convertMedicoesToHistoricoMedicaoModel,
  getMedicoesPreNatal,
  hasAllAttrsNull,
  hasMedicoes,
  naoRegistradoNesseAtendimento,
} from 'view/atendimentos/detail/historico/utils/utils-historico'
import { HistoricoMedicaoModel } from 'view/atendimentos/types/HistoricoMedicaoModel'

import { HistoricoMedicoesObservacaoPanel } from '../../atendimento-observacao/HistoricoMedicoesObservacaoPanel'
import HistoricoMCATableBox from '../../HistoricoMCATableBox'
import HistoricoMedicoesTableBox from '../../HistoricoMedicoesTableBox'
import HistoricoResultadoExamesTable from '../../HistoricoResultadoExamesTable'
import HistoricoMedicoesPreNatalTableBox from '../pre-natal/HistoricoMedicoesPreNatalTableBox'
import HistoricoObjetivoInformacoesPuericultura from '../puericultura/HistoricoObjetivoInformacoesPuericultura'

export interface AtendimentoProfissionalPreNatalModel
  extends Pick<AtendimentoProfissionalPreNatal, 'id' | 'gravidezPlanejada' | 'movimentacaoFetal' | 'tipoEdema'> {}

export interface HistoricoObjetivoPecPanelProps {
  evolucaoObjetivo: EvolucaoObjetivo
  medicoes: HistoricoMedicaoModel[]
  isAtendObservacao?: boolean
  examesResultados?: ResultadoExame[]
  atendProfPreNatal?: AtendimentoProfissionalPreNatalModel
  atendProfPuericultura?: AtendimentoProfissionalPuericultura
  marcadorConsumoAlimentar?: MarcadorConsumoAlimentar
}

export default function HistoricoObjetivoPecPanel(props: HistoricoObjetivoPecPanelProps) {
  const {
    evolucaoObjetivo,
    medicoes,
    examesResultados,
    atendProfPreNatal,
    atendProfPuericultura,
    marcadorConsumoAlimentar,
    isAtendObservacao,
  } = props

  const { recentes } = filterByRegistroAnterior<HistoricoMedicaoModel>(medicoes, 'medicaoAnterior')

  const descricao = evolucaoObjetivo?.descricao?.removeTags()

  const possuiInformacoesPuericultura: boolean = atendProfPuericultura && !hasAllAttrsNull(atendProfPuericultura)

  const hasAtendProfPreNatal: boolean =
    atendProfPreNatal?.gravidezPlanejada || atendProfPreNatal?.movimentacaoFetal || !!atendProfPreNatal?.tipoEdema

  const preNatalInfo = getMedicoesPreNatal(recentes)

  const hasPreNatalInfo = hasAtendProfPreNatal || !isEmpty(preNatalInfo)

  const shouldDisplayMCA = !!marcadorConsumoAlimentar

  const hasObjetivo: boolean =
    !!descricao ||
    !isEmpty(examesResultados) ||
    hasMedicoes(recentes) ||
    hasPreNatalInfo ||
    possuiInformacoesPuericultura ||
    shouldDisplayMCA

  return (
    <>
      {hasObjetivo ? (
        <VFlow>
          {descricao && <Text dangerouslySetInnerHTML={{ __html: evolucaoObjetivo.descricao }} />}
          {hasMedicoes(recentes) &&
            (isAtendObservacao ? (
              <HistoricoMedicoesObservacaoPanel medicoes={recentes} />
            ) : (
              <HistoricoMedicoesTableBox sectionStyle='O' medicoes={recentes} />
            ))}

          {shouldDisplayMCA && <HistoricoMCATableBox marcadorConsumoAlimentar={marcadorConsumoAlimentar} />}

          {hasPreNatalInfo && (
            <HistoricoMedicoesPreNatalTableBox medicoes={preNatalInfo} atendProfPreNatal={atendProfPreNatal} />
          )}
          {possuiInformacoesPuericultura && (
            <HistoricoObjetivoInformacoesPuericultura
              medicoes={convertMedicoesToHistoricoMedicaoModel(recentes)}
              atendProfPuericultura={atendProfPuericultura}
            />
          )}
          {!isEmpty(examesResultados) && <HistoricoResultadoExamesTable examesResultados={examesResultados} />}
        </VFlow>
      ) : (
        <Text fontStyle='italic'>{naoRegistradoNesseAtendimento}</Text>
      )}
    </>
  )
}

import { ApolloProvider } from '@apollo/react-hooks'
import { LocaleContext, ThemeProvider } from 'bold-ui'
import ptBr from 'bold-ui/lib/i18n/locales/pt-BR'
import { AlertContext, defaultAlertStore } from 'components/alert'
import { BreadcrumbContext, defaultBreadcrumbStore } from 'components/breadcrumb'
import { handleError, PromiseErrorBoundary } from 'components/error'
import { defaultEsusViewStore, EsusViewContext } from 'components/esus/EsusViewContext'
import { history } from 'config'
import theme from 'config/theme'
import { FlagsProvider } from 'config/useFlagsContext'
import { ThemeProvider as EmotionThemeProvider } from 'emotion-theming'
import apolloClient from 'graphql/client'
import { ConfiguracoesProvider } from 'hooks/configuracoes'
import React from 'react'
import { Router } from 'react-router'
import { InitialView } from 'view/InitialView'

export const App = () => {
  return (
    <ApolloProvider client={apolloClient}>
      <PromiseErrorBoundary onError={(error) => handleError({ error, suppressValidationError: true })}>
        <EmotionThemeProvider theme={theme}>
          <ThemeProvider theme={theme}>
            <LocaleContext.Provider value={ptBr}>
              <AlertContext.Provider value={defaultAlertStore}>
                <BreadcrumbContext.Provider value={defaultBreadcrumbStore}>
                  <EsusViewContext.Provider value={defaultEsusViewStore}>
                    <ConfiguracoesProvider>
                      <FlagsProvider>
                        <Router history={history}>
                          <InitialView />
                        </Router>
                      </FlagsProvider>
                    </ConfiguracoesProvider>
                  </EsusViewContext.Provider>
                </BreadcrumbContext.Provider>
              </AlertContext.Provider>
            </LocaleContext.Provider>
          </ThemeProvider>
        </EmotionThemeProvider>
      </PromiseErrorBoundary>
    </ApolloProvider>
  )
}

import { LotacaoEditableTableModel } from 'components/form/editable-table/LotacaoEditableTable'
import { LotacaoResponsavelFormModel } from 'components/lotacao-responsavel/model-lotacaoResponsavel'
import { AtividadeColetivaTableQuery, TemaReuniaoEnum, TipoAtividadeEnum } from 'graphql/types.generated'
import { Record } from 'immutable'

import { LocalAtividadeFieldModel } from './componentes/LocalAtividadeField'
import { TemaReuniaoSelectFieldModel } from './componentes/TemaReuniaoSelectField'
import { TipoAtividadeSelectFieldModel } from './componentes/TipoAtividadeSelectField'
import { TurnoSelectFieldModel } from './componentes/TurnoSelectField'

export type AtividadeColetivaTableItem = AtividadeColetivaTableQuery['atividadesColetivas']['content'][0]

export const tipoAtividadeRecord: Record<TipoAtividadeEnum, string> = {
  REUNIAO_DE_EQUIPE: 'Reunião de equipe',
  REUNIAO_COM_OUTRAS_EQUIPES_DE_SAUDE: 'Reunião com outras equipes de saúde',
  REUNIAO_INTERSETORIAL_CONSELHO_LOCAL_DE_SAUDE_CONTROLE_SOCIAL:
    'Reunião intersetorial / Conselho local de saúde / Controle social',
  EDUCACAO_EM_SAUDE: 'Educação em saúde',
  ATENDIMENTO_EM_GRUPO: 'Atendimento em grupo',
  AVALIACAO_PROCEDIMENTO_COLETIVO: 'Avaliação / Procedimento coletivo',
  MOBILIZACAO_SOCIAL: 'Mobilização social',
}

export const temaReuniaoRecord: Record<TemaReuniaoEnum, string> = {
  QUESTOES_ADMINISTRATIVAS_FUNCIONAMENTO: 'Questões administrativas / Funcionamento',
  PROCESSOS_DE_TRABALHO: 'Processos de trabalho',
  DIAGNOSTICO_DO_TERRITORIO_MONITORAMENTO_DO_TERRITORIO: 'Diagnóstico do território / Monitoramento do território',
  PLANEJAMENTO_MONITORAMENTO_DAS_ACOES_DA_EQUIPE: 'Planejamento / Monitoramento das ações da equipe',
  DISCUSSAO_DE_CASO_PROJETO_TERAPEUTICO_SINGULAR: 'Discussão de caso / Projeto terapêutico singular',
  EDUCACAO_PERMANENTE: 'Educação permanente',
  OUTROS: 'Outros',
}

export interface AtividadeColetivaFormModel {
  lotacaoResponsavel: LotacaoResponsavelFormModel
  dataAtividade: LocalDate
  turno: TurnoSelectFieldModel
  numeroParticipantes: number
  localAtividade?: LocalAtividadeFieldModel
  tipoAtividade: TipoAtividadeSelectFieldModel
  temaReuniao?: TemaReuniaoSelectFieldModel
  anotacoes?: string
  lotacoes: LotacaoEditableTableModel[]
}

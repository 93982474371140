/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Heading, HFlow, Tag, Theme, VFlow } from 'bold-ui'
import { HLabel } from 'components/HLabel'
import { Telefone } from 'components/label'
import theme from 'config/theme'
import { ResponsavelTecnicoImovel } from 'graphql/types.generated'
import { CSSProperties, Fragment } from 'react'

interface ResponsavelTecnicoProps {
  nomeInstituicao: string
  responsavel: ResponsavelTecnicoImovel
}

export function ResponsavelTecnicoSection({ responsavel, nomeInstituicao }: ResponsavelTecnicoProps) {
  const { nome, cargo, cns, contato } = responsavel
  const { tag, card, headingMargin, hlabelPlaceholder } = createStyles(theme)
  return (
    <Fragment>
      <Heading level={2} style={headingMargin}>
        Contato responsável pela instuituição{nomeInstituicao && ` - ${nomeInstituicao.titleCase()}`}
      </Heading>
      <VFlow style={card}>
        <Tag icon='userFilled' style={tag}>
          Responsável técnico
        </Tag>
        <HLabel title={`${nome.titleCase()} | `} placeholder='Cargo não informado' placeholderStyle={hlabelPlaceholder}>
          {cargo && cargo.titleCase()}
        </HLabel>
        <HFlow>
          <HLabel placeholder='Não informado' title='CNS' placeholderStyle={hlabelPlaceholder}>
            {cns}
          </HLabel>
          <HLabel title='Telefone' placeholder='Não informado' placeholderStyle={hlabelPlaceholder}>
            <Telefone value={contato} />
          </HLabel>
        </HFlow>
      </VFlow>
    </Fragment>
  )
}

const createStyles = (theme: Theme) => ({
  tag: css`
    color: ${theme.pallete.primary.c40};
    background-color: transparent;
    border: 1px solid;
  `,
  card: css`
    border: 1px solid ${theme.pallete.gray.c80};
    padding: 1rem;
  `,
  headingMargin: css`
    margin: 1rem 0;
  `,
  hlabelPlaceholder: {
    fontStyle: 'italic',
    color: `${theme.pallete.text.secondary}`,
  } as CSSProperties,
})

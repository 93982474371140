/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Heading } from 'bold-ui'
import { Fragment } from 'react'

export interface DoSearchPanel {
  isCoordenador: boolean
  equipeResponsavelId: ID
}

export function DoSearchPanel(props: DoSearchPanel) {
  const { isCoordenador, equipeResponsavelId } = props
  const isAbleToBuscar = isCoordenador || equipeResponsavelId

  return (
    <div css={styles.div}>
      {isAbleToBuscar ? (
        <Fragment>
          <Heading level={3}>
            Realize uma busca por cidadãos que tenham algum problema ou <br /> condição ativo ou avaliado.
          </Heading>
          <Heading level={5} css={styles.secondHeading}>
            É possível buscar uma lista de cidadãos a partir do preenchimento de qualquer informação, <br /> não esqueça
            de preencher os filtros obrigatórios.
          </Heading>
        </Fragment>
      ) : (
        <Heading level={3}>
          Seu perfil não possui acesso aos resultados. Para buscar os dados é necessário estar vinculado a uma equipe.
        </Heading>
      )}
    </div>
  )
}

const styles = {
  div: css`
    text-align: center;
    margin-top: 1rem;
    padding-bottom: 4rem;
  `,
  secondHeading: css`
    font-weight: lighter;
    margin-top: 1rem;
  `,
}

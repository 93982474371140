import { Omit } from 'bold-ui'
import { SelectField, SelectFieldProps } from 'components/form/final-form'
import { RendaFamiliar } from 'graphql/types.generated'
import React from 'react'

export interface RendaFamiliarSelectFieldProps
  extends Omit<SelectFieldProps<RendaFamiliar>, 'items' | 'itemToString'> {}

export function RendaFamiliarSelectField(props: RendaFamiliarSelectFieldProps) {
  return <SelectField<RendaFamiliar> items={items} itemToString={itemToString} {...props} />
}
const itemToString = (item: RendaFamiliar) => item && descriptions[item]

const items = [
  RendaFamiliar._1_4_SALARIO_MINIMO,
  RendaFamiliar._1_2_SALARIO_MINIMO,
  RendaFamiliar._1_SALARIO_MINIMO,
  RendaFamiliar._2_SALARIOS_MINIMOS,
  RendaFamiliar._3_SALARIOS_MINIMOS,
  RendaFamiliar._4_SALARIOS_MINIMOS,
  RendaFamiliar.ACIMA_4_SALARIOS_MINIMOS,
  RendaFamiliar.AUSENCIA_RENDA,
]

export const descriptions = {
  _1_4_SALARIO_MINIMO: '1/4 salário mínimo',
  _1_2_SALARIO_MINIMO: '1/2 salário mínimo',
  _1_SALARIO_MINIMO: '1 salário mínimo',
  _2_SALARIOS_MINIMOS: '2 salários mínimos',
  _3_SALARIOS_MINIMOS: '3 salários mínimos',
  _4_SALARIOS_MINIMOS: '4 salários mínimos',
  ACIMA_4_SALARIOS_MINIMOS: 'Acima de 4 salários mínimos',
  AUSENCIA_RENDA: 'Ausência de renda',
}

/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, Icon, Text, Theme, useTheme, VFlow } from 'bold-ui'
import { blue, gray, turquoise } from 'bold-ui/lib/styles/colors'
import { dateFnsDefaultLocale } from 'components/agenda/agendaLocalizer'
import { Box } from 'components/Box'
import { HLabel } from 'components/HLabel'
import { LoadingIndicator } from 'components/loading'
import { format, isSameDay } from 'date-fns'
import { useDetalhesAgendamentoQuery } from 'graphql/hooks.generated'
import { SituacaoAgendadoEnum } from 'graphql/types.generated'
import { useServerTime } from 'hooks/useServerTime'
import { useMemo } from 'react'
import { convertLotacaoAgendamento } from 'view/agenda/converter-agenda'
import { lotacaoAgendamentoToString } from 'view/agenda/util-agenda'

import { EventoAgendaLotacao, situacaoAgendamentoRecord } from '../../model-agenda'
import { AgendamentoFooter } from '../AgendamentoFooter'
import { BoxResumoCidadao } from './BoxResumoCidadao'
import { DetalhesAgendamentoCompartilhado } from './DetalhesAgendamentoCompartilhado'

export interface DetalhesEventoAgendamentoProps {
  event: EventoAgendaLotacao
  isAgendaOnlineAtiva: boolean
  onEnviarEmailButtonClick(): void
}

export function DetalhesEventoAgendamento({
  event,
  isAgendaOnlineAtiva,
  onEnviarEmailButtonClick,
}: DetalhesEventoAgendamentoProps) {
  const {
    loading,
    data: {
      agendado: {
        lotacaoAgendada,
        responsavelCriador,
        dataCriacao,
        cidadao,
        lotacaoParticipante,
        videochamada,
        agendamentoPrincipal,
      } = {},
    } = {},
  } = useDetalhesAgendamentoQuery({ variables: { id: event.id } })

  const cidadaoAgendamentoCompartilhado = cidadao ?? agendamentoPrincipal?.cidadao

  const lotacaoParticipanteModel = useMemo(() => convertLotacaoAgendamento(lotacaoParticipante), [lotacaoParticipante])

  const theme = useTheme()
  const { getServerTimeNow } = useServerTime()
  const styles = useMemo(
    () => createStyles(theme, event.situacao, event.isForaUbs && isSameDay(event.end, getServerTimeNow())),
    [event.end, event.isForaUbs, event.situacao, getServerTimeNow, theme]
  )
  return (
    <Box style={styles.box}>
      {loading ? (
        <LoadingIndicator />
      ) : (
        <VFlow vSpacing={0.7}>
          <HFlow hSpacing={0.5}>
            <Icon size={1} icon='calendarFilled' color={blue.c50} />
            <Text component='p'>
              <Text fontWeight='bold'>
                {`${format(event.start, 'dd/MM/yyyy')} - ${format(event.start, "eeee 'das' HH:mm", {
                  locale: dateFnsDefaultLocale,
                }).capitalize()}
              às ${format(event.end, 'HH:mm')}`}
              </Text>
              {lotacaoParticipante && <Text> | Agendamento entre profissionais</Text>}
              {event.isForaUbs && <Text> | Fora da UBS - {event.localAtendimento}</Text>}
            </Text>
          </HFlow>

          <VFlow vSpacing={0.7}>
            {agendamentoPrincipal?.lotacaoAgendada ? (
              <HLabel title='Profissional anfitrião:'>
                {lotacaoAgendamentoToString({
                  nomeProfissional: agendamentoPrincipal.lotacaoAgendada.profissional.nome,
                  nomeCbo: agendamentoPrincipal.lotacaoAgendada.cbo.nome,
                  ineEquipe: agendamentoPrincipal.lotacaoAgendada.equipe?.ine,
                  nomeEquipe: agendamentoPrincipal.lotacaoAgendada.equipe?.nome,
                })}
              </HLabel>
            ) : (
              <HLabel title='Profissional:'>
                {lotacaoAgendamentoToString({
                  nomeProfissional: lotacaoAgendada?.profissional?.nome,
                  nomeCbo: lotacaoAgendada?.cbo?.nome,
                  ineEquipe: lotacaoAgendada?.equipe?.ine,
                  nomeEquipe: lotacaoAgendada?.equipe?.nome,
                })}
              </HLabel>
            )}
            {!!lotacaoParticipante && (
              <DetalhesAgendamentoCompartilhado
                lotacaoParticipante={lotacaoParticipanteModel}
                videochamadaUuid={videochamada?.uuid}
                onEnviarEmailButtonClick={onEnviarEmailButtonClick}
              />
            )}
            {!!event.observacoes && (
              <HLabel title='Observações:'>
                <Text fontStyle='italic'>{event.observacoes}</Text>
              </HLabel>
            )}
            {cidadaoAgendamentoCompartilhado && <BoxResumoCidadao cidadao={cidadaoAgendamentoCompartilhado} />}
          </VFlow>
          <AgendamentoFooter
            responsavelCriador={responsavelCriador}
            origem={event.origem}
            dataCriacao={dataCriacao}
            isAgendaOnlineAtiva={isAgendaOnlineAtiva}
            situacaoSincronizacao={event.situacaoSincronizacao}
          />
        </VFlow>
      )}
    </Box>
  )
}

const createStyles = (theme: Theme, status: SituacaoAgendadoEnum, isAguardandoRegistro: boolean) => ({
  box: css`
    width: 52rem;
    box-shadow: ${theme.shadows.outer[160]};
    border-color: ${(isAguardandoRegistro ? turquoise : situacaoAgendamentoRecord[status]?.cor)?.c60 ?? gray.c60};
    margin-top: 2px;
  `,
})

/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, Grid, Heading, HFlow, Icon, Text, Theme, Tooltip, useTheme } from 'bold-ui'
import { Box, BoxProps } from 'components/Box'
import { useCallback, useEffect, useRef, useState } from 'react'
import { formatHoursMinutes } from 'util/date/formatDate'

import { ChatMessage, SUPPORTS_VIRTUAL_KEYBOARD } from '../model-videochamada'
import { ChatTextInput } from './ChatTextInput'

export interface ChatVideochamadaProps extends BoxProps {
  messages: ChatMessage[]
  onSendMessage(message: string): void
  open: boolean
  onClose?(): void
  hasScrolledToLastMessage: boolean
  onHasScrolledToLastMessage(scrolled: boolean): void
  scrolledToLastMessage: boolean
  onScrolledToLastMessage(scrolled: boolean): void
  scrollDelay?: number
  isMobile?: boolean
  messageInput: string
  onChangeInput(message: string): void
  animated?: boolean
}

const SCROLL_CONFIDENCE_INTERVAL = 0.975

export function ChatVideochamada(props: ChatVideochamadaProps) {
  const {
    open,
    onClose,
    onSendMessage,
    messages,
    scrollDelay = 300,
    style: externalStyles,
    hasScrolledToLastMessage,
    onHasScrolledToLastMessage,
    scrolledToLastMessage,
    onScrolledToLastMessage,
    isMobile = false,
    animated = false,
    messageInput,
    onChangeInput,
    ...rest
  } = props

  const messagesContainerRef = useRef<HTMLDivElement>()

  const theme = useTheme()
  const styles = createStyles(theme, isMobile, hasScrolledToLastMessage)

  const [autoScrollToLastMessage, setAutoScrollToLastMessage] = useState(true)

  const scrollToBottom = useCallback(() => {
    const element = messagesContainerRef.current

    if (element) element.scrollTop = element.scrollHeight
  }, [messagesContainerRef])

  useEffect(() => {
    const timeOutDelay = hasScrolledToLastMessage ? 100 : scrollDelay
    var scrollProcess
    if (autoScrollToLastMessage && messages.length) {
      scrollProcess = setTimeout(scrollToBottom, scrollDelay)
    }

    var checkScrollEndProcess
    const checkScrollEnd = () => {
      if (!scrolledToLastMessage) {
        checkScrollEndProcess = setTimeout(() => {
          onScrolledToLastMessage(true)
          if (!hasScrolledToLastMessage) onHasScrolledToLastMessage(true)
        }, timeOutDelay + 50)
      }
    }

    const messagesContainerRefCurrent = messagesContainerRef.current

    messagesContainerRefCurrent?.addEventListener('scrollend', checkScrollEnd)

    if (!open) setAutoScrollToLastMessage(true)

    return () => {
      clearTimeout(scrollProcess)
      clearTimeout(checkScrollEndProcess)
      messagesContainerRefCurrent?.removeEventListener('scrollend', checkScrollEnd)
    }
  }, [
    autoScrollToLastMessage,
    hasScrolledToLastMessage,
    messages.length,
    open,
    scrollDelay,
    scrolledToLastMessage,
    onHasScrolledToLastMessage,
    onScrolledToLastMessage,
    scrollToBottom,
  ])

  const handleScrollMessages = (ev) => {
    const element = ev.currentTarget
    const scrollMax = element?.scrollHeight - element?.clientHeight
    const newAutoScrollToLastMessageState = element?.scrollTop >= scrollMax * SCROLL_CONFIDENCE_INTERVAL
    if (newAutoScrollToLastMessageState !== autoScrollToLastMessage)
      setAutoScrollToLastMessage(newAutoScrollToLastMessageState)
  }

  const handleSendMessage = (message: string) => {
    onSendMessage(message)
    setTimeout(scrollToBottom, scrollDelay)
  }

  return (
    (scrolledToLastMessage || !hasScrolledToLastMessage || !animated) && (
      <Box style={styles.chat} {...rest}>
        <div>
          <HFlow justifyContent={isMobile ? 'flex-start' : 'flex-end'} alignItems='center'>
            <Tooltip text='Ocultar mensagens'>
              <Icon
                style={styles.closeIcon}
                icon={isMobile ? 'arrowLeft' : 'timesDefault'}
                color={isMobile ? 'white' : null}
                onClick={onClose}
              />
            </Tooltip>
          </HFlow>
          <Heading level={1} style={[styles.headingMobile, styles.textMobile]}>
            Mensagens
          </Heading>
        </div>
        <div css={[styles.messages, externalStyles]} ref={messagesContainerRef} onScroll={handleScrollMessages}>
          {messages.map(({ sender, time, content, local }) => (
            <div css={local && styles.messageGap} key={sender + '-' + time}>
              <Grid gap={0}>
                <Cell style={styles.senderContainer}>
                  <Heading level={5} style={[styles.sender, styles.textMobile]} fontWeight='bold'>
                    {local ? 'Você' : sender}
                  </Heading>
                </Cell>
                <Cell style={styles.senderTime}>
                  <Text style={styles.messageTime}>&nbsp;| {formatHoursMinutes(time)}</Text>
                </Cell>
              </Grid>
              <Text style={styles.textMobile}>{content}</Text>
            </div>
          ))}
        </div>
        <ChatTextInput onSend={handleSendMessage} isMobile={isMobile} value={messageInput} onChange={onChangeInput} />
      </Box>
    )
  )
}

const createStyles = (theme: Theme, isMobile: boolean, hasScrolledToLastMessage: boolean) => ({
  closeIcon: css`
    cursor: pointer;
  `,
  parentChat: css`
    display: contents;
  `,
  messages: css`
    flex: 1 1 auto;
    overflow-y: auto;
    height: 0px;
    gap: 0.5rem;
    overflow-wrap: break-word;
    margin-right: 0;
    margin-left: 0;
    padding-right: 1rem;
    padding-left: 1rem;
    scroll-behavior: ${hasScrolledToLastMessage ? 'auto' : 'smooth'};

    display: flex;
    flex-direction: column;

    & > div:first-of-type {
      margin-top: ${isMobile && !SUPPORTS_VIRTUAL_KEYBOARD ? 'auto' : '0'} !important;
    }
    & > div:last-of-type {
      margin-bottom: 0.25rem !important;
    }
  `,
  chat: css`
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 1rem;
    padding: 1rem 0;
    overflow: hidden;
    border-radius: 0.25rem;
    border: none;
    background-color: ${isMobile && theme.pallete.gray.c10};

    > *:not(:nth-child(2)) {
      margin-right: 1rem;
      margin-left: 1rem;
    }
  `,
  sender: css`
    max-width: 10rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
  senderContainer: css`
    max-width: calc(100% - 3rem);
  `,
  senderTime: css`
    width: 3rem;
  `,
  messageTime: css`
    color: ${isMobile ? theme.pallete.gray.c70 : theme.pallete.gray.c40};
  `,
  messageGap: css`
    margin-bottom: 0.25rem;
  `,
  ...(isMobile && {
    textMobile: css`
      color: ${theme.pallete.gray.c100};
    `,
    headingMobile: css`
      margin-top: -2rem;
      margin-left: 3rem;
    `,
  }),
})

import { Cell, Grid } from 'bold-ui'
import { DateField, NumberField, TextField, UfSelectField } from 'components/form'
import { useServerTime } from 'hooks/useServerTime'
import React from 'react'
import { MetaPath } from 'util/metaPath'

import { CidadaoAldeadoFormModel } from '../types/model-cidadao'

export interface RaniFieldGroupProps {
  name: MetaPath<CidadaoAldeadoFormModel>
}

export function RaniFieldGroup(props: RaniFieldGroupProps) {
  const { name } = props
  const { getServerTimeNow } = useServerTime()
  const now = getServerTimeNow()

  return (
    <Grid wrap>
      <Cell size={3}>
        <TextField name={name.unidadeFunai} label='Unidade da FUNAI' maxLength={40} />
      </Cell>
      <Cell size={3}>
        <NumberField name={name.livro} label='Livro' maxLength={8} />
      </Cell>
      <Cell size={3}>
        <NumberField name={name.folha} label='Folha' maxLength={4} />
      </Cell>
      <Cell size={3}>
        <NumberField name={name.cadastroUnico} label='Nº Cadastro Único' maxLength={20} />
      </Cell>
      <Cell size={3}>
        <DateField name={name.dtEmissao} maxDate={now} label='Data de emissão' />
      </Cell>
      <Cell size={3}>
        <UfSelectField name={name.ufNascimento} label='UF' />
      </Cell>
    </Grid>
  )
}

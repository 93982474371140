import { Cell, Grid, Heading, VFlow } from 'bold-ui'
import { DateField, Form, NumberField, RichTextField } from 'components/form'
import { LotacaoEditableTable } from 'components/form/editable-table/LotacaoEditableTable'
import { LotacaoResponsavelSection } from 'components/lotacao-responsavel/LotacaoResponsavelSection'
import { subMonths } from 'date-fns'
import { css } from 'emotion'
import { useServerTime } from 'hooks/useServerTime'
import React from 'react'
import { metaPath } from 'util/metaPath'

import { LocalAtividadeField } from '../componentes/LocalAtividadeField'
import { TemaReuniaoSelectField } from '../componentes/TemaReuniaoSelectField'
import { TipoAtividadeSelectField } from '../componentes/TipoAtividadeSelectField'
import { TurnoSelectField } from '../componentes/TurnoSelectField'
import { AtividadeColetivaFormModel } from '../model-atividadeColetiva'

const path = metaPath<AtividadeColetivaFormModel>()

interface AtividadeColetivaReuniaoFormProps {
  initialValues?: AtividadeColetivaFormModel
}

export default function AtividadeColetivaReuniaoForm(props: AtividadeColetivaReuniaoFormProps) {
  const { initialValues } = props
  const { getServerTimeNow } = useServerTime()
  const serverTime = getServerTimeNow()

  const renderForm = () => (
    <Grid gap={2}>
      <Cell size={12}>
        <VFlow>
          <Heading level={3}>Responsabilidade de registro</Heading>
          <LotacaoResponsavelSection name={path.lotacaoResponsavel} />
        </VFlow>
      </Cell>

      <Cell xs={3} lg={2} style={styles.section}>
        <DateField
          name={path.dataAtividade}
          label='Data da atividade'
          title='Data da atividade'
          minDate={subMonths(serverTime, 12)}
          maxDate={serverTime}
          required
        />
      </Cell>

      <Cell size={2} style={styles.section}>
        <TurnoSelectField name={path.turno} label='Turno' title='Turno' required />
      </Cell>

      <Cell size={2} style={styles.section}>
        <NumberField
          name={path.numeroParticipantes}
          label='Nº de participantes'
          title='Nº de participantes'
          placeholder='Informe o número'
          min={1}
          max={999}
          maxLength={3}
          required
        />
      </Cell>

      <Cell size={8}>
        <LocalAtividadeField name={path.localAtividade} />
      </Cell>

      <Cell size={12} style={styles.section}>
        <Heading level={2} style={styles.title}>
          Profissionais envolvidos
        </Heading>
      </Cell>

      <Cell size={12}>
        <LotacaoEditableTable name={path.lotacoes} required />
      </Cell>

      <Cell size={12} style={styles.section}>
        <Heading level={2} style={styles.title}>
          Dados da atividade
        </Heading>
      </Cell>

      <Cell size={4}>
        <TipoAtividadeSelectField
          name={path.tipoAtividade}
          label='Tipo da atividade'
          title='Tipo da atividade'
          isReuniao
          required
        />
      </Cell>

      <Cell size={4}>
        <TemaReuniaoSelectField
          name={path.temaReuniao}
          label='Temas da reunião'
          title='Temas da reunião'
          multiple
          required
        />
      </Cell>

      <Cell size={12}>
        <RichTextField
          name={path.anotacoes}
          label='Anotações da atividade'
          placeholder='Insira as anotações da atividade'
          maxLength={4000}
          height={6}
        />
      </Cell>
    </Grid>
  )

  // TODO Foundation: implementar o onSubmit na issue #21433
  return <Form<AtividadeColetivaFormModel> initialValues={initialValues} render={renderForm} onSubmit={undefined} />
}

const styles = {
  section: css`
    margin-top: 1.5rem;
  `,
  title: css`
    margin-bottom: 0.5rem;
  `,
}

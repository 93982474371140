import { Alert, Cell, Grid } from 'bold-ui'
import { FormRenderProps, TelefoneField, TextField } from 'components/form'
import { usePecField } from 'components/form/final-form/hooks/useField'
import { getFieldError } from 'components/form/final-form/util'
import React from 'react'
import { MetaPath } from 'util/metaPath'
import { createValidator, email, maxLength, telefone } from 'util/validation'

import { ContatosFormModel } from '../types/model-cidadao'

export const validate = createValidator<ContatosFormModel>({
  telefoneResidencial: [telefone],
  telefoneCelular: [telefone],
  telefoneContato: [telefone],
  email: [email, maxLength(50)],
})

export interface ContatosGroupFieldGroupProps {
  name: MetaPath<ContatosFormModel>
  formProps: FormRenderProps<any>
  required?: boolean
}

export function ContatosFieldGroup(props: ContatosGroupFieldGroupProps) {
  const { name, required = true } = props
  const { meta } = usePecField({ name: 'contato.telefones' })
  const hasError = getFieldError(meta)
  return (
    <Grid wrap>
      <Cell size={12}>
        {required && (
          <Alert type={hasError ? 'danger' : 'info'} inline>
            É obrigatório o preenchimento de pelo menos um telefone.
          </Alert>
        )}
      </Cell>
      <Cell size={2}>
        <TelefoneField name={name.telefoneResidencial} label='Telefone residencial' />
      </Cell>
      <Cell size={2}>
        <TelefoneField name={name.telefoneCelular} label='Telefone celular' />
      </Cell>
      <Cell size={2}>
        <TelefoneField name={name.telefoneContato} label='Telefone de contato' />
      </Cell>
      <Cell size={6} />
      <Cell size={6}>
        <TextField lowercase name={name.email} label='E-mail' maxLength={50} />
      </Cell>
    </Grid>
  )
}

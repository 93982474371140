/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, Grid, HFlow, Icon, VFlow } from 'bold-ui'
import { LogoutLinkWrapper } from 'components/auth/LogoutLinkWrapper'
import useSession from 'components/auth/useSession'
import { Breadcrumb } from 'components/breadcrumb'
import { FormFooter } from 'components/form/FormFooter'
import { PageContent } from 'components/layout/PageContent'
import { PageHeader } from 'components/PageHeader'
import { ButtonLink } from 'components/route'
import { useFlags } from 'config/useFlagsContext'
import { useAceitarTermosUsoMutation } from 'graphql/hooks.generated'
import { useConfiguracoes } from 'hooks/configuracoes'
import { useCookieConfig } from 'hooks/useCookieConfig'
import { useMemo } from 'react'
import { useHistory } from 'react-router'

import { TermosDeUsoSideMenu } from './components/TermosDeUsoSideMenu'
import { BaseLegalParaTratamentoDeDadosPessoaisSection } from './sections/politica-de-privacidade/BaseLegalParaTratamentoDeDadosPessoaisSection'
import { CompartilhamentoDeDadosSection } from './sections/politica-de-privacidade/CompartilhamentoDeDadosSection'
import { ControladorSection } from './sections/politica-de-privacidade/ControladorSection'
import { CookiesSection } from './sections/politica-de-privacidade/CookiesSection'
import { DadosSaoTratadosSection } from './sections/politica-de-privacidade/DadosSaoTratadosSection'
import { DefinicoesSection } from './sections/politica-de-privacidade/DefinicoesSection'
import { DireitosDoTitularDeDadosPessoaisSection } from './sections/politica-de-privacidade/DireitosDoTitularDeDadosPessoaisSection'
import { EncarregadoSection } from './sections/politica-de-privacidade/EncarregadoSection'
import { FinalidadeDeTratamentoDosDadosSection } from './sections/politica-de-privacidade/FinalidadeDeTratamentoDosDadosSection'
import { MudancasNaPoliticaDePrivacidadeSection } from './sections/politica-de-privacidade/MudancasNaPoliticaDePrivacidadeSection'
import { OperadorSection } from './sections/politica-de-privacidade/OperadorSection'
import { SegurancaDosDadosSection } from './sections/politica-de-privacidade/SegurancaDosDadosSection'
import { TransferenciaInternacionalDeDadosSection } from './sections/politica-de-privacidade/TransferenciaInternacionalDeDadosSection'
import { AceitacaoTermosDeUsoSection } from './sections/termos-de-uso/AceitacaoTermosDeUsoSection'
import { ArcaboucoLegalSection } from './sections/termos-de-uso/ArcaboucoLegalSection'
import { DefinicoesDoTermoDeUsoSection } from './sections/termos-de-uso/DefinicoesDoTermoDeUsoSection'
import { DescricaoDoServicoSection } from './sections/termos-de-uso/DescricaoDoServicoSection'
import { DireitosDoUsuarioDoServicoSection } from './sections/termos-de-uso/DireitosDoUsuarioDoServicoSection'
import { ForoSection } from './sections/termos-de-uso/ForoSection'
import { InformacoesParaContatoSection } from './sections/termos-de-uso/InformacoesParaContatoSection'
import { MudancasNoTermoDeUsoSection } from './sections/termos-de-uso/MudancasNoTermoDeUsoSection'
import { ResponsabilidadeDaAdministracaoPublicaSection } from './sections/termos-de-uso/ResponsabilidadeDaAdministracaoPublicaSection'
import { ResponsabilidadesDoUsuarioSection } from './sections/termos-de-uso/ResponsabilidadesDoUsuarioSection'
import { TermosDeUsoSimplificado } from './sections/TermosDeUsoSimplificadoSection'

export const TermosDeUsoView = () => {
  const session = useSession({ fetchPolicy: 'no-cache' })

  const history = useHistory()

  const handleClickVoltarTelaInicial = () => history.push('/')

  const { cookiesEnabled } = useCookieConfig()

  const faltaAceitarTermos = session.data && !session.data.profissional.usuario.aceitouTermosUso

  const styles = useMemo(() => createStyles(cookiesEnabled), [cookiesEnabled])

  const [aceitarTermos] = useAceitarTermosUsoMutation()

  const handleAceitarTermos = () => aceitarTermos().then(handleClickVoltarTelaInicial)

  const {
    configuracoes: { versao },
  } = useConfiguracoes()

  const { PILOTO_BOLSA_FAMILIA_ENABLED } = useFlags()
  const DATA_ATUALIZACAO = PILOTO_BOLSA_FAMILIA_ENABLED ? '02/07/2024' : '15/01/2024'

  if (!versao) {
    return null
  }

  const versaoAndDataAtualizacao = {
    dataAtualizacao: DATA_ATUALIZACAO,
    versao: versao,
    formattedString: `Versão ${versao} | Última atualização em ${DATA_ATUALIZACAO}`,
  }

  return (
    <PageContent type='filled' style={styles.pageContentTermosUso}>
      {!faltaAceitarTermos && (
        <Button onClick={handleClickVoltarTelaInicial} style={styles.button}>
          <Icon icon='arrowLeft' style={styles.icon} />
          Voltar à tela inicial
        </Button>
      )}

      <HFlow hSpacing={2} style={styles.hFlowPageHeaderContent}>
        <PageHeader title='Termos de Uso e Política de Privacidade' />
      </HFlow>

      <Grid>
        <Cell size={3} style={styles.cellSideMenu}>
          <TermosDeUsoSideMenu />
        </Cell>

        <Cell size={7}>
          <Breadcrumb title='Termos de uso' />
          <VFlow vSpacing={2}>
            <PageHeader title='Termos de Uso' subtitle={versaoAndDataAtualizacao.formattedString} />

            <TermosDeUsoSimplificado faltaAceitarTermos={faltaAceitarTermos} />

            <AceitacaoTermosDeUsoSection />

            <DefinicoesDoTermoDeUsoSection />

            <ArcaboucoLegalSection
              tableCellStyle={styles.col50}
              pilotoBolsaFamiliaEnabled={PILOTO_BOLSA_FAMILIA_ENABLED}
            />

            <DescricaoDoServicoSection />

            <DireitosDoUsuarioDoServicoSection listStyle={styles.lista} />

            <ResponsabilidadesDoUsuarioSection listStyle={styles.lista} />

            <ResponsabilidadeDaAdministracaoPublicaSection />

            <MudancasNoTermoDeUsoSection versaoAndDataAtualizacao={versaoAndDataAtualizacao} />

            <InformacoesParaContatoSection />

            <ForoSection />
          </VFlow>

          <VFlow vSpacing={2} style={styles.vflowPageContent}>
            <PageHeader
              title='Política de Privacidade'
              subtitle={'Versão ' + versao}
              style={styles.pageContentePoliticaPrivacidade}
            />

            <DefinicoesSection />

            <BaseLegalParaTratamentoDeDadosPessoaisSection listStyle={styles.lista} />

            <ControladorSection />

            <OperadorSection />

            <EncarregadoSection />

            <DireitosDoTitularDeDadosPessoaisSection listStyle={styles.lista} />

            <DadosSaoTratadosSection pilotoBolsaFamiliaEnabled={PILOTO_BOLSA_FAMILIA_ENABLED} />

            <FinalidadeDeTratamentoDosDadosSection />

            <CompartilhamentoDeDadosSection listStyle={styles.lista} />

            <TransferenciaInternacionalDeDadosSection />

            <SegurancaDosDadosSection />

            <CookiesSection tableCellStyles={{ col25: styles.col25, col50: styles.col50 }} />

            <MudancasNaPoliticaDePrivacidadeSection versaoAndDataAtualizacao={versaoAndDataAtualizacao} />

            {faltaAceitarTermos && (
              <Button kind='primary' onClick={handleAceitarTermos}>
                Declaro que li e estou ciente dos termos de uso
              </Button>
            )}
          </VFlow>
          {faltaAceitarTermos && (
            <FormFooter>
              <LogoutLinkWrapper>
                <Button>Sair</Button>
              </LogoutLinkWrapper>
            </FormFooter>
          )}
          {!session.data && (
            <FormFooter>
              <ButtonLink style={styles.buttonLink} to='/'>
                Voltar
              </ButtonLink>
            </FormFooter>
          )}
        </Cell>
      </Grid>
    </PageContent>
  )
}

const createStyles = (aceitouCookies: boolean) => ({
  pageContentTermosUso: css`
    margin-top: 2rem;
    margin-bottom: ${!aceitouCookies ? '8rem' : '2rem'};
  `,
  vflowPageContent: css`
    width: 640px;
    text-align: left;
    font-size: 0.875rem;
  `,
  pageContentePoliticaPrivacidade: css`
    margin-top: 4rem;
  `,
  cellSideMenu: css`
    padding-left: 0 !important;
  `,
  hFlowPageHeaderContent: css`
    margin-top: 1rem;
    margin-bottom: 1rem;
  `,
  button: css`
    padding: 0.5rem;
    margin-bottom: 1rem;
  `,
  icon: css`
    margin-right: 0.5rem;
  `,
  buttonLink: css`
    padding-left: 2rem;
    padding-right: 2rem;
  `,
  col50: css`
    width: 50%;
    padding: 0.5rem 1rem;
  `,
  col25: css`
    width: 25%;
    padding: 0.5rem 1rem;
  `,
  lista: css`
    margin: 0 !important;
    line-height: 1.5;
  `,
})

/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow, Text, VFlow } from 'bold-ui'
import { DateTime } from 'components/date'
import { TableBox } from 'components/table'
import theme from 'config/theme'
import { PeriodoAusencia } from 'graphql/types.generated'

interface PeriodoAusenciaTableProps {
  periodoAusenciaList: PeriodoAusencia[]
  onlyTable?: boolean
}

export function PeriodoAusenciaTable({ periodoAusenciaList, onlyTable = false }: PeriodoAusenciaTableProps) {
  const hasPeriodoAusencia = periodoAusenciaList && periodoAusenciaList.length > 0

  return (
    <TableBox>
      <VFlow vSpacing={0} style={[styles.content, !onlyTable && styles.contentMaxHeight]}>
        <Table style={!onlyTable && styles.table}>
          <TableHead>
            <TableRow>
              <TableHeader>Data da ausência</TableHeader>
              <TableHeader>Data do retorno</TableHeader>
            </TableRow>
          </TableHead>
          {hasPeriodoAusencia && (
            <TableBody>
              {periodoAusenciaList.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>
                    <DateTime format='DD/MM/YYYY' value={item.dtAusencia} />
                  </TableCell>
                  <TableCell>
                    {item.dtRetorno ? <DateTime format='DD/MM/YYYY' value={item.dtRetorno} /> : <Text>{'-'}</Text>}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
        {!hasPeriodoAusencia && (
          <VFlow style={[styles.notFound, onlyTable && styles.notFoundBorder]}>
            <Text fontStyle='italic'>Nenhum registro encontrado</Text>
          </VFlow>
        )}
      </VFlow>
    </TableBox>
  )
}

const styles = {
  heading: css`
    padding: 0.5rem 0;
  `,
  content: css`
    overflow: auto;
  `,
  contentMaxHeight: css`
    max-height: 12.2rem;
  `,
  table: css`
    border-top: 0;
    border-left: 0;
    border-right: 0;
  `,
  notFound: css`
    align-items: center;
    justify-content: center;
    padding: 1rem;
  `,
  notFoundBorder: css`
    border: ${`1px solid ${theme.pallete.divider}`};
    border-top: 0px;
  `,
}

/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Heading, VFlow } from 'bold-ui'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import useSession from 'components/auth/useSession'
import { Breadcrumb } from 'components/breadcrumb/Breadcrumb'
import { PageContainer } from 'components/layout/PageContainer'
import { PageContent } from 'components/layout/PageContent'
import { useAtendimentosFinalizadosQuery } from 'graphql/hooks.generated'
import { Fragment, useState } from 'react'
import { grupoCboAtendimento } from 'view/cidadao/acessos'

import {
  AssinaturaEletronicaAtendInfo,
  AssinaturaEletronicaAtendTable,
  AssinaturaEletronicaAtendTableFilter,
} from './components'
import { convertAssinaturaFilterModelToInput } from './converter'
import {
  AssinaturaEletronicaAtendTableFilterModel,
  CriterioExibicaoAtendimento,
  MAXIMO_ATENDIMENTOS_PAGINA,
} from './model'

export default function AssinaturaEletronicaAtendView() {
  const { hasCboAuth } = useSession()
  const { acesso } = useAcessoLotacaoOrEstagio()
  const unidadeSaudeId = acesso.unidadeSaude.id
  const isCoordenador = !hasCboAuth(grupoCboAtendimento)

  const [currentFilter, setCurrentFilter] = useState<AssinaturaEletronicaAtendTableFilterModel>({
    criterioExibicao: CriterioExibicaoAtendimento.TODOS_OS_ATENDIMENTOS,
    lotacao: isCoordenador ? undefined : acesso,
    pageParams: {
      page: 0,
      size: MAXIMO_ATENDIMENTOS_PAGINA,
    },
  })

  const { data, loading } = useAtendimentosFinalizadosQuery({
    variables: { input: convertAssinaturaFilterModelToInput(currentFilter) },
  })

  return (
    <Fragment>
      <Breadcrumb title='Assinatura eletrônica' />

      <PageContainer
        fluid
        style={css`
          padding: 2rem;
        `}
      >
        <VFlow vSpacing={2}>
          <Heading level={1}>Assinatura eletrônica</Heading>
          <AssinaturaEletronicaAtendTableFilter
            initialValues={currentFilter}
            onChange={setCurrentFilter}
            isCoordenador={isCoordenador}
            unidadeSaudeId={unidadeSaudeId}
          />
        </VFlow>
      </PageContainer>

      <PageContent fluid>
        <VFlow>
          <AssinaturaEletronicaAtendInfo
            periodo={currentFilter.periodo}
            equipe={currentFilter.equipe}
            lotacao={currentFilter.lotacao}
            isCoordenador={isCoordenador}
          />
          <AssinaturaEletronicaAtendTable
            isCoordenador={isCoordenador}
            data={data}
            loading={loading}
            setCurrentFilter={setCurrentFilter}
          />
        </VFlow>
      </PageContent>
    </Fragment>
  )
}

import { NacionalidadeEnum, TipoLocalNascimentoEnum } from 'graphql/types.generated'
import { calculateAge } from 'util/date/calculateAge'
import {
  afterEqualTo,
  beforeEqualToday,
  createValidator,
  ErrorObject,
  maxLength,
  required,
  validate,
} from 'util/validation'

import CidadaoFormModel, { CidadaoAldeadoFormModel } from '../types/model-cidadao'

export const validateCidadaoAldeado = (cidadaoValues: CidadaoFormModel) =>
  createValidator<CidadaoAldeadoFormModel>(
    {
      dtAusencia: [beforeEqualToday],
      dtRetorno: [beforeEqualToday],
      dtReconhecimento: [required, beforeEqualToday],
      stChefeFamilia: [required],
      nomeTradicional: [maxLength(70)],
      responsavelLegal: [maxLength(70)],
      unidadeFunai: [maxLength(40)],
      cadastroUnico: [maxLength(20)],
      livro: [maxLength(8)],
      folha: [maxLength(4)],
    },
    (
      cidadaoAldeadoValues: CidadaoAldeadoFormModel,
      errors: ErrorObject<CidadaoAldeadoFormModel>
    ): ErrorObject<CidadaoAldeadoFormModel> => {
      if (cidadaoAldeadoValues.dtRetorno) {
        if (!cidadaoAldeadoValues.dtAusencia) {
          errors = {
            ...errors,
            dtAusencia: validate(cidadaoAldeadoValues.dtAusencia, required),
          }
        }

        errors = {
          ...errors,
          dtRetorno:
            afterEqualTo(cidadaoAldeadoValues.dtRetorno, cidadaoAldeadoValues.dtAusencia, 'data da ausência') ||
            errors.dtRetorno,
        }
      }

      if (cidadaoAldeadoValues.dtAusencia) {
        const periodoAusenciaList = cidadaoValues.periodoAusenciaList

        if (periodoAusenciaList && periodoAusenciaList.length > 0) {
          errors = {
            ...errors,
            dtAusencia:
              afterEqualTo(
                cidadaoAldeadoValues.dtAusencia,
                cidadaoValues.periodoAusenciaList[0].dtRetorno,
                'ultima data do retorno'
              ) || errors.dtAusencia,
          }
        } else {
          errors = {
            ...errors,
            dtAusencia:
              afterEqualTo(cidadaoAldeadoValues.dtAusencia, cidadaoValues.dataNascimento, 'data de nascimento') ||
              errors.dtAusencia,
          }
        }
      }

      errors = {
        ...errors,
        dtReconhecimento:
          afterEqualTo(cidadaoAldeadoValues.dtReconhecimento, cidadaoValues.dataNascimento, 'data de nascimento') ||
          errors.dtReconhecimento,
      }

      if (
        NacionalidadeEnum.BRASILEIRA === cidadaoValues.nacionalidade &&
        TipoLocalNascimentoEnum.ALDEIA === cidadaoValues.tipoLocalNascimento &&
        cidadaoValues.dataNascimento &&
        calculateAge(cidadaoValues.dataNascimento).years < 1
      ) {
        errors = {
          ...errors,
          aldeiaNascimento: validate(cidadaoAldeadoValues.aldeiaNascimento, required),
          localOcorrencia: validate(cidadaoAldeadoValues.localOcorrencia, required),
        }
      }

      return errors
    }
  )

/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, Grid, Heading, HFlow, InfoLabel, Theme } from 'bold-ui'
import {
  tipoAbastecimentoAguaEnumRecord,
  tipoAcessoDomicilioEnumRecord,
  tipoDestinoLixoEnumRecord,
  tipoEscoamentoSanitarioEnumRecord,
  tipoLocalizacaoEnumRecord,
  tipoMaterialParedeEnumRecord,
  tipoPosseTerraEnumRecord,
  tipoSituacaoMoradiaEnumRecord,
  tipoTratamentoAguaEnumRecord,
} from 'components/form/field/select/CondicoesMoradiaSelectFields/condicoes-moradia-model'
import { HLabel } from 'components/HLabel'
import theme from 'config/theme'
import { AnimaisImovel, CondicoesMoradia, TipoLocalizacaoEnum } from 'graphql/types.generated'
import { CSSProperties } from 'react'
import { isUndefinedOrNull } from 'util/checks'

import { ReactComponent as AbastecimentoAgua } from '../../../images/visualizacao-imovel/abastecimento-agua.svg'
import { ReactComponent as AguaConsumo } from '../../../images/visualizacao-imovel/agua-consumo.svg'
import { ReactComponent as DestinoLixo } from '../../../images/visualizacao-imovel/destino-lixo.svg'
import { ReactComponent as EscoamentoBanheiro } from '../../../images/visualizacao-imovel/escoamento-banheiro.svg'
import { ReactComponent as MaterialPredominante } from '../../../images/visualizacao-imovel/material-predominante.svg'
import { ReactComponent as PossuiAnimais } from '../../../images/visualizacao-imovel/possui-animais.svg'
import { ReactComponent as PossuiEletricidade } from '../../../images/visualizacao-imovel/possui-eletrecidade.svg'
import { ReactComponent as TipoAcesso } from '../../../images/visualizacao-imovel/tipo-acesso.svg'

interface CondicoesMoradiaSectionProps {
  condicoesMoradia: CondicoesMoradia
  animais: AnimaisImovel
}

export function CondicoesMoradiaSection({ condicoesMoradia, animais }: CondicoesMoradiaSectionProps) {
  const {
    tipoAbastecimentoAgua,
    tipoTratamentoAgua,
    tipoPosseTerra,
    tipoMaterialParede,
    tipoLocalizacao,
    tipoEscoamentoSanitario,
    tipoDestinoLixo,
    tipoAcessoDomicilio,
    tipoSituacaoMoradia,
    isEnergiaEletricaDisponivel,
    numeroComodos,
  } = condicoesMoradia ?? {}
  const {
    possuiAnimais,
    possuiGato,
    possuiCachorro,
    possuiPassaro,
    possuiMacaco,
    possuiGalinha,
    possuiPorco,
    possuiRepteis,
    possuiOutros,
    quantidadeAnimais,
  } = animais ?? {}
  const { card, hlabelPlaceholder } = createStyles(theme)

  return (
    <Grid>
      <Cell size={12}>
        <Heading level={2}>Condição da moradia</Heading>
      </Cell>
      <Cell size={3}>
        <InfoLabel placeholder='Não informado' title='Situação de moradia/Posse de terra'>
          {tipoSituacaoMoradiaEnumRecord[tipoSituacaoMoradia]}
        </InfoLabel>
      </Cell>
      <Cell size={3}>
        <InfoLabel placeholder='Não informado' title='Localização'>
          {tipoLocalizacaoEnumRecord[tipoLocalizacao]}
        </InfoLabel>
      </Cell>
      <Cell size={3}>
        <InfoLabel placeholder='Não informado' title='Número de cômodos'>
          {numeroComodos}
        </InfoLabel>
      </Cell>
      {tipoLocalizacao === TipoLocalizacaoEnum.RURAL && (
        <Cell size={3}>
          <InfoLabel placeholder='Não informado' title='Condições de posse e uso de terra'>
            {tipoPosseTerraEnumRecord[tipoPosseTerra]}
          </InfoLabel>
        </Cell>
      )}

      <Cell size={6} style={card}>
        <HFlow>
          <MaterialPredominante />
          <InfoLabel placeholder='Não informado' title='Material predominante'>
            {tipoMaterialParedeEnumRecord[tipoMaterialParede]}
          </InfoLabel>
        </HFlow>
      </Cell>
      <Cell size={6} style={card}>
        <HFlow>
          <TipoAcesso />
          <InfoLabel placeholder='Não informado' title='Tipo de acesso'>
            {tipoAcessoDomicilioEnumRecord[tipoAcessoDomicilio]}
          </InfoLabel>
        </HFlow>
      </Cell>
      <Cell size={6} style={card}>
        <HFlow>
          <AbastecimentoAgua />
          <InfoLabel placeholder='Não informado' title='Abastecimento de água'>
            {tipoAbastecimentoAguaEnumRecord[tipoAbastecimentoAgua]}
          </InfoLabel>
        </HFlow>
      </Cell>
      <Cell size={6} style={card}>
        <HFlow>
          <AguaConsumo />
          <InfoLabel placeholder='Não informado' title='Água para consumo'>
            {tipoTratamentoAguaEnumRecord[tipoTratamentoAgua]}
          </InfoLabel>
        </HFlow>
      </Cell>
      <Cell size={6} style={card}>
        <HFlow>
          <EscoamentoBanheiro />
          <InfoLabel placeholder='Não informado' title='Escoamento do banheiro'>
            {tipoEscoamentoSanitarioEnumRecord[tipoEscoamentoSanitario]}
          </InfoLabel>
        </HFlow>
      </Cell>
      <Cell size={6} style={card}>
        <HFlow>
          <DestinoLixo />
          <InfoLabel placeholder='Não informado' title='Destino do lixo'>
            {tipoDestinoLixoEnumRecord[tipoDestinoLixo]}
          </InfoLabel>
        </HFlow>
      </Cell>
      <Cell size={6} style={card}>
        <HFlow>
          <PossuiEletricidade />
          <InfoLabel placeholder='Não informado' title='Possui eletricidade'>
            {isUndefinedOrNull(isEnergiaEletricaDisponivel)
              ? isEnergiaEletricaDisponivel
              : isEnergiaEletricaDisponivel
              ? 'Sim'
              : 'Não'}
          </InfoLabel>
        </HFlow>
      </Cell>
      <Cell size={6} style={card}>
        <HFlow>
          <PossuiAnimais />
          <InfoLabel title='Possui animais de estimação'>
            <HFlow>
              <HLabel title='Quantidade' placeholder='Não informado' placeholderStyle={hlabelPlaceholder}>
                {possuiAnimais && quantidadeAnimais}
              </HLabel>
              <HLabel title='Tipos' placeholder='Não informado' placeholderStyle={hlabelPlaceholder}>
                {possuiAnimais &&
                  getStringAnimais(
                    possuiGato,
                    possuiCachorro,
                    possuiPassaro,
                    possuiMacaco,
                    possuiGalinha,
                    possuiPorco,
                    possuiRepteis,
                    possuiOutros
                  )}
              </HLabel>
            </HFlow>
          </InfoLabel>
        </HFlow>
      </Cell>
    </Grid>
  )
}

const createStyles = (theme: Theme) => ({
  card: css`
    border: 1px solid ${theme.pallete.divider};
    padding: 1rem;
    margin: 1rem 1rem 0rem 1rem;
    flex: 1 0 calc(50% - 2rem);
  `,
  hlabelPlaceholder: {
    fontStyle: 'italic',
    color: `${theme.pallete.text.secondary}`,
  } as CSSProperties,
})

function getStringAnimais(
  possuiGato: boolean,
  possuiCachorro: boolean,
  possuiPassaro: boolean,
  possuiMacaco: boolean,
  possuiGalinha: boolean,
  possuiPorco: boolean,
  possuiRepteis: boolean,
  possuiOutros: boolean
): string {
  const animais = [
    { possui: possuiGato, animal: 'Gato' },
    { possui: possuiCachorro, animal: 'Cachorro' },
    { possui: possuiPassaro, animal: 'Pássaro' },
    { possui: possuiMacaco, animal: 'Macaco' },
    { possui: possuiGalinha, animal: 'Galinha' },
    { possui: possuiPorco, animal: 'Porco' },
    { possui: possuiRepteis, animal: 'Répteis' },
    { possui: possuiOutros, animal: 'Outros' },
  ]

  return animais
    .filter((animais) => animais.possui)
    .map((animais) => animais.animal)
    .join(' | ')
}

import { useTheme } from 'bold-ui'
import { useEffect, useState } from 'react'

export function useViewportType() {
  const theme = useTheme()
  const mobileSize = theme.breakpoints.size['sm']
  const tabletSize = theme.breakpoints.size['md']
  const [isMobile, setIsMobile] = useState(window.innerWidth < mobileSize)
  const [isTablet, setIsTablet] = useState(window.innerWidth < tabletSize && window.innerWidth > mobileSize)

  useEffect(() => {
    const handleResize = () => {
      const isMobileConditionTrue = window.innerWidth < mobileSize
      const isTabletConditionTrue = window.innerWidth < tabletSize && !isMobileConditionTrue
      if (isMobile !== isMobileConditionTrue) setIsMobile(isMobileConditionTrue)
      if (isTablet !== isTabletConditionTrue) setIsTablet(isTabletConditionTrue)
    }
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [isMobile, isTablet, mobileSize, tabletSize])

  return { isMobile, isTablet }
}

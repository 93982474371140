import { CidadaoSelectFieldModel, EnderecoFieldGroupModel, SimNaoEnum } from 'components/form'
import { EnderecoIndigenaFieldGroupModel } from 'components/form/field/enderecoindigena/model-enderecoIndigena'
import { LotacaoResponsavelFormModel } from 'components/lotacao-responsavel/model-lotacaoResponsavel'
import {
  AnimaisDomicilioEnum,
  CidadaoQuery,
  NacionalidadeEnum,
  RacaCorDbEnum,
  ReferenceMonth,
  RendaFamiliar,
  SexoEnum,
  TipoAbastecimentoAguaEnum,
  TipoAcessoDomicilioEnum,
  TipoDestinoLixoEnum,
  TipoDomicilioEnum,
  TipoEnderecoEnum,
  TipoEscoamentoSanitarioEnum,
  TipoImovelEnum,
  TipoLocalizacaoEnum,
  TipoMaterialParedeEnum,
  TipoOrigemEnergiaEletricaEnum,
  TipoPosseTerraEnum,
  TipoSituacaoMoradiaEnum,
  TipoTratamentoAguaEnum,
} from 'graphql/types.generated'
import { Dispatch, SetStateAction } from 'react'

export interface ImovelFormModel {
  id?: ID
  responsabilidade: LotacaoResponsavelFormModel
  microarea?: string
  statusForaArea?: boolean
  stMicroareaPoloBase?: boolean
  dataColeta: LocalDate
  tipoEndereco?: TipoEnderecoEnum
  endereco?: EnderecoFieldGroupModel
  enderecoIndigena?: EnderecoIndigenaFieldGroupModel

  telefoneContato?: string
  telefoneResidencial?: string
  condicoesMoradia?: CondicoesMoradiaFormModel
  animaisNoDomicilio?: AnimaisImovelFormModel
  numeroMoradores?: number
  tipoImovel?: TipoImovelEnum
  instituicaoPermanencia?: InstituicaoPermanenciaFormModel
  possuiFamiliasSalvas: boolean
  familias?: FamiliaFormModel[]
}

export interface CondicoesMoradiaFormModel {
  tipoAbastecimentoAgua?: TipoAbastecimentoAguaEnum
  tipoSituacaoMoradia?: TipoSituacaoMoradiaEnum
  tipoAcessoDomicilio?: TipoAcessoDomicilioEnum
  tipoDestinoLixo?: TipoDestinoLixoEnum
  tipoDomicilio?: TipoDomicilioEnum
  tipoEscoamentoSanitario?: TipoEscoamentoSanitarioEnum
  tipoLocalizacao?: TipoLocalizacaoEnum
  tipoMaterialParede?: TipoMaterialParedeEnum
  tipoPosseTerra?: TipoPosseTerraEnum
  tipoTratamentoAgua?: TipoTratamentoAguaEnum
  isEnergiaEletricaDisponivel?: SimNaoEnum
  tipoOrigemEnergiaEletrica?: TipoOrigemEnergiaEletricaEnum
  numeroComodos?: number
}

export interface AnimaisImovelFormModel {
  possuiAnimais?: SimNaoEnum
  animais?: AnimaisDomicilioEnum[]
  possuiGato?: boolean
  possuiCachorro?: boolean
  possuiPassaro?: boolean
  possuiMacaco?: boolean
  possuiGalinha?: boolean
  possuiPorco?: boolean
  possuiRepteis?: boolean
  possuiOutros?: boolean
  quantidadeAnimais?: number
}

export interface FamiliaFormModel {
  _id: ID
  numeroProntuarioFamiliar?: string
  numeroMembros?: number
  rendaFamiliar?: RendaFamiliar
  resideDesde?: LocalDate
  responsavelId?: ID
  isMudouSe: boolean
  cidadaos: Array<CidadaoTerritorioFormModel>
  isNovoCadastro: boolean
  isResponsavelCadastrado: boolean
  isResponsavelDeclarado: boolean
}

export interface CidadaoTerritorioFormModel {
  _id: ID
  id: ID
  isNovoCadastroNaFamilia: boolean
  mudouSe: boolean
  registroObito?: {
    dataObito: LocalDate
    numeroDeclaracaoObito?: string
  }
}

export interface CidadaoTerritorioModel {
  id: ID
  cns?: string
  cpf?: string
  dataNascimento?: LocalDate
  telefoneCelular?: string
  telefoneContato?: string
  telefoneResidencial?: string
  racaCor?: {
    id: ID
    racaCorDbEnum: RacaCorDbEnum
  }
  etnia?: {
    id: ID
  }
  nomeMae?: string
  nomePai?: string
  desconheceNomeMae?: boolean
  desconheceNomePai?: boolean
  nacionalidade?: {
    id: ID
    nacionalidadeDbEnum: NacionalidadeEnum
  }
  paisNascimento?: {
    id: ID
  }
  localidadeNascimento?: {
    id: ID
    uf: {
      id: ID
    }
  }
  cidadaoAldeado?: {
    id: ID
  }
  dataNaturalizacao?: LocalDate
  portariaNaturalizacao?: string
  dataEntradaBrasil?: LocalDate
  nome: string
  nomeSocial?: string
  sexo: SexoEnum
}

export interface CidadaoCardModel {
  id: ID
  cns?: string
  cpf?: string
  dataNascimento?: LocalDate
  nome: string
  nomeSocial?: string
  sexo: SexoEnum
  isNovoCadastroNaFamilia: boolean
  mudouSe: boolean
  registroObito?: {
    dataObito: LocalDate
    numeroDeclaracaoObito?: string
  }
}

export interface CadastrarEditarFamiliaFormModel {
  _id?: ID
  numeroProntuarioFamiliar?: string
  numeroMembros?: number
  rendaFamiliar?: RendaFamiliar
  resideDesdeMonthYear?: ReferenceMonth
  responsavel: CidadaoSelectFieldModel
}

export interface InstituicaoPermanenciaFormModel {
  nomeInstituicaoPermanencia?: string
  possuiOutrosProfissionaisVinculados?: SimNaoEnum
  responsavelTecnico: ResponsavelTecnicoFormModel
}

export interface ResponsavelTecnicoFormModel {
  nome: string
  cns?: string
  cargo?: string
  contato?: string
}

export const tipoImoveisResidenciais = [
  TipoImovelEnum.DOMICILIO,
  TipoImovelEnum.ABRIGO,
  TipoImovelEnum.INSTITUICAO_LONGA_PERMANENCIA_PARA_IDOSOS,
  TipoImovelEnum.UNIDADE_PRISIONAL,
  TipoImovelEnum.UNIDADE_MEDIDA_SOCIOEDUCATIVA,
  TipoImovelEnum.DELEGACIA,
]

export const tipoEnderecoIndigena = [TipoEnderecoEnum.ALDEIA, TipoEnderecoEnum.ACAMPAMENTO]

export const tipoEnderecoOptions = [
  { value: TipoEnderecoEnum.ALDEIA, label: 'Aldeia' },
  { value: TipoEnderecoEnum.ACAMPAMENTO, label: 'Acampamento' },
  { value: TipoEnderecoEnum.LOGRADOURO, label: 'Logradouro' },
]

export interface CadastrarCidadaoFamiliaFormModel {
  cidadao: CidadaoSelectFieldModel
  familiaId: ID
}

export interface RegistroObitoCidadaoFormModel {
  dataObito: LocalDate
  numeroDeclaracaoObito?: string
}

export interface FamiliaCidadaoModalState {
  cadastroFamiliaModal: {
    form?: CadastrarEditarFamiliaFormModel
    modalOpen: boolean
    editionId: ID
  }
  cadastroCidadaoModal: {
    form?: CadastrarCidadaoFamiliaFormModel
    modalOpen: boolean
  }
  atualizacaoCidadao: boolean
}

export interface ListaLogradouroState {
  fillEnderecoFields?: boolean
}

export interface CadastroImovelCacheState {
  form: ImovelFormModel
  familiaCidadaoModalState: FamiliaCidadaoModalState
  listaLogradouroState?: ListaLogradouroState
  dirtyFields?: { [key: string]: boolean }
}

export type UpdateCadastroImovelCacheState = Dispatch<SetStateAction<CadastroImovelCacheState>>

export type CidadaoCadastroImovel = CidadaoQuery['cidadao']

export const MENSAGEM_RESPONSAVEL_ACOMPANHAMENTO_SEM_CBO =
  'O responsável pelo acompanhamento não possui CBO. Altere o responsável.'

import { useAccordionControl } from 'components/accordion/useAccordionControl'
import { PageContent } from 'components/layout/PageContent'
import { LoadingIndicator } from 'components/loading'
import { useImovelUltimasVisitasQuery } from 'graphql/hooks.generated'
import { TipoImovelEnum } from 'graphql/types.generated'
import React from 'react'
import { useParams } from 'react-router'

import { VisualizarUltimasVisitasPagedList } from './components/VisualizarUltimasVisitasPagedList'
import { ImovelUrlParams } from './model-visualizacao-imovel'

export function VisualizarUltimasVisitasView() {
  const { resetExpandedItems, ...accordionProps } = useAccordionControl({})
  const { imovelId } = useParams<ImovelUrlParams>()
  const {
    data: { visitas, imovel },
    loading,
  } = useImovelUltimasVisitasQuery({
    variables: {
      input: { imovelId },
      imovelId: imovelId,
    },
    fetchPolicy: 'network-only',
  })

  return loading ? (
    <LoadingIndicator />
  ) : (
    <PageContent fluid type='filled'>
      <VisualizarUltimasVisitasPagedList
        visitas={visitas.content}
        isLoading={loading}
        isDomicilio={imovel.tipoImovel === TipoImovelEnum.DOMICILIO}
        resetExpandedItems={resetExpandedItems}
        {...accordionProps}
      />
    </PageContent>
  )
}

import useSession from 'components/auth/useSession'
import { useFlags } from 'config/useFlagsContext'
import Permissions from 'types/Permissions'

export function useSaudeIndigenaAuthorization() {
  const { hasAuthorization } = useSession()
  const { SAUDE_INDIGENA_ENABLED } = useFlags()

  const hasCadastrarEditarAldeadoPermission =
    SAUDE_INDIGENA_ENABLED &&
    hasAuthorization(
      Permissions.gestaoDeCadastrosDeCidadao.visualizarCidadao.cadastrarEEditar.cadastrarEEditarCidadaoAldeado
    )

  const hasTerritorioIndigenaPermission =
    SAUDE_INDIGENA_ENABLED && hasAuthorization(Permissions.acompanhamentos.territorio.territorioIndigena)

  return {
    hasCadastrarEditarAldeadoPermission,
    hasTerritorioIndigenaPermission,
  }
}

import { Cell, Grid, VFlow } from 'bold-ui'
import { DateField } from 'components/form'
import { addDays } from 'date-fns'
import { useServerTime } from 'hooks/useServerTime'
import React, { useEffect } from 'react'
import { FormRenderProps } from 'react-final-form'
import { MetaPath } from 'util/metaPath'

import CidadaoFormModel, { CidadaoAldeadoFormModel } from '../types/model-cidadao'
import { PeriodoAusenciaHistory } from './PeriodoAusenciaHistory'

interface PeriodoAusenciaFieldGroupProps {
  name: MetaPath<CidadaoAldeadoFormModel>
  formProps: FormRenderProps<CidadaoFormModel>
}

export function PeriodoAusenciaFieldGroup({ name, formProps }: PeriodoAusenciaFieldGroupProps) {
  const { getServerTimeNow } = useServerTime()
  const now = getServerTimeNow()

  const periodoAusenciaList = formProps.values?.periodoAusenciaList
  const ultimoPeriodo = periodoAusenciaList && periodoAusenciaList[0]
  const disableAusenciaField = ultimoPeriodo?.dtAusencia && !ultimoPeriodo?.dtRetorno

  const dtAusencia = formProps.values?.cidadaoAldeadoInput?.dtAusencia
  const minAusenciaDate = addDays(new Date(ultimoPeriodo?.dtRetorno || formProps.values?.dataNascimento), 1)
  const minRetornoDate = addDays(new Date(dtAusencia), 1)

  useEffect(() => {
    if (ultimoPeriodo?.dtAusencia && !ultimoPeriodo?.dtRetorno) {
      formProps.form.change(name.dtAusencia.absolutePath(), ultimoPeriodo.dtAusencia)
    }
  }, [formProps, name.dtAusencia, ultimoPeriodo])

  return (
    <VFlow>
      <Grid wrap>
        <Cell size={3}>
          <DateField
            name={name.dtAusencia}
            minDate={minAusenciaDate}
            maxDate={now}
            disabled={disableAusenciaField}
            label='Data da ausência'
          />
        </Cell>
        <Cell size={3}>
          <DateField name={name.dtRetorno} minDate={minRetornoDate} maxDate={now} label='Data do retorno' />
        </Cell>
      </Grid>
      <PeriodoAusenciaHistory periodoAusenciaList={periodoAusenciaList} />
    </VFlow>
  )
}

/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, Grid, HFlow, Text, VFlow } from 'bold-ui'
import { TelefoneField, TextField } from 'components/form'
import { InfoIcon } from 'components/InfoIcon'
import { useConfiguracoesQuery } from 'graphql/hooks.generated'
import { MetaPath } from 'util/metaPath'

import { ContatoCidadaoAgendamento } from '../model-agenda'

interface ContatoCidadaoAgendamentoFieldProps {
  name: MetaPath<ContatoCidadaoAgendamento>
  disabled: boolean
  telefoneCelularRequired: boolean
  emailRequired: boolean
}

export const ContatoCidadaoAgendamentoField = (props: ContatoCidadaoAgendamentoFieldProps) => {
  const { name, disabled, telefoneCelularRequired: telefoneRequired, emailRequired } = props

  const {
    data: { info: { smtpConfigurado } = {} },
  } = useConfiguracoesQuery({
    fetchPolicy: 'cache-first',
  })

  return (
    <Grid>
      <Cell size={12}>
        <VFlow vSpacing={0}>
          <HFlow hSpacing={0}>
            <Text fontWeight='bold'>Dados de contato do cidadão</Text>
            <InfoIcon
              icon='infoCircleFilled'
              text='Verifique os dados de contato do cidadão durante o agendamento. O cadastro do cidadão será atualizado com os dados adicionados.'
              style={styles.infoIcon}
            />
          </HFlow>
          {smtpConfigurado && (
            <Text>O comprovante de agendamento será enviado ao cidadão caso ele possua e-mail cadastrado.</Text>
          )}
        </VFlow>
      </Cell>
      <Cell size={6}>
        <TextField
          lowercase
          name={name.email}
          label='E-mail'
          maxLength={50}
          required={emailRequired}
          disabled={disabled}
        />
      </Cell>
      <Cell size={6}>
        <TelefoneField
          name={name.telefoneCelular}
          label='Telefone celular'
          required={telefoneRequired}
          disabled={disabled}
          // Foi definido assim pois a máscara só funciona com o campo 'sujo'
          initialValue='0000000000'
        />
      </Cell>
    </Grid>
  )
}

const styles = {
  infoIcon: css`
    padding: 0.2rem 0 0 0.2rem;
  `,
}

/** @jsx jsx */
import { jsx } from '@emotion/core'
import { LoadingIndicator } from 'components/loading'
import { useHistoricoConsultaPecQuery } from 'graphql/hooks.generated'
import { RegistroVacinacao } from 'graphql/types.generated'
import { memo } from 'react'
import { HistoricoPecPanelProps } from 'view/atendimentos/detail/historico/model/model-historico'
import {
  convertMedicoesToHistoricoMedicaoModel,
  mountInfosAdministrativasAtendimentosRecentes,
} from 'view/atendimentos/detail/historico/utils/utils-historico'

import HistoricoSection from '../../../../HistoricoSection'
import HistoricoAntecedenteSection from '../../atendimento-individual/consulta/HistoricoAntecedenteSection'
import HistoricoObjetivoPecPanel from '../../atendimento-individual/consulta/HistoricoObjetivoPecPanel'
import HistoricoPlanoPecPanel from '../../atendimento-individual/consulta/HistoricoPlanoPecPanel'
import HistoricoSubjetivoPecPanel from '../../atendimento-individual/consulta/HistoricoSubjetivoPecPanel'
import HistoricoDesfechoPanel from '../../HistoricoDesfechoPecPanel'
import HistoricoGapPanel from '../../HistoricoGapPanel'
import HistoricoNoDetailAvailable from '../../HistoricoNoDetailAvailable'
import HistoricoRegistrosAnterioresPanel from '../../HistoricoRegistrosAnterioresPanel'

interface HistoricoProcedimentoPecProps extends HistoricoPecPanelProps {}

function HistoricoProcedimentoPec(props: HistoricoProcedimentoPecProps) {
  const { idAtend, idadeOrigemAtendimento, dataNascimento, isAtendObservacao } = props
  const {
    data: { historicoAtendimentoPec: historico },
    loading,
  } = useHistoricoConsultaPecQuery({
    variables: {
      input: {
        idAtend,
        isOrigemPec: true,
      },
    },
  })

  if (loading) return <LoadingIndicator />

  if (!historico) return <HistoricoNoDetailAvailable />

  return (
    <HistoricoGapPanel
      idadeOrigemAtendimento={idadeOrigemAtendimento}
      informacoesAdministrativas={mountInfosAdministrativasAtendimentosRecentes(historico)}
    >
      <HistoricoSection section='S'>
        <HistoricoSubjetivoPecPanel evolucaoSubjetivo={historico.evolucaoSubjetivo} />
      </HistoricoSection>
      <HistoricoSection section='O'>
        <HistoricoObjetivoPecPanel
          evolucaoObjetivo={historico.evolucaoObjetivo}
          medicoes={convertMedicoesToHistoricoMedicaoModel(historico.medicoes)}
          examesResultados={historico.examesResultados}
          marcadorConsumoAlimentar={historico.marcadorConsumoAlimentar}
          isAtendObservacao={isAtendObservacao}
        />
      </HistoricoSection>
      <HistoricoSection section='P'>
        <HistoricoPlanoPecPanel
          isAtendProcedimento
          evolucaoPlano={historico.evolucaoPlano}
          quantidadeOrientacoes={historico.quantidadeOrientacoes}
          planosDeCuidado={historico.planosDeCuidado}
          isAtendObservacao={isAtendObservacao}
        />
      </HistoricoSection>
      <HistoricoAntecedenteSection
        antecedentes={historico.antecedentes}
        cirurgiasInternacoes={historico.cirurgiasInternacoes}
        dataNascimento={dataNascimento}
      />
      <HistoricoSection section='RA'>
        <HistoricoRegistrosAnterioresPanel
          medicoes={historico.medicoes}
          registrosVacinacao={historico.vacinacao?.registrosVacinacao as RegistroVacinacao[]}
          dataNascimentoCidadao={idadeOrigemAtendimento.dataNascimentoCidadao}
        />
      </HistoricoSection>
      <HistoricoSection section='D'>
        <HistoricoDesfechoPanel
          condutas={historico.condutas}
          procedimentosAdministrativos={historico.procedimentosAtendimento?.map((proced) => proced.procedimento)}
          notificacoesCasoSuspeito={historico.notificacoesCasoSuspeito}
          racionalidadeEmSaude={historico.racionalidadeSaude}
        />
      </HistoricoSection>
    </HistoricoGapPanel>
  )
}

export default memo(HistoricoProcedimentoPec)

/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Heading, HFlow, Icon, Theme, useTheme } from 'bold-ui'
import { AccordionCompositeItem } from 'components/accordion/AccordionCompositeItem'
import { useAccordionControl } from 'components/accordion/useAccordionControl'
import { usePecField } from 'components/form/final-form/hooks/useField'
import { StatusInformation } from 'components/StatusInformation'
import { parseISO } from 'date-fns'
import { useAtendimentoContext } from 'hooks/atendimento-context/useAtendimentoContext'
import { useMemo, useRef, useState } from 'react'
import { Accordion } from 'react-accessible-accordion'
import { dateAsYyyyMmDd } from 'util/date/formatDate'
import { MetaPath } from 'util/metaPath'

import { IvcfForm } from '../ivcf-form/IvcfForm'
import { AtendimentoIvcfModel } from '../model-ivcf'

interface IvcfResultadoAccordionProps {
  ivcfPath: MetaPath<AtendimentoIvcfModel>
}

const ACCORDION_UUID = 'respostasIvcf'

export function IvcfResultadoAccordion(props: IvcfResultadoAccordionProps) {
  const { ivcfPath } = props

  const theme = useTheme()
  const styles = useMemo(() => createStyles(theme), [theme])

  const accordionRef = useRef(null)
  const [isEditing, setIsEditing] = useState(false)

  const {
    cidadao: { dataNascimento },
    atendimentoProfissional: { iniciadoEm },
  } = useAtendimentoContext()

  const { isExpanded, handleAccordionItemClick, resetExpandedItems } = useAccordionControl({})

  const {
    input: { value, onChange },
  } = usePecField({ name: ivcfPath, subscription: { value: true } })

  const isAccordionExpanded = isExpanded(ACCORDION_UUID)
  const accordionButtonTooltip = isEditing && 'Não é possível minimizar as respostas durante a edição.'

  const handleAccordionClick = () => {
    if (!isEditing) {
      handleAccordionItemClick(ACCORDION_UUID)
    }
  }

  const handleEdit = () => {
    setIsEditing(true)
    if (!isAccordionExpanded) {
      handleAccordionItemClick(ACCORDION_UUID)
    }
  }

  const handleEditSubmit = (values: AtendimentoIvcfModel) => {
    onChange(values)
    setIsEditing(false)
    resetExpandedItems()
  }

  const handleEditCancel = () => {
    setIsEditing(false)
    resetExpandedItems()
    window.scrollTo({ behavior: 'smooth', top: accordionRef.current?.offsetTop - 500 })
  }

  return (
    <div ref={accordionRef}>
      <Accordion allowZeroExpanded={true}>
        <AccordionCompositeItem
          uuid={ACCORDION_UUID}
          dangerouslySetExpanded={isAccordionExpanded}
          accordionButtonTooltip={accordionButtonTooltip}
          onClick={handleAccordionClick}
          header={
            <HFlow hSpacing={0.5} alignItems='center' style={styles.accordionItemHeader}>
              <Heading level={4}>Respostas do instrumento</Heading>
              <StatusInformation text='Registrado agora' />
            </HFlow>
          }
          headerButtons={
            <Button size='small' skin='ghost' onClick={handleEdit} disabled={isEditing}>
              <Icon icon='penOutline' />
            </Button>
          }
          body={
            <IvcfForm
              name={ivcfPath}
              cidadaoDataNascimento={dateAsYyyyMmDd(parseISO(dataNascimento))}
              dataInicioAtendimento={dateAsYyyyMmDd(iniciadoEm)}
              rootRef={accordionRef}
              initialValues={value.respostas}
              hideHeader
              hideBorder
              readOnly
              isEditing={isEditing}
              onSubmit={handleEditSubmit}
              onCancelEdit={handleEditCancel}
            />
          }
          accordionButtonContentStyle={styles.accordionButtonContent}
        />
      </Accordion>
    </div>
  )
}

const createStyles = (theme: Theme) => ({
  accordionButtonContent: css`
    background: ${theme.pallete.surface.main};
  `,
  accordionItemHeader: css`
    width: 100%;
  `,
})

import {
  BeneficioDbEnum,
  IdentidadeGeneroEnum,
  NacionalidadeEnum,
  OrientacaoSexualEnum,
  PeriodoAusencia,
  RacaCorDbEnum,
  SexoEnum,
  TipoEnderecoEnum,
  TipoLocalNascimentoEnum,
  TipoSanguineoEnum,
} from 'graphql/types.generated'
import Permissions from 'types/Permissions'

export const visualizarCidadaoAndProntuarioPermissions = [
  Permissions.gestaoDeCadastrosDeCidadao.visualizarCidadao,
  Permissions.visualizarProntuario,
]
export const visualizarAgendaAndProntuarioPermissions = [Permissions.visualizarAgenda, Permissions.visualizarProntuario]

export interface EnderecoIndigenaSectionModel {
  aldeiaResidencia?: {
    id: ID
    nome: string
  }
  poloBaseResidencia?: {
    id: ID
    nome: string
  }
  dseiResidencia?: {
    id: ID
    nome: string
  }
  municipio?: {
    id: ID
    nome: string
  }
  uf?: {
    id: ID
    sigla: string
  }
  numero?: string
}

export interface CidadaoAldeadoSectionModel {
  id: ID
  funcaoSocial?: {
    id: ID
    nome: string
  }
  aldeiaNascimento?: {
    id: ID
    nome: string
  }
  localOcorrencia?: {
    id: ID
    nome: string
  }
  beneficios?: BeneficioDbEnum[]
  nomeTradicional?: string
  responsavelLegal?: string
  stChefeFamilia?: boolean
  unidadeFunai?: string
  livro?: number
  folha?: number
  cadastroUnico?: string
  dtEmissao?: LocalDate
  ufNascimento?: {
    id: ID
    sigla: string
  }
  dtReconhecimento?: LocalDate
}

export interface CidadaoAldeadoTabSectionModel {
  periodoAusenciaList?: PeriodoAusencia[]
  cidadaoAldeado?: CidadaoAldeadoSectionModel
  enderecoIndigena?: EnderecoIndigenaSectionModel
  numeroFamilia?: string
  tipoEndereco?: TipoEnderecoEnum
  tipoLocalNascimento?: TipoLocalNascimentoEnum
}

export interface CidadaoTabSectionModel extends CidadaoAldeadoTabSectionModel {
  id: ID
  cpf?: string
  cns?: string
  nomeSocial?: string
  nome: string
  dataNascimento?: string
  sexo: SexoEnum
  racaCor: {
    id: string
    nome: string
    racaCorDbEnum: RacaCorDbEnum
  }
  etnia?: {
    id: string
    nome: string
  }
  nomeMae?: string
  nomePai?: string
  nacionalidade?: {
    id: string
    nacionalidadeDbEnum: NacionalidadeEnum
  }
  prontuario?: {
    id: ID
    gestacoes?: {
      id: string
      inicio: LocalDate
      fim?: LocalDate
    }[]
    preNatalAtivo?: {
      id: string
      ultimaDum?: any
    }
    puerpera?: boolean
  }
  localidadeNascimento?: {
    id: string
    nome: string
    uf: {
      id: string
      sigla: string
    }
  }
  portariaNaturalizacao?: string
  dataNaturalizacao?: string
  paisNascimento?: {
    id: string
    nome: string
  }
  dataEntradaBrasil?: string
  faleceu?: boolean
  dataObito?: string
  numeroDocumentoObito?: string
  telefoneResidencial?: string
  telefoneCelular?: string
  telefoneContato?: string
  email?: string
  cidadaoVinculacaoEquipe?: {
    id?: string
    tpCdsOrigem?: string
    utilizarCadastroIndividual?: boolean
    unidadeSaude?: {
      id: string
      nome?: string
    }
    equipe?: {
      id: string
      nome?: string
      ine: string
    }
  }
  localidadeExterior?: string
  paisExterior?: {
    id: string
    nome: string
  }
  endereco?: {
    cep?: string
    bairro?: string
    logradouro?: string
    numero?: string
    semNumero?: boolean
    complemento?: string
    pontoReferencia?: string
    uf?: {
      id: string
      nome: string
    }
    municipio?: {
      id: string
      nome: string
    }
    tipoLogradouro?: {
      id: string
      nome: string
    }
  }
  area?: string
  microArea?: string
  nisPisPasep?: string
  estadoCivil?: {
    id: string
    nome: string
  }
  tipoSanguineo?: TipoSanguineoEnum
  escolaridade?: {
    id: string
    nome: string
  }
  orientacaoSexualDbEnum?: OrientacaoSexualEnum
  identidadeGeneroDbEnum?: IdentidadeGeneroEnum
  ativo?: boolean
  unificado?: boolean
  stCompartilhaProntuario?: boolean
  possuiAgendamento?: boolean
  cbo?: {
    id: string
    nome: string
  }
  dataAtualizado?: string
}

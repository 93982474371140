import { Cell, FormControl, Grid, Heading, HeadingSection } from 'bold-ui'
import { Breadcrumb } from 'components/breadcrumb'
import { PageContent } from 'components/layout/PageContent'
import { PageLoading } from 'components/loading/PageLoading'
import { LotacaoResponsavelCard } from 'components/lotacao-responsavel/LotacaoResponsavelCard'
import { css } from 'emotion'
import { useAtividadeColetivaVisualizarReuniaoQuery } from 'graphql/hooks.generated'
import React, { Fragment } from 'react'
import { Redirect, useParams } from 'react-router'
import { turnoRecord } from 'types/enums'
import { formatDate } from 'util/date/formatDate'

import { LotacoesEnvolvidasTable } from '../componentes/LotacoesEnvolvidasTable'
import { temaReuniaoRecord, tipoAtividadeRecord } from '../model-atividadeColetiva'

interface AtividadeColetivaVisualizarReuniaoViewProps {
  atividadeColetivaId: ID
}

export const AtividadeColetivaVisualizarReuniaoView = () => {
  const { atividadeColetivaId } = useParams<AtividadeColetivaVisualizarReuniaoViewProps>()

  const {
    data: { atividadeColetiva },
    loading,
  } = useAtividadeColetivaVisualizarReuniaoQuery({
    variables: { atividadeColetivaId },
  })

  if (loading) {
    return <PageLoading message='Carregando' />
  } else if (!atividadeColetiva) {
    return <Redirect to='/404' />
  }

  return (
    <Fragment>
      <Breadcrumb title='Visualização de registro de reunião de equipe' />
      <PageContent type='filled'>
        <HeadingSection level={1} title='Registro de reunião' style={styles.header} />

        <Grid gap={2}>
          <Cell size={12}>
            <Heading level={3}>Responsabilidade de registro</Heading>
          </Cell>

          <Cell size={12}>
            <LotacaoResponsavelCard responsavel={atividadeColetiva.lotacaoResponsavel} />
          </Cell>

          <Cell xs={3} lg={2} style={styles.section}>
            <FormControl label='Data da atividade'>{formatDate(atividadeColetiva.data)}</FormControl>
          </Cell>

          <Cell size={2} style={styles.section}>
            <FormControl label='Turno'>{turnoRecord[atividadeColetiva.turno]}</FormControl>
          </Cell>

          <Cell size={2} style={styles.section}>
            <FormControl label='Nº de participantes'>{atividadeColetiva.numeroParticipantes}</FormControl>
          </Cell>

          <Cell size={8}>
            <FormControl label='Local da atividade'>
              {atividadeColetiva.unidadeSaudeLocalAtividade?.nome ?? atividadeColetiva.outraLocalidade ?? '-'}
            </FormControl>
          </Cell>

          <Cell size={12} style={styles.section}>
            <Heading level={2} style={styles.title}>
              Profissionais envolvidos
            </Heading>
          </Cell>

          <Cell size={12}>
            <LotacoesEnvolvidasTable items={atividadeColetiva.lotacoesEnvolvidas ?? []} />
          </Cell>

          <Cell size={12} style={styles.section}>
            <Heading level={2} style={styles.title}>
              Dados da atividade
            </Heading>
          </Cell>

          <Cell size={4}>
            <FormControl label='Tipo da atividade'>{tipoAtividadeRecord[atividadeColetiva.tipoAtividade]}</FormControl>
          </Cell>

          <Cell size={4}>
            <FormControl label='Temas da reunião'>
              {atividadeColetiva.temasReuniao.map((tema) => temaReuniaoRecord[tema]).join(', ')}
            </FormControl>
          </Cell>

          <Cell size={12}>
            <FormControl label='Anotações da atividade'>{atividadeColetiva.anotacoes ?? '-'}</FormControl>
          </Cell>
        </Grid>
      </PageContent>
    </Fragment>
  )
}

const styles = {
  header: css`
    margin-top: 1.5rem;
    margin-bottom: 2.5rem;
  `,
  section: css`
    margin-top: 1.5rem;
  `,
  title: css`
    margin-bottom: 0.5rem;
  `,
}

/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, Grid, Heading, HFlow, Icon, Text, Theme, useTheme, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import { Box } from 'components/Box'
import { Breadcrumb } from 'components/breadcrumb'
import { CheckboxField, Form, FormRenderProps } from 'components/form'
import { PageContent } from 'components/layout/PageContent'
import { confirm } from 'components/modals/confirm'
import { information } from 'components/modals/information'
import { PageHeaderSection } from 'components/PageHeaderSection'
import { HideOnScreenSize } from 'components/responsive/HideOnScreenSize'
import { isBefore } from 'date-fns'
import { useIniciarVideochamadaMutation } from 'graphql/hooks.generated'
import { useServerTime } from 'hooks/useServerTime'
import { capitalize } from 'lodash'
import { Fragment, useCallback, useMemo, useState } from 'react'
import { Route, useHistory, useRouteMatch } from 'react-router'
import { betweenBreakpoints } from 'util/breakpoints'
import { toDate } from 'util/date/formatDate'
import { isHttpsConnection } from 'util/https'
import { metaPath } from 'util/metaPath'

import { TermoModal, TermosRecord } from '../../components/TermoModal'
import { CameraPreview } from './componentes/CameraPreview'
import { AtorVideochamada, VideochamadaUrlParams } from './model-videochamada'
import {
  termosVideochamadaCidadaoPresente,
  termosVideochamadaProfissionalPec,
  termosVideochamadaProfissionalPecCidadaoPresente,
} from './termos'

const meta = metaPath<CriarVideochamadaFormModel>()

interface CriarvideochamadaViewProps {
  horarioInicioVideochamada?: Instant
}

interface ModalTermosResponsabilidadeState {
  termos: TermosRecord<AtorVideochamada.PROFISSIONAL | AtorVideochamada.CIDADAO>
  readonly: boolean
}

interface CriarVideochamadaFormModel {
  cidadaoPresente: boolean
}

export function CriarVideochamadaView(props: CriarvideochamadaViewProps) {
  const { horarioInicioVideochamada = null } = props
  const theme = useTheme()
  const styles = useMemo(() => createStyles(theme), [theme])

  const { getServerTimeNow } = useServerTime()

  const {
    params: { uuid: videochamadaUuid },
    url: matchUrl,
  } = useRouteMatch<VideochamadaUrlParams>()
  const history = useHistory()

  const alert = useAlert()
  const {
    acesso: { cbo },
    profissional: {
      nome,
      usuario: { aceitouTermoTeleinterconsulta },
    },
    refresh: refreshAcesso,
  } = useAcessoLotacaoOrEstagio()

  const [audioEnabled, setAudioEnabled] = useState(true)
  const [videoEnabled, setVideoEnabled] = useState(true)

  const [modalTermosResponsabilidadeState, setModalTermosResponsabilidadeState] = useState<
    ModalTermosResponsabilidadeState
  >({
    termos: termosVideochamadaProfissionalPec,
    readonly: true,
  })
  const [iniciarVideochamada] = useIniciarVideochamadaMutation()

  const openTermosResponsabilidadeModal = useCallback(() => {
    history.push(`${matchUrl}/termosResponsabilidade`)
  }, [history, matchUrl])
  const closeTermosResponsabilidadeModal = useCallback(() => {
    history.replace(history.location.pathname.replace('/termosResponsabilidade', ''))
  }, [history])

  const handleAcceptTermos = useCallback(
    async (
      autorizacoes: Partial<Record<AtorVideochamada.PROFISSIONAL | AtorVideochamada.CIDADAO, boolean>>,
      cidadaoPresente: boolean
    ) => {
      await refreshAcesso()

      closeTermosResponsabilidadeModal()

      if (!isHttpsConnection) {
        alert('danger', 'Não é possível iniciar uma videochamada em um acesso sem HTTPS.')
      } else if (!autorizacoes[AtorVideochamada.PROFISSIONAL] && !aceitouTermoTeleinterconsulta) {
        alert('danger', 'O profissional precisa autorizar a videochamada.')
      } else if (!autorizacoes[AtorVideochamada.CIDADAO] && cidadaoPresente) {
        alert('danger', 'O cidadão precisa autorizar a videochamada.')
      } else {
        try {
          const {
            data: { iniciarVideochamada: videochamadaUuidResponse },
          } = await iniciarVideochamada({
            variables: {
              input: {
                termoProfissionalAceito: autorizacoes[AtorVideochamada.PROFISSIONAL],
                termoCidadaoAceito: autorizacoes[AtorVideochamada.CIDADAO],
                videochamadaUuid: videochamadaUuid,
              },
            },
          })

          information({
            title: <Text fontSize={1.5}>A chamada de vídeo foi aberta em outra janela do navegador.</Text>,
            iconStyle: styles.icon,
          })()

          const url = `/videochamada/${videochamadaUuidResponse}?video=${videoEnabled}&audio=${audioEnabled}`
          videochamadaUuid
            ? window.location.replace(url)
            : window.open(
                url,
                'videochamada',
                `width=${window.screen.availWidth}, heigth=${window.screen.availHeight}, noreferrer`
              )
        } catch {
          alert('danger', 'Erro ao criar videochamada.')
        }
      }
    },
    [
      aceitouTermoTeleinterconsulta,
      alert,
      audioEnabled,
      closeTermosResponsabilidadeModal,
      iniciarVideochamada,
      refreshAcesso,
      videoEnabled,
      videochamadaUuid,
      styles.icon,
    ]
  )

  const handleVisualizarTermo = useCallback(() => {
    setModalTermosResponsabilidadeState({
      termos: termosVideochamadaProfissionalPec,
      readonly: true,
    })
    openTermosResponsabilidadeModal()
  }, [openTermosResponsabilidadeModal])

  const handleSubmit = useCallback(
    async ({ cidadaoPresente }: CriarVideochamadaFormModel) => {
      if (horarioInicioVideochamada && isBefore(getServerTimeNow(), toDate(horarioInicioVideochamada))) {
        const confirmed = await iniciarVideochamadaConfirm()
        if (!confirmed) return
      }
      if (!cidadaoPresente && aceitouTermoTeleinterconsulta) await handleAcceptTermos({}, cidadaoPresente)
      else {
        setModalTermosResponsabilidadeState({
          termos: getTermosAceitacao(cidadaoPresente, aceitouTermoTeleinterconsulta),
          readonly: false,
        })
        openTermosResponsabilidadeModal()
      }
    },
    [
      horarioInicioVideochamada,
      getServerTimeNow,
      aceitouTermoTeleinterconsulta,
      handleAcceptTermos,
      openTermosResponsabilidadeModal,
    ]
  )

  const renderForm = useCallback(
    ({ handleSubmit, values: { cidadaoPresente } }: FormRenderProps<CriarVideochamadaFormModel>) => (
      <form onSubmit={handleSubmit} noValidate>
        <VFlow>
          <Route path={`${matchUrl}/termosResponsabilidade`}>
            <TermoModal
              open
              {...modalTermosResponsabilidadeState}
              onAccept={(autorizacoes) => handleAcceptTermos(autorizacoes, cidadaoPresente)}
              onClose={() => closeTermosResponsabilidadeModal()}
              confirmText='Iniciar chamada'
              style={css`
                height: ${modalTermosResponsabilidadeState.readonly ? '35.75rem' : '44.25rem'};
              `}
            />
          </Route>
          <Heading level={2}>Criar nova chamada</Heading>
          <Text>
            As videochamadas e-SUS APS permitem que os profissionais do PEC criem conversas síncronas que podem ser
            acessadas por usuários internos ou externos ao PEC.
          </Text>
          <Text>
            Você está realizando essa chamada como
            <Text fontWeight='bold'>{` ${nome} | ${capitalize(cbo.nome)}`}</Text>
          </Text>
          <Box style={styles.checkboxContainer}>
            <HFlow justifyContent='space-between'>
              <CheckboxField
                name={meta.cidadaoPresente}
                label={<Text fontWeight='bold'>Cidadão participará presencialmente</Text>}
              />
              <div css={styles.buttonContainer}>
                <Button type='submit' kind='primary'>
                  {videochamadaUuid ? 'Iniciar chamada' : 'Criar chamada'}
                </Button>
              </div>
            </HFlow>
          </Box>
          {aceitouTermoTeleinterconsulta && (
            <Button skin='outline' size='small' kind='primary' onClick={handleVisualizarTermo}>
              <Icon
                icon='fileVisualizationOutline'
                style={css`
                  margin-right: 0.5rem;
                `}
              />
              Ver termo de responsabilidade do profissional
            </Button>
          )}
        </VFlow>
      </form>
    ),
    [
      aceitouTermoTeleinterconsulta,
      cbo.nome,
      closeTermosResponsabilidadeModal,
      handleAcceptTermos,
      handleVisualizarTermo,
      matchUrl,
      modalTermosResponsabilidadeState,
      nome,
      styles.buttonContainer,
      styles.checkboxContainer,
      videochamadaUuid,
    ]
  )

  return (
    <Fragment>
      <Breadcrumb title='Videochamadas' />
      <PageHeaderSection title='Videochamadas e-SUS APS' />
      <PageContent>
        <Box style={styles.container}>
          <Grid>
            <Cell lg={6} sm={8} xs={12}>
              <Grid>
                <Cell size={12}>
                  <Form<CriarVideochamadaFormModel> render={renderForm} onSubmit={handleSubmit} />
                </Cell>
              </Grid>
            </Cell>
            <HideOnScreenSize down='md'>
              <Cell size={1} />
            </HideOnScreenSize>
            <Cell lg={5} sm={12} xs={12} style={styles.cameraContainer}>
              <CameraPreview
                audioEnabled={audioEnabled}
                videoEnabled={videoEnabled}
                setAudioEnabled={setAudioEnabled}
                setVideoEnabled={setVideoEnabled}
              />
            </Cell>
          </Grid>
        </Box>
      </PageContent>
    </Fragment>
  )
}

const getTermosAceitacao = (cidadaoPresente: boolean, aceitouTermoTeleinterconsulta: boolean) =>
  cidadaoPresente
    ? aceitouTermoTeleinterconsulta
      ? termosVideochamadaCidadaoPresente
      : termosVideochamadaProfissionalPecCidadaoPresente
    : termosVideochamadaProfissionalPec

const iniciarVideochamadaConfirm = () =>
  confirm({
    title: `Deseja iniciar a videochamada antes do horário do agendamento?`,
    body: 'No momento, as videochamadas e-SUS APS possuem duração máxima de 3 horas.',
    type: 'primary',
    confirmLabel: 'Iniciar chamada',
    cancelLabel: 'Cancelar',
  })()

const createStyles = (theme: Theme) => ({
  container: css`
    padding: 2.5rem;
    ${betweenBreakpoints(theme.breakpoints, 'md', 'lg')} {
      border: none;
      padding: 0 2rem 0 2rem;
      margin: -2rem;
    }
  `,
  buttonContainer: css`
    display: flex;
    align-items: end;
  `,
  checkboxContainer: css`
    ${betweenBreakpoints(theme.breakpoints, 'md', 'lg')} {
      margin-top: 1rem;
    }
  `,
  cameraContainer: css`
    ${betweenBreakpoints(theme.breakpoints, 'md', 'lg')} {
      margin-top: 2rem;
    }
  `,
  icon: css`
    width: 5.5rem;
    height: 3rem;
  `,
})

import {
  TipoAtendimentoEnum,
  TipoAtendimentoProfissional,
  TipoConsultaOdontoEnum,
  TipoEstabelecimentoEnum,
} from 'graphql/types.generated'
import {
  ProcedimentosAutomaticoAtendimentoOdonto,
  ProcedimentosAutomaticoAtendimentoPoliclinica,
  ProcedimentosAutomaticoAtendimentoUpa,
  ProcedimentosAutomaticosAtendimentoIndividual,
} from 'types/enums'
import { isCboDentista, isCboDentistaClinicoGeral, isCboNivelSuperior } from 'types/NivelCbo'
import { isCboMedico } from 'util/atendimento/cboHelper'
import { isUndefinedOrNull } from 'util/checks'

export interface CalculateProcedimentoAutomaticoToAddProps {
  tipoAtendimentoProfissional: TipoAtendimentoProfissional
  tipoEstabelecimento: TipoEstabelecimentoEnum
  tipoAtendimento: TipoAtendimentoEnum
  cbo2002: string
  isProfResponsavelObservacao: boolean
  tipoConsulta?: TipoConsultaOdontoEnum
}

export const calculateProcedimentoAutomaticoToAdd = (props: CalculateProcedimentoAutomaticoToAddProps) => {
  const {
    tipoAtendimentoProfissional,
    tipoEstabelecimento,
    tipoAtendimento,
    cbo2002,
    isProfResponsavelObservacao,
    tipoConsulta,
  } = props
  switch (tipoAtendimentoProfissional) {
    case TipoAtendimentoProfissional.ATENDIMENTO_ODONTOLOGICO: {
      return calculateProcedimentoAutomaticoOdontologico(tipoEstabelecimento, tipoAtendimento, tipoConsulta, cbo2002)
    }
    default: {
      return calculateProcedimentoAutomaticoIndividual(
        tipoEstabelecimento,
        tipoAtendimento,
        cbo2002,
        isProfResponsavelObservacao
      )
    }
  }
}

export const calculateProcedimentoAutomaticoIndividual = (
  tipoEstabelecimento: TipoEstabelecimentoEnum,
  tipoAtendimento: TipoAtendimentoEnum,
  cbo2002: string,
  isProfResponsavelObservacao: boolean
) => {
  const isMedico = isCboMedico(cbo2002)
  const isProfNivelSuperior = isCboNivelSuperior(cbo2002)

  const isTipoAtendimentoUrgencia = tipoAtendimento === TipoAtendimentoEnum.URGENCIA
  const isTipoAtendimentoConsulta =
    tipoAtendimento === TipoAtendimentoEnum.CONSULTA_NO_DIA ||
    tipoAtendimento === TipoAtendimentoEnum.CONSULTA ||
    tipoAtendimento === TipoAtendimentoEnum.CONSULTA_AGENDADA_CUIDADO_CONTINUADO

  switch (tipoEstabelecimento) {
    case TipoEstabelecimentoEnum.UBS: {
      return procedAutomaticoUbs(isMedico, isTipoAtendimentoUrgencia)
    }
    case TipoEstabelecimentoEnum.POLICLINICA: {
      if (isMedico) {
        if (isTipoAtendimentoUrgencia) {
          return ProcedimentosAutomaticoAtendimentoPoliclinica.ATENDIMENTO_DE_URGENCIA_EM_ATENCAO_ESPECIALIZADA
        } else {
          return ProcedimentosAutomaticoAtendimentoPoliclinica.CONSULTA_MEDICA_EM_ATENCAO_ESPECIALIZADA
        }
      } else if (!isTipoAtendimentoUrgencia) {
        return ProcedimentosAutomaticoAtendimentoPoliclinica.CONSULTA_DE_PROFISSIONAIS_DE_NIVEL_SUPERIOR_NA_ATENCAO_ESPECIALIZADA
      }
      return null
    }
    case TipoEstabelecimentoEnum.UPA: {
      return isProfResponsavelObservacao
        ? ProcedimentosAutomaticoAtendimentoUpa.ATENDIMENTO_URGENCIA_COM_OBSERVACAO_AE
        : isTipoAtendimentoConsulta
        ? isMedico
          ? ProcedimentosAutomaticoAtendimentoUpa.ATENDIMENTO_MEDICO_UPA
          : isProfNivelSuperior
          ? ProcedimentosAutomaticoAtendimentoPoliclinica.CONSULTA_DE_PROFISSIONAIS_DE_NIVEL_SUPERIOR_NA_ATENCAO_ESPECIALIZADA
          : null
        : isTipoAtendimentoUrgencia
        ? isMedico
          ? ProcedimentosAutomaticoAtendimentoPoliclinica.ATENDIMENTO_DE_URGENCIA_EM_ATENCAO_ESPECIALIZADA
          : null
        : null
    }
    default: {
      // Não existem procedimentos automaticos
      return null
    }
  }
}

const procedAutomaticoUbs = (isMedico: boolean, isTipoAtendimentoUrgencia: boolean) => {
  return isTipoAtendimentoUrgencia
    ? isMedico
      ? ProcedimentosAutomaticosAtendimentoIndividual.ATENDIMENTO_DE_URGENCIA_NA_ATENCAO_BASICA
      : null
    : isMedico
    ? ProcedimentosAutomaticosAtendimentoIndividual.CONSULTA_MEDICA_EM_ATENCAO_BASICA
    : ProcedimentosAutomaticosAtendimentoIndividual.CONSULTA_DE_PROFISSIONAIS_DE_NIVEL_SUPERIOR_NA_ATENCAO_BASICA
}

export const calculateProcedimentoAutomaticoOdontologico = (
  tipoEstabelecimento: TipoEstabelecimentoEnum,
  tipoAtendimento: TipoAtendimentoEnum,
  tipoConsulta: TipoConsultaOdontoEnum,
  cbo2002: string
) => {
  if (tipoConsulta === TipoConsultaOdontoEnum.PRIMEIRA_CONSULTA_ODONTOLOGICA_PROGRAMATICA) {
    return ProcedimentosAutomaticoAtendimentoOdonto.PRIMEIRA_CONSULTA_ODONTOLOGICA_PROGRAMATICA
  }

  const isDentista = isCboDentista(cbo2002)
  const isDentistaClinicoGeral = isCboDentistaClinicoGeral(cbo2002)

  const isTipoAtendimentoUrgencia = tipoAtendimento === TipoAtendimentoEnum.URGENCIA
  const isTipoAtendimentoConsulta =
    tipoAtendimento === TipoAtendimentoEnum.CONSULTA_NO_DIA ||
    tipoAtendimento === TipoAtendimentoEnum.CONSULTA ||
    tipoAtendimento === TipoAtendimentoEnum.CONSULTA_AGENDADA_CUIDADO_CONTINUADO

  switch (tipoEstabelecimento) {
    case TipoEstabelecimentoEnum.UBS: {
      return procedAutomaticoOdontoUbs(isTipoAtendimentoUrgencia)
    }
    case TipoEstabelecimentoEnum.CEO: {
      return isTipoAtendimentoUrgencia
        ? ProcedimentosAutomaticoAtendimentoOdonto.ATENDIMENTO_DE_URGENCIA_EM_ATENCAO_ESPECIALIZADA
        : ProcedimentosAutomaticoAtendimentoOdonto.CONSULTA_DE_PROFISSIONAIS_DE_NIVEL_SUPERIOR_NA_ATENCAO_ESPECIALIZADA
    }
    case TipoEstabelecimentoEnum.POLICLINICA: {
      return isDentista && isTipoAtendimentoUrgencia
        ? ProcedimentosAutomaticoAtendimentoOdonto.ATENDIMENTO_DE_URGENCIA_EM_ATENCAO_ESPECIALIZADA
        : ProcedimentosAutomaticoAtendimentoOdonto.CONSULTA_DE_PROFISSIONAIS_DE_NIVEL_SUPERIOR_NA_ATENCAO_ESPECIALIZADA
    }
    case TipoEstabelecimentoEnum.UPA: {
      const isTipoConsultaVazio = isUndefinedOrNull(tipoConsulta)
      const isConsultaManutencao = tipoConsulta === TipoConsultaOdontoEnum.CONSULTA_DE_MANUTENCAO
      const isConsultaManutencaoOuRetorno =
        isConsultaManutencao || tipoConsulta === TipoConsultaOdontoEnum.CONSULTA_DE_RETORNO

      return isTipoAtendimentoConsulta
        ? isTipoConsultaVazio || isConsultaManutencaoOuRetorno
          ? isDentistaClinicoGeral
            ? ProcedimentosAutomaticoAtendimentoUpa.ATENDIMENTO_MEDICO_UPA
            : isDentista
            ? ProcedimentosAutomaticoAtendimentoOdonto.CONSULTA_DE_PROFISSIONAIS_DE_NIVEL_SUPERIOR_NA_ATENCAO_ESPECIALIZADA
            : null
          : null
        : isTipoAtendimentoUrgencia && isDentista && (isTipoConsultaVazio || isConsultaManutencao)
        ? ProcedimentosAutomaticoAtendimentoPoliclinica.ATENDIMENTO_DE_URGENCIA_EM_ATENCAO_ESPECIALIZADA
        : null
    }
    default: {
      // Não existem procedimentos automaticos
      return null
    }
  }
}

const procedAutomaticoOdontoUbs = (isTipoAtendimentoUrgencia: boolean) => {
  return isTipoAtendimentoUrgencia
    ? ProcedimentosAutomaticosAtendimentoIndividual.ATENDIMENTO_DE_URGENCIA_NA_ATENCAO_BASICA
    : ProcedimentosAutomaticosAtendimentoIndividual.CONSULTA_DE_PROFISSIONAIS_DE_NIVEL_SUPERIOR_NA_ATENCAO_BASICA
}

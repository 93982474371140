/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Heading, HFlow, InfoLabel, isEmpty, Text, VFlow } from 'bold-ui'
import { HLabel } from 'components/HLabel'
import theme from 'config/theme'
import { CSSProperties, Fragment } from 'react'
import { formatDate } from 'util/date/formatDate'
import { humanizeAge } from 'util/date/humanize-age'
import { stringGrouper } from 'util/string/string-grouper'

import { MapLink } from './MapLink'

export interface VisualizarVisitaListItemBodyPanelProps {
  motivosVisita?: string[]
  buscaAtiva?: string[]
  acompanhamento?: string[]
  controleAmbiental?: string[]
  desfecho: string
  dataVisita: any
  turno: string
  geolocalizacao: [number, number]
  isCompartilhada: boolean
  cpfCidadao: string
  cnsCidadao: string
  nomeCidadao?: string
  nomeSocialCidadao?: string
  sexoCidadao?: string
  dataNascimentoCidadao?: string
  peso?: number
  altura?: number
  temperatura?: string
  pressaoArterial?: string
  glicemiaCapilar?: string
  momentoColetaGlicemia?: string
  anotacoes?: string
  isDomicilio?: boolean
}

export function VisualizarVisitaListItemBodyPanel(props: VisualizarVisitaListItemBodyPanelProps) {
  const {
    acompanhamento,
    altura,
    anotacoes,
    buscaAtiva,
    cnsCidadao,
    controleAmbiental,
    cpfCidadao,
    dataNascimentoCidadao,
    dataVisita,
    desfecho,
    geolocalizacao,
    glicemiaCapilar,
    isCompartilhada,
    momentoColetaGlicemia,
    motivosVisita,
    nomeCidadao,
    nomeSocialCidadao,
    peso,
    pressaoArterial,
    sexoCidadao,
    temperatura,
    turno,
    isDomicilio,
  } = props

  return (
    <VFlow
      style={css`
        background-color: ${theme.pallete.gray.c90};
        padding: 1rem 1.25rem;
        margin-bottom: 0.1rem;
      `}
    >
      <VFlow vSpacing={1}>
        <VFlow vSpacing={0}>
          <Heading level={3}>Motivos de visita</Heading>
          {!isEmpty(motivosVisita) && stringGrouper(...motivosVisita)}
        </VFlow>
        {!isEmpty(buscaAtiva) && (
          <InfoLabel placeholder='Não informado' title='Busca ativa'>
            {stringGrouper(...buscaAtiva)}
          </InfoLabel>
        )}
        {!isEmpty(acompanhamento) && (
          <InfoLabel placeholder='Não informado' title='Acompanhamento'>
            {stringGrouper(...acompanhamento)}
          </InfoLabel>
        )}
        {!isEmpty(controleAmbiental) && (
          <InfoLabel placeholder='Não informado' title='Controle ambiental/vetorial'>
            {stringGrouper(...controleAmbiental)}
          </InfoLabel>
        )}
      </VFlow>
      <HFlow hSpacing={6}>
        <InfoLabel placeholder='Não informado' title='Desfecho'>
          {desfecho}
        </InfoLabel>

        <InfoLabel placeholder='Não informado' title='Data e turno'>
          {`${dataVisita} | ${turno}`}
        </InfoLabel>

        <InfoLabel placeholder='Não informado' title='Geolocalização'>
          {geolocalizacao ? <MapLink latitude={geolocalizacao[0]} longitude={geolocalizacao[1]} /> : undefined}
        </InfoLabel>
      </HFlow>

      {isDomicilio && (
        <VFlow>
          <VFlow vSpacing={0.25}>
            <InfoLabel
              placeholder='Não informado'
              title='Cidadão visitado'
              titleStyles={css`
                font-size: 1rem;
                margin-bottom: 1rem;
              `}
            >
              {nomeSocialCidadao || nomeCidadao ? (
                <Fragment>
                  <Text fontWeight='bold'>{nomeSocialCidadao?.titleCase() ?? nomeCidadao?.titleCase()}</Text>
                  {` | ${sexoCidadao}`}
                </Fragment>
              ) : (
                sexoCidadao
              )}
            </InfoLabel>
            <HFlow hSpacing={2}>
              {dataNascimentoCidadao && (
                <Text>{`${humanizeAge(dataNascimentoCidadao)} | Nasceu em ${formatDate(dataNascimentoCidadao)}`}</Text>
              )}

              <HLabel placeholder='Não informado' title='CPF' placeholderStyle={styles.hlabelPlaceholder}>
                {cpfCidadao}
              </HLabel>

              <HLabel placeholder='Não informado' title='CNS' placeholderStyle={styles.hlabelPlaceholder}>
                {cnsCidadao}
              </HLabel>
            </HFlow>
          </VFlow>
          <HFlow hSpacing={6}>
            <InfoLabel placeholder='Não informado' title='Peso'>
              {peso ? `${peso}kg` : null}
            </InfoLabel>
            <InfoLabel placeholder='Não informado' title='Altura'>
              {altura ? `${altura}cm` : null}
            </InfoLabel>
            <InfoLabel placeholder='Não informado' title='Temperatura'>
              {temperatura ? `${temperatura}°C` : null}
            </InfoLabel>
            <InfoLabel placeholder='Não informado' title='Pressão Arterial'>
              {pressaoArterial ? `${pressaoArterial}mmHg` : null}
            </InfoLabel>
            <InfoLabel placeholder='Não informado' title='Glicemia capilar'>
              {glicemiaCapilar && momentoColetaGlicemia
                ? `${glicemiaCapilar}mg/dl (Momento da coleta: ${momentoColetaGlicemia.toLowerCase()})`
                : null}
            </InfoLabel>
          </HFlow>
        </VFlow>
      )}

      {anotacoes && (
        <VFlow
          vSpacing={0.5}
          style={css`
            margin-top: 0.5rem;
          `}
        >
          <Heading level={3}>Anotações vindas do e-SUS Território</Heading>
          <Text>{anotacoes}</Text>
        </VFlow>
      )}
      {isCompartilhada && <Text>Visita acompanhada por outro profissional</Text>}
    </VFlow>
  )
}

const styles = {
  hlabelPlaceholder: {
    fontStyle: 'italic',
    color: `${theme.pallete.text.secondary}`,
  } as CSSProperties,
}

import { HFlow, Text, Tooltip, VFlow } from 'bold-ui'
import { DateTime } from 'components/date'
import { Age } from 'components/date/Age'
import { Cpf } from 'components/label'
import React from 'react'
import { identidadeGenero, sexo } from 'types/enums'
import { CidadaoListingFormModel } from 'view/cidadao/types/model-pesquisarCidadao'

interface UnificacaoCidadaoCardInfoProps {
  cidadao: CidadaoListingFormModel
  testid: string
}

export default function UnificacaoCidadaoCardInfo({ cidadao, testid }: UnificacaoCidadaoCardInfoProps) {
  return (
    <>
      <VFlow vSpacing={0} style={{ width: 350 }}>
        <HFlow>
          <HFlow hSpacing={0.5} data-cy={testid + cidadao.id + '.CPF'}>
            <Text fontWeight='bold'>CPF</Text>
            {cidadao.cpf ? <Cpf value={cidadao.cpf} /> : '-'}
          </HFlow>
          <HFlow hSpacing={0.5} data-cy={testid + cidadao.id + '.CNS'}>
            <Text fontWeight='bold'>CNS</Text>
            {cidadao.cns ? <Text>{cidadao.cns}</Text> : '-'}
          </HFlow>
        </HFlow>
        <HFlow hSpacing={0.5} data-cy={testid + cidadao.id + '.DataNascimento'}>
          <Text fontWeight='bold'>Data de nascimento</Text>
          {cidadao.dataNascimento ? (
            <>
              <DateTime format='DD/MM/YYYY' value={cidadao.dataNascimento} />
              <Age dateOfBirth={cidadao.dataNascimento} />
            </>
          ) : (
            '-'
          )}
        </HFlow>
        <HFlow hSpacing={0.5}>
          <Text fontWeight='bold'>Sexo</Text>
          <Text data-cy={testid + cidadao.id + '.Sexo'}> {sexo[cidadao.sexo]}</Text>
          <Text fontWeight='bold'>{cidadao.identidadeGeneroDbEnum ? 'Id. de gênero' : null}</Text>
          <Text data-cy={testid + cidadao.id + '.IdGenero'}>
            {' '}
            {cidadao.identidadeGeneroDbEnum && identidadeGenero[cidadao.identidadeGeneroDbEnum]}
          </Text>
        </HFlow>
        <HFlow alignItems='center' hSpacing={0.5} data-testid={testid + cidadao.id + '.UltimaAtualizacao'}>
          <Tooltip text='É recomendado que, além da data da última atualização, também seja verificado o cadastro como um todo (completude, informações corretas, etc) antes de realizar a unificação'>
            <Text fontWeight='bold'>Última atualização</Text>
          </Tooltip>
          {cidadao.dataAtualizado ? <DateTime format='DD/MM/YYYY' value={cidadao.dataAtualizado} /> : '-'}
        </HFlow>
      </VFlow>
      <VFlow vSpacing={0}>
        <HFlow data-cy={testid + cidadao.id + '.NomeMae'}>
          <Text fontWeight='bold'>Nome da mãe</Text>
          <Text>{cidadao.nomeMae ? cidadao.nomeMae.titleCase() : '-'}</Text>
        </HFlow>
        <HFlow data-cy={testid + cidadao.id + '.UnidadeResponsavel'}>
          <Text fontWeight='bold'>Unidade responsável</Text>
          <Text>{cidadao.cidadaoVinculacaoEquipe?.unidadeSaude?.nome?.titleCase() ?? '-'}</Text>
        </HFlow>
        <HFlow data-cy={testid + cidadao.id + '.MunicipioNascimento'}>
          <Text fontWeight='bold'>Município de nascimento</Text>
          <Text>
            {cidadao.localidadeNascimento?.nome
              ? `${cidadao.localidadeNascimento?.nome?.titleCase()} - ${cidadao.localidadeNascimento?.uf?.sigla}`
              : '-'}
          </Text>
        </HFlow>
      </VFlow>
    </>
  )
}

import { Omit } from 'bold-ui'
import { SelectField, SelectFieldProps } from 'components/form/final-form'
import { TipoDestinoLixoEnum } from 'graphql/types.generated'
import React from 'react'

import { tipoDestinoLixoEnumRecord } from './condicoes-moradia-model'

export interface TipoDestinoLixoSelectFieldProps
  extends Omit<SelectFieldProps<TipoDestinoLixoEnum>, 'items' | 'itemToString'> {}

export function TipoDestinoLixoSelectField(props: TipoDestinoLixoSelectFieldProps) {
  return <SelectField<TipoDestinoLixoEnum> items={items} itemToString={itemToString} {...props} />
}

const legacyOptions: TipoDestinoLixoEnum[] = [TipoDestinoLixoEnum.QUEIMADO_ENTERRADO]
const items = Object.values(TipoDestinoLixoEnum).filter((val) => !legacyOptions.includes(val))

const itemToString = (item: TipoDestinoLixoEnum) => item && tipoDestinoLixoEnumRecord[item]

/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Checkbox, HFlow, Icon, Tag, Text, Tooltip, VFlow } from 'bold-ui'
import { VisualizarAtendimentoButton } from 'components/atendimento/VisualizarAtendimentoButton'
import CheckPermission from 'components/auth/CheckPermission'
import { Cns, Cpf } from 'components/label'
import { ChangeEvent } from 'react'
import Permissions from 'types/Permissions'
import { formatDate, formatDateAndHoursMinutes, formatHoursMinutes } from 'util/date/formatDate'
import { tipoRegistroHistoricoData as tipoAtendimentoHistoricoData } from 'view/atendimentos/detail/historico/model/model-historico'

import { convertAssinaturaModelToHistoricoModel } from '../converter'
import {
  AssinaturaEletronicaAtendTableRowModel,
  getMensagemValidacao,
  LIMITE_ATENDIMENTOS_ASSINATURA,
  statusAssinaturaAtendimentoRecord,
  ValidacaoAssinaturaEnum,
} from '../model'

export const renderCheckbox = (
  row: AssinaturaEletronicaAtendTableRowModel,
  checkedItems: Set<ID>,
  setCheckedItems: (state: Set<ID>) => void,
  processoAssinaturaInProgress: boolean
) => {
  const handleCheck = (event: ChangeEvent<HTMLInputElement>, id: ID) => {
    const current = new Set([...checkedItems])
    event.target.checked ? current.add(id) : current.delete(id)
    setCheckedItems(current)
  }

  const shouldDisableCheckbox = (row: AssinaturaEletronicaAtendTableRowModel): boolean => {
    return (
      row.isAtendimentoAssinado ||
      (!checkedItems.has(row.id) && checkedItems.size >= LIMITE_ATENDIMENTOS_ASSINATURA) ||
      processoAssinaturaInProgress
    )
  }

  const getTooltip = (): string => {
    if (row.isAtendimentoAssinado) {
      return getMensagemValidacao[ValidacaoAssinaturaEnum.ATENDIMENTO_JA_ASSINADO]
    } else if (!checkedItems.has(row.id) && checkedItems.size >= LIMITE_ATENDIMENTOS_ASSINATURA) {
      return getMensagemValidacao[ValidacaoAssinaturaEnum.LIMITE_ATENDIMENTOS_SELECIONADOS_EXCEDIDO]
    } else if (processoAssinaturaInProgress) {
      return getMensagemValidacao[ValidacaoAssinaturaEnum.ASSINATURA_EM_ANDAMENTO]
    }
  }

  return (
    <Tooltip text={shouldDisableCheckbox(row) && getTooltip()} placement='right'>
      <div>
        <Checkbox
          onChange={(event) => handleCheck(event, row.id)}
          disabled={shouldDisableCheckbox(row)}
          checked={checkedItems.has(row.id)}
        />
      </div>
    </Tooltip>
  )
}

export const renderDataHora = (row: AssinaturaEletronicaAtendTableRowModel) => {
  const data = formatDate(row.dataAtendimento)
  const hora = formatHoursMinutes(row.dataAtendimento)

  return (
    <VFlow vSpacing={0}>
      <Text>{data}</Text>
      <Text>{hora}</Text>
    </VFlow>
  )
}

export const renderTipoAtendimento = (row: AssinaturaEletronicaAtendTableRowModel) =>
  tipoAtendimentoHistoricoData[row.tipoAtendimento].descricao

export const renderProfissional = (row: AssinaturaEletronicaAtendTableRowModel) => {
  return (
    <VFlow vSpacing={0}>
      <Text fontWeight='bold'> {row.nomeProfissional} </Text>
      <Text> {row.cboProfissional.titleCase()} </Text>
    </VFlow>
  )
}

export const renderEquipe = (row: AssinaturaEletronicaAtendTableRowModel) => {
  return row.nomeEquipe ? (
    <VFlow vSpacing={0}>
      <Text> {row.nomeEquipe} </Text>
      <Text> {row.ineEquipe} </Text>
    </VFlow>
  ) : (
    <Text> - </Text>
  )
}

export const renderCidadao = (row: AssinaturaEletronicaAtendTableRowModel) => {
  return (
    <VFlow vSpacing={0}>
      <Text fontWeight='bold'>{(row.cidadao.nomeSocial ?? row.cidadao.nome).titleCase()}</Text>
      {row.cidadao.cpf ? (
        <Cpf value={row.cidadao.cpf} />
      ) : row.cidadao.cns ? (
        <Cns value={row.cidadao.cns} />
      ) : (
        'Cidadão sem CPF/CNS'
      )}
    </VFlow>
  )
}

export const renderTags = (row: AssinaturaEletronicaAtendTableRowModel) => {
  const statusTag = statusAssinaturaAtendimentoRecord(row.horarioAssinatura)[row.statusAssinatura || 'NAO_ASSINADO']

  return (
    <Tag
      type={statusTag.type}
      icon={statusTag.icon}
      style={css`
        color: ${statusTag.color};
        background: ${statusTag.backgroundColor};
      `}
    >
      {statusTag.descricao}
    </Tag>
  )
}

export const renderActions = (
  row: AssinaturaEletronicaAtendTableRowModel,
  processoAssinaturaInProgress: boolean,
  checkedItems: Set<ID>
) => {
  const historicoModel = tipoAtendimentoHistoricoData[row.tipoAtendimento]
  const dataOrTurno = formatDateAndHoursMinutes(row.dataAtendimento)
  const historicoAtendimento = convertAssinaturaModelToHistoricoModel(row)
  const selected = checkedItems.has(row.id)
  const tooltipAssinaturaEmAndamento = getMensagemValidacao[ValidacaoAssinaturaEnum.ASSINATURA_EM_ANDAMENTO]
  const tooltipBaixarAtendimento = processoAssinaturaInProgress
    ? tooltipAssinaturaEmAndamento
    : 'Baixar documento assinado'
  const tooltipVisualizarAtendimento = processoAssinaturaInProgress
    ? tooltipAssinaturaEmAndamento
    : 'Visualizar atendimento'

  return (
    <HFlow>
      <Tooltip text={selected && tooltipBaixarAtendimento} placement='bottom'>
        <Button
          size='small'
          skin='ghost'
          disabled={!row.isAtendimentoAssinado || (selected && processoAssinaturaInProgress)}
        >
          <Icon icon='download' fill='normal' />
        </Button>
      </Tooltip>

      <CheckPermission permission={Permissions.visualizarProntuario}>
        <VisualizarAtendimentoButton
          modalTitle={historicoModel.descricao}
          dataOrTurno={dataOrTurno}
          modalTitleColor={historicoModel.iconColor}
          historicoAtendimento={historicoAtendimento}
          cidadao={row.cidadao}
          tooltip={selected && tooltipVisualizarAtendimento}
          disabled={selected && processoAssinaturaInProgress}
          isAtendimentoAssinado={row.isAtendimentoAssinado}
        />
      </CheckPermission>
    </HFlow>
  )
}

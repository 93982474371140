import { msg } from '../messages'

const REGEX_CAMPO_NUMERO = /^[a-zA-Z0-9]*$/

export function numeroEndereco(semNumero: boolean = false) {
  return (value: string) => {
    if (!semNumero && !isValid(value)) {
      return msg('addressNumberPattern', value)
    }
    if (semNumero && value !== 'S/N') {
      return msg('invalid', value)
    }
  }
}

function isValid(value: any) {
  return !value || REGEX_CAMPO_NUMERO.test(value)
}

import { SelectField, SelectFieldProps } from 'components/form'
import { TurnoEnum } from 'graphql/types.generated'
import React from 'react'

export interface TurnoSelectFieldModel {
  value: TurnoEnum
  label: string
}

type TurnoSelectFieldProps = Omit<SelectFieldProps<TurnoSelectFieldModel>, 'items' | 'itemToString'>

const turnoItems: TurnoSelectFieldModel[] = [
  {
    value: TurnoEnum.MANHA,
    label: 'Manhã',
  },
  {
    value: TurnoEnum.TARDE,
    label: 'Tarde',
  },
  {
    value: TurnoEnum.NOITE,
    label: 'Noite',
  },
]

const itemToString = (item: TurnoSelectFieldModel): string => item?.label

export function TurnoSelectField(props: TurnoSelectFieldProps) {
  return <SelectField<TurnoSelectFieldModel> items={turnoItems} itemToString={itemToString} {...props} />
}

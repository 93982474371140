import { PecSwitch, PrivateRoute } from 'components/route'
import { useConfiguracoes } from 'hooks/configuracoes'
import React from 'react'
import { useRouteMatch } from 'react-router'
import Permissions from 'types/Permissions'

import { RelatorioGerencialAbsenteismoView } from './absenteismo/RelatorioGerencialAbsenteismoView'
import { RelatorioGerencialAtendimentosProfissionalView } from './atendimentos/RelatorioGerencialAtendimentosProfissionalView'
import { RelatorioGerencialCuidadoCompartilhadoView } from './cuidado-compartilhado/RelatorioGerencialCuidadoCompartilhadoView'
import { RelatorioGerencialVacinacaoView } from './vacinacao/RelatorioGerencialVacinacaoView'

export function RelatoriosGerenciaisRootView(props: any) {
  const { path } = useRouteMatch()
  const {
    configuracoes: { rocksdbEnabled },
  } = useConfiguracoes()

  return (
    <>
      <PecSwitch>
        {rocksdbEnabled && (
          <PrivateRoute path={`${path}/atendimentos`} permission={Permissions.relatorios.gerenciais.atendimentos}>
            <RelatorioGerencialAtendimentosProfissionalView />
          </PrivateRoute>
        )}
        <PrivateRoute path={`${path}/absenteismo`} permission={Permissions.relatorios.gerenciais.absenteismo}>
          <RelatorioGerencialAbsenteismoView />
        </PrivateRoute>
        <PrivateRoute
          path={`${path}/cuidado-compartilhado`}
          permission={Permissions.relatorios.gerenciais.cuidadoCompartilhado}
        >
          <RelatorioGerencialCuidadoCompartilhadoView />
        </PrivateRoute>
        {rocksdbEnabled && (
          <PrivateRoute path={`${path}/vacinacao`} permission={Permissions.relatorios.gerenciais.vacinacao}>
            <RelatorioGerencialVacinacaoView />
          </PrivateRoute>
        )}
      </PecSwitch>
    </>
  )
}

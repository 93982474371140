import { css } from '@emotion/core'
import { Cell, Grid, Heading } from 'bold-ui'
import { NumberField, RadioGroupField } from 'components/form'
import { TipoDestinoLixoSelectField } from 'components/form/field/select/CondicoesMoradiaSelectFields/TipoDestinoLixoSelectField'
import { usePecField } from 'components/form/final-form/hooks/useField'
import { TipoAcessoDomicilioEnum, TipoLocalizacaoEnum } from 'graphql/types.generated'
import React from 'react'
import { MetaPath } from 'util/metaPath'
import { CondicoesMoradiaFormModel } from 'view/cadastro-imovel/model-cadastroImovel'

import {
  TipoAbastecimentoAguaSelectField,
  TipoEscoamentoSanitarioSelectField,
  TipoMaterialParedeSelectField,
  TipoPosseTerraSelectField,
  TipoSituacaoMoradiaSelectField,
  TipoTratamentoAguaSelectField,
} from '../../../components/form/field/select/CondicoesMoradiaSelectFields'

interface CondicoesDeMoradiaFieldGroupProps {
  name: MetaPath<CondicoesMoradiaFormModel>
  isResidencial: boolean
  isResidencialExcetoDomicilio: boolean
}

export function CondicoesDeMoradiaFieldGroup(props: CondicoesDeMoradiaFieldGroupProps) {
  const { name, isResidencial, isResidencialExcetoDomicilio } = props
  const {
    input: { value: values },
  } = usePecField<CondicoesMoradiaFormModel>({ name: name.absolutePath() })
  const isOutrosImoveis = !isResidencial && !isResidencialExcetoDomicilio
  const isDomicilio = isResidencial && !isResidencialExcetoDomicilio

  return (
    <Grid>
      <Cell
        size={12}
        style={css`
          margin-top: 3rem;
        `}
      >
        <Heading level={1}>Condições de moradia</Heading>
      </Cell>
      <Cell size={4}>
        <TipoSituacaoMoradiaSelectField
          name={name.tipoSituacaoMoradia}
          label='Situação de moradia/Posse de terra'
          disabled={!isDomicilio}
          required={isDomicilio}
        />
      </Cell>
      <Cell size={3}>
        <RadioGroupField
          label='Localização'
          name={name.tipoLocalizacao}
          options={[
            { value: TipoLocalizacaoEnum.URBANA, label: 'Urbana' },
            { value: TipoLocalizacaoEnum.RURAL, label: 'Rural' },
            { value: TipoLocalizacaoEnum.PERIURBANA, label: 'Periurbana' },
          ]}
          disabled={isOutrosImoveis}
          required={!isOutrosImoveis}
        />
      </Cell>
      <Cell size={5}>
        {values?.tipoLocalizacao !== TipoLocalizacaoEnum.RURAL ||
          (isDomicilio && (
            <TipoPosseTerraSelectField name={name.tipoPosseTerra} label='Condições de posse e uso de terra' />
          ))}
      </Cell>
      <Cell size={2}>
        <NumberField name={name.numeroComodos} label='Número de cômodos' disabled={!isDomicilio} min={1} />
      </Cell>
      <Cell size={4}>
        <TipoMaterialParedeSelectField
          name={name.tipoMaterialParede}
          label='Material predominante'
          disabled={!isDomicilio}
        />
      </Cell>
      <Cell size={6}>
        <RadioGroupField
          label='Tipo de acesso'
          name={name.tipoAcessoDomicilio}
          options={[
            { value: TipoAcessoDomicilioEnum.PAVIMENTO, label: 'Pavimento' },
            { value: TipoAcessoDomicilioEnum.CHAO_BATIDO, label: 'Chão batido' },
            { value: TipoAcessoDomicilioEnum.FLUVIAL, label: 'Fluvial' },
            { value: TipoAcessoDomicilioEnum.OUTRO, label: 'Outro' },
          ]}
          disabled={!isDomicilio}
        />
      </Cell>
      <Cell size={6}>
        <TipoAbastecimentoAguaSelectField
          name={name.tipoAbastecimentoAgua}
          label='Principal tipo de abastecimento de água'
          disabled={isOutrosImoveis}
        />
      </Cell>
      <Cell size={6}>
        <TipoTratamentoAguaSelectField
          name={name.tipoTratamentoAgua}
          label='Água para consumo'
          disabled={isOutrosImoveis}
        />
      </Cell>
      <Cell size={6}>
        <TipoEscoamentoSanitarioSelectField
          name={name.tipoEscoamentoSanitario}
          label='Escoamento do banheiro'
          disabled={isOutrosImoveis}
        />
      </Cell>
      <Cell size={6}>
        <TipoDestinoLixoSelectField name={name.tipoDestinoLixo} label='Destino do lixo' disabled={isOutrosImoveis} />
      </Cell>
    </Grid>
  )
}

import { Omit } from 'bold-ui'
import { SelectField, SelectFieldProps } from 'components/form/final-form'
import { TipoPosseTerraEnum } from 'graphql/types.generated'
import React from 'react'

import { tipoPosseTerraEnumRecord } from './condicoes-moradia-model'

export interface TipoPosseTerraSelectFieldProps
  extends Omit<SelectFieldProps<TipoPosseTerraEnum>, 'items' | 'itemToString'> {}

export function TipoPosseTerraSelectField(props: TipoPosseTerraSelectFieldProps) {
  return <SelectField<TipoPosseTerraEnum> items={items} itemToString={itemToString} {...props} />
}

const items = Object.values(TipoPosseTerraEnum)
const itemToString = (item: TipoPosseTerraEnum) => item && tipoPosseTerraEnumRecord[item]

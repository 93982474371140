/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, Grid, HFlow, Text, Tooltip, VFlow } from 'bold-ui'
import { HLabel } from 'components/HLabel'
import { InfoIcon } from 'components/InfoIcon'
import { SMTP_NAO_CONFIGURADO_MESSAGE, VideochamadaUrlTextInput } from 'components/videochamada'
import { useConfiguracoes } from 'hooks/configuracoes'
import { LotacaoAgendamentoModel } from 'view/agenda/model-agenda'
import { lotacaoAgendamentoToString } from 'view/agenda/util-agenda'

interface DetalhesAgendamentoCompartilhadoProps {
  lotacaoParticipante: LotacaoAgendamentoModel
  videochamadaUuid?: string
  onEnviarEmailButtonClick(): void
}
export function DetalhesAgendamentoCompartilhado(props: DetalhesAgendamentoCompartilhadoProps) {
  const { lotacaoParticipante, videochamadaUuid, onEnviarEmailButtonClick } = props

  const {
    configuracoes: { smtpConfigurado },
  } = useConfiguracoes()

  return (
    <VFlow vSpacing={0}>
      <HLabel title='Profissional convidado:'>
        {lotacaoAgendamentoToString({
          nomeProfissional: lotacaoParticipante.nome,
          nomeCbo: lotacaoParticipante.nomeCbo,
          ineEquipe: lotacaoParticipante.ineEquipe,
          nomeEquipe: lotacaoParticipante.nomeEquipe,
        })}
      </HLabel>
      {!!lotacaoParticipante.email && (
        <HFlow hSpacing={0.2} alignItems='center'>
          <HLabel title='E-mail do convidado:'>{lotacaoParticipante.email}</HLabel>
          {lotacaoParticipante.email?.includes('*') && (
            <InfoIcon icon='infoCircleFilled' text='E-mail ofuscado em concordância com as exigências da LGPD' />
          )}
        </HFlow>
      )}
      {!!videochamadaUuid && (
        <div css={styles.textWrapper}>
          <Text fontWeight='bold'>Consulta via videochamada e-SUS APS</Text>
          <Grid>
            <Cell size={6}>
              <VideochamadaUrlTextInput videochamadaUuid={videochamadaUuid} />
            </Cell>
            <Cell>
              <Tooltip text={!smtpConfigurado && SMTP_NAO_CONFIGURADO_MESSAGE}>
                <Button
                  onClick={onEnviarEmailButtonClick}
                  type='submit'
                  kind='primary'
                  size='small'
                  disabled={!smtpConfigurado}
                >
                  Enviar e-mail
                </Button>
              </Tooltip>
            </Cell>
          </Grid>
        </div>
      )}
    </VFlow>
  )
}

const styles = {
  textWrapper: css`
    margin-top: 0.5rem;
  `,
}

import { Omit } from 'bold-ui'
import { SelectField, SelectFieldProps } from 'components/form/final-form'
import { TipoTratamentoAguaEnum } from 'graphql/types.generated'
import React from 'react'

import { tipoTratamentoAguaEnumRecord } from './condicoes-moradia-model'

export interface TipoTratamentoAguaSelectFieldProps
  extends Omit<SelectFieldProps<TipoTratamentoAguaEnum>, 'items' | 'itemToString'> {}

export function TipoTratamentoAguaSelectField(props: TipoTratamentoAguaSelectFieldProps) {
  return <SelectField<TipoTratamentoAguaEnum> items={items} itemToString={itemToString} {...props} />
}

const legacyOptions: TipoTratamentoAguaEnum[] = [TipoTratamentoAguaEnum.FILTRADA]
const items = Object.values(TipoTratamentoAguaEnum).filter((val) => !legacyOptions.includes(val))

const itemToString = (item: TipoTratamentoAguaEnum) => item && tipoTratamentoAguaEnumRecord[item]

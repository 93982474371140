/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { InfoLabel, TableFooter, Text } from 'bold-ui'
import { AccordionDataTable } from 'components/accordion/accordion-data-table/AccordionDataTable'
import { AccordionControls } from 'components/accordion/useAccordionControl'
import { DateTime } from 'components/date'
import { TableBox } from 'components/table'
import { usePagination } from 'components/table/usePagination'
import { stringToCountList } from 'util/string/string-to-countlist'

import { TipoOrigemRecord, UltimasVisitasQueryResult, UltimasVisitasQueryResultRow } from '../model-visualizacao-imovel'
import { VisualizarVisitaListItemBody } from './VisualizarUltimasVisitasListItemBody'

export interface VisualizarUltimasVisitasPagedListProps extends AccordionControls {
  visitas: UltimasVisitasQueryResult
  isDomicilio: boolean
  isLoading: boolean
  expandedItems: string[]
  resetExpandedItems(): void
}

export function VisualizarUltimasVisitasPagedList(props: VisualizarUltimasVisitasPagedListProps) {
  const { visitas, isDomicilio, isLoading, resetExpandedItems, ...accordionProps } = props
  const { paginatedItems, tableProps } = usePagination<UltimasVisitasQueryResultRow>({
    items: visitas,
    onChange: resetExpandedItems,
  })

  return (
    <TableBox>
      <AccordionDataTable
        {...accordionProps}
        rows={paginatedItems}
        components={{
          AccordionPanel: (row) => <VisualizarVisitaListItemBody {...row} isDomicilio={isDomicilio} />,
        }}
        loading={isLoading}
        columns={[
          {
            name: 'dataVisita',
            header: 'Data da visita',
            render: (row) => <DateTime format='DD/MM/YYYY' value={row.dataVisita} />,
          },
          {
            name: 'desfecho',
            header: 'Desfecho',
            render: (row) => <Text>{row.dimensaoDesfechoVisita.descricao}</Text>,
          },
          {
            name: 'motivoVisita',
            header: 'Motivo da visita',
            render: (row) => (
              <Text>
                {stringToCountList(
                  ...row.motivoVisita,
                  ...row.acompanhamento,
                  ...row.buscaAtiva,
                  ...row.controleAmbiental
                )}
              </Text>
            ),
          },
          {
            name: 'profissionalResponsavel',
            header: 'Profissional Responsável',
            render: (row) => (
              <InfoLabel title={`${row.dimensaoProfissional.nome}`}>
                <Text>{row.dimensaoCbo.nome}</Text>
              </InfoLabel>
            ),
          },
          {
            name: 'equipe',
            header: 'Equipe',
            render: (row) =>
              row?.dimensaoEquipe ? (
                <InfoLabel title={`${row?.dimensaoEquipe?.nome}`}>
                  <Text>{row?.dimensaoEquipe?.ine}</Text>
                </InfoLabel>
              ) : (
                <Text>Sem equipe</Text>
              ),
          },
          {
            name: 'origem',
            header: 'Origem',
            render: (row) => (row.tipoOrigem ? <Text>{TipoOrigemRecord[row.tipoOrigem.nome]}</Text> : 'Não informado'),
          },
        ]}
      />
      <TableFooter
        {...tableProps}
        style={css`
          border-top-width: 0;
        `}
      />
    </TableBox>
  )
}

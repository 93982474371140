import { Calculation } from 'final-form-calculate'
import { ProcedimentosAutomaticosAtendimentoIndividual } from 'types/enums'
import { MetaPath } from 'util/metaPath'
import { SoapState } from 'view/atendimentos/atendimento-individual/model'
import { MedicoesPanelModel } from 'view/atendimentos/components/MedicoesPanel/MedicoesPanel'
import { ProcedimentoAutomatico } from 'view/atendimentos/model'

import { addProcedimentoAutomatico, removeProcedimentoAutomatico } from './calculator-plano'
import { PlanoFormModel } from './PlanoForm'

export interface HasAccessProcedimentos {
  desenvolvimentoCrianca: boolean
  crescimentoCrianca: boolean
  glicemiaCapilar: boolean
}

export const createPlanoMedicoesCalculations = (
  plano: MetaPath<PlanoFormModel>,
  medicoes: MetaPath<MedicoesPanelModel>,
  procedimentosAutomaticos: ProcedimentoAutomatico[],
  hasAccessProcedimentos: HasAccessProcedimentos
): Calculation[] => [
  {
    field: [medicoes.pressaoArterialSistolica.absolutePath(), medicoes.pressaoArterialDiastolica.absolutePath()],
    updates: {
      [plano.intervencoesProcedimentos.procedimentos.absolutePath()]: (_, allValues: SoapState) => {
        const listaProcedimentos = addProcedimentoAutomatico(
          allValues,
          procedimentosAutomaticos,
          ProcedimentosAutomaticosAtendimentoIndividual.AFERICAO_PRESSAO_ARTERIAL
        )
        return allValues.objetivo?.medicoes?.pressaoArterialSistolica &&
          allValues.objetivo?.medicoes?.pressaoArterialDiastolica
          ? listaProcedimentos
          : removeProcedimentoAutomatico(
              listaProcedimentos,
              ProcedimentosAutomaticosAtendimentoIndividual.AFERICAO_PRESSAO_ARTERIAL
            )
      },
    },
  },
  {
    field: medicoes.glicemia.absolutePath(),
    updates: {
      [plano.intervencoesProcedimentos.procedimentos.absolutePath()]: (_, allValues: SoapState) => {
        const procedToAdd =
          hasAccessProcedimentos.glicemiaCapilar && allValues.objetivo?.medicoes?.glicemia
            ? ProcedimentosAutomaticosAtendimentoIndividual.GLICEMIA_CAPILAR
            : null

        let listaProcedimentos = allValues.plano?.intervencoesProcedimentos?.procedimentos ?? []
        if (procedToAdd) {
          listaProcedimentos = addProcedimentoAutomatico(allValues, procedimentosAutomaticos, procedToAdd)
        } else {
          listaProcedimentos = removeProcedimentoAutomatico(
            listaProcedimentos,
            ProcedimentosAutomaticosAtendimentoIndividual.GLICEMIA_CAPILAR
          )
        }
        return listaProcedimentos
      },
    },
  },
  {
    field: medicoes.temperatura.absolutePath(),
    updates: {
      [plano.intervencoesProcedimentos.procedimentos.absolutePath()]: (_, allValues: SoapState) => {
        const listaProcedimentos = addProcedimentoAutomatico(
          allValues,
          procedimentosAutomaticos,
          ProcedimentosAutomaticosAtendimentoIndividual.AFERICAO_DE_TEMPERATURA
        )
        return allValues.objetivo?.medicoes.temperatura
          ? listaProcedimentos
          : removeProcedimentoAutomatico(
              listaProcedimentos,
              ProcedimentosAutomaticosAtendimentoIndividual.AFERICAO_DE_TEMPERATURA
            )
      },
    },
  },
]

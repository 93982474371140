import { Cell, Grid, Heading, InfoLabel, VFlow } from 'bold-ui'
import { descriptions } from 'components/form/field/select/RendaFamiliarSelectField'
import { css } from 'emotion'
import { Familia } from 'graphql/types.generated'
import React, { Fragment } from 'react'
import { CidadaoFamiliaCard } from 'view/cadastro-imovel/components/CidadaoFamiliaCard'
import { getResponsavelDependentesFamilia } from 'view/visualizacaoTerritorial/utils/visualizacaoTerritorialUtils'

import { convertCidadaoTerritorioToCidadaoCard } from '../visualizacao-imovel-converter'
import { VisualizarCidadaoButton } from './VisualizarCidadaoButton'

interface FamiliaSectionProps {
  familia: Familia
}
export const FamiliaSection = (props: FamiliaSectionProps) => {
  const { familia } = props

  const { responsavel, dependentes } = getResponsavelDependentesFamilia(familia)

  return (
    <Grid
      style={css`
        padding: 0.5rem;
        margin-top: 0.75rem;
      `}
    >
      <Cell size={12}>
        <Heading level={3}>
          {responsavel
            ? `Família de ${responsavel.nomeSocial?.titleCase() ?? responsavel.nome.titleCase()}`
            : 'Família sem responsável cadastrado'}
        </Heading>
      </Cell>
      <Cell size={3}>
        <VFlow>
          <InfoLabel placeholder='Não informado' title='Nº do prontuário familiar'>
            {familia.numeroProntuarioFamiliar}
          </InfoLabel>
        </VFlow>
      </Cell>
      <Cell size={3}>
        <VFlow>
          <InfoLabel placeholder='Não informado' title='Renda'>
            {descriptions[familia.rendaFamiliar]}
          </InfoLabel>
        </VFlow>
      </Cell>
      <Cell size={3}>
        <VFlow>
          <InfoLabel placeholder='Não informado' title='Reside desde'>
            {familia.resideDesde}
          </InfoLabel>
        </VFlow>
      </Cell>
      {responsavel && (
        <Cell size={12}>
          <CidadaoFamiliaCard
            cidadao={convertCidadaoTerritorioToCidadaoCard(responsavel)}
            isResponsavel={true}
            possuiFichaNaoProcessada={responsavel.ultimaFichaCadastrada?.isProcessado !== true}
            buttonComponent={<VisualizarCidadaoButton cidadaoId={responsavel.id} />}
          />
        </Cell>
      )}
      {dependentes
        .filter((cidadao) => !cidadao.isFaleceu)
        .map((cidadao) => {
          return (
            <Fragment key={cidadao.id}>
              {cidadao.id !== responsavel?.id && (
                <Cell size={12}>
                  <CidadaoFamiliaCard
                    cidadao={convertCidadaoTerritorioToCidadaoCard(cidadao)}
                    isResponsavel={false}
                    possuiFichaNaoProcessada={cidadao.ultimaFichaCadastrada?.isProcessado !== true}
                    buttonComponent={<VisualizarCidadaoButton cidadaoId={cidadao.id} />}
                  />
                </Cell>
              )}
            </Fragment>
          )
        })}
    </Grid>
  )
}

/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, FormControl, Grid, Heading, HFlow, Icon, isEmpty, Text, Tooltip, VFlow } from 'bold-ui'
import { blue, ColorScale, pink } from 'bold-ui/lib/styles/colors'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import { ErrorField, RichTextField } from 'components/form'
import {
  CboCuidadoCompartilhadoSelectField,
  CboCuidadoCompartilhadoSelectModel,
} from 'components/form/field/select/CboCuidadoCompartilhadoSelectField/CboCuidadoCompartilhadoSelectField'
import { CiapSelectField } from 'components/form/field/select/CiapSelectField/CiapSelectField'
import { CidSelectField } from 'components/form/field/select/CidSelectField/CidSelectField'
import { SimNaoRadioGroupField } from 'components/form/field/SimNaoRadioGroupField'
import theme from 'config/theme'
import { useFlags } from 'config/useFlagsContext'
import { CiapCapituloEnum, UnidadeSaude } from 'graphql/types.generated'
import { useAtendimentoContext } from 'hooks/atendimento-context/useAtendimentoContext'
import { ReactComponent as CuidadoCompartilhado } from 'images/historico/icone-cuidado-compartilhado.svg'
import { ReactComponent as GuiaEncaminhamento } from 'images/soap/ic_guia-encaminhamento.svg'
import { Fragment, useEffect } from 'react'
import { useField } from 'react-final-form'
import { isUndefinedOrNull } from 'util/checks'
import { MetaRoot } from 'util/metaPath'

import { ClassificacaoPrioridadeField } from '../components/ClassificacaoPrioridadeField'
import { EstabelecimentosCuidadoCompartilhadoSelectField } from '../components/EstabelecimentosCuidadoCompartilhadoSelectField'
import { LotacoesCuidadoCompartilhadoSelectField } from '../components/LotacoesCuidadoCompartilhadoSelectField'
import { CuidadoCompartilhadoPlanoModel, TipoCompartilhamentoCuidadoPlanoEnum } from '../model-cuidadocompartilhado'

interface CuidadoCompartilhadoFormFieldsProps {
  path: MetaRoot<CuidadoCompartilhadoPlanoModel>
  isAtendimentoOdonto: boolean
  isEdit?: boolean
  disabled?: boolean
  setDisabled?(isDisabled: boolean): void
}

export const CuidadoCompartilhadoFormFields = (props: CuidadoCompartilhadoFormFieldsProps) => {
  const { path, disabled = false, isEdit = false, isAtendimentoOdonto = false, setDisabled } = props

  const {
    cidadao,
    permissoes: { somenteCiap },
  } = useAtendimentoContext()
  const { PILOTO_CUIDADO_COMPARTILHADO_2_ENABLED } = useFlags()
  const { acesso, profissional } = useAcessoLotacaoOrEstagio()
  const excludeProfissionaisIds = [profissional.id]
  const isIdentidadeGeneroEmpty = isEmpty(cidadao?.identidadeGeneroDbEnum)
  const cidadaoSexo = isIdentidadeGeneroEmpty ? cidadao?.sexo : null

  const {
    input: { value: unidadeSaudeValue },
  } = useField<UnidadeSaude>(path.unidadeSaude.absolutePath(), {
    subscription: {
      value: true,
    },
  })
  const {
    input: { value: cboValue },
  } = useField<CboCuidadoCompartilhadoSelectModel>(path.cbo.absolutePath(), {
    subscription: {
      value: true,
    },
  })
  const {
    input: { value: ciapValue },
  } = useField<CboCuidadoCompartilhadoSelectModel>(path.problemasECondicoes.ciap.absolutePath(), {
    subscription: {
      value: true,
    },
  })
  const {
    input: { value: registradoVia },
  } = useField<TipoCompartilhamentoCuidadoPlanoEnum>(path.tipo.absolutePath(), {
    subscription: {
      value: true,
    },
  })

  const unidadeSaudeId = unidadeSaudeValue?.id
  const cboId = cboValue?.cbo?.id
  const cboCodigo2002 = cboValue?.cbo?.cbo2002
  const acessoCnes = acesso.unidadeSaude.cnes
  const ciapId = ciapValue?.id
  const isGuiaEncaminhamento = cboValue && !cboValue?.isVinculado
  const isEMulti =
    !PILOTO_CUIDADO_COMPARTILHADO_2_ENABLED ||
    (!isGuiaEncaminhamento && acesso.unidadeSaude.id === unidadeSaudeId) ||
    isAtendimentoOdonto
  const equipeId = isEMulti ? acesso.equipe?.id : null

  useEffect(() => {
    setDisabled?.(isUndefinedOrNull(cboId))
  }, [cboId, setDisabled])

  return (
    <Grid>
      {PILOTO_CUIDADO_COMPARTILHADO_2_ENABLED && !isAtendimentoOdonto && (
        <Cell size={12}>
          <HFlow hSpacing={0.5} alignItems='center'>
            <Heading level={5}>Registrar via:</Heading>
            {registradoVia === 'CUIDADO_COMPARTILHADO' ? (
              <HFlow hSpacing={0.5} alignItems='center'>
                <CuidadoCompartilhado css={styles.icon(pink)} />
                <Heading level={4} css={styles.registrarHeading(pink)}>
                  Cuidado compartilhado
                </Heading>
              </HFlow>
            ) : registradoVia === 'GUIA_ENCAMINHAMENTO' ? (
              <HFlow hSpacing={0.5} alignItems='center'>
                <GuiaEncaminhamento css={styles.icon(blue)} />
                <Heading level={4} css={styles.registrarHeading(blue)}>
                  Guia de encaminhamento
                </Heading>
              </HFlow>
            ) : (
              <Text>—</Text>
            )}
          </HFlow>
        </Cell>
      )}
      <Cell size={6}>
        {PILOTO_CUIDADO_COMPARTILHADO_2_ENABLED && !isAtendimentoOdonto ? (
          <EstabelecimentosCuidadoCompartilhadoSelectField
            name={path.unidadeSaude}
            label='Unidade de saúde/Serviço de referência'
            cboCodigo2002={cboCodigo2002}
            isVinculado={!isGuiaEncaminhamento}
            acessoCnes={acessoCnes}
            disabled={disabled || (PILOTO_CUIDADO_COMPARTILHADO_2_ENABLED && isGuiaEncaminhamento)}
            required={!isGuiaEncaminhamento}
          />
        ) : (
          <CboCuidadoCompartilhadoSelectField name={path.cbo} label='CBO' isAtendimentoOdonto={isAtendimentoOdonto} />
        )}
      </Cell>
      <Cell size={6}>
        <FormControl label='Profissional' required={!disabled && isEMulti}>
          <HFlow hSpacing={0.5} alignItems='baseline' style={styles.hFlow}>
            <LotacoesCuidadoCompartilhadoSelectField
              name={path.lotacao}
              unidadeSaudeId={isEMulti ? acesso.unidadeSaude.id : unidadeSaudeId}
              cboId={cboId}
              excludeProfissionaisIds={excludeProfissionaisIds}
              equipeVinculadaId={equipeId}
              required={!disabled && isEMulti}
              disabled={
                disabled ||
                (PILOTO_CUIDADO_COMPARTILHADO_2_ENABLED &&
                  !isAtendimentoOdonto &&
                  (isGuiaEncaminhamento || isUndefinedOrNull(unidadeSaudeId)))
              }
            />
            {isEMulti && (
              <Tooltip
                text='O campo só traz profissionais de equipes NASF e eMulti vinculados a essa unidade.'
                placement='top'
              >
                <Icon icon='infoCircleFilled' fill='normal' size={1} style={styles.iconTooltip} />
              </Tooltip>
            )}
          </HFlow>
        </FormControl>
      </Cell>
      {(!disabled || isEdit) && (
        <Fragment>
          <Cell size={12}>
            <VFlow vSpacing={0.5}>
              <FormControl label='Problemas/condições' required>
                <Grid>
                  {PILOTO_CUIDADO_COMPARTILHADO_2_ENABLED && isGuiaEncaminhamento ? (
                    <Fragment>
                      {(somenteCiap || isEdit) && (
                        <Cell size={6}>
                          <CiapSelectField
                            name={path.problemasECondicoes.ciap}
                            label='CIAP 2'
                            idadeCidadaoEmAnos={cidadao.idadeEmAnos}
                            sexo={isEmpty(cidadao.identidadeGeneroDbEnum) ? cidadaoSexo : undefined}
                            capitulosExcluidos={[CiapCapituloEnum.PROCEDIMENTOS]}
                            excluirCIAPsDAB
                            menuMinWidth={620}
                            required={!disabled && somenteCiap}
                            disabled={disabled || !somenteCiap}
                          />
                        </Cell>
                      )}
                      <Cell size={6}>
                        <CidSelectField
                          name={path.problemasECondicoes.cid10}
                          label='CID 10'
                          renderGrupoCiapRelacionada={somenteCiap}
                          idadeCidadaoEmAnos={cidadao.idadeEmAnos}
                          sexo={cidadaoSexo}
                          ciapRelacionada={somenteCiap ? ciapId : null}
                          somenteRelacionados={somenteCiap}
                          disabled={disabled || (somenteCiap && isUndefinedOrNull(ciapId))}
                          required={!disabled}
                        />
                      </Cell>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <Cell size={6}>
                        <CiapSelectField
                          name={path.problemasECondicoes.ciap}
                          label='CIAP 2'
                          idadeCidadaoEmAnos={cidadao.idadeEmAnos}
                          sexo={isEmpty(cidadao.identidadeGeneroDbEnum) ? cidadaoSexo : undefined}
                          capitulosExcluidos={[CiapCapituloEnum.PROCEDIMENTOS]}
                          excluirCIAPsDAB
                          menuMinWidth={620}
                          disabled={disabled}
                        />
                      </Cell>
                      {(!somenteCiap || (PILOTO_CUIDADO_COMPARTILHADO_2_ENABLED && isEdit)) && (
                        <Cell size={6}>
                          <CidSelectField
                            name={path.problemasECondicoes.cid10}
                            label='CID 10'
                            renderGrupoCiapRelacionada
                            idadeCidadaoEmAnos={cidadao.idadeEmAnos}
                            ciapRelacionada={ciapId}
                            sexo={cidadaoSexo}
                            disabled={disabled || somenteCiap}
                          />
                        </Cell>
                      )}
                    </Fragment>
                  )}
                </Grid>
              </FormControl>
              <ErrorField name={path.problemasECondicoes} ignoreObjectError />
            </VFlow>
          </Cell>
          <Cell size={12}>
            <ClassificacaoPrioridadeField name={path.prioridade} required={!disabled} />
          </Cell>
          <Cell size={12}>
            <RichTextField
              name={path.discussao}
              label={PILOTO_CUIDADO_COMPARTILHADO_2_ENABLED ? 'Discussão' : 'Discussão de caso clínico'}
              placeholder='Insira objetivamente o relato do caso, além de dúvidas e questionamentos'
              maxLength={isGuiaEncaminhamento ? 2000 : 1000}
              required={!disabled}
            />
          </Cell>
          {!isGuiaEncaminhamento && (
            <Cell size={12}>
              <FormControl
                label='Após devida explicação, o cidadão declara concordar de forma livre e esclarecida que o agendamento da consulta com o especialista poderá ser mediado por tecnologia?'
                required={!disabled && !isGuiaEncaminhamento}
              >
                <HFlow>
                  <SimNaoRadioGroupField
                    name={path.cidadaoAceitaAtendTic}
                    disabled={disabled || (PILOTO_CUIDADO_COMPARTILHADO_2_ENABLED && isGuiaEncaminhamento)}
                  />
                </HFlow>
              </FormControl>
            </Cell>
          )}
        </Fragment>
      )}
    </Grid>
  )
}

const styles = {
  hFlow: css`
    grid-auto-columns: 1fr auto;
  `,
  iconTooltip: css`
    &:hover {
      fill: ${theme.pallete.primary.main};
    }
  `,
  icon: (color: ColorScale) => css`
    height: 1.5rem;
    width: 1.5rem;
    filter: drop-shadow(0 0.08rem 0.1rem ${color.c60});
  `,
  registrarHeading: (color: ColorScale) => css`
    color: ${color.c40};
  `,
}

import { ImovelUltimasVisitasQuery, ImovelVisitaQuery } from 'graphql/types.generated'

export type UltimasVisitasQueryResult = ImovelUltimasVisitasQuery['visitas']['content']
export type UltimasVisitasQueryResultRow = UltimasVisitasQueryResult[0]
export type ImovelVisitaResult = ImovelVisitaQuery['visita']

export const TipoOrigemRecord: Record<string, string> = {
  'Não informado': 'Não informado',
  OFFLINE: 'CDS Offline',
  ONLINE: 'CDS',
  PEC: 'PEC',
  EXTERNO: 'RAS PEC',
  ANDROID_ACS: 'e-SUS Território',
}

export interface ImovelUrlParams {
  imovelId: ID
}

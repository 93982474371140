/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, ButtonProps, HFlow, Text, Theme, useTheme } from 'bold-ui'
import { green } from 'bold-ui/lib/styles/colors'
import icon from 'images/soap/ic_ivcf.png'
import { useMemo } from 'react'

interface IvcfButtonProps extends Omit<ButtonProps, 'style'> {
  isIvcfAtivo: boolean
}

export function IvcfButton(props: IvcfButtonProps) {
  const { isIvcfAtivo, ...rest } = props

  const theme = useTheme()
  const styles = useMemo(() => createStyles(theme, isIvcfAtivo), [isIvcfAtivo, theme])

  return (
    <Button size='medium' style={styles.button} {...rest}>
      <HFlow alignItems='center' hSpacing={0.5}>
        <img src={icon} alt='IVCF' css={styles.icon} />
        <Text fontWeight='bold' style={styles.buttonText}>
          IVCF
        </Text>
      </HFlow>
    </Button>
  )
}

const createStyles = (theme: Theme, isIvcfAtivo: boolean) => ({
  button: css`
    width: 9.75rem;
    justify-content: flex-start;
    border: ${isIvcfAtivo ? '0' : `1px ${green.c60} solid`};
    background-color: ${isIvcfAtivo ? green.c60 : theme.pallete.gray.c100};
    margin: 0;
    &:not(:disabled):hover {
      background-color: ${isIvcfAtivo ? green.c50 : green.c90};
    }
  `,
  buttonText: css`
    color: ${isIvcfAtivo ? theme.pallete.gray.c100 : green.c40};
  `,
  icon: css`
    margin: -2px;
  `,
})

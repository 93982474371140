/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Alert, Text } from 'bold-ui'
import { useFlags } from 'config/useFlagsContext'
import { useIsBeneficiarioBolsaFamiliaQuery } from 'graphql/hooks.generated'

interface FolhaRostoBolsaFamiliaAlertProps {
  cidadaoId: ID
}

export function FolhaRostoBolsaFamiliaAlert(props: FolhaRostoBolsaFamiliaAlertProps) {
  const { cidadaoId } = props

  const { PILOTO_BOLSA_FAMILIA_ENABLED } = useFlags()
  const { data, loading } = useIsBeneficiarioBolsaFamiliaQuery({ variables: { cidadaoId } })

  if (!PILOTO_BOLSA_FAMILIA_ENABLED || loading || !data?.cidadao?.beneficiarioBolsaFamilia) {
    return null
  }

  return (
    <Alert inline type='info' styles={{ container: styles.container }}>
      <Text color='info' fontWeight='bold'>
        Cidadão beneficiário do programa bolsa família
      </Text>
      <br />
      Esse cidadão consta como beneficiário do bolsa família na vigência mais recente importada. Verifique o
      acompanhamento das condicionalidades de saúde.
    </Alert>
  )
}

const styles = {
  container: css`
    align-items: center;
    margin: 0.25rem 0;
  `,
}

/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { SelectHelperMenuItem, Spinner, useLocale, useTheme } from 'bold-ui'
import {
  AsyncSelectFieldProps,
  DEFAULT_SELECT_SIZE,
  useAsyncQuerySelect,
} from 'components/form/field/select/useAsyncQuerySelect'
import { CidadaoAtendimentoSelectFieldDocument } from 'graphql/hooks.generated'
import {
  CidadaoAtendimentoSelectFieldQuery,
  CidadaoAtendimentoSelectFieldQueryVariables,
} from 'graphql/types.generated'

import { skipNomeCidadaoAtendimentoSelectField } from '../CidadaoAtendimentoPorPropriedadeSelectField/util-cidadaoAtendimentoPorPropriedadeSelectField'
import { CidadaoAtendimentoSelectFieldCommon } from './CidadaoAtendimentoSelectFieldCommon'

export interface CidadaoAtendimentoSelectFieldProps extends AsyncSelectFieldProps<CidadaoAtendimentoSelectFieldModel> {
  ativo?: boolean
  obito?: boolean
  verificarPresenteNaListaAtendimento?: boolean
  onBeforeRedirect?: () => void
  addCidadaoCallbackUrl: string
  retrieveContato?: boolean
}

export type CidadaoAtendimentoSelectFieldModel = {
  id: string
  nome: string
  nomeSocial?: string
  cpf?: string
  cns?: string
  nomeMae?: string
  dataNascimento?: any
  presenteListaAtendimento?: boolean
  contato?: {
    telefoneCelular?: string
    email?: string
  }
  isCidadaoAusente?: boolean
  nomeTradicional?: string
}

export function CidadaoAtendimentoSelectField(props: CidadaoAtendimentoSelectFieldProps) {
  const {
    ativo,
    obito,
    verificarPresenteNaListaAtendimento = true,
    addCidadaoCallbackUrl,
    retrieveContato = false,
    onBeforeRedirect,
    ...rest
  } = props

  const theme = useTheme()
  const locale = useLocale()

  const { skipping, selectProps } = useAsyncQuerySelect<
    CidadaoAtendimentoSelectFieldModel,
    CidadaoAtendimentoSelectFieldQuery,
    CidadaoAtendimentoSelectFieldQueryVariables
  >({
    query: CidadaoAtendimentoSelectFieldDocument,
    extractItems: (data) => data && data.cidadaosCombo,
    variables: (inputString) => ({
      input: {
        query: inputString,
        ativo: ativo,
        obito: obito,
        limit: DEFAULT_SELECT_SIZE,
      },
      emAtencaoDomiciliar: false,
      retrieveContato,
    }),
    skip: (inputString) => skipNomeCidadaoAtendimentoSelectField(inputString),
    debounceTime: 500,
  })

  return (
    <CidadaoAtendimentoSelectFieldCommon
      addCidadaoCallbackUrl={addCidadaoCallbackUrl}
      verificarPresenteNaListaAtendimento={verificarPresenteNaListaAtendimento}
      onBeforeRedirect={onBeforeRedirect}
      placeholder='Pesquise pelo nome, CPF ou CNS.'
      components={{
        EmptyItem: () => (
          <SelectHelperMenuItem>
            {skipping ? 'Digite para pesquisar por um cidadão.' : locale.select.emptyItem}
          </SelectHelperMenuItem>
        ),
        LoadingItem: () => (
          <SelectHelperMenuItem>
            Pesquise pelo CPF/CNS para ter resultados mais rápido.
            <Spinner
              style={css`
                color: ${theme.pallete.primary.main};
                float: right;
              `}
            />
          </SelectHelperMenuItem>
        ),
      }}
      selectProps={selectProps}
      {...rest}
    />
  )
}

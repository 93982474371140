import { ClassNames, HFlow, Tag, Text, useStyles, VFlow } from 'bold-ui'
import { DateTime } from 'components/date'
import { Ellipsis } from 'components/Ellipsis'
import { isSameDay } from 'date-fns'
import { SituacaoAgendadoEnum, StatusAtendimento } from 'graphql/types.generated'
import { useServerTime } from 'hooks/useServerTime'
import { isEmpty } from 'lodash'
import React from 'react'
import { humanizeAge } from 'util/date/humanize-age'

import { isAtendimentoObservacaoByStatusAtendimento } from '../atendimento-individual/atendimento-observacao/util-atendObservacao'
import AtendimentoListingItem from '../components/AtendimentoListingItem'
import { AtendimentoTags } from './AtendimentoTags'
import ListaAtendimentoItemActions from './ListaAtendimentoItemActions'
import { listaAtendimentoStylesRecord } from './listaAtendimentoStyles'
import { createItemStyles } from './listaUtils'
import { AtendimentoModel, STATUS_ATENDIMENTO_FINALIZADO, statusRevisaoAtendimentoDescricaoRecord } from './model'
import { ResponsavelAtendimentoText } from './ResponsavelAtendimentoText'

export interface ListaAtendimentoItemProps {
  atend: AtendimentoModel
}

export function ListaAtendimentoItem(props: ListaAtendimentoItemProps) {
  const { atend } = props
  const { classes } = useStyles(createItemStyles)

  const statusAtendimento = listaAtendimentoStylesRecord[atend.statusAtendimento]
  const isAtendimentoObservacao = isAtendimentoObservacaoByStatusAtendimento(atend.statusAtendimento)

  const { getServerTimeNow } = useServerTime()

  const showDate =
    mustShowDateByStatusAtendimento(atend.statusAtendimento) ||
    mustShowDateByIniciadoEm(getServerTimeNow().getTime(), atend.iniciadoEm)

  const shouldNotShowTagAgendamento = !!(
    atend.agendado?.situacao !== SituacaoAgendadoEnum.CIDADAO_PRESENTE_NA_UNIDADE &&
    atend.statusAtendimento !== StatusAtendimento.ATENDIMENTO_REALIZADO
  )

  const renderTime = (
    <VFlow vSpacing={0}>
      <HFlow hSpacing={0.25}>
        {!!atend.statusRevisaoAtendimento && (
          <Tag icon='penFilled' style={classes.revisarRetificarTag}>
            {statusRevisaoAtendimentoDescricaoRecord[atend.statusRevisaoAtendimento].descricao}
          </Tag>
        )}

        {atend.hasTagNaoFinalizado && (
          <Tag icon='exclamationTriangleFilled' style={classes.naoFinalizadoTag}>
            <DateTime
              style={{ lineHeight: '1.35rem', fontWeight: 'bold' }}
              value={atend.encaminhadoEm}
              format='[Não finalizado - ]  DD/MM/YYYY'
            />
          </Tag>
        )}
      </HFlow>
      <HFlow style={classes.hFlow}>
        <VFlow vSpacing={0}>
          <DateTime
            style={{ lineHeight: '1.35rem', fontWeight: 'bold' }}
            value={atend.ultimaAlteracaoStatusEm}
            format={showDate ? 'DD/MM/YYYY [-] HH:mm' : 'HH:mm'}
          />

          <Text>{statusAtendimento.descricao}</Text>
        </VFlow>

        <VFlow vSpacing={0}>
          <span className={classes.nomeCidadao}>{`${atend.cidadao.nomeSocial || atend.cidadao.nome} `}</span>
          <span className={classes.idadeCidadao}>
            {atend.cidadao.dataNascimento ? humanizeAge(atend.cidadao.dataNascimento) : '-'}
          </span>
        </VFlow>
      </HFlow>
    </VFlow>
  )

  const renderTags = (
    <AtendimentoTags
      tiposServico={atend.tiposServico}
      classificacaoRisco={atend.classificacaoRisco}
      horaAgendamento={shouldNotShowTagAgendamento ? null : atend.agendado?.horarioInicial}
      nomeProfissional={shouldNotShowTagAgendamento ? null : atend.agendado?.lotacaoAgendada?.profissional?.nome}
      nomeProfissionalParticipante={
        shouldNotShowTagAgendamento ? null : atend.agendado?.lotacaoParticipante?.profissional?.nome
      }
      possuiVideochamada={!shouldNotShowTagAgendamento && !!atend.agendado?.videochamada?.id}
    />
  )

  const renderActions = (
    <ListaAtendimentoItemActions
      actions={atend.actions}
      atendimentoId={atend.id}
      cidadaoId={atend.cidadao.id}
      prontuarioId={atend.prontuarioId}
      responsavelId={atend.responsavel?.id}
      statusAtendimento={atend.statusAtendimento}
      statusRevisaoAtendimento={atend.statusRevisaoAtendimento}
      isAtendimentoObservacao={isAtendimentoObservacao}
    />
  )

  return (
    <AtendimentoListingItem
      status={statusAtendimento}
      time={renderTime}
      tags={renderTags}
      lotacao={!isAtendimentoObservacao ? renderLotacao(atend) : renderObservacaoResponsavel(atend, classes)}
      actions={renderActions}
    />
  )
}

const renderLotacao = (atend: AtendimentoModel) => {
  const isMinhaLotacao = atend.responsavel?.id === atend.agendado?.lotacaoAgendada.id
  const showParticipante = isMinhaLotacao && !!atend.agendado?.lotacaoParticipante

  return (
    <>
      {atend.responsavel && (
        <ResponsavelAtendimentoText
          showParticipante={showParticipante}
          nomeProfissionalResponsavel={atend.responsavel.profissional.nome}
          nomeProfissionalParticipanteAgendamento={atend.agendado?.lotacaoParticipante?.profissional?.nome}
        />
      )}

      {!atend.responsavel && atend.equipe && (
        <VFlow vSpacing={0}>
          <Text fontWeight='bold'> {atend.equipe.nome} </Text>
          <Text>
            <b>Área </b> {atend.equipe.area}
          </Text>
        </VFlow>
      )}
    </>
  )
}

const renderObservacaoResponsavel = (atend: AtendimentoModel, classes: ClassNames<string>) => {
  const responsavelAtendimento = atend.responsavel?.profissional
  const nomeResponsavelAtendimento = responsavelAtendimento?.nome
  const ultimoResponsavelObservacao = atend.ultimoResponsavelObservacao.profissional
  const nomeUltimoResponsavelObservacao = ultimoResponsavelObservacao.nome

  if (ultimoResponsavelObservacao.id === responsavelAtendimento?.id) {
    return (
      <VFlow vSpacing={0}>
        <Text fontWeight='bold'>Encaminhado para o responsável</Text>
        <Ellipsis maxLines={2} title={nomeUltimoResponsavelObservacao}>
          Responsável: {nomeUltimoResponsavelObservacao}
        </Ellipsis>
      </VFlow>
    )
  } else if (isEmpty(responsavelAtendimento)) {
    return (
      <Ellipsis maxLines={2} title={nomeUltimoResponsavelObservacao}>
        <Text fontWeight='bold' style={classes.responsavelLabel}>
          Responsável:
        </Text>
        <Text>{nomeUltimoResponsavelObservacao}</Text>
      </Ellipsis>
    )
  } else {
    return (
      <VFlow vSpacing={0}>
        <Ellipsis maxLines={2} title={nomeResponsavelAtendimento}>
          <Text fontWeight='bold'>Encaminhado para {nomeResponsavelAtendimento}</Text>
        </Ellipsis>
        <Ellipsis maxLines={2} title={nomeUltimoResponsavelObservacao}>
          <Text>Responsável: {nomeUltimoResponsavelObservacao}</Text>
        </Ellipsis>
      </VFlow>
    )
  }
}

const mustShowDateByStatusAtendimento = (statusAtendimento: StatusAtendimento): boolean => {
  return STATUS_ATENDIMENTO_FINALIZADO.includes(statusAtendimento)
}

const mustShowDateByIniciadoEm = (serverTimeNow: Instant, dataInicioAtendimento: Instant): boolean => {
  return !isSameDay(dataInicioAtendimento, serverTimeNow)
}

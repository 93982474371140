import { getSingleAgeRangeValue } from 'components/date/AgeRange'
import { createValidator, ErrorObject, required } from 'util/validation'

import { AcompanhamentoCondicaoSaudeFormModel, AgeRangeFilter } from './model-acompanhamentoCondicaoSaude'

export const validate = createValidator<AcompanhamentoCondicaoSaudeFormModel>(
  {
    equipeResponsavel: required,
  },
  (form: AcompanhamentoCondicaoSaudeFormModel, errors: ErrorObject<AcompanhamentoCondicaoSaudeFormModel>) => {
    const { faixaEtaria, faixaEtariaFilter } = form

    if (AgeRangeFilter.OUTRA === faixaEtariaFilter) {
      errors.faixaEtaria = required(getSingleAgeRangeValue(faixaEtaria))
    }

    return errors
  }
)

/** @jsx jsx */
import { jsx } from '@emotion/core'
import { LoadingIndicator } from 'components/loading'
import { useImovelVisitaQuery } from 'graphql/hooks.generated'

import { convertVistasRowToListItemBodyPanel } from '../converter-visualizacao-imovel'
import { UltimasVisitasQueryResultRow } from '../model-visualizacao-imovel'
import { VisualizarVisitaListItemBodyPanel } from './VisualizarVisitaListItemBodyPanel'

export interface VisualizarVisitaListItemBodyProps {
  row: UltimasVisitasQueryResultRow
  isDomicilio: boolean
}

export function VisualizarVisitaListItemBody({ row, isDomicilio }: VisualizarVisitaListItemBodyProps) {
  const {
    data: { visita },
    loading,
  } = useImovelVisitaQuery({
    variables: {
      input: row.id,
    },
    fetchPolicy: 'network-only',
  })

  return loading ? (
    <LoadingIndicator />
  ) : (
    <VisualizarVisitaListItemBodyPanel {...convertVistasRowToListItemBodyPanel(visita)} isDomicilio={isDomicilio} />
  )
}

/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Icon } from 'bold-ui'
import { SubmitButton } from 'components/form'

export interface SearchFooterProps {
  isCoordenador: boolean
  equipeResponsavelId: ID
  onCleanFilters?(): void
  handleSubmit(event?: React.SyntheticEvent<HTMLFormElement>): void
}

export const SearchFooter = (props: SearchFooterProps) => {
  const { isCoordenador, equipeResponsavelId, onCleanFilters, handleSubmit } = props
  const classes = createStyles()
  const isAbleToBuscar = isCoordenador || equipeResponsavelId

  return (
    <div css={classes.container}>
      <Button onClick={onCleanFilters} disabled={!isAbleToBuscar}>
        Limpar filtros
      </Button>
      <SubmitButton handleSubmit={handleSubmit} data-cy='BuscarCidadaosButton' disabled={!isAbleToBuscar}>
        <Icon icon='zoomOutline' style={classes.icon} />
        Buscar cidadãos
      </SubmitButton>
    </div>
  )
}

const createStyles = () => ({
  container: css`
    display: flex;
    justify-content: flex-end;
    padding: 1rem 0;
    & > *:not(:last-child) {
      margin-right: 0.75rem;
    }
  `,
  icon: css`
    margin-right: 0.5rem;
  `,
})

import { SelectField } from 'components/form/final-form'
import { DseiSelectFieldDocument } from 'graphql/hooks.generated'
import { DseiSelectFieldQuery, DseiSelectFieldQueryVariables } from 'graphql/types.generated'
import React from 'react'

import { AsyncSelectFieldProps, DEFAULT_SELECT_PAGE_PARAM, useAsyncQuerySelect } from '../useAsyncQuerySelect'

export type DseiSelectFieldModel = Partial<DseiSelectFieldQuery['dseis']['content'][0]>

export interface DseiSelectFieldProps extends AsyncSelectFieldProps<DseiSelectFieldModel> {
  poloBaseId?: ID
  aldeiaId?: ID
}

export function DseiSelectField(props: DseiSelectFieldProps) {
  const { poloBaseId, aldeiaId, ...rest } = props

  const {
    selectProps: { loading, ...asyncProps },
  } = useAsyncQuerySelect<DseiSelectFieldModel, DseiSelectFieldQuery, DseiSelectFieldQueryVariables>({
    query: DseiSelectFieldDocument,
    extractItems: (data) => data && data.dseis && data.dseis.content,
    variables: (inputQuery: string) => ({
      input: {
        poloBaseId,
        aldeiaId,
        query: inputQuery,
        pageParams: {
          ...DEFAULT_SELECT_PAGE_PARAM,
          sort: ['nome'],
        },
      },
    }),
  })

  const itemToString = (item: DseiSelectFieldModel) => item && item.nome

  return <SelectField<DseiSelectFieldModel> itemToString={itemToString} {...asyncProps} {...rest} />
}

import { SimNaoEnum, simNaoEnumToBoolean } from 'components/form'
import {
  IvcfInput,
  IvcfPerguntaEnum,
  MarcadorConsumoAlimentar,
  MedicoesInput,
  ObjetivoInput,
  ResultadoExameInput,
  TipoAtendimentoProfissional,
} from 'graphql/types.generated'
import { partition } from 'lodash'
import { isUndefinedOrNull } from 'util/checks'
import { parseNumber } from 'util/number'
import { v4 as uuidv4 } from 'uuid'
import { MedicoesPanelModel } from 'view/atendimentos/components/MedicoesPanel/MedicoesPanel'

import { FormAtivoObjetivoEnum, SwitchButtonObjetivoFormModel } from './components/SwitchButtonObjetivoForm'
import { AtendimentoIvcfModel } from './ivcf/model-ivcf'
import { convertMCAQueryToForm, convertMCAToInput } from './marcadores-consumo-alimentar/converter'
import { EvolucaoObjetivoQueryModel } from './model'
import { ObjetivoFormModel } from './ObjetivoForm'
import { convertPuericulturaToInput } from './puericultura/converter-objetivo'
import {
  ResultadoExameComSolicitacaoModel,
  ResultadoExameEspecificoModel,
  ResultadoExameQueryModel,
  ResultadoExameSemSolicitacaoModel,
  ResultadosExamesModel,
  ResultadosExamesNaoSolicitadosModel,
  ResultadosExamesSolicitadosModel,
} from './resultados-exames/model-resultadosExames'
import {
  addPrefix,
  getResultadoExameEspecificoConverterToInput,
  getResultadoExameEspecificoConverterToModel,
  isExameEspecifico,
  removePrefix,
} from './resultados-exames/util-resultadosExames'

export const convertObjetivoFormToInput = (
  form: ObjetivoFormModel,
  isObservacaoAndHasPuericultura: boolean,
  dataNascimentoCidadao: number,
  dataReferencia: number
): ObjetivoInput =>
  form && {
    texto: form.texto,
    medicoes: convertMedicoesToInput(form),
    marcadoresConsumoAlimentar: convertMCAToInput(form, dataNascimentoCidadao, dataReferencia),
    necessidadesEspeciais: form.necessidadesEspeciais,
    puericultura:
      !isObservacaoAndHasPuericultura && form.atendimentosEspecificos?.formAtivo === FormAtivoObjetivoEnum.PUERICULTURA
        ? convertPuericulturaToInput(form.atendimentosEspecificos.puericultura)
        : null,
    resultadosExame: form?.resultadosExames && convertResultadosExamesToInput(form.resultadosExames),
    ivcf: form?.atendimentosEspecificos?.ivcf && convertIvcfToInput(form?.atendimentosEspecificos?.ivcf),
  }

const convertIvcfToInput = (ivcf: AtendimentoIvcfModel): IvcfInput => {
  return {
    aplicacao: Object.entries(ivcf.respostas)
      .filter(([, resposta]: [IvcfPerguntaEnum, SimNaoEnum]) => !isUndefinedOrNull(resposta))
      .map(([pergunta, resposta]: [IvcfPerguntaEnum, SimNaoEnum]) => ({
        pergunta,
        resposta: simNaoEnumToBoolean[resposta],
      })),
  }
}

export const convertMedicoesToInput = (form: ObjetivoFormModel): MedicoesInput => {
  const { openedAccordions, ...rest } = form.medicoes ?? {}
  return {
    dum: form.dum,
    vacinacaoEmDia: form.vacinacaoEmDia,
    ...convertMedicoesPanelToInput(rest),
  }
}

export const convertMedicoesPanelToInput = (medicoes: MedicoesPanelModel): MedicoesInput => ({
  altura: parseNumber(medicoes?.altura),
  circunferenciaAbdominal: parseNumber(medicoes?.circunferenciaAbdominal),
  frequenciaCardiaca: parseNumber(medicoes?.frequenciaCardiaca),
  frequenciaRespiratoria: parseNumber(medicoes?.frequenciaRespiratoria),
  perimetroCefalico: parseNumber(medicoes?.perimetroCefalico),
  perimetroPanturrilha: parseNumber(medicoes?.perimetroPanturrilha),
  peso: parseNumber(medicoes?.peso),
  pressaoArterialDiastolica: parseNumber(medicoes?.pressaoArterialDiastolica),
  pressaoArterialSistolica: parseNumber(medicoes?.pressaoArterialSistolica),
  saturacaoO2: parseNumber(medicoes?.saturacaoO2),
  glicemia: parseNumber(medicoes?.glicemia),
  temperatura: parseNumber(medicoes?.temperatura),
  tipoGlicemia: medicoes?.tipoGlicemia,
})

export type ConverterResultadoExameType = ResultadoExameComSolicitacaoModel &
  ResultadoExameSemSolicitacaoModel &
  ResultadoExameEspecificoModel

export const convertResultadosExamesToInput = (values: ResultadosExamesModel): ResultadoExameInput[] => {
  const { resultadosComSolicitacao, resultadosSemSolicitacao } = values

  const resultadosComSolicitacaoInput = resultadosComSolicitacao
    ? Object.entries(resultadosComSolicitacao).map(([id, value]) =>
        convertResultadoExameToInput(value as ConverterResultadoExameType, id)
      )
    : []

  const resultadosSemSolicitacaoInput =
    Object.values(resultadosSemSolicitacao ?? {}).map((value) => convertResultadoExameToInput(value)) ?? []

  return [...resultadosComSolicitacaoInput, ...resultadosSemSolicitacaoInput]
}

export const convertResultadoExameToInput = (value: ConverterResultadoExameType, id?: ID): ResultadoExameInput => {
  const { dataRealizado, dataResultado, dataSolicitado, descricao, exame = undefined, resultado, editingId } = value

  const isSemSolicitacao = !id
  const isEspecifico = isExameEspecifico(exame)

  const converter = getResultadoExameEspecificoConverterToInput(exame)

  return {
    id: !isUndefinedOrNull(editingId) ? editingId : !isSemSolicitacao ? removePrefix(id) : null,
    exameId: isSemSolicitacao && isUndefinedOrNull(editingId) ? exame.id : null,
    dataResultado,
    dataRealizacao: dataRealizado,
    dataSolicitacao: dataSolicitado,
    resultado: isEspecifico ? descricao : resultado,
    especifico: isEspecifico ? converter(value) : undefined,
  }
}

export const convertResultadosExamesQueryToForm = (inputs: ResultadoExameQueryModel[]): ResultadosExamesModel => {
  const [comSolicitacao, semSolicitacao] = partition(inputs, (input) => !!input.solicitacao)

  return {
    resultadosSemSolicitacao: convertResultadosExamesSemSolicitacaoQueryToModel(semSolicitacao),
    resultadosComSolicitacao: convertResultadosExamesComSolicitacaoQueryToModel(comSolicitacao),
  }
}

const convertResultadosExamesComSolicitacaoQueryToModel = (
  inputs: ResultadoExameQueryModel[]
): ResultadosExamesSolicitadosModel => {
  return inputs
    ? Object.fromEntries(
        inputs.map((input) => {
          const { id } = input

          return [
            addPrefix(id),
            // @diegoasmello fix cast https://github.com/laboratoriobridge/pec/issues/11424
            convertResultadoExameComSolicitacaoQueryToModel(input) as ResultadoExameComSolicitacaoModel &
              ResultadoExameEspecificoModel,
          ]
        })
      )
    : {}
}

const convertResultadoExameComSolicitacaoQueryToModel = (
  input: ResultadoExameQueryModel
): ResultadoExameComSolicitacaoModel | ResultadoExameEspecificoModel => {
  const { id, exame, resultado, dataRealizacao, dataResultado } = input

  const isEspecifico = isExameEspecifico(exame)

  if (isEspecifico) {
    return convertResultadoExameEspecificoQueryToModel(input, true)
  }

  return {
    editingId: id,
    exame,
    resultado,
    dataRealizado: dataRealizacao,
    dataResultado,
  } as ResultadoExameComSolicitacaoModel
}

const convertResultadosExamesSemSolicitacaoQueryToModel = (
  inputs: ResultadoExameQueryModel[]
): ResultadosExamesNaoSolicitadosModel => {
  return inputs
    ? Object.fromEntries(
        inputs.map((input) => {
          return [
            uuidv4(),
            convertResultadoExameSemSolicitacaoQueryToModel(input) as ResultadoExameSemSolicitacaoModel &
              ResultadoExameEspecificoModel,
          ]
        })
      )
    : {}
}

const convertResultadoExameSemSolicitacaoQueryToModel = (
  input: ResultadoExameQueryModel
): ResultadoExameSemSolicitacaoModel | ResultadoExameEspecificoModel => {
  const { id, exame, dataRealizacao, dataResultado, dataSolicitacao, resultado } = input

  const isEspecifico = isExameEspecifico(exame)

  if (isEspecifico) {
    return convertResultadoExameEspecificoQueryToModel(input, false)
  }

  return {
    editingId: id,
    exame,
    resultado,
    dataResultado,
    dataRealizado: dataRealizacao,
    dataSolicitado: dataSolicitacao,
  } as ResultadoExameSemSolicitacaoModel
}

const convertResultadoExameEspecificoQueryToModel = (
  input: ResultadoExameQueryModel,
  hasSolicitacao: boolean
): ResultadoExameEspecificoModel => {
  const { id, exame, dataRealizacao, dataResultado, dataSolicitacao, resultado, especifico } = input

  const converterToModel = getResultadoExameEspecificoConverterToModel(exame)
  const resultadoValue = converterToModel(especifico, exame)

  return {
    editingId: id,
    exame,
    resultado: resultadoValue,
    descricao: resultado,
    dataResultado,
    dataRealizado: dataRealizacao,
    dataSolicitado: hasSolicitacao ? null : dataSolicitacao,
  }
}

const convertTipoAtendimentoToAtendimentosEspecificos = (
  prevValue: SwitchButtonObjetivoFormModel,
  tipoAtendimento: TipoAtendimentoProfissional
): SwitchButtonObjetivoFormModel => {
  const switchFormAtivo = (tipoAtendimentoProfissional: TipoAtendimentoProfissional) => {
    switch (tipoAtendimentoProfissional) {
      case TipoAtendimentoProfissional.PRE_NATAL:
        return FormAtivoObjetivoEnum.PRE_NATAL
      case TipoAtendimentoProfissional.PUERICULTURA:
        return FormAtivoObjetivoEnum.PUERICULTURA
      default:
        return null
    }
  }
  return {
    ...prevValue,
    formAtivo: switchFormAtivo(tipoAtendimento),
  }
}

export const convertObjetivoQueryToForm = (
  prevValue: ObjetivoFormModel,
  tipoAtendimento: TipoAtendimentoProfissional,
  {
    evolucaoObjetivo,
    resultadosExames,
    marcadorConsumoAlimentar,
  }: {
    evolucaoObjetivo: EvolucaoObjetivoQueryModel
    resultadosExames: ResultadoExameQueryModel[]
    marcadorConsumoAlimentar: MarcadorConsumoAlimentar
  }
): ObjetivoFormModel => {
  return {
    ...prevValue,
    texto: evolucaoObjetivo?.descricao,
    resultadosExames: convertResultadosExamesQueryToForm(resultadosExames),
    marcadoresConsumoAlimentar: convertMCAQueryToForm(marcadorConsumoAlimentar),
    atendimentosEspecificos: convertTipoAtendimentoToAtendimentosEspecificos(
      prevValue.atendimentosEspecificos,
      tipoAtendimento
    ),
  }
}

import { ProcedimentoSelectModel } from 'components/form/field/select/ProcedimentoSelectField'
import { Calculation } from 'final-form-calculate'
import { ProcedimentosAutomaticosAtendimentoIndividual } from 'types/enums'
import { isUndefinedOrNull } from 'util/checks'
import { MetaPath } from 'util/metaPath'
import { v4 as uuidv4 } from 'uuid'
import { SoapState } from 'view/atendimentos/atendimento-individual/model'

import { ObjetivoFormModel } from '../objetivo'
import { AtendimentoIvcfModel } from '../objetivo/ivcf/model-ivcf'
import { ProcedimentoPlanoModel } from './components/ProcedimentoPlanoField'
import { PlanoFormModel } from './PlanoForm'

export const createPlanoIvcfCalculations = (
  plano: MetaPath<PlanoFormModel>,
  objetivo: MetaPath<ObjetivoFormModel>,
  intervencoesProcedimentosAutomaticos: ProcedimentoSelectModel[]
): Calculation => ({
  field: [objetivo.atendimentosEspecificos.ivcf.absolutePath()],
  updates: {
    [plano.intervencoesProcedimentos.procedimentos.absolutePath()]: (
      value: AtendimentoIvcfModel,
      allValues: SoapState
    ) => {
      const deveAdicionarProcedimentoAutomaticoIvcf = !isUndefinedOrNull(value)

      if (deveAdicionarProcedimentoAutomaticoIvcf) {
        const procedimentoAutomaticoIvcf = findIntevencoesProcedimentos(
          intervencoesProcedimentosAutomaticos || [],
          ProcedimentosAutomaticosAtendimentoIndividual.AVALIACAO_MULTIDIMENSIONAL_PESSOA_IDOSA
        )
        return addIntervencoesProcedimento(
          allValues.plano?.intervencoesProcedimentos?.procedimentos || [],
          procedimentoAutomaticoIvcf
        )
      }
      return allValues.plano?.intervencoesProcedimentos?.procedimentos
    },
  },
})

const addIntervencoesProcedimento = (
  intervencoesProcedimentos: ProcedimentoPlanoModel[],
  procedimento: ProcedimentoSelectModel
): ProcedimentoPlanoModel[] => {
  if (isUndefinedOrNull(procedimento)) return intervencoesProcedimentos

  const containsProcedimento = intervencoesProcedimentos.find(
    (item) => item.procedimento?.codigo === procedimento.codigo
  )
  if (!containsProcedimento) {
    intervencoesProcedimentos.push({
      _id: uuidv4(),
      procedimento: procedimento,
      automatico: true,
    })
  }

  return intervencoesProcedimentos
}

const findIntevencoesProcedimentos = (
  intervencoesProcedimentos: ProcedimentoSelectModel[],
  codigoProcedimento: String
): ProcedimentoSelectModel => intervencoesProcedimentos.find((item) => item.codigo === codigoProcedimento)

/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, Icon, Text, VFlow } from 'bold-ui'

interface SdcAlertProps {
  alerta: string
}

export const SdcAlert = (props: SdcAlertProps) => {
  const { alerta } = props
  return (
    <VFlow vSpacing={0} style={styles.alertContainer}>
      <HFlow hSpacing={0.5} alignItems='center'>
        <Text fontWeight='bold' color='info'>
          Suporte à decisão clínica
        </Text>
        <Icon icon='bellFilled' size={1.25} />
      </HFlow>
      <Text color='info'>{alerta}</Text>
    </VFlow>
  )
}

const styles = {
  alertContainer: css`
    padding: 0.5rem 0;
  `,
}

import { TipoAgendamentoEnum } from 'graphql/types.generated'
import { EventoAgendaLotacao, TipoAgendamento } from 'view/agenda/model-agenda'

import { AgendamentoCidadaoModel } from '../model-cidadao'

export function convertAgendamentoCidadao(agendamento: AgendamentoCidadaoModel): EventoAgendaLotacao {
  return {
    id: agendamento.id,
    dataCriacao: agendamento.dataCriacao,
    tipo:
      agendamento.tipoAgendamento === TipoAgendamentoEnum.CONSULTA ? TipoAgendamento.CONSULTA : TipoAgendamento.RESERVA,
    title: agendamento.cidadao.nomeSocial ?? agendamento.cidadao.nome,
    start: agendamento.horarioInicial,
    end: agendamento.horarioFinal,
    situacao: agendamento.situacao,
    nomeCidadao: agendamento.cidadao.nome,
    lotacao: agendamento.lotacaoAgendada,
    isAtencaoDomiciliar: agendamento.isAtencaoDomiciliar,
  }
}

/** @jsx jsx * */
import { css, jsx } from '@emotion/core'
import { ApolloError } from 'apollo-client'
import { Alert, Checkbox, Heading, HFlow, InfoLabel, Modal, ModalBody, ModalFooter, VFlow } from 'bold-ui'
import { FooterButton } from 'components/footer-button'
import { useTornarseExecutanteCuidadoCompartilhadoMutation } from 'graphql/hooks.generated'
import { useCallback, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import StatusSquare from 'view/atendimentos/detail/historico/components/StatusSquare'
import { cuidadoCompartilhadoClassificacaoPrioridadeRecord } from 'view/atendimentos/detail/soap/plano/cuidado-compartilhado/model-cuidadocompartilhado'
import { DateTimeInfoCuidadoCompartilhado } from 'view/cuidados-compartilhados/cuidado-compartilhado/components/DateTimeInfoCuidadoCompartilhado'
import { CuidadoCompartilhadoItem } from 'view/cuidados-compartilhados/model-cuidadoCompartilhado'

interface TornarseExecutanteModalProps {
  item: CuidadoCompartilhadoItem
  open: boolean
  onClose(): void
}

export const TornarseExecutanteModal = (props: TornarseExecutanteModalProps) => {
  const { item, open, onClose } = props
  const [tornarseExecutante] = useTornarseExecutanteCuidadoCompartilhadoMutation()
  const [isChecked, setChecked] = useState<boolean>(true)
  const [error, setError] = useState<string>('')

  const match = useRouteMatch()
  const history = useHistory()
  const discutirCasoLink = `${match.path.substring(0, match.path.lastIndexOf('/unidade'))}/compartilhamentos/${
    item.id
  }/discussao-caso`

  const handleClose = () => {
    setChecked(true)
    setError('')
    onClose?.()
  }

  const handleSave = useCallback(async () => {
    await tornarseExecutante({
      variables: {
        id: item.id,
      },
    })
  }, [item.id, tornarseExecutante])

  const handleSubmit = async () => {
    handleSave()
      .then(() => {
        if (isChecked) {
          // setTimeout evita problema com refresh do atmosphere
          setTimeout(() => history.push(discutirCasoLink), 400)
        }
        handleClose()
      })
      .catch((error) => {
        setError((error as ApolloError).graphQLErrors[0].extensions.validationError ?? error)
      })
  }

  const { label, mainColor } = cuidadoCompartilhadoClassificacaoPrioridadeRecord[item.classificacaoPrioridadeAtual]

  return (
    <Modal
      open={open}
      onClose={handleClose}
      onSubmit={handleSubmit}
      onClick={(e) => e.stopPropagation()}
      style={styles.modal}
    >
      <ModalBody style={styles.modalBody}>
        <VFlow vSpacing={0.5}>
          <Heading level={1}>Deseja se tornar o profissional executante deste cuidado?</Heading>
          {error && <Alert type='danger'>{error}</Alert>}
          <InfoLabel title='Cidadão'>{(item.cidadao.nomeSocial ?? item.cidadao.nome).titleCase()}</InfoLabel>
          <InfoLabel title='Problemas e condições'>
            {item.ciap && item.ciap.descricao.capitalize() + ' - ' + item.ciap.codigo + ' (CIAP 2)'}
            {item.ciap && item.cid10 && ' | '}
            {item.cid10 && item.cid10.nome.capitalize() + ' - ' + item.cid10.codigo + ' (CID 10)'}
          </InfoLabel>
          <HFlow justifyContent='flex-start'>
            <InfoLabel title='Prioridade'>
              <HFlow justifyContent='center'>
                <StatusSquare color={mainColor} description={label} />
              </HFlow>
            </InfoLabel>
            <DateTimeInfoCuidadoCompartilhado cuidadoCompartilhado={item} />
          </HFlow>
          <Alert type='warning' styles={{ wrapper: styles.wrapperAlert, container: styles.containerAlert }}>
            Ao se tornar executante, esse registro fica disponível na aba "Compartilhados comigo". Após essa ação, só
            será possível alterar o executante a partir de uma conduta na Discussão de caso.
          </Alert>
        </VFlow>
      </ModalBody>
      <ModalFooter>
        <HFlow justifyContent='space-between'>
          <HFlow hSpacing={0.4} alignItems='center'>
            <Checkbox checked={isChecked} onChange={() => setChecked(!isChecked)} />
            Abrir discussão de caso após confirmar
          </HFlow>
          <HFlow>
            <FooterButton kind='normal' onClick={handleClose}>
              Cancelar
            </FooterButton>
            <FooterButton kind='primary' onClick={handleSubmit}>
              Sim, desejo
            </FooterButton>
          </HFlow>
        </HFlow>
      </ModalFooter>
    </Modal>
  )
}

const styles = {
  modal: css`
    width: 44rem;
  `,
  modalBody: css`
    padding-bottom: 1rem;
  `,
  wrapperAlert: css`
    margin-top: 0.5rem;
  `,
  containerAlert: css`
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  `,
}

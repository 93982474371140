/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Heading, isEmpty, VFlow } from 'bold-ui'
import { turquoise } from 'bold-ui/lib/styles/colors'
import { AtendimentoProfissionalPuericultura } from 'graphql/types.generated'
import {
  convertMedicoesToHistoricoMedicaoModel,
  hasMedicoes,
} from 'view/atendimentos/detail/historico/utils/utils-historico'
import { HistoricoMedicaoModel } from 'view/atendimentos/types/HistoricoMedicaoModel'

import HistoricoMarcosDesenvolvimentoTableBox from './HistoricoMarcosDesenvolvimentoTableBox'
import HistoricoMedicoesPuericulturaTableBox from './HistoricoMedicoesPuericulturaTableBox'
import HistoricoNeuroPuericulturaStatus from './HistoricoNeuroPuericulturaStatus'

interface HistoricoObjetivoInformacoesPuericulturaProps {
  atendProfPuericultura: AtendimentoProfissionalPuericultura
  medicoes: HistoricoMedicaoModel
}

export default function HistoricoObjetivoInformacoesPuericultura(props: HistoricoObjetivoInformacoesPuericulturaProps) {
  const { atendProfPuericultura, medicoes } = props

  return (
    <VFlow vSpacing={1}>
      <Heading
        level={3}
        style={css`
          color: ${turquoise.c40};
        `}
      >
        Informações de puericultura
      </Heading>
      {(hasMedicoes([medicoes]) || atendProfPuericultura.aleitamentoMaterno) && (
        <HistoricoMedicoesPuericulturaTableBox
          medicoes={convertMedicoesToHistoricoMedicaoModel(medicoes)}
          tipoAleitamentoMaterno={atendProfPuericultura.aleitamentoMaterno}
        />
      )}
      {(atendProfPuericultura.neuroAlteracaoFenotipica || atendProfPuericultura.neuroFatorRisco) && (
        <HistoricoNeuroPuericulturaStatus
          neuroAlteracaoFenotipicaElements={atendProfPuericultura.neuroAlteracaoFenotipica}
          neuroFatorRiscoEvolucaoElements={atendProfPuericultura.neuroFatorRisco}
        />
      )}
      {!isEmpty(atendProfPuericultura.neuroMarco) && (
        <HistoricoMarcosDesenvolvimentoTableBox sectionStyle='RA' marcosEvolucao={atendProfPuericultura.neuroMarco} />
      )}
    </VFlow>
  )
}

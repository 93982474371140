import { useApolloClient } from '@apollo/react-hooks'
import {
  ConfiguracoesIceServersDocument,
  useNotificarEntradaParticipanteVideochamadaMutation,
} from 'graphql/hooks.generated'
import { ConfiguracoesIceServersQuery, ConfiguracoesIceServersQueryVariables } from 'graphql/types.generated'
import { useFirebase } from 'hooks/firebase/useFirebase'
import useAtmosphere from 'hooks/useAtmosphere'
import { useOnBeforeUnload } from 'hooks/useOnBeforeUnload'
import { useServerTime } from 'hooks/useServerTime'
import { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react'
import { emptyArray } from 'util/array'

import {
  ChatMessage,
  LocalVideocallParticipant,
  MediaStreamTrackKind,
  RemoteVideocallParticipant,
} from '../model-videochamada'
import { createWebRtcPeer, WebRtcPeer } from '../types/WebRtcPeer'
import { enableTrackStreamEvent } from './useUserMedia'
import { SendSignalCallback, useWebRtcSignalingServer, WebRtcSignal } from './useWebRtcSignalingServer'

interface RemoteParticipantsOptions {
  selfData: LocalVideocallParticipant
  roomId: ID
  audioEnabled: boolean
  videoEnabled: boolean
  presentationStream?: MediaStream
  localStream?: MediaStream
  onPeerDisconnected?(peerId: ID): void
  onNewRemoteMessage?(newMessage: ChatMessage): void
  onConnectionFail?(error: Error): void
}

interface WebRtcChatMessage {
  type: 'chat'
  senderName: string
  message: string
}

interface WebRtcPresentationsMessage {
  type: 'presentations'
  action: 'add' | 'set'
  presentingStreamsIds: ReadonlyArray<ID>
}

interface WebRtcTrackMessage {
  type: 'track'
  kind: MediaStreamTrackKind
  peerId: ID
  audioEnabled?: boolean
  videoEnabled?: boolean
}

type WebRtcDataMessage = WebRtcChatMessage | WebRtcPresentationsMessage | WebRtcTrackMessage

const updateAudioTracks = (stream: MediaStream, enabled: boolean) => {
  stream.getAudioTracks().forEach((track) => {
    track.enabled = enabled
    stream.dispatchEvent(enableTrackStreamEvent(track))
  })
}

const updateVideoTracks = (stream: MediaStream, enabled: boolean) => {
  stream.getVideoTracks().forEach((track) => {
    track.enabled = enabled
    stream.dispatchEvent(enableTrackStreamEvent(track))
  })
}

const updateTracks = (stream: MediaStream, audioEnabled: boolean, videoEnabled: boolean) => {
  updateAudioTracks(stream, audioEnabled)
  updateVideoTracks(stream, videoEnabled)
}

export function useWebRtc(props: RemoteParticipantsOptions) {
  const {
    selfData,
    roomId,
    audioEnabled = false,
    videoEnabled = false,
    localStream,
    presentationStream,
    onPeerDisconnected,
    onNewRemoteMessage,
    onConnectionFail,
  } = props
  const { analytics } = useFirebase()

  const { getServerTimeNow } = useServerTime()
  const client = useApolloClient()
  const iceServersConfiguration = useMemo(
    () =>
      client
        .query<ConfiguracoesIceServersQuery, ConfiguracoesIceServersQueryVariables>({
          query: ConfiguracoesIceServersDocument,
          variables: { input: { userCpf: selfData.cpf } },
        })
        .then((response) => response.data.iceServersConfiguration),
    [client, selfData.cpf]
  )

  const [messages, addMessage] = useReducer(messagesReducer, [])
  const [presentingStreamsIds, dispatchPresenPresentingStreamsIds] = useReducer(
    presentingStreamsIdsReducer,
    new Set<ID>()
  )

  // Tratamento necessário para receber os valores atualizados do state dentro de um useEffect https://legacy.reactjs.org/docs/hooks-faq.html#why-am-i-seeing-stale-props-or-state-inside-my-function
  const presentingStreamsIdsRef = useRef<Set<ID>>(new Set())

  const [remoteParticipants, setRemoteParticipants] = useState<RemoteVideocallParticipant[]>([])
  const [localStreams, setLocalStreams] = useState<ReadonlyArray<MediaStream>>(emptyArray)

  const peers = useRef<Map<string, WebRtcPeer<WebRtcDataMessage>>>(new Map())
  const remoteParticipantsMap = useRef<Map<string, RemoteVideocallParticipant>>(new Map())
  const signalingServerConnected = useRef(false)
  const participantesTopicConnected = useRef(false)

  const [notifyNewParticipant] = useNotificarEntradaParticipanteVideochamadaMutation()

  const handleIncomingTrack = useCallback(
    (participant: LocalVideocallParticipant, stream: MediaStream, track: MediaStreamTrack) => {
      const currentRemoteParticipant = remoteParticipantsMap.current.get(participant.id)
      if (!currentRemoteParticipant.streams.has(stream.id)) currentRemoteParticipant.streams.set(stream.id, stream)
      const currentStream = currentRemoteParticipant.streams.get(stream.id)

      if (track.kind === MediaStreamTrackKind.AUDIO)
        currentStream.getAudioTracks().forEach((t) => currentStream.removeTrack(t))
      else if (track.kind === MediaStreamTrackKind.VIDEO)
        currentStream.getVideoTracks().forEach((t) => currentStream.removeTrack(t))

      currentStream.addTrack(track)
      currentStream.dispatchEvent(enableTrackStreamEvent(track))

      remoteParticipantsMap.current.set(participant.id, currentRemoteParticipant)

      track.addEventListener('mute', () => {
        if (!presentingStreamsIdsRef.current.has(currentStream.id)) {
          currentStream?.removeTrack(track)
          currentStream?.dispatchEvent(enableTrackStreamEvent(null))
        }
        setRemoteParticipants([...remoteParticipantsMap.current.values()])
      })
      track.addEventListener('unmute', () => {
        currentStream?.addTrack(track)
        currentStream?.dispatchEvent(enableTrackStreamEvent(track))
        currentRemoteParticipant.streams.set(currentStream.id, currentStream)
        setRemoteParticipants([...remoteParticipantsMap.current.values()])
      })

      setRemoteParticipants([...remoteParticipantsMap.current.values()])
    },
    []
  )

  useEffect(() => {
    presentingStreamsIdsRef.current = presentingStreamsIds
  }, [presentingStreamsIds])

  const handlePeerClose = useCallback(
    (peerId: ID) => {
      remoteParticipantsMap.current.delete(peerId)
      peers.current.delete(peerId)
      setRemoteParticipants([...remoteParticipantsMap.current.values()])
      onPeerDisconnected?.(peerId)
    },
    [onPeerDisconnected]
  )

  const handleData = useCallback(
    (data: WebRtcDataMessage) => {
      switch (data.type) {
        case 'chat':
          const message = {
            sender: data.senderName,
            time: getServerTimeNow().getTime(),
            content: data.message,
            local: false,
          }
          addMessage(message)
          onNewRemoteMessage(message)
          break
        case 'presentations':
          if (data.action === 'add')
            data.presentingStreamsIds.forEach((streamId) =>
              dispatchPresenPresentingStreamsIds({
                type: 'add',
                streamId,
                peers: peers.current,
              })
            )
          else if (data.action === 'set')
            dispatchPresenPresentingStreamsIds({
              type: 'set',
              streamIds: data.presentingStreamsIds,
            })
          break
        case 'track':
          const remoteStreams = remoteParticipantsMap.current.get(data.peerId)?.streams
          remoteStreams.forEach((stream) => {
            if (!presentingStreamsIdsRef.current.has(stream.id)) {
              if (data.kind === MediaStreamTrackKind.BOTH) updateTracks(stream, data.audioEnabled, data.videoEnabled)
              else if (data.kind === MediaStreamTrackKind.AUDIO) updateAudioTracks(stream, data.audioEnabled)
              else updateVideoTracks(stream, data.videoEnabled)
            }
          })
          setRemoteParticipants([...remoteParticipantsMap.current.values()])
      }
    },
    [getServerTimeNow, onNewRemoteMessage]
  )

  const addPresentingStreamId = useCallback(
    (streamId: ID) => dispatchPresenPresentingStreamsIds({ type: 'add', streamId, peers: peers.current }),
    []
  )

  const removePresentingStreamId = useCallback(
    (streamId: ID) => dispatchPresenPresentingStreamsIds({ type: 'remove', streamId, peers: peers.current }),
    []
  )

  const sendMessage = useCallback(
    (message: string) => {
      peers.current.forEach((peer) => {
        peer.sendData({ type: 'chat', senderName: selfData.nome, message })
      })
      addMessage({
        sender: selfData.nome,
        time: getServerTimeNow().getTime(),
        content: message,
        local: true,
      })
    },
    [getServerTimeNow, selfData.nome]
  )

  const handleConnect = useCallback(
    async (peer: WebRtcPeer<WebRtcDataMessage>) => {
      peer.sendData({ type: 'presentations', action: 'add', presentingStreamsIds: [...presentingStreamsIds] })

      if (await peer.isUsingRelay()) analytics.logEvent('TELE_videochamadas_conexao_sucedida_com_TURN')
      else analytics.logEvent('TELE_videochamadas_conexao_sucedida_sem_TURN')
    },
    [analytics, presentingStreamsIds]
  )

  const addNewPeer = useCallback(
    async (participant: LocalVideocallParticipant, initiator: boolean, sendSignal: SendSignalCallback) => {
      if (
        selfData.id !== participant.id &&
        (!peers.current.has(participant.id) || peers.current.get(participant.id).destroyed)
      ) {
        try {
          const { urls, user, password } = await iceServersConfiguration

          const newPeer = createWebRtcPeer<WebRtcDataMessage>({
            initiator,
            trickle: false,
            config: {
              iceServers: [
                {
                  urls: urls,
                  username: user,
                  credential: password,
                },
              ],
            },
            onSignal: (signal) => sendSignal(participant.id, signal),
            onTrack: (track, stream) => handleIncomingTrack(participant, stream, track),
            onClose: () => handlePeerClose(participant.id),
            onConnect: () => handleConnect(newPeer),
            onData: handleData,
            onError: (error, peer) => {
              onConnectionFail?.(error)
              peer?.destroy()
              handlePeerClose(participant.id)
            },
          })

          remoteParticipantsMap.current.set(participant.id, {
            id: participant.id,
            streams: new Map<ID, MediaStream>(),
            nome: participant.nome,
          })
          setRemoteParticipants([...remoteParticipantsMap.current.values()])
          peers.current.set(participant.id, newPeer)

          localStreams.forEach((stream: MediaStream) => newPeer.addStream(stream))
        } catch (err) {
          onConnectionFail?.(err as Error)
        }
      }
    },
    [
      selfData.id,
      iceServersConfiguration,
      handleData,
      localStreams,
      handleIncomingTrack,
      handlePeerClose,
      handleConnect,
      onConnectionFail,
    ]
  )

  const handleSignal = useCallback(
    async ({ sender, data }: WebRtcSignal, sendSignal: SendSignalCallback) => {
      await addNewPeer(sender, false, sendSignal)
      peers.current.get(sender.id)?.signal(data)
    },
    [addNewPeer]
  )

  const handleTopicConnected = useCallback(
    () =>
      participantesTopicConnected.current &&
      signalingServerConnected.current &&
      notifyNewParticipant({
        variables: { videochamadaUuid: roomId, participanteId: selfData.id, nomeParticipante: selfData.nome },
      }),
    [notifyNewParticipant, roomId, selfData.id, selfData.nome]
  )

  const handleSignalingServerConnected = useCallback(() => {
    signalingServerConnected.current = true
    handleTopicConnected()
  }, [handleTopicConnected])

  const handleParticipantesTopicConnected = useCallback(() => {
    participantesTopicConnected.current = true
    handleTopicConnected()
  }, [handleTopicConnected])

  const { sendSignal } = useWebRtcSignalingServer({
    selfData,
    roomId,
    onSignal: handleSignal,
    onConnect: handleSignalingServerConnected,
  })

  const handleNewParticipant = useCallback(
    (newParticipant: LocalVideocallParticipant) => {
      addNewPeer(newParticipant, true, sendSignal)
    },
    [addNewPeer, sendSignal]
  )

  useAtmosphere<LocalVideocallParticipant>({
    topic: `public/videochamada/${roomId}/participantes`,
    onMessage: handleNewParticipant,
    onConnect: handleParticipantesTopicConnected,
  })

  const lastAudioTrackEnabled = useRef<boolean>()
  const lastVideoTrackEnabled = useRef<boolean>()
  const changedLocalStreams = useRef<ReadonlyArray<MediaStream>>()
  useEffect(() => {
    const localStreamsCurrent = [
      ...(presentationStream ? [presentationStream] : emptyArray),
      ...(localStream ? [localStream] : emptyArray),
    ]

    if (localStreamsCurrent !== changedLocalStreams.current) {
      setLocalStreams(localStreamsCurrent)
      changedLocalStreams.current = localStreamsCurrent
    }

    if (localStream) {
      peers.current.forEach((peer) => {
        if (!peer.destroyed && !!localStream) {
          const kind =
            lastAudioTrackEnabled.current !== audioEnabled && lastVideoTrackEnabled.current !== videoEnabled
              ? MediaStreamTrackKind.BOTH
              : lastAudioTrackEnabled.current !== audioEnabled
              ? MediaStreamTrackKind.AUDIO
              : MediaStreamTrackKind.VIDEO

          peer.sendData({
            type: 'track',
            kind: kind,
            peerId: selfData.id,
            audioEnabled: audioEnabled,
            videoEnabled: videoEnabled,
          })
        }
      })
      lastAudioTrackEnabled.current = audioEnabled
      lastVideoTrackEnabled.current = videoEnabled
    }
  }, [audioEnabled, localStream, presentationStream, selfData.id, videoEnabled])

  const lastLocalStreams = useRef<ReadonlyArray<MediaStream>>()
  useEffect(() => {
    const streamsAdd =
      localStreams?.filter((stream) => !lastLocalStreams.current.some((prevStream) => prevStream.id === stream.id)) ??
      []
    const streamsRemove =
      lastLocalStreams.current?.filter((prevStream) => !localStreams.some((stream) => prevStream.id === stream.id)) ??
      []

    peers.current.forEach((peer) => {
      if (!peer.destroyed) {
        streamsRemove.forEach((stream) => peer.removeStream(stream))
        streamsAdd.forEach((stream) => peer.addStream(stream))
      }
    })
    lastLocalStreams.current = localStreams
  }, [localStreams])

  const destroyAllPeers = useCallback(() => {
    peers.current.forEach((peer) => peer && !peer.destroyed && peer.destroy())
  }, [])

  //onDestroy
  useEffect(() => destroyAllPeers, [destroyAllPeers])
  useOnBeforeUnload(destroyAllPeers)

  return {
    remoteParticipants,
    sendMessage,
    messages,
    addPresentingStreamId,
    removePresentingStreamId,
    presentingStreamsIds: presentingStreamsIds as ReadonlySet<ID>,
  }
}

const messagesReducer = (prevMessages: ChatMessage[], newMessage: ChatMessage) => [...prevMessages, newMessage]

const presentingStreamsIdsReducer = (
  prevPresentingStreamsIds: Set<ID>,
  action:
    | { type: 'add' | 'remove'; streamId: ID; peers: Map<string, WebRtcPeer<WebRtcDataMessage>> }
    | { type: 'set'; streamIds: ReadonlyArray<ID> }
) => {
  let actionTaken = false

  switch (action.type) {
    case 'add':
      actionTaken = !prevPresentingStreamsIds.has(action.streamId)
      if (actionTaken) prevPresentingStreamsIds.add(action.streamId)
      break
    case 'remove':
      actionTaken = prevPresentingStreamsIds.delete(action.streamId)
      break
    case 'set':
      return new Set(action.streamIds)
  }

  if (actionTaken) {
    action.peers.forEach((peer) => {
      peer.sendData({
        type: 'presentations',
        action: 'set',
        presentingStreamsIds: [...prevPresentingStreamsIds],
      })
    })
    return new Set(prevPresentingStreamsIds)
  }

  return prevPresentingStreamsIds
}

export const WEBRTC_CONNECTION_FAILURE_MESSAGE = 'Connection failed.'
export const WEBRTC_ICE_CONNECTION_FAILURE_MESSAGE = 'Ice connection failed.'

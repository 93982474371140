/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, Grid, HFlow, Icon, Text, VFlow } from 'bold-ui'
import {
  CepField,
  Form,
  FormRenderProps,
  RadioGroupField,
  SubmitButton,
  TextField,
  TipoLogradouroSelectField,
} from 'components/form'
import { AldeiaSelectField } from 'components/form/field/select/AldeiaSelectField'
import { DseiSelectField } from 'components/form/field/select/DseiSelectField'
import { SEM_EQUIPE_ITEM } from 'components/form/field/select/EquipeSelectField/EquipeSelectField'
import { PoloBaseSelectField } from 'components/form/field/select/PoloBaseSelectField'
import { Equipe, UnidadeSaude } from 'graphql/types.generated'
import { Fragment, useMemo } from 'react'
import { metaPath } from 'util/metaPath'
import { tipoEnderecoIndigena, tipoEnderecoOptions } from 'view/cadastro-imovel/model-cadastroImovel'
import { useSaudeIndigenaAuthorization } from 'view/cidadao/authorization/useSaudeIndigenaAuthorization'

import { createVisualizacaoTerritorialCalculator } from './calculator-visualizacaoTerritorial'
import { VisualizacaoTerritorialBuscaFormModel } from './model'
import { validateVisualizacaoTerritorialForm } from './validator'

const meta = metaPath<VisualizacaoTerritorialBuscaFormModel>()

interface VisualizacaoTerritorialFormProps {
  onSubmit(formValues: VisualizacaoTerritorialBuscaFormModel): void
  unidadeSaude: UnidadeSaude
  equipe: Equipe
  initialValues: VisualizacaoTerritorialBuscaFormModel
}

export function VisualizacaoTerritorialBuscaForm({
  onSubmit,
  unidadeSaude,
  equipe,
  initialValues,
}: VisualizacaoTerritorialFormProps) {
  const equipeTerritorio = equipe ?? SEM_EQUIPE_ITEM
  const { hasTerritorioIndigenaPermission } = useSaudeIndigenaAuthorization()
  const decorators = useMemo(() => [createVisualizacaoTerritorialCalculator(meta)], [])

  const renderForm = ({ handleSubmit, form, values }: FormRenderProps<VisualizacaoTerritorialBuscaFormModel>) => {
    const isEnderecoLogradouro = !tipoEnderecoIndigena.includes(values?.tipoEndereco)
    const isEnderecoIndigena = tipoEnderecoIndigena.includes(values?.tipoEndereco)
    const isBuscarDisabled = isEnderecoIndigena && (!values?.poloBase || !values?.dsei)

    return (
      <VFlow vSpacing={4}>
        <Grid wrap>
          <Cell size={4}>
            <VFlow vSpacing={0.2}>
              <Text variant='h4' fontWeight='bold'>
                Unidade responsável
              </Text>
              <Text variant='h4' fontWeight='normal'>
                {unidadeSaude.nome}
              </Text>
            </VFlow>
          </Cell>
          <Cell size={3}>
            <VFlow vSpacing={0.2}>
              <Text variant='h4' fontWeight='bold'>
                Equipe
              </Text>
              <Text variant='h4' fontWeight='normal'>{`${equipeTerritorio?.nome} ${
                equipeTerritorio?.ine !== SEM_EQUIPE_ITEM.ine ? `| ${equipeTerritorio?.ine}` : ''
              }`}</Text>
            </VFlow>
          </Cell>
          <Cell size={5} />
          {hasTerritorioIndigenaPermission && (
            <Cell size={12}>
              <RadioGroupField
                label='Tipo de endereço'
                name={meta.tipoEndereco}
                options={tipoEnderecoOptions}
                required
              />
            </Cell>
          )}
          {isEnderecoLogradouro && (
            <Fragment>
              <Cell size={4}>
                <TextField name={meta.bairro} label='Bairro' maxLength={72} />
              </Cell>
              <Cell size={2}>
                <CepField name={meta.cep} label='CEP' />
              </Cell>
              <Cell size={6} />
              <Cell size={3}>
                <TipoLogradouroSelectField name={meta.tipoLogradouro} label='Tipo de logradouro' />
              </Cell>
              <Cell size={5}>
                <TextField name={meta.logradouro} label='Logradouro' maxLength={72} />
              </Cell>
            </Fragment>
          )}
          {isEnderecoIndigena && (
            <Fragment>
              <Cell size={4}>
                <DseiSelectField
                  name={meta.dsei}
                  label='DSEI'
                  poloBaseId={values.poloBase?.id}
                  aldeiaId={values.aldeia?.id}
                  required
                />
              </Cell>
              <Cell size={4}>
                <PoloBaseSelectField
                  name={meta.poloBase}
                  label='Polo base'
                  dseiId={values.dsei?.id}
                  aldeiaId={values.aldeia?.id}
                  required
                />
              </Cell>
              <Cell size={4}>
                <AldeiaSelectField
                  name={meta.aldeia}
                  label='Aldeia'
                  dseiId={values.dsei?.id}
                  poloBaseId={values.poloBase?.id}
                />
              </Cell>
            </Fragment>
          )}
          <Cell size={isEnderecoIndigena ? 12 : 3}>
            <HFlow hSpacing={0.5} justifyContent={isEnderecoIndigena ? 'flex-end' : 'flex-start'}>
              <SubmitButton
                size='small'
                handleSubmit={handleSubmit}
                style={css`
                  margin-top: 1.45rem;
                `}
                disabled={isBuscarDisabled}
              >
                <Icon
                  icon='zoomOutline'
                  style={css`
                    margin-right: 0.5rem;
                  `}
                />
                Buscar
              </SubmitButton>
              <Button
                onClick={() =>
                  form.reset({ cnes: unidadeSaude.cnes, ine: equipeTerritorio.ine, tipoEndereco: values.tipoEndereco })
                }
                size='small'
                style={css`
                  margin-top: 1.45rem;
                `}
              >
                Limpar busca
              </Button>
            </HFlow>
          </Cell>
        </Grid>
      </VFlow>
    )
  }

  return (
    <Form<VisualizacaoTerritorialBuscaFormModel>
      render={renderForm}
      onSubmit={onSubmit}
      validate={validateVisualizacaoTerritorialForm}
      initialValues={initialValues}
      decorators={decorators}
    />
  )
}

import { Breadcrumb } from 'components/breadcrumb'
import { PecSwitch, PrivateRoute } from 'components/route'
import React from 'react'
import { useRouteMatch } from 'react-router'
import Permissions from 'types/Permissions'

import { CidadaoCreateView } from './create/CidadaoCreateView'
import { CidadaoEditView } from './create/CidadaoEditView'
import { CidadaoListView } from './list/CidadaoListView'
import { visualizarCidadaoAndProntuarioPermissions } from './types/model-cidadaoTabSection'
import { CidadaoDadosView } from './visualizacao/CidadaoDadosView'

export function CidadaoRootView() {
  const match = useRouteMatch()

  return (
    <>
      <Breadcrumb title='Cidadão' />

      <PecSwitch>
        <PrivateRoute
          path={`${match.url}/create`}
          component={CidadaoCreateView}
          permission={Permissions.gestaoDeCadastrosDeCidadao.visualizarCidadao.cadastrarEEditar}
        />

        <PrivateRoute
          exact
          path={`${match.url}/:cidadaoId(\\d+)/edit`}
          component={CidadaoEditView}
          permission={Permissions.gestaoDeCadastrosDeCidadao.visualizarCidadao.cadastrarEEditar}
        />
        <PrivateRoute
          exact
          path={`${match.url}`}
          component={CidadaoListView}
          permission={Permissions.gestaoDeCadastrosDeCidadao.visualizarCidadao}
        />
        <PrivateRoute
          path={`${match.url}/:cidadaoId(\\d+)`}
          component={CidadaoDadosView}
          permission={visualizarCidadaoAndProntuarioPermissions}
        />
      </PecSwitch>
    </>
  )
}

import { EnderecoFieldGroupModel, MunicipioSelectModel } from 'components/form'
import { EnderecoIndigenaFieldGroupModel } from 'components/form/field/enderecoindigena/model-enderecoIndigena'
import { AldeiaSelectFieldModel } from 'components/form/field/select/AldeiaSelectField'
import { DseiSelectFieldModel } from 'components/form/field/select/DseiSelectField'
import { PaisSelectFieldModel } from 'components/form/field/select/PaisSelectField'
import { PoloBaseSelectFieldModel } from 'components/form/field/select/PoloBaseSelectField'
import { resolveValue } from 'components/form/final-form/hooks/useField'
import createDecorator, { Calculation } from 'final-form-calculate'
import { TipoEnderecoEnum } from 'graphql/types.generated'
import { MetaRoot } from 'util/metaPath'
import { tipoEnderecoIndigena } from 'view/cadastro-imovel/model-cadastroImovel'

import CidadaoFormModel from '../types/model-cidadao'

const CODIGO_PAIS_BRASIL = '31'

const enderecoIndigenaCalculator = (meta: MetaRoot<EnderecoIndigenaFieldGroupModel>): Calculation[] => [
  {
    field: meta.dsei.absolutePath(),
    updates: {
      [meta.poloBase.absolutePath()]: (dsei: DseiSelectFieldModel, allValues: CidadaoFormModel) =>
        dsei?.id !== allValues?.enderecoIndigena?.poloBase?.dsei.id ? null : allValues?.enderecoIndigena?.poloBase,
    },
  },
  {
    field: meta.poloBase.absolutePath(),
    updates: {
      [meta.dsei.absolutePath()]: (poloBase: PoloBaseSelectFieldModel, allValues: CidadaoFormModel) =>
        !!poloBase?.dsei ? poloBase.dsei : allValues?.enderecoIndigena?.dsei,
      [meta.aldeia.absolutePath()]: (poloBase: PoloBaseSelectFieldModel, allValues: CidadaoFormModel) =>
        poloBase?.id !== allValues?.enderecoIndigena?.aldeia?.poloBase?.id ? null : allValues?.enderecoIndigena?.aldeia,
    },
  },
  {
    field: meta.aldeia.absolutePath(),
    updates: {
      [meta.poloBase.absolutePath()]: (aldeia: AldeiaSelectFieldModel, allValues: CidadaoFormModel) =>
        !!aldeia?.poloBase ? aldeia.poloBase : allValues?.enderecoIndigena?.poloBase,
      [meta.dsei.absolutePath()]: (aldeia: AldeiaSelectFieldModel, allValues: CidadaoFormModel) =>
        !!aldeia?.poloBase?.dsei ? aldeia.poloBase.dsei : allValues?.enderecoIndigena?.dsei,
      [meta.municipio.absolutePath()]: (aldeia: AldeiaSelectFieldModel) => {
        if (aldeia?.id) {
          if (aldeia.municipio.length > 1) {
            return null
          } else {
            return aldeia.municipio[0]
          }
        } else return undefined
      },
      [meta.uf.absolutePath()]: (aldeia: AldeiaSelectFieldModel) => {
        if (aldeia?.id) {
          if (aldeia.municipio.length > 1) {
            return null
          } else {
            return aldeia.municipio[0].uf
          }
        } else return undefined
      },
    },
  },
  {
    field: meta.municipio.absolutePath(),
    updates: {
      [meta.uf.absolutePath()]: (municipio: MunicipioSelectModel) => (!!municipio?.uf ? municipio.uf : undefined),
    },
  },
]

export const enderecoCalculator = (path: MetaRoot<EnderecoFieldGroupModel>): Calculation[] => [
  {
    field: path.pais.absolutePath(),
    updates: {
      [path.cep.absolutePath()]: (value: PaisSelectFieldModel, values: EnderecoFieldGroupModel) => {
        const endereco = resolveValue(values, path)
        return value?.id !== CODIGO_PAIS_BRASIL ? null : endereco.cep
      },
      [path.uf.absolutePath()]: (value: PaisSelectFieldModel, values: EnderecoFieldGroupModel) => {
        const endereco = resolveValue(values, path)
        return value?.id !== CODIGO_PAIS_BRASIL ? null : endereco.uf
      },
      [path.municipio.absolutePath()]: (value: PaisSelectFieldModel, values: EnderecoFieldGroupModel) => {
        const endereco = resolveValue(values, path)
        return value?.id !== CODIGO_PAIS_BRASIL ? null : endereco.municipio
      },
      [path.bairro.absolutePath()]: (value: PaisSelectFieldModel, values: EnderecoFieldGroupModel) => {
        const endereco = resolveValue(values, path)
        return value?.id !== CODIGO_PAIS_BRASIL ? null : endereco.bairro
      },
      [path.tipoLogradouro.absolutePath()]: (value: PaisSelectFieldModel, values: EnderecoFieldGroupModel) => {
        const endereco = resolveValue(values, path)
        return value?.id !== CODIGO_PAIS_BRASIL ? null : endereco.tipoLogradouro
      },
      [path.logradouro.absolutePath()]: (value: PaisSelectFieldModel, values: EnderecoFieldGroupModel) => {
        const endereco = resolveValue(values, path)
        return value?.id !== CODIGO_PAIS_BRASIL ? null : endereco.logradouro
      },
      [path.numero.absolutePath()]: (value: PaisSelectFieldModel, values: EnderecoFieldGroupModel) => {
        const endereco = resolveValue(values, path)
        return value?.id !== CODIGO_PAIS_BRASIL ? null : endereco.numero
      },
      [path.semNumero.absolutePath()]: (value: PaisSelectFieldModel, values: EnderecoFieldGroupModel) => {
        const endereco = resolveValue(values, path)
        return value?.id !== CODIGO_PAIS_BRASIL ? null : endereco.semNumero
      },
      [path.complemento.absolutePath()]: (value: PaisSelectFieldModel, values: EnderecoFieldGroupModel) => {
        const endereco = resolveValue(values, path)
        return value?.id !== CODIGO_PAIS_BRASIL ? null : endereco.complemento
      },
      [path.pontoReferencia.absolutePath()]: (value: PaisSelectFieldModel, values: EnderecoFieldGroupModel) => {
        const endereco = resolveValue(values, path)
        return value?.id !== CODIGO_PAIS_BRASIL ? null : endereco.pontoReferencia
      },
      [path.area.absolutePath()]: (value: PaisSelectFieldModel, values: EnderecoFieldGroupModel) => {
        const endereco = resolveValue(values, path)
        return value?.id !== CODIGO_PAIS_BRASIL ? null : endereco.area
      },
      [path.microArea.absolutePath()]: (value: PaisSelectFieldModel, values: EnderecoFieldGroupModel) => {
        const endereco = resolveValue(values, path)
        return value?.id !== CODIGO_PAIS_BRASIL ? null : endereco.microArea
      },
      [path.foraArea.absolutePath()]: (value: PaisSelectFieldModel, values: EnderecoFieldGroupModel) => {
        const endereco = resolveValue(values, path)
        return value?.id !== CODIGO_PAIS_BRASIL ? null : endereco.foraArea
      },
      [path.municipioResidenciaExterior.absolutePath()]: (
        value: PaisSelectFieldModel,
        values: EnderecoFieldGroupModel
      ) => {
        const endereco = resolveValue(values, path)
        return value?.id === CODIGO_PAIS_BRASIL ? null : endereco.municipioResidenciaExterior
      },
    },
  },
  {
    field: path.semNumero.absolutePath(),
    updates: {
      [path.numero.absolutePath()]: (value: boolean, values: EnderecoFieldGroupModel) => {
        const endereco = resolveValue(values, path)
        return value ? 'S/N' : endereco.numero === 'S/N' ? null : endereco.numero
      },
    },
  },
  {
    field: path.foraArea.absolutePath(),
    updates: {
      [path.area.absolutePath()]: (value: boolean, values: EnderecoFieldGroupModel) => {
        const endereco = resolveValue(values, path)
        return value ? null : endereco.area
      },
      [path.microArea.absolutePath()]: (value: boolean, values: EnderecoFieldGroupModel) => {
        const endereco = resolveValue(values, path)
        return value ? 'FA' : endereco.microArea === 'FA' ? null : endereco.microArea
      },
    },
  },
]

const clearEnderecoCalculator = (meta: MetaRoot<CidadaoFormModel>): Calculation[] => {
  const metaEndereco = meta.endereco
  const metaEnderecoIndigena = meta.enderecoIndigena

  return [
    {
      field: meta.tipoEndereco.absolutePath(),
      updates: {
        [metaEndereco.pais.absolutePath()]: (tipoEndereco: TipoEnderecoEnum, values: CidadaoFormModel) => {
          const endereco = resolveValue(values, metaEndereco)
          if (tipoEndereco !== TipoEnderecoEnum.LOGRADOURO) {
            return null
          } else {
            return endereco.pais || { id: CODIGO_PAIS_BRASIL, nome: 'BRASIL' }
          }
        },
        [metaEndereco.cep.absolutePath()]: (tipoEndereco: TipoEnderecoEnum, values: CidadaoFormModel) => {
          const endereco = resolveValue(values, metaEndereco)
          return tipoEndereco !== TipoEnderecoEnum.LOGRADOURO ? null : endereco.cep
        },
        [metaEndereco.uf.absolutePath()]: (tipoEndereco: TipoEnderecoEnum, values: CidadaoFormModel) => {
          const endereco = resolveValue(values, metaEndereco)
          return tipoEndereco !== TipoEnderecoEnum.LOGRADOURO ? null : endereco.uf
        },
        [metaEndereco.municipio.absolutePath()]: (tipoEndereco: TipoEnderecoEnum, values: CidadaoFormModel) => {
          const endereco = resolveValue(values, metaEndereco)
          return tipoEndereco !== TipoEnderecoEnum.LOGRADOURO ? null : endereco.municipio
        },
        [metaEndereco.bairro.absolutePath()]: (tipoEndereco: TipoEnderecoEnum, values: CidadaoFormModel) => {
          const endereco = resolveValue(values, metaEndereco)
          return tipoEndereco !== TipoEnderecoEnum.LOGRADOURO ? null : endereco.bairro
        },
        [metaEndereco.tipoLogradouro.absolutePath()]: (tipoEndereco: TipoEnderecoEnum, values: CidadaoFormModel) => {
          const endereco = resolveValue(values, metaEndereco)
          return tipoEndereco !== TipoEnderecoEnum.LOGRADOURO ? null : endereco.tipoLogradouro
        },
        [metaEndereco.logradouro.absolutePath()]: (tipoEndereco: TipoEnderecoEnum, values: CidadaoFormModel) => {
          const endereco = resolveValue(values, metaEndereco)
          return tipoEndereco !== TipoEnderecoEnum.LOGRADOURO ? null : endereco.logradouro
        },
        [metaEndereco.numero.absolutePath()]: (tipoEndereco: TipoEnderecoEnum, values: CidadaoFormModel) => {
          const endereco = resolveValue(values, metaEndereco)
          return tipoEndereco !== TipoEnderecoEnum.LOGRADOURO ? null : endereco.numero
        },
        [metaEndereco.semNumero.absolutePath()]: (tipoEndereco: TipoEnderecoEnum, values: CidadaoFormModel) => {
          const endereco = resolveValue(values, metaEndereco)
          return tipoEndereco !== TipoEnderecoEnum.LOGRADOURO ? null : endereco.semNumero
        },
        [metaEndereco.complemento.absolutePath()]: (tipoEndereco: TipoEnderecoEnum, values: CidadaoFormModel) => {
          const endereco = resolveValue(values, metaEndereco)
          return tipoEndereco !== TipoEnderecoEnum.LOGRADOURO ? null : endereco.complemento
        },
        [metaEndereco.pontoReferencia.absolutePath()]: (tipoEndereco: TipoEnderecoEnum, values: CidadaoFormModel) => {
          const endereco = resolveValue(values, metaEndereco)
          return tipoEndereco !== TipoEnderecoEnum.LOGRADOURO ? null : endereco.pontoReferencia
        },
        [metaEndereco.area.absolutePath()]: (tipoEndereco: TipoEnderecoEnum, values: CidadaoFormModel) => {
          const endereco = resolveValue(values, metaEndereco)
          return tipoEndereco !== TipoEnderecoEnum.LOGRADOURO ? null : endereco.area
        },
        [metaEndereco.microArea.absolutePath()]: (tipoEndereco: TipoEnderecoEnum, values: CidadaoFormModel) => {
          const endereco = resolveValue(values, metaEndereco)
          return tipoEndereco !== TipoEnderecoEnum.LOGRADOURO ? null : endereco.microArea
        },
        [metaEndereco.foraArea.absolutePath()]: (tipoEndereco: TipoEnderecoEnum, values: CidadaoFormModel) => {
          const endereco = resolveValue(values, metaEndereco)
          return tipoEndereco !== TipoEnderecoEnum.LOGRADOURO ? null : endereco.foraArea
        },
        [metaEndereco.municipioResidenciaExterior.absolutePath()]: (
          tipoEndereco: TipoEnderecoEnum,
          values: CidadaoFormModel
        ) => {
          const endereco = resolveValue(values, metaEndereco)
          return tipoEndereco !== TipoEnderecoEnum.LOGRADOURO ? null : endereco.municipioResidenciaExterior
        },
        [metaEndereco.numeroFamilia.absolutePath()]: (tipoEndereco: TipoEnderecoEnum, values: CidadaoFormModel) => {
          const endereco = resolveValue(values, metaEndereco)
          return tipoEndereco !== TipoEnderecoEnum.LOGRADOURO ? null : endereco.numeroFamilia
        },
        [metaEnderecoIndigena.aldeia.absolutePath()]: (tipoEndereco: TipoEnderecoEnum, values: CidadaoFormModel) => {
          const enderecoIndigena = resolveValue(values, metaEnderecoIndigena)
          return !tipoEnderecoIndigena.includes(tipoEndereco) ? null : enderecoIndigena?.aldeia
        },
        [metaEnderecoIndigena.dsei.absolutePath()]: (tipoEndereco: TipoEnderecoEnum, values: CidadaoFormModel) => {
          const enderecoIndigena = resolveValue(values, metaEnderecoIndigena)
          return !tipoEnderecoIndigena.includes(tipoEndereco) ? null : enderecoIndigena?.dsei
        },
        [metaEnderecoIndigena.poloBase.absolutePath()]: (tipoEndereco: TipoEnderecoEnum, values: CidadaoFormModel) => {
          const enderecoIndigena = resolveValue(values, metaEnderecoIndigena)
          return !tipoEnderecoIndigena.includes(tipoEndereco) ? null : enderecoIndigena?.poloBase
        },
        [metaEnderecoIndigena.uf.absolutePath()]: (tipoEndereco: TipoEnderecoEnum, values: CidadaoFormModel) => {
          const enderecoIndigena = resolveValue(values, metaEnderecoIndigena)
          return !tipoEnderecoIndigena.includes(tipoEndereco) ? null : enderecoIndigena?.uf
        },
        [metaEnderecoIndigena.municipio.absolutePath()]: (tipoEndereco: TipoEnderecoEnum, values: CidadaoFormModel) => {
          const enderecoIndigena = resolveValue(values, metaEnderecoIndigena)
          return !tipoEnderecoIndigena.includes(tipoEndereco) ? null : enderecoIndigena?.municipio
        },
        [metaEnderecoIndigena.numero.absolutePath()]: (tipoEndereco: TipoEnderecoEnum, values: CidadaoFormModel) => {
          const enderecoIndigena = resolveValue(values, metaEnderecoIndigena)
          return !tipoEnderecoIndigena.includes(tipoEndereco) ? null : enderecoIndigena?.numero
        },
        [metaEnderecoIndigena.numeroFamilia.absolutePath()]: (
          tipoEndereco: TipoEnderecoEnum,
          values: CidadaoFormModel
        ) => {
          const enderecoIndigena = resolveValue(values, metaEnderecoIndigena)
          return !tipoEnderecoIndigena.includes(tipoEndereco) ? null : enderecoIndigena?.numeroFamilia
        },
      },
    },
  ]
}

export const createEnderecoCalculator = (meta: MetaRoot<CidadaoFormModel>) =>
  createDecorator(
    ...enderecoCalculator(meta.endereco),
    ...enderecoIndigenaCalculator(meta.enderecoIndigena),
    ...clearEnderecoCalculator(meta)
  )

import { CidadaoSelectFieldModel, SimNaoEnum, simNaoEnumToBoolean } from 'components/form'
import { isBefore, parseISO } from 'date-fns'
import {
  AnimaisDomicilioEnum,
  AnimaisImovel,
  CidadaoTerritorioBasico,
  NacionalidadeEnum,
  RacaCorDbEnum,
  TipoImovelEnum,
} from 'graphql/types.generated'
import { isEmpty } from 'lodash'
import { isUndefinedOrNull } from 'util/checks'

import {
  AnimaisImovelFormModel,
  CadastrarCidadaoFamiliaFormModel,
  CadastrarEditarFamiliaFormModel,
  CadastroImovelCacheState,
  CidadaoTerritorioFormModel,
  CidadaoTerritorioModel,
  FamiliaFormModel,
  tipoImoveisResidenciais,
} from './model-cadastroImovel'

export const simNaoEnumToBooleanWrapper = (simNaoEnumValue: SimNaoEnum) => {
  return simNaoEnumToBoolean[simNaoEnumValue ?? SimNaoEnum.NAO]
}

export const booleanToSimNaoEnum = (bool: boolean) => (bool === null ? null : bool ? SimNaoEnum.SIM : SimNaoEnum.NAO)

export const buildAnimais = (animaisImovel: AnimaisImovel) => {
  let animais: AnimaisDomicilioEnum[] = []
  animaisImovel.possuiCachorro && animais.push(AnimaisDomicilioEnum.CACHORRO)
  animaisImovel.possuiGato && animais.push(AnimaisDomicilioEnum.GATO)
  animaisImovel.possuiPassaro && animais.push(AnimaisDomicilioEnum.PASSARO)
  animaisImovel.possuiMacaco && animais.push(AnimaisDomicilioEnum.MACACO)
  animaisImovel.possuiGalinha && animais.push(AnimaisDomicilioEnum.GALINHA)
  animaisImovel.possuiPorco && animais.push(AnimaisDomicilioEnum.PORCO)
  animaisImovel.possuiRepteis && animais.push(AnimaisDomicilioEnum.REPTEIS)
  animaisImovel.possuiOutros && animais.push(AnimaisDomicilioEnum.OUTRO)
  return animais
}

export const evaluatePossuiAnimal = (animaisNoDomicilio: AnimaisImovelFormModel, animal: AnimaisDomicilioEnum) =>
  animaisNoDomicilio?.animais?.indexOf(animal) !== -1

export const isTipoImovelDomicilio = (tipoImovelEnum: TipoImovelEnum) => tipoImovelEnum === TipoImovelEnum.DOMICILIO

export const isTipoImovelResidencialExcetoDomicilio = (tipoImovel: TipoImovelEnum) =>
  tipoImoveisResidenciais.filter((tipoImovel) => tipoImovel !== TipoImovelEnum.DOMICILIO).includes(tipoImovel)

export const isTipoImovelResidencial = (tipoImovel: TipoImovelEnum) => tipoImoveisResidenciais.includes(tipoImovel)

export const possuiOutrosProfissionais = (possuiOutrosProfissionais: string) => {
  if (isUndefinedOrNull(possuiOutrosProfissionais)) return null
  else return possuiOutrosProfissionais === SimNaoEnum.SIM ? true : false
}

export const possuiOutrosProfissionaisToSimNaoEnum = (possuiOutrosProfissionais: boolean) => {
  if (isUndefinedOrNull(possuiOutrosProfissionais)) return null
  return possuiOutrosProfissionais ? SimNaoEnum.SIM : SimNaoEnum.NAO
}

export const possuiCidadaoPresenteOutrasFamilias = (
  familias: Array<FamiliaFormModel>,
  cidadaoId: ID,
  familiaId: ID
): boolean => {
  const outrasFamilias = familias?.filter((familia) => familia._id !== familiaId)
  if (!outrasFamilias) return false

  return outrasFamilias.some(
    (familia) => familia.responsavelId === cidadaoId || isCidadaoPresenteFamilia(familia, cidadaoId)
  )
}

export const isDataBeforeDataNascimento = (data: string, dataNascimento: string) => {
  return data && isBefore(parseISO(data), parseISO(dataNascimento))
}

export const isCidadaoPresenteFamilia = (familia: FamiliaFormModel, cidadaoId: ID) =>
  familia.cidadaos.some((cidadao) => cidadao.id === cidadaoId)

export const cidadaoIdByCpfCns = (cpfCns: string, cidadaos: Array<CidadaoTerritorioBasico>) => {
  return cidadaos.find((cidadao) => cidadao.cpf === cpfCns || cidadao.cns === cpfCns)?.id
}

export const getResponsavelDependentesForm = (responsavelId: ID, cidadaos: Array<CidadaoTerritorioFormModel>) => {
  return cidadaos?.reduce(
    (partition, cidadao) => {
      if (cidadao.id === responsavelId) {
        partition.responsavelFormModel = cidadao
      } else {
        partition.dependentesFormModel.push(cidadao)
      }
      return partition
    },
    { responsavelFormModel: null, dependentesFormModel: [] }
  )
}

export const setCadastroCidadaoCacheState = (
  form: CadastrarCidadaoFamiliaFormModel | null,
  renderAfterRedirect: boolean,
  cache: CadastroImovelCacheState
): CadastroImovelCacheState => ({
  ...cache,
  familiaCidadaoModalState: {
    ...cache?.familiaCidadaoModalState,
    cadastroCidadaoModal: {
      form,
      modalOpen: renderAfterRedirect,
    },
  },
})

export const setCadastroFamiliaCacheState = (
  form: CadastrarEditarFamiliaFormModel | null,
  renderAfterRedirect: boolean,
  cache: CadastroImovelCacheState,
  editionId?: ID
) => ({
  ...cache,
  familiaCidadaoModalState: {
    ...cache?.familiaCidadaoModalState,
    cadastroFamiliaModal: {
      form: form,
      modalOpen: renderAfterRedirect,
      editionId,
    },
  },
})

export const mudancaResponsavelFamiliar = (
  familia: FamiliaFormModel,
  responsavel: CidadaoSelectFieldModel
): FamiliaFormModel => {
  const novoResponsavelPresenteFamilia = familia.cidadaos.some((cidadao) => cidadao.id === responsavel.id)

  if (!novoResponsavelPresenteFamilia) {
    familia.cidadaos.push({
      _id: responsavel.id,
      id: responsavel.id,
      mudouSe: false,
      registroObito: null,
      isNovoCadastroNaFamilia: true,
    })
  }

  familia.responsavelId = responsavel.id
  return familia
}

export const getCamposNulosCidadaoTerritorioModel = (cidadao: CidadaoTerritorioModel): string[] => {
  let camposNulos = []

  if (isEmpty(cidadao?.cpf) && isEmpty(cidadao?.cns)) camposNulos.push('CPF ou CNS')
  if (isEmpty(cidadao?.dataNascimento)) camposNulos.push('data de nascimento')
  if (isEmpty(cidadao?.nome)) camposNulos.push('nome')
  if (isEmpty(cidadao?.nomeMae) && !cidadao?.desconheceNomeMae) camposNulos.push('nome da mãe')
  if (isEmpty(cidadao?.nomePai) && !cidadao?.desconheceNomePai) camposNulos.push('nome do pai')
  if (cidadao?.racaCor?.racaCorDbEnum === RacaCorDbEnum.INDIGENA && isEmpty(cidadao?.etnia)) camposNulos.push('etnia')

  if (cidadao?.nacionalidade?.nacionalidadeDbEnum === NacionalidadeEnum.BRASILEIRA) {
    if (isUndefinedOrNull(cidadao?.localidadeNascimento?.id)) camposNulos.push('município de nascimento')
    if (isUndefinedOrNull(cidadao?.localidadeNascimento?.uf?.id)) camposNulos.push('UF de nascimento')
    if (isUndefinedOrNull(cidadao?.cidadaoAldeado?.id)) {
      if (isUndefinedOrNull(cidadao?.telefoneCelular)) camposNulos.push('telefone celular')
    }
  }

  if (cidadao?.nacionalidade?.nacionalidadeDbEnum === NacionalidadeEnum.ESTRANGEIRA) {
    if (isEmpty(cidadao?.paisNascimento)) camposNulos.push('pais de nascimento')
    if (isEmpty(cidadao?.dataEntradaBrasil)) camposNulos.push('data de entrada no Brasil')
  }

  if (cidadao?.nacionalidade?.nacionalidadeDbEnum === NacionalidadeEnum.NATURALIZADA) {
    if (isEmpty(cidadao?.dataNaturalizacao)) camposNulos.push('data de naturalização')
    if (isEmpty(cidadao?.portariaNaturalizacao)) camposNulos.push('portaria de naturalização')
    if (isUndefinedOrNull(cidadao?.cidadaoAldeado?.id)) {
      if (isUndefinedOrNull(cidadao?.telefoneCelular)) camposNulos.push('telefone celular')
    }
  }

  if (isEmpty(cidadao?.sexo)) camposNulos.push('sexo')
  if (isEmpty(cidadao?.racaCor?.racaCorDbEnum)) camposNulos.push('raça')
  if (isEmpty(cidadao?.nacionalidade)) camposNulos.push('nacionalidade')

  return camposNulos.filterNotNull().sort()
}

import { SimNaoEnum } from 'components/form'
import { resolveValue } from 'components/form/final-form/hooks/useField'
import createCalculator from 'final-form-calculate'
import { metaPath } from 'util/metaPath'

import CidadaoFormModel from '../types/model-cidadao'

const path = metaPath<CidadaoFormModel>()

export const infoComplementaresCalculator = createCalculator(
  {
    field: path.informacoesComplementares.desejaInformarOrientacaoSexual.absolutePath(),
    updates: {
      [path.informacoesComplementares.orientacaoSexual.absolutePath()]: (
        value: SimNaoEnum,
        values: CidadaoFormModel
      ) => {
        const infoComplementares = resolveValue(values, path.informacoesComplementares)
        return SimNaoEnum.NAO === value ? null : infoComplementares?.orientacaoSexual
      },
    },
  },
  {
    field: path.informacoesComplementares.desejaInformarIdentidadeGenero.absolutePath(),
    updates: {
      [path.informacoesComplementares.identidadeGenero.absolutePath()]: (
        value: SimNaoEnum,
        values: CidadaoFormModel
      ) => {
        const infoComplementares = resolveValue(values, path.informacoesComplementares)
        return SimNaoEnum.NAO === value ? null : infoComplementares?.identidadeGenero
      },
    },
  }
)

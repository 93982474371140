import { Breadcrumb } from 'components/breadcrumb'
import { PecSwitch, PrivateRoute } from 'components/route'
import { useFlags } from 'config/useFlagsContext'
import React, { Fragment } from 'react'
import { useRouteMatch } from 'react-router'
import Permissions from 'types/Permissions'
import CadastrarImovelView from 'view/cadastro-imovel/CadastrarImovelView'
import EditarImovelView from 'view/cadastro-imovel/EditarImovelView'
import { VisualizarImovelView } from 'view/visualizacao-imovel/VisualizarImovelView'

import { VisualizacaoTerritorialView } from './VisualizacaoTerritorialView'

export function VisualizacaoTerritorialRootView() {
  const match = useRouteMatch()
  const { CADASTRO_IMOVEL_ENABLED } = useFlags()

  return (
    <Fragment>
      <Breadcrumb title='Acompanhamento do território' />
      <PecSwitch>
        <PrivateRoute
          permission={Permissions.acompanhamentos.territorio}
          path={`${match.url}`}
          exact
          component={VisualizacaoTerritorialView}
        />
        <PrivateRoute
          permission={Permissions.acompanhamentos.territorio}
          path={`${match.url}/visualizarImovel/:imovelId(\\d+)`}
          component={VisualizarImovelView}
        />
        {CADASTRO_IMOVEL_ENABLED && (
          <Fragment>
            <PrivateRoute
              permission={Permissions.acompanhamentos.territorio}
              path={`${match.url}/cadastrarImovel`}
              component={CadastrarImovelView}
              exact
            />
            <PrivateRoute
              permission={Permissions.acompanhamentos.territorio}
              path={`${match.url}/editarImovel/:imovelId(\\d+)`}
              exact
              component={EditarImovelView}
            />
          </Fragment>
        )}
      </PecSwitch>
    </Fragment>
  )
}

/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, Grid, Heading, Text, Theme, useTheme, VFlow } from 'bold-ui'
import { PageContent } from 'components/layout/PageContent'
import { ExternalUserHeader } from 'components/user/ExternalUserHeader'
import { useUrlQueryParams } from 'hooks/useUrlQueryParams'
import { ReactComponent as VideoCallEndedImage } from 'images/videochamada/videochamada-encerrada.svg'
import { Fragment } from 'react'

interface VideochamadaEncerradaQueryParams {
  owner?: string
}

export function VideochamadaEncerradaView() {
  const { owner } = useUrlQueryParams<VideochamadaEncerradaQueryParams>()
  const isOwner = owner === 'true'

  const theme = useTheme()
  const styles = createStyles(theme)

  return (
    <Fragment>
      <ExternalUserHeader />
      <PageContent type='filled' containerStyle={styles.containerStyle} style={styles.container}>
        <Grid alignItems='center' justifyContent='space-between' direction='row' style={styles.pageContentGrid}>
          <Cell sm={5} style={styles.cellHeading}>
            <VFlow vSpacing={0}>
              <Heading level={1}>Chamada encerrada</Heading>
              {isOwner ? (
                <VFlow vSpacing={0}>
                  <Text fontWeight='bold'>Você encerrou a chamada, não é mais possível acessá-la.</Text>
                  <Text>Para criar uma nova chamada, volte ao módulo de Videochamadas.</Text>
                </VFlow>
              ) : (
                <Text>A chamada foi encerrada pelo profissional responsável, não é mais possível acessá-la.</Text>
              )}
            </VFlow>
          </Cell>
          <Cell sm={7} flexGrow={1} style={styles.cellImage}>
            <VideoCallEndedImage
              title='Duas pessoas com as mãos levantadas, representando que estão se despedindo em uma chamada de vídeo.'
              css={styles.image}
            />
          </Cell>
        </Grid>
      </PageContent>
    </Fragment>
  )
}

const createStyles = (theme: Theme) => ({
  container: css`
    padding: 2rem 1rem;

    ${theme.breakpoints.up('sm')} {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  `,
  containerStyle: css`
    padding: 0;

    ${theme.breakpoints.up('sm')} {
      padding: 0 2rem;
    }
  `,
  pageContentGrid: css`
    align-items: flex-start;
    flex-direction: column;

    ${theme.breakpoints.up('sm')} {
      align-items: center;
      flex-direction: row;
    }
  `,
  cellHeading: css`
    padding-top: 0;
    ${theme.breakpoints.up('sm')} {
      padding-top: 0.5rem;
    }
  `,
  cellImage: css`
    padding-bottom: 0;
    ${theme.breakpoints.up('sm')} {
      padding-bottom: 0.5rem;
    }
  `,
  image: css`
    width: 100%;
    height: 100%;
  `,
})

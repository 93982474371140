import moment from 'moment'
import React from 'react'
import { Label, XAxis, XAxisProps, YAxis, YAxisProps } from 'recharts'

import { AxisDomain, AxisOptions, isValueRange, ReferenceAreaWithPercents, TickProps } from './model'
import { ReferenceAreaTick } from './ReferenceAreaTick'
import { Tick } from './Tick'

export function renderAxis(
  axis: 'x' | 'y',
  options: AxisOptions,
  domain: AxisDomain,
  domainPoints: any[],
  hasOutliers: boolean,
  isBar?: boolean
) {
  if (axis === 'x')
    return (
      <XAxis
        dataKey={isBar ? undefined : 'x'}
        axisLine={!isBar}
        tickLine={!isBar}
        {...getAxisProps(domain, domainPoints, options, hasOutliers)}
      >
        {options.title && (
          <Label
            position='bottom'
            value={options.title + (options?.unit ? ` (${options.unit})` : '')}
            dy={5}
            style={{ fontWeight: 'bold' }}
          />
        )}
      </XAxis>
    )
  else
    return (
      <YAxis
        dataKey={isBar ? 'x' : undefined}
        axisLine={isBar}
        tickLine={isBar}
        yAxisId='data'
        {...getAxisProps(domain, domainPoints, options, hasOutliers)}
      >
        {options?.title && (
          <Label
            value={options?.title + (options?.unit ? ` (${options.unit})` : '')}
            dy={-45}
            dx={8}
            position='insideTopLeft'
            style={{ fontWeight: 'bold' }}
          />
        )}
      </YAxis>
    )
}

export function renderReferenceAxis(axis: 'x' | 'y', referenceAreas: ReferenceAreaWithPercents<any>[]) {
  const refTicks = getRefTicks(referenceAreas)
  if (axis === 'y')
    return (
      <YAxis
        dataKey='y'
        yAxisId='refArea'
        domain={[0, 100]}
        orientation='right'
        unit='%'
        interval={0}
        axisLine={false}
        tickLine={false}
        ticks={Array.from(refTicks.keys())}
        tick={<ReferenceAreaTick refTicks={refTicks} />}
      />
    )
  else
    return (
      <XAxis
        dataKey='y'
        xAxisId='refArea'
        domain={[0, 100]}
        orientation='bottom'
        unit='%'
        interval={0}
        axisLine={false}
        tickLine={false}
        ticks={Array.from(refTicks.keys())}
        tick={<ReferenceAreaTick refTicks={refTicks} />}
      />
    )
}

function getAxisProps(
  axisDomain: AxisDomain,
  domainPoints: any[],
  axisOptions: AxisOptions,
  hasOutliers?: boolean
): XAxisProps & YAxisProps {
  const outliersIndex = hasOutliers ? domainPoints.length - 1 : -1
  if (!axisDomain || Array.isArray(axisDomain))
    return {
      type: Array.isArray(axisDomain) ? 'category' : 'number',
      ticks: domainPoints,
      allowDuplicatedCategory: false,
      interval: 0,
      tick: axisOptions.tickRenderer ?? true,
    }
  else if (isValueRange(axisDomain))
    return {
      type: 'number',
      domain: [axisDomain.init, axisDomain.end],
      ticks: domainPoints,
      interval: 0,
      tick: axisOptions.tickRenderer
        ? (props) => axisOptions.tickRenderer(props, axisDomain.end, hasOutliers && props.index === outliersIndex)
        : (props) => (
            <Tick
              {...props}
              isOutlierIndicator={hasOutliers && props.index === outliersIndex}
              domainMaxValue={axisDomain.end}
            />
          ),
    }
  else
    return {
      type: 'number',
      domain: [axisDomain.init.valueOf(), axisDomain.end.valueOf()],
      ticks: domainPoints,
      interval: 0,
      tickFormatter: (x) =>
        typeof axisDomain.format === 'function'
          ? axisDomain.format(moment(x))
          : moment(x).format(axisDomain.format ?? 'MM/YY'),
      tick: axisOptions.tickRenderer
        ? (props) =>
            axisOptions.tickRenderer(
              convertTickProps(props),
              axisDomain.end,
              hasOutliers && props.index === outliersIndex
            )
        : (props) => (
            <Tick {...props} isOutlierIndicator={hasOutliers && props.index === outliersIndex} end={axisDomain.end} />
          ),
    }
}

const convertTickProps = (props: any): TickProps => ({
  fill: props.fill,
  height: props.height,
  payload: props.payload,
  stroke: props.stroke,
  textAnchor: props.textAnchor,
  width: props.width,
  x: props.x,
  y: props.y,
})

function getRefTicks(referenceAreas: ReferenceAreaWithPercents<any>[]): Map<number, ReferenceAreaWithPercents<any>> {
  return referenceAreas?.reduce(
    (acc, ref) => {
      const lastPercent = ref.areaPercents.slice(-1)[0].percent
      acc.map.set(acc.acc + lastPercent, ref)
      acc.acc += lastPercent
      return acc
    },
    { acc: 0, map: new Map<number, ReferenceAreaWithPercents<any>>() }
  ).map
}

import { Checkbox, HFlow, Tooltip } from 'bold-ui'
import React, { ChangeEvent } from 'react'

import {
  AssinaturaEletronicaAtendTableRowModel,
  getMensagemValidacao,
  LIMITE_ATENDIMENTOS_ASSINATURA,
  ValidacaoAssinaturaEnum,
} from '../model'
import { AssinaturaEletronicaAtendButton } from './AssinaturaEletronicaAtendButton'

interface AssinaturaEletronicaAtendTableHeaderProps {
  quantidadeAtendimentosPendentes: number
  checkedItems: Set<ID>
  setCheckedItems: (state: Set<ID>) => void
  atendimentos: Pick<AssinaturaEletronicaAtendTableRowModel, 'id' | 'isAtendimentoAssinado'>[]
  processoAssinaturaInProgress: boolean
  internetHabilidada: boolean
  setProcessoAssinaturaInProgress: (state: boolean) => void
}

export function AssinaturaEletronicaAtendTableHeader(props: AssinaturaEletronicaAtendTableHeaderProps) {
  const {
    quantidadeAtendimentosPendentes,
    checkedItems,
    setCheckedItems,
    atendimentos,
    processoAssinaturaInProgress,
    setProcessoAssinaturaInProgress,
  } = props

  const handleCheck = (event: ChangeEvent<HTMLInputElement>) => {
    const current = new Set(...checkedItems)
    event.target.checked
      ? atendimentos.forEach(
          (atendimento) =>
            !atendimento.isAtendimentoAssinado &&
            current.size < LIMITE_ATENDIMENTOS_ASSINATURA &&
            current.add(atendimento.id)
        )
      : atendimentos.forEach((atendimento) => current.delete(atendimento.id))
    setCheckedItems(current)
  }

  const getAssinarButtonLabel = `Assinar atendimentos${!!checkedItems.size ? ` (${checkedItems.size})` : ''}`

  const shouldDisableAssinarAtendimentos =
    !checkedItems.size || checkedItems.size > LIMITE_ATENDIMENTOS_ASSINATURA || processoAssinaturaInProgress

  const shoudCheckMarcarTodosAtendimentos =
    !!quantidadeAtendimentosPendentes &&
    atendimentos?.every((el) => el.isAtendimentoAssinado || checkedItems.has(el.id))

  const shouldDisableMarcarTodosAtendimentos =
    !quantidadeAtendimentosPendentes ||
    processoAssinaturaInProgress ||
    (!shoudCheckMarcarTodosAtendimentos && checkedItems.size >= LIMITE_ATENDIMENTOS_ASSINATURA)

  const getAssinarButtonTooltip = (): string => {
    if (!checkedItems.size) {
      return getMensagemValidacao[ValidacaoAssinaturaEnum.SEM_ATENDIMENTOS_SELECIONADOS]
    } else if (checkedItems.size > LIMITE_ATENDIMENTOS_ASSINATURA) {
      return getMensagemValidacao[ValidacaoAssinaturaEnum.LIMITE_ATENDIMENTO_SELECIONADOS_PARA_ASSINATURA_EXCEDIDO]
    } else if (processoAssinaturaInProgress) {
      return getMensagemValidacao[ValidacaoAssinaturaEnum.ASSINATURA_EM_ANDAMENTO]
    }
  }

  const getMarcarTodosAtendimentosTooltip = (): string => {
    if (!shoudCheckMarcarTodosAtendimentos && checkedItems.size >= LIMITE_ATENDIMENTOS_ASSINATURA) {
      return getMensagemValidacao[ValidacaoAssinaturaEnum.LIMITE_ATENDIMENTOS_SELECIONADOS_EXCEDIDO]
    } else if (!quantidadeAtendimentosPendentes) {
      return getMensagemValidacao[ValidacaoAssinaturaEnum.SEM_ATENDIMENTOS_PENDENTES]
    } else if (processoAssinaturaInProgress) {
      return getMensagemValidacao[ValidacaoAssinaturaEnum.ASSINATURA_EM_ANDAMENTO]
    }
  }

  const limiteAtendimentosAssinatura = Math.min(quantidadeAtendimentosPendentes, LIMITE_ATENDIMENTOS_ASSINATURA)

  return (
    <HFlow justifyContent='space-between'>
      <Tooltip text={getMarcarTodosAtendimentosTooltip()}>
        <div>
          <Checkbox
            disabled={shouldDisableMarcarTodosAtendimentos}
            onChange={handleCheck}
            checked={shoudCheckMarcarTodosAtendimentos}
            label={`Marcar todos os atendimentos pendentes da página (${'atendimento'.pluralizeAndConcatValue(
              limiteAtendimentosAssinatura
            )})`}
          />
        </div>
      </Tooltip>
      <Tooltip text={getAssinarButtonTooltip()}>
        <AssinaturaEletronicaAtendButton
          internetHabilitada={props.internetHabilidada}
          setProcessoAssinaturaInProgress={setProcessoAssinaturaInProgress}
          shouldDisableAssinarAtendimentos={shouldDisableAssinarAtendimentos}
        >
          {getAssinarButtonLabel}
        </AssinaturaEletronicaAtendButton>
      </Tooltip>
    </HFlow>
  )
}

/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, Grid, Theme, useTheme } from 'bold-ui'
import { Box } from 'components/Box'

import { CookieBannerView } from './CookieBannerView'

interface CookieBannerProps {
  onClickAceitarNecessarios: () => void
  onClickAceitarTodos: () => void
}

export const CookieBanner = (props: CookieBannerProps) => {
  const { onClickAceitarTodos, onClickAceitarNecessarios } = props
  const theme = useTheme()
  const styles = createStyles(theme)

  return (
    <Box style={styles.container}>
      <Grid justifyContent='center' alignItems='center'>
        <Cell xs={12} lg={8}>
          <CookieBannerView
            onClickAceitarTodos={onClickAceitarTodos}
            onClickAceitarNecessarios={onClickAceitarNecessarios}
          />
        </Cell>
      </Grid>
    </Box>
  )
}

const createStyles = (theme: Theme) => ({
  container: css`
    width: 100%;
    height: 10rem;
    position: fixed;
    bottom: 0;
    padding: 1rem 2rem;
    z-index: 20;
    display: flex;
    justify-content: center;
    ${theme.breakpoints.down('sm')} {
      height: 27.75rem;
    }
    ${theme.breakpoints.down('md')} {
      height: 20rem;
    }
  `,
})

import createDecorator, { Calculation } from 'final-form-calculate'
import { MetaRoot } from 'util/metaPath'

import CidadaoFormModel from '../types/model-cidadao'

const cidadaoAldeadoCalculator = (meta: MetaRoot<CidadaoFormModel>): Calculation[] => [
  {
    field: meta.isCidadaoAldeado.absolutePath(),
    updates: {
      [meta.cidadaoAldeadoInput.nomeTradicional.absolutePath()]: (value: boolean, values: CidadaoFormModel) => {
        return !value ? null : values.cidadaoAldeadoInput?.nomeTradicional
      },
      [meta.cidadaoAldeadoInput.dtReconhecimento.absolutePath()]: (value: boolean, values: CidadaoFormModel) => {
        return !value ? null : values.cidadaoAldeadoInput?.dtReconhecimento
      },
      [meta.cidadaoAldeadoInput.stChefeFamilia.absolutePath()]: (value: boolean, values: CidadaoFormModel) => {
        return !value ? null : values.cidadaoAldeadoInput?.stChefeFamilia
      },
      [meta.cidadaoAldeadoInput.funcaoSocial.absolutePath()]: (value: boolean, values: CidadaoFormModel) => {
        return !value ? null : values.cidadaoAldeadoInput?.funcaoSocial
      },
      [meta.cidadaoAldeadoInput.beneficios.absolutePath()]: (value: boolean, values: CidadaoFormModel) => {
        return !value ? null : values.cidadaoAldeadoInput?.beneficios
      },
      [meta.cidadaoAldeadoInput.unidadeFunai.absolutePath()]: (value: boolean, values: CidadaoFormModel) => {
        return !value ? null : values.cidadaoAldeadoInput?.unidadeFunai
      },
      [meta.cidadaoAldeadoInput.livro.absolutePath()]: (value: boolean, values: CidadaoFormModel) => {
        return !value ? null : values.cidadaoAldeadoInput?.livro
      },
      [meta.cidadaoAldeadoInput.folha.absolutePath()]: (value: boolean, values: CidadaoFormModel) => {
        return !value ? null : values.cidadaoAldeadoInput?.folha
      },
      [meta.cidadaoAldeadoInput.cadastroUnico.absolutePath()]: (value: boolean, values: CidadaoFormModel) => {
        return !value ? null : values.cidadaoAldeadoInput?.cadastroUnico
      },
      [meta.cidadaoAldeadoInput.dtEmissao.absolutePath()]: (value: boolean, values: CidadaoFormModel) => {
        return !value ? null : values.cidadaoAldeadoInput?.dtEmissao
      },
      [meta.cidadaoAldeadoInput.ufNascimento.absolutePath()]: (value: boolean, values: CidadaoFormModel) => {
        return !value ? null : values.cidadaoAldeadoInput?.ufNascimento
      },
      [meta.cidadaoAldeadoInput.dtAusencia.absolutePath()]: (value: boolean, values: CidadaoFormModel) => {
        return !value ? null : values.cidadaoAldeadoInput?.dtAusencia
      },
      [meta.cidadaoAldeadoInput.dtRetorno.absolutePath()]: (value: boolean, values: CidadaoFormModel) => {
        return !value ? null : values.cidadaoAldeadoInput?.dtRetorno
      },
    },
  },
]

export const createCidadaoAldeadoCalculator = (meta: MetaRoot<CidadaoFormModel>) =>
  createDecorator(...cidadaoAldeadoCalculator(meta))

/* eslint-disable no-console */
import { Cell, FormControl, Grid, Heading, HFlow, Text, Tooltip, VFlow } from 'bold-ui'
import {
  CheckboxField,
  CnsField,
  CpfField,
  DateField,
  FormRenderProps,
  MunicipioSelectField,
  SexoSelectField,
  TextField,
} from 'components/form'
import { BeneficiosCheckboxGroupField } from 'components/form/field/BeneficiosCheckboxGroupField'
import { DeclaracaoObitoField } from 'components/form/field/DeclaracaoObitoField'
import { RadioGroupField } from 'components/form/field/RadioGroupField'
import { AldeiaSelectField } from 'components/form/field/select/AldeiaSelectField'
import { EtniaSelectField } from 'components/form/field/select/EtniaSelectField'
import { FuncaoSocialSelectField } from 'components/form/field/select/FuncaoSocialSelectField'
import { LocalOcorrenciaSelectField } from 'components/form/field/select/LocalOcorrenciaSelectField'
import { PaisSelectField } from 'components/form/field/select/PaisSelectField'
import { RacaCorSelectField } from 'components/form/field/select/RacaCorSelectField'
import { SimNaoRadioGroupField } from 'components/form/field/SimNaoRadioGroupField'
import { resolveValue } from 'components/form/final-form/hooks/useField'
import { InfoIcon } from 'components/InfoIcon'
import { useFlags } from 'config/useFlagsContext'
import { addDays } from 'date-fns'
import { NacionalidadeEnum, RacaCorDbEnum, TipoEnderecoEnum, TipoLocalNascimentoEnum } from 'graphql/types.generated'
import { useServerTime } from 'hooks/useServerTime'
import React, { useMemo } from 'react'
import { FormSpy } from 'react-final-form'
import { calculateAge } from 'util/date/calculateAge'
import { dateAsYyyyMmDd } from 'util/date/formatDate'
import { onlyNumbers } from 'util/mask'
import { MetaPath, MetaRoot } from 'util/metaPath'

import { useSaudeIndigenaAuthorization } from '../authorization/useSaudeIndigenaAuthorization'
import CidadaoFormModel from '../types/model-cidadao'

export interface CidadaoDadosFieldGroupProps {
  name: MetaPath<CidadaoFormModel> | MetaRoot<CidadaoFormModel>
  formProps: FormRenderProps<CidadaoFormModel>
  isEdit?: Boolean
}

export function CidadaoDadosFieldGroup(props: CidadaoDadosFieldGroupProps) {
  const {
    name,
    isEdit,
    formProps: { initialValues, values: formValues, form },
  } = props
  const { getServerTimeNow } = useServerTime()
  const now = getServerTimeNow()
  const { hasCadastrarEditarAldeadoPermission } = useSaudeIndigenaAuthorization()
  const { SAUDE_INDIGENA_ENABLED } = useFlags()

  const disableEditAldeado = initialValues?.id && initialValues?.isCidadaoAldeado

  const values = resolveValue(formValues, name) || ({} as CidadaoFormModel)
  const idadeEmAnos = useMemo(() => values?.dataNascimento && calculateAge(values.dataNascimento).years, [values])
  const idadeMenorQueUm = idadeEmAnos !== null && idadeEmAnos < 1
  const tooltipLocalNascimento = idadeMenorQueUm
    ? 'Cidadão aldeado menor de 1 ano.'
    : NacionalidadeEnum.BRASILEIRA !== values?.nacionalidade
    ? 'Habilitado quando nacionalidade é brasileira.'
    : ''

  const handleChangeAldeado = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!isEdit) {
      if (e.target.checked) {
        form.change(name.tipoLocalNascimento.absolutePath(), TipoLocalNascimentoEnum.ALDEIA)
        form.change(name.tipoEndereco.absolutePath(), TipoEnderecoEnum.ALDEIA)
      } else {
        form.change(name.tipoLocalNascimento.absolutePath(), TipoLocalNascimentoEnum.MUNICIPIO)
        form.change(name.tipoEndereco.absolutePath(), TipoEnderecoEnum.LOGRADOURO)
      }
    } else {
      if (e.target.checked && idadeMenorQueUm) {
        form.change(name.tipoLocalNascimento.absolutePath(), TipoLocalNascimentoEnum.ALDEIA)
      }
    }
  }

  const handleChangeBirthDate = (value: Date) => {
    if (SAUDE_INDIGENA_ENABLED && values.isCidadaoAldeado && value && calculateAge(dateAsYyyyMmDd(value)).years < 1) {
      form.change(name.tipoLocalNascimento.absolutePath(), TipoLocalNascimentoEnum.ALDEIA)
    }
  }

  const minDtReconhecimento = addDays(new Date(values?.dataNascimento), 1)

  return (
    <>
      <Grid wrap>
        <Cell size={3}>
          <CpfField name={name.cpf} label='CPF' />
        </Cell>
        <Cell size={3}>
          <CnsField name={name.cns} label='CNS' disabled={!!initialValues?.cns} />
        </Cell>
        {hasCadastrarEditarAldeadoPermission ? (
          <Cell size={6}>
            <FormControl label={<span>&nbsp;</span>}>
              <CheckboxField
                name={name.isCidadaoAldeado}
                label='Cidadão é aldeado'
                onChange={handleChangeAldeado}
                disabled={disableEditAldeado}
                style={{ marginTop: '0.25rem' }}
              />
            </FormControl>
          </Cell>
        ) : (
          <Cell size={6} />
        )}
        <Cell size={6}>
          <TextField uppercase name={name.nome} label='Nome completo' maxLength={70} required />
        </Cell>
        <FormSpy subscription={{ values: true }}>
          {({ values }) => (
            <>
              {values.isCidadaoAldeado && hasCadastrarEditarAldeadoPermission && (
                <>
                  <Cell size={6}></Cell>
                  <Cell size={6}>
                    <TextField
                      uppercase
                      name={name.cidadaoAldeadoInput.nomeTradicional}
                      label='Nome tradicional'
                      maxLength={70}
                    />
                  </Cell>
                </>
              )}
            </>
          )}
        </FormSpy>
        <Cell size={6}>
          <TextField
            uppercase
            name={name.nomeSocial}
            label={
              <HFlow hSpacing={0.5} alignItems='center'>
                <Text>Nome social</Text>
                <InfoIcon
                  icon='infoCircleFilled'
                  text='O nome social do cidadão cadastrado pelo PEC não será enviado ao CADSUS. Atualmente, só é possível incluir o nome social no CADSUS a partir do cadastro na Receita Federal.'
                />
              </HFlow>
            }
            maxLength={70}
          />
        </Cell>
        <Cell size={3}>
          <DateField
            name={name.dataNascimento}
            maxDate={now}
            label='Data de nascimento'
            onChange={handleChangeBirthDate}
            required
          />
        </Cell>
        <FormSpy subscription={{ values: true }}>
          {({ values }) => (
            <>
              {values.isCidadaoAldeado && hasCadastrarEditarAldeadoPermission && (
                <Cell size={3}>
                  <DateField
                    name={name.cidadaoAldeadoInput.dtReconhecimento}
                    minDate={minDtReconhecimento}
                    maxDate={now}
                    label='Data de reconhecimento'
                    required
                  />
                </Cell>
              )}
            </>
          )}
        </FormSpy>
        <Cell size={3}>
          <SexoSelectField name={name.sexo} label='Sexo' required />
        </Cell>
        <Cell size={3} />
        <Cell size={6}>
          <RacaCorSelectField name={name.racaCor} label='Raça/Cor' required />
        </Cell>
        <Cell size={6}>
          <FormSpy subscription={{ values: true }}>
            {({ values }) => (
              <EtniaSelectField
                name={name.etnia}
                label='Etnia'
                disabled={RacaCorDbEnum.INDIGENA !== values.racaCor?.value}
                required={RacaCorDbEnum.INDIGENA === values.racaCor?.value}
              />
            )}
          </FormSpy>
        </Cell>
        <FormSpy subscription={{ values: true }}>
          {({ values }) => (
            <>
              {values.isCidadaoAldeado && hasCadastrarEditarAldeadoPermission && (
                <>
                  <Cell size={6}>
                    <FuncaoSocialSelectField name={name.cidadaoAldeadoInput.funcaoSocial} label='Função social' />
                  </Cell>
                  <Cell size={6}>
                    <SimNaoRadioGroupField
                      name={name.cidadaoAldeadoInput.stChefeFamilia}
                      label='Chefe de família'
                      required
                    />
                  </Cell>
                </>
              )}
            </>
          )}
        </FormSpy>
        <Cell size={6}>
          <FormSpy subscription={{ values: true }}>
            {({ values }) =>
              !values.desconheceNomeMae ? (
                <TextField uppercase name={name.nomeMae} label='Nome da mãe' maxLength={70} required />
              ) : (
                <TextField name='nomeMaeDummy' label='Nome da mãe' disabled />
              )
            }
          </FormSpy>
        </Cell>
        <Cell size={6}>
          <FormControl label={<span>&nbsp;</span>}>
            <CheckboxField
              name={name.desconheceNomeMae}
              label='Desconhece essa informação'
              style={{ marginTop: '0.25rem' }}
            />
          </FormControl>
        </Cell>
        <Cell size={6}>
          <FormSpy subscription={{ values: true }}>
            {({ values }) =>
              !values.desconheceNomePai ? (
                <TextField uppercase name={name.nomePai} label='Nome do pai' maxLength={70} required />
              ) : (
                <TextField name='nomePaiDummy' label='Nome do pai' disabled />
              )
            }
          </FormSpy>
        </Cell>
        <Cell size={6}>
          <FormControl label={<span>&nbsp;</span>}>
            <CheckboxField
              name={name.desconheceNomePai}
              label='Desconhece essa informação'
              style={{ marginTop: '0.25rem' }}
            />
          </FormControl>
        </Cell>
        <FormSpy subscription={{ values: true }}>
          {({ values }) => (
            <>
              {values.isCidadaoAldeado && hasCadastrarEditarAldeadoPermission && (
                <>
                  <Cell size={6}>
                    <TextField
                      uppercase
                      name={name.cidadaoAldeadoInput.responsavelLegal}
                      label='Responsável legal'
                      maxLength={70}
                    />
                  </Cell>
                  <Cell size={12}>
                    <BeneficiosCheckboxGroupField
                      name={name.cidadaoAldeadoInput.beneficios}
                      values={formValues.cidadaoAldeadoInput?.beneficios}
                    />
                  </Cell>
                </>
              )}
            </>
          )}
        </FormSpy>
        <Cell size={12}>
          <Heading level={2}>Naturalidade</Heading>
        </Cell>
        <Cell size={12}>
          <VFlow vSpacing={0.8}>
            <Text fontWeight='bold'>Nacionalidade</Text>
            <HFlow>
              <RadioGroupField
                name={name.nacionalidade}
                options={[
                  { value: NacionalidadeEnum.BRASILEIRA, label: 'Brasileira' },
                  { value: NacionalidadeEnum.NATURALIZADA, label: 'Naturalizado' },
                  { value: NacionalidadeEnum.ESTRANGEIRA, label: 'Estrangeiro' },
                ]}
              />
            </HFlow>
          </VFlow>
        </Cell>
        <FormSpy subscription={{ values: true }}>
          {({ values }) => (
            <>
              {hasCadastrarEditarAldeadoPermission && values.isCidadaoAldeado && (
                <>
                  <Cell size={4}>
                    <VFlow vSpacing={0.8}>
                      <Text fontWeight='bold'>Local de nascimento</Text>
                      <Tooltip text={tooltipLocalNascimento}>
                        <HFlow>
                          <RadioGroupField
                            name={name.tipoLocalNascimento}
                            options={[
                              { value: TipoLocalNascimentoEnum.ALDEIA, label: 'Aldeia' },
                              { value: TipoLocalNascimentoEnum.MUNICIPIO, label: 'Município' },
                            ]}
                            disabled={values.nacionalidade !== NacionalidadeEnum.BRASILEIRA || idadeMenorQueUm}
                          />
                        </HFlow>
                      </Tooltip>
                    </VFlow>
                  </Cell>
                  <Cell size={8} />
                </>
              )}
            </>
          )}
        </FormSpy>
        <FormSpy subscription={{ values: true }}>
          {({ values }) => (
            <>
              <Cell size={6}>
                {values.nacionalidade === NacionalidadeEnum.ESTRANGEIRA && (
                  <PaisSelectField name={name.paisNascimento} label='País de nascimento' omiteBrasil={true} required />
                )}
                {values.nacionalidade === NacionalidadeEnum.NATURALIZADA && (
                  <TextField
                    name={name.portariaNaturalizacao}
                    label='Portaria de naturalização'
                    maxLength={16}
                    parse={onlyNumbers}
                    required
                  />
                )}
                {values.nacionalidade === NacionalidadeEnum.BRASILEIRA &&
                  (!values.isCidadaoAldeado ||
                    (values.isCidadaoAldeado && values.tipoLocalNascimento === TipoLocalNascimentoEnum.MUNICIPIO)) && (
                    <MunicipioSelectField name={name.municipioNascimento} label='Município de nascimento' required />
                  )}
                {hasCadastrarEditarAldeadoPermission &&
                  values.nacionalidade === NacionalidadeEnum.BRASILEIRA &&
                  values.isCidadaoAldeado &&
                  values.tipoLocalNascimento === TipoLocalNascimentoEnum.ALDEIA && (
                    <AldeiaSelectField
                      name={name.cidadaoAldeadoInput.aldeiaNascimento}
                      label='Aldeia'
                      required={idadeMenorQueUm}
                    />
                  )}
              </Cell>
              <Cell size={values.isCidadaoAldeado && values.nacionalidade === NacionalidadeEnum.BRASILEIRA ? 6 : 3}>
                {values.nacionalidade === NacionalidadeEnum.ESTRANGEIRA && (
                  <DateField name={name.dataEntradaPais} label='Data de entrada no Brasil' required />
                )}
                {values.nacionalidade === NacionalidadeEnum.NATURALIZADA && (
                  <DateField name={name.dataNaturalizacao} label='Data de naturalização' required />
                )}
                {hasCadastrarEditarAldeadoPermission &&
                  values.nacionalidade === NacionalidadeEnum.BRASILEIRA &&
                  values.isCidadaoAldeado &&
                  values.tipoLocalNascimento === TipoLocalNascimentoEnum.ALDEIA && (
                    <LocalOcorrenciaSelectField
                      name={name.cidadaoAldeadoInput.localOcorrencia}
                      label='Local de ocorrência do nascimento'
                      required={idadeMenorQueUm}
                    />
                  )}
              </Cell>
            </>
          )}
        </FormSpy>
        <Cell size={12}>
          <CheckboxField name={name.cidadaoFaleceu} label='Cidadão faleceu' />
        </Cell>
        <FormSpy subscription={{ values: true }}>
          {({ values }) => (
            <>
              {values.cidadaoFaleceu && (
                <>
                  <Cell size={3}>
                    <DateField name={name.dataObito} label='Data de óbito' required />
                  </Cell>
                  <Cell size={3}>
                    <DeclaracaoObitoField name={name.numeroDeclaracaoObito} label='Número da declaração de óbito' />
                  </Cell>
                </>
              )}
            </>
          )}
        </FormSpy>
      </Grid>
    </>
  )
}

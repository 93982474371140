import { SelectField } from 'components/form/final-form'
import { MunicipioSelectFieldDocument } from 'graphql/hooks.generated'
import { MunicipioSelectFieldQuery, MunicipioSelectFieldQueryVariables } from 'graphql/types.generated'
import React from 'react'

import { AsyncSelectFieldProps, DEFAULT_SELECT_PAGE_PARAM, useAsyncQuerySelect } from '../useAsyncQuerySelect'

export type MunicipioSelectModel = MunicipioSelectFieldQuery['municipios']['content'][0]

export interface MunicipioSelectFieldProps extends AsyncSelectFieldProps<MunicipioSelectModel> {
  /**
   * Caso especificado, apresenta apenas municípios desta UF.
   */
  ufId?: ID

  /**
   * Caso especificado, apresenta apenas municípios que esta aldeia faz parte.
   */
  aldeiaId?: ID

  ativo?: boolean
}

export function MunicipioSelectField(props: MunicipioSelectFieldProps) {
  const { ufId, aldeiaId, ativo, ...rest } = props

  const { selectProps } = useAsyncQuerySelect<
    MunicipioSelectModel,
    MunicipioSelectFieldQuery,
    MunicipioSelectFieldQueryVariables
  >({
    query: MunicipioSelectFieldDocument,
    extractItems: (data) => data && data.municipios && data.municipios.content,
    variables: (inputQuery: string) => ({
      input: {
        ufId,
        aldeiaId,
        ativo,
        query: inputQuery,
        pageParams: {
          ...DEFAULT_SELECT_PAGE_PARAM,
          sort: ['nome'],
        },
      },
    }),
  })

  const itemToString = (item: MunicipioSelectModel) =>
    item && item.nome && item.uf.sigla && `${item.nome} - ${item.uf.sigla}`

  return <SelectField<MunicipioSelectModel> itemToString={itemToString} {...selectProps} {...rest} />
}

import { css } from '@emotion/core'
import { HFlow, Icon, Link, Text, Theme, useTheme, VFlow } from 'bold-ui'
import { PageContainer } from 'components/layout/PageContainer'
import React, { useState } from 'react'

import { ModalNovidadesAcompanhamentoTerritorio } from './modal/ModalNovidadesAcompanhamentoTerritorio'

export function AlertNovidadesAcompanhamentoTerritorio() {
  const theme = useTheme()
  const styles = createStyles(theme)

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const handleClose = () => setIsModalOpen(false)
  const handleOpen = () => setIsModalOpen(true)

  return (
    <PageContainer>
      <ModalNovidadesAcompanhamentoTerritorio isModalOpen={isModalOpen} onClose={handleClose} />
      <HFlow justifyContent='center'>
        <HFlow hSpacing={1} alignItems='center' justifyContent='center' style={styles.container}>
          <Icon icon='infoCircleOutline' size={3} fill='primary' />
          <VFlow vSpacing={0.2} style={styles.text}>
            <Text>
              Para iniciar um novo cadastro domiciliar e territorial acesse a funcionalidade de acompanhamento de
              território no menu lateral.
            </Text>
            <Link onClick={handleOpen}>Saiba mais</Link>
          </VFlow>
        </HFlow>
      </HFlow>
    </PageContainer>
  )
}

const createStyles = (theme: Theme) => ({
  container: css`
    margin-top: 2rem;
    margin-bottom: 0.5rem;
    padding: 0.75rem 1.5rem;
    outline: 1px solid ${theme.pallete.gray.c60};
    background-color: ${theme.pallete.surface.background};
    max-width: 50rem;
    border-radius: 0.4rem;
  `,
  text: css`
    max-width: 30rem;
  `,
})

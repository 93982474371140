/** @jsx jsx * */
import { css, jsx } from '@emotion/core'
import {
  Button,
  Checkbox,
  Heading,
  HFlow,
  Modal,
  ModalBody,
  ModalFooter,
  ModalProps,
  Text,
  Theme,
  useTheme,
  VFlow,
} from 'bold-ui'
import { Box } from 'components/Box'
import { RequiredLabel } from 'components/form/final-form/RequiredLabel/RequiredLabel'
import { HLabel } from 'components/HLabel'
import { useEffect, useMemo, useRef, useState } from 'react'

import { HideOnScreenSize } from './responsive'

export interface Termo {
  titulo: string
  subtitulo?: string
  itens: ReadonlyArray<string>
  acceptText: string
}
export type TermosRecord<TTiposTermos extends string | number | symbol> = Record<TTiposTermos, Termo>

interface TermoModalProps<TTiposTermos extends string | number | symbol> extends ModalProps {
  onAccept?(autorizacoes: Record<TTiposTermos, boolean>): void
  termos: TermosRecord<TTiposTermos>
  nextText?: string
  prevText?: string
  confirmText?: string
  readonly?: boolean
}

export function TermoModal<TTiposTermos extends string | number | symbol>(props: TermoModalProps<TTiposTermos>) {
  const {
    open,
    onClose,
    onAccept,
    termos,
    nextText = 'Continuar',
    prevText = 'Anterior',
    confirmText = 'Aceitar termos',
    style: externalStyles,
    readonly = false,
    ...rest
  } = props

  const totalPaginas = Object.keys(termos).length - 1
  const paginasTermo = useMemo(() => Object.keys(termos) as TTiposTermos[], [termos])

  const [indexPaginaAtual, setIndexPaginaAtual] = useState(0)

  const paginaAtual = paginasTermo[indexPaginaAtual]
  const termoPaginaAtual = termos[paginaAtual]

  const theme = useTheme()
  const mediaMobile = `@media (max-width: ${theme.breakpoints.size['sm']}px)`
  const styles = createStyles(theme, !termoPaginaAtual.subtitulo, mediaMobile)

  const initialCheckboxValues = useMemo(
    () =>
      paginasTermo.reduce((acc, cur) => {
        acc[cur] = false
        return acc
      }, {} as Record<TTiposTermos, boolean>),
    [paginasTermo]
  )
  const [checkboxValues, setCheckboxValues] = useState(initialCheckboxValues)

  const refTermos = useRef<HTMLDivElement>()
  const refModal = useRef<HTMLDivElement>()

  const closeModal = () => {
    setIndexPaginaAtual(0)
    onClose?.()
  }

  const nextPageOrAccept = () => {
    if (indexPaginaAtual < totalPaginas) {
      setIndexPaginaAtual(indexPaginaAtual + 1)
      refTermos.current?.scrollIntoView()
    } else {
      setIndexPaginaAtual(0)
      onAccept?.(checkboxValues)
    }
  }

  const prevPage = () => {
    if (indexPaginaAtual > 0) {
      setIndexPaginaAtual(indexPaginaAtual - 1)
      refTermos.current?.scrollIntoView()
    }
  }

  useEffect(() => {
    !open && setCheckboxValues(initialCheckboxValues)
  }, [initialCheckboxValues, open, termos])

  useEffect(() => {
    const containerElement = refModal.current?.parentNode as Element

    if (containerElement) {
      const estiloCss = css(styles.parentModalMobile)
      containerElement.setAttribute('style', estiloCss.styles)
    }
  }, [styles.parentModalMobile])

  return (
    <Modal
      open={open}
      onClose={closeModal}
      size='large'
      hasCloseIcon
      style={css(styles.modalMobile, styles.modal, externalStyles)}
      containerRef={refModal}
      {...rest}
    >
      <ModalBody style={[styles.scrollTerm, styles.scrollTermMobile]}>
        <VFlow vSpacing={1}>
          <div ref={refTermos}>
            <HFlow alignItems='center' justifyContent='center' style={styles.gridHeadingMobile}>
              <Heading level={1} style={[styles.modalHeadings, styles.titulo]}>
                {termoPaginaAtual.titulo}
              </Heading>
            </HFlow>
            {termoPaginaAtual.subtitulo && (
              <Heading level={2} style={styles.modalHeadings}>
                {termoPaginaAtual.subtitulo}
              </Heading>
            )}
          </div>
          <VFlow vSpacing={1}>
            {termoPaginaAtual.itens?.map((termo, index) => (
              <HLabel title={`${index + 1} -`} key={index}>
                {termo}
              </HLabel>
            ))}
            {!readonly && (
              <Box>
                <Checkbox
                  onChange={() =>
                    setCheckboxValues((prev) => {
                      return { ...prev, [paginaAtual]: !checkboxValues[paginaAtual] }
                    })
                  }
                  checked={checkboxValues[paginaAtual]}
                  label={<RequiredLabel inline fontWeight='bold' label={termoPaginaAtual.acceptText} />}
                />
              </Box>
            )}
          </VFlow>
        </VFlow>
      </ModalBody>
      {(!readonly || totalPaginas > 1) && (
        <ModalFooter style={[styles.modalFooter, styles.modalFooterMobile]}>
          <HFlow justifyContent='flex-end' alignItems='center' style={styles.gridFooterMobile}>
            {totalPaginas > 0 && (
              <Text>
                <Text fontWeight='bold'>{indexPaginaAtual + 1} </Text>
                de
                <Text fontWeight='bold'> {totalPaginas + 1} </Text>
                termos obrigatórios
              </Text>
            )}
            {indexPaginaAtual > 0 && readonly && (
              <Button onClick={prevPage} kind='primary'>
                {prevText}
              </Button>
            )}
            <VFlow vSpacing={0.5} style={styles.textFooterMobile}>
              <HideOnScreenSize up='sm'>
                <Text fontStyle='italic'>É necessário concordar com o termo no final da tela para continuar.</Text>
              </HideOnScreenSize>
              {(indexPaginaAtual < totalPaginas || !readonly) && (
                <Button
                  disabled={!checkboxValues[paginaAtual]}
                  onClick={nextPageOrAccept}
                  kind='primary'
                  style={styles.buttonMobile}
                >
                  {indexPaginaAtual < totalPaginas ? nextText : confirmText}
                </Button>
              )}
            </VFlow>
          </HFlow>
        </ModalFooter>
      )}
    </Modal>
  )
}

const createStyles = (theme: Theme, onlyTitulo: boolean, mediaMobile: string) => ({
  scrollTerm: css`
    overflow-y: auto;
    width: 100%;
    padding-top: 0;
    flex: 1;
  `,
  modal: css`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  `,
  modalHeadings: css`
    text-align: center;
  `,
  titulo: css`
    padding-bottom: ${onlyTitulo && '1.85rem'};
    ${mediaMobile} {
      padding-bottom: 0;
      font-size: 1rem;
      line-height: 1.5rem;
    }
  `,
  modalFooter: css`
    width: 100%;
  `,
  parentModalMobile: css`
    padding: 0;
    width: 100%;
    height: 100%;
  `,
  modalMobile: css`
    ${mediaMobile} {
      height: 100%;
      max-height: 100%;
      min-width: min-content;
      width: 100%;
    }
  `,
  gridHeadingMobile: css`
    ${mediaMobile} {
      grid-gap: 0;
    }
  `,
  arrowLeftMobile: css`
    ${mediaMobile} {
      color: ${theme.pallete.gray.c10};
      grid-column: 1;
    }
  `,
  scrollTermMobile: css`
    ${mediaMobile} {
      padding: 1rem;
    }
  `,
  modalFooterMobile: css`
    ${mediaMobile} {
      border-top: 1px solid ${theme.pallete.divider};
      background-color: ${theme.pallete.gray.c100};
      grid-template: 1fr / 1fr;
    }
  `,
  gridFooterMobile: css`
    ${mediaMobile} {
      grid-template: 1fr / 1fr;
    }
  `,
  textFooterMobile: css`
    ${mediaMobile} {
      text-align: center;
    }
  `,
  buttonMobile: css`
    ${mediaMobile} {
      width: 100%;
    }
  `,
})

import { css } from '@emotion/core'
import { Button, Cell, Grid, HFlow, Icon } from 'bold-ui'
import { Box } from 'components/Box'
import { SubmitButton } from 'components/form'
import { PeriodoUltimaAtualizacaoSelectField } from 'components/form/field/select/PeriodoUltimaAtualizacaoSelectField'
import { PeriodoUltimaVisitaSelectField } from 'components/form/field/select/PeriodoUltimaVisitaSelectField'
import { StatusCadastroSelectField } from 'components/form/field/select/StatusCadastroSelectField'
import { PopperControls } from 'components/popper'
import { TipoEnderecoEnum } from 'graphql/types.generated'
import React from 'react'
import { metaPath } from 'util/metaPath'
import { tipoEnderecoIndigena } from 'view/cadastro-imovel/model-cadastroImovel'
import { VisualizacaoTerritorialMicroareasFilterModel } from 'view/visualizacaoTerritorial/model'

interface MicroareasFilterPopperProps extends PopperControls {
  tipoEndereco: TipoEnderecoEnum
  onSubmit(e: React.SyntheticEvent<HTMLFormElement, Event>): void
  onLimparCampos(): void
}
const meta = metaPath<VisualizacaoTerritorialMicroareasFilterModel>()

export function MicroareasFilterPopper(props: MicroareasFilterPopperProps) {
  const { tipoEndereco, close, onLimparCampos, onSubmit: handleSubmit } = props

  return (
    <Box
      style={css`
        width: 25rem;
        padding: 1rem;
      `}
    >
      <Grid>
        <Cell size={12}>
          <PeriodoUltimaAtualizacaoSelectField
            label='Atualizado há'
            name={meta.isCadastroAtualizadoMenosDeUmAno}
            openOnFocus={false}
          />
        </Cell>
        {!tipoEnderecoIndigena.includes(tipoEndereco) && (
          <Cell size={12}>
            <PeriodoUltimaVisitaSelectField
              label='Última visita há'
              name={meta.isImovelVisitadoMenosDeUmMes}
              openOnFocus={false}
            />
          </Cell>
        )}
        <Cell size={12}>
          <StatusCadastroSelectField label='Status do cadastro' name={meta.isCadastroCompleto} openOnFocus={false} />
        </Cell>
        <Cell size={12}>
          <HFlow justifyContent='flex-end'>
            <Button size='small' onClick={close}>
              Fechar
            </Button>
            <Button size='small' kind='normal' onClick={onLimparCampos}>
              <Icon
                icon='redo'
                style={css`
                  margin-right: 0.5rem;
                `}
              />
              Limpar filtros
            </Button>
            <SubmitButton size='small' handleSubmit={handleSubmit}>
              Filtrar
            </SubmitButton>
          </HFlow>
        </Cell>
      </Grid>
    </Box>
  )
}

/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, colors, Grid, Heading, HFlow } from 'bold-ui'
import CheckPermission from 'components/auth/CheckPermission'
import { Box } from 'components/Box'
import { CheckboxField } from 'components/form'
import { AgeRangeField } from 'components/form/field/AgeRangeField'
import { AgeRangeType, RiscoGravidez } from 'graphql/types.generated'
import Permissions from 'types/Permissions'
import { PreNatalButton } from 'view/atendimentos/detail/soap/pre-natal/PreNatalButton'

import { AcompanhamentoCondicaoSaudeTabsProps } from './AcompanhamentoCondicaoSaudeTabs'
import { ExamesPreNatalSelectField } from './components/ExamesPreNatalSelectField'
import { RiscoGravidezCor, riscoGravidezRecord } from './model-acompanhamentoCondicaoSaude'

const riscoGestacionalLabelRender = (riscoGravidez: RiscoGravidezCor) => (
  <HFlow hSpacing={0} alignItems='center'>
    <div css={css(styles.checkboxRectangle, { backgroundColor: riscoGravidez.cor })} />
    {riscoGravidez.descricao}
  </HFlow>
)

export function AcompanhamentoGestantesPuerperasTabContent(props: AcompanhamentoCondicaoSaudeTabsProps) {
  const { path } = props

  return (
    <Grid gapVertical={0.5}>
      <CheckPermission permission={Permissions.acompanhamentos.condicoesDeSaude}>
        <Cell size={12}>
          <Heading level={4}>Filtrar por</Heading>
        </Cell>
        <Cell size={12}>
          <PreNatalButton
            handleClick={() => {
              /*TODO (Royal Flush) #19174: Implementar troca de tipoRelatorio quando houver botão de Puerpério*/
            }}
            isSelecionado
          />
        </Cell>
        <Cell size={5}>
          <Box style={styles.preNatalBox}>
            <Grid>
              <Cell size={12}>
                <Heading level={5}>Risco gestacional</Heading>
              </Cell>
              <Cell size={12}>
                <HFlow hSpacing={3}>
                  <CheckboxField
                    name={path.gestantesPuerperas.riscoGestacional}
                    label={riscoGestacionalLabelRender(riscoGravidezRecord[RiscoGravidez.ALTO])}
                    value={RiscoGravidez.ALTO}
                  />
                  <CheckboxField
                    name={path.gestantesPuerperas.riscoGestacional}
                    label={riscoGestacionalLabelRender(riscoGravidezRecord[RiscoGravidez.HABITUAL])}
                    value={RiscoGravidez.HABITUAL}
                  />
                </HFlow>
              </Cell>
              <Cell size={12}>
                <AgeRangeField
                  name={path.gestantesPuerperas.idadeGestacional}
                  label='Idade gestacional'
                  maxLength={2}
                  rangeTypeOptions={[AgeRangeType.SEMANAS]}
                />
              </Cell>
              <Cell size={12}>
                <ExamesPreNatalSelectField
                  name={path.gestantesPuerperas.examesNaoSolicitados}
                  label='Exames não solicitados'
                  placeholder='Selecione um exame não solicitado'
                  multiple
                />
              </Cell>
            </Grid>
          </Box>
        </Cell>
        <Cell size={8} />
      </CheckPermission>
    </Grid>
  )
}

const styles = {
  preNatalBox: css`
    border: 1px ${colors.purple.c60} solid;
  `,
  checkboxRectangle: css`
    width: 0.5rem;
    height: 1.5rem;
    border-radius: 0.125rem;
    margin-right: 0.5rem;
  `,
}

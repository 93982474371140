import { Heading, HFlow, Icon, modal, ModalBody, Text, VFlow } from 'bold-ui'
import React from 'react'

interface CidadaoAusenteModalProps {
  hasPermission: boolean
  onConfirm: () => void
}

export const cidadaoAusenteModal = ({ hasPermission, onConfirm }: CidadaoAusenteModalProps) =>
  modal({
    size: 'small',
    closeOnBackdropClick: false,
    render: () => (
      <ModalBody style={{ padding: 0 }}>
        <HFlow alignItems='flex-start'>
          <Icon icon='infoCircleOutline' fill='info' size={3} />
          <VFlow>
            <Heading level={1}>Atenção</Heading>
            <Text>
              Não é possível realizar atendimentos com cidadão ausente. É necessário atualizar o cadastro do cidadão com
              a data de retorno.
            </Text>
          </VFlow>
        </HFlow>
      </ModalBody>
    ),
    actions: hasPermission
      ? [{ label: 'Cancelar' }, { label: 'Atualizar Cadastro', kind: 'primary', onClick: onConfirm }]
      : [{ label: 'Ok', kind: 'primary' }],
  })()

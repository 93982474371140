import React, { useContext } from 'react'

import { AssinaturaContextModel } from './model'

interface AssinaturaContextProps {
  isAtendimentoAssinado: boolean
  children: React.ReactNode
}

export const AssinaturaContext = React.createContext<AssinaturaContextModel>(undefined)

export function AssinaturaProvider(props: AssinaturaContextProps) {
  const { isAtendimentoAssinado, children } = props

  return <AssinaturaContext.Provider value={{ isAtendimentoAssinado }}>{children}</AssinaturaContext.Provider>
}

export const useAssinaturaContext = () => useContext(AssinaturaContext)

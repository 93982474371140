import { LocalAtendimentoSelectFieldModel } from 'components/form'
import createDecorator from 'final-form-calculate'
import { metaPath } from 'util/metaPath'

import { RegistroTardioFormModel } from './model-registroTardio'
import { isLocalAtendimentoComJustificativa } from './validator-registroTardio'

const meta = metaPath<RegistroTardioFormModel>()

export const calculator = createDecorator({
  field: meta.localAtendimento.absolutePath(),
  updates: {
    [meta.justificativa.absolutePath()]: (
      localAtendimento: LocalAtendimentoSelectFieldModel,
      values: RegistroTardioFormModel
    ) => (!isLocalAtendimentoComJustificativa(localAtendimento?.id) ? undefined : values.justificativa),
  },
})

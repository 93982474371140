/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, colors, HFlow, Text, Theme, Tooltip, useTheme } from 'bold-ui'
import puericulturaImage from 'images/soap/ic_puericultura.png'
import { useMemo } from 'react'

interface PuericulturaButtonProps {
  puericulturaAtivo: boolean
  handleClick(): void
  tooltipText?: string
  disabled?: boolean
}

export function PuericulturaButton(props: PuericulturaButtonProps) {
  const { puericulturaAtivo, handleClick, tooltipText, disabled } = props
  const theme = useTheme()
  const classes = useMemo(() => styles(theme, puericulturaAtivo), [puericulturaAtivo, theme])

  return (
    <Tooltip text={tooltipText}>
      <Button size='medium' style={classes.puericulturaButton} onClick={handleClick} disabled={disabled}>
        <HFlow alignItems='center' hSpacing={0.5}>
          <img src={puericulturaImage} alt='Puericultura' />
          <Text fontWeight='bold' style={classes.puericulturaButtonText}>
            Puericultura
          </Text>
        </HFlow>
      </Button>
    </Tooltip>
  )
}

const styles = (theme: Theme, puericulturaAtivo: boolean) => ({
  puericulturaButton: css`
    width: 9.75rem;
    border: ${puericulturaAtivo ? '0' : `1px ${colors.turquoise.c60} solid`};
    background-color: ${puericulturaAtivo ? colors.turquoise.c60 : theme.pallete.gray.c100};
    margin: 0;
    &:not(:disabled):hover {
      background-color: ${puericulturaAtivo ? colors.turquoise.c50 : colors.turquoise.c90};
    }
  `,
  puericulturaButtonText: css`
    color: ${puericulturaAtivo ? theme.pallete.gray.c100 : colors.turquoise.c40};
  `,
})

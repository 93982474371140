import { Cell, Grid } from 'bold-ui'
import { resolveValue } from 'components/form/final-form/hooks/useField'
import React from 'react'
import { FormRenderProps } from 'react-final-form'
import { MetaPath } from 'util/metaPath'

import { TextField } from '../../final-form/TextField'
import { MunicipioSelectField, UfSelectField } from '../select'
import { AldeiaSelectField } from '../select/AldeiaSelectField'
import { DseiSelectField } from '../select/DseiSelectField'
import { PoloBaseSelectField } from '../select/PoloBaseSelectField'
import { EnderecoIndigenaFieldGroupModel } from './model-enderecoIndigena'

export type AutoCompleteOptions = 'none' | 'on'

export interface EnderecoIndigenaFieldGroupProps {
  name: MetaPath<EnderecoIndigenaFieldGroupModel>
  formProps: FormRenderProps<any>
  formModelValue?: any
  autoComplete?: AutoCompleteOptions
  isCadastroCidadao?: boolean
}

export function EnderecoIndigenaFieldGroup(props: EnderecoIndigenaFieldGroupProps) {
  const { name, formProps, formModelValue, autoComplete = 'none', isCadastroCidadao } = props
  const values =
    resolveValue(formModelValue ? formModelValue : formProps.values, name) || ({} as EnderecoIndigenaFieldGroupModel)

  return (
    <Grid wrap>
      <Cell size={6}>
        <DseiSelectField
          name={name.dsei}
          label='DSEI'
          poloBaseId={values.poloBase?.id}
          aldeiaId={values.aldeia?.id}
          required
          autoComplete={autoComplete}
        />
      </Cell>
      <Cell size={6}>
        <PoloBaseSelectField
          name={name.poloBase}
          label='Polo base'
          dseiId={values.dsei?.id}
          aldeiaId={values.aldeia?.id}
          required
          autoComplete={autoComplete}
        />
      </Cell>
      <Cell size={6}>
        <AldeiaSelectField
          name={name.aldeia}
          label='Aldeia'
          dseiId={values.dsei?.id}
          poloBaseId={values.poloBase?.id}
          required
          autoComplete={autoComplete}
        />
      </Cell>
      <Cell size={6}>
        <MunicipioSelectField
          name={name.municipio}
          disabled={!values.aldeia}
          label='Município da aldeia'
          aldeiaId={values.aldeia?.id}
          required
          autoComplete={autoComplete}
        />
      </Cell>
      <Cell size={6}>
        <UfSelectField
          name={name.uf}
          disabled={!values.municipio}
          sigla={values.municipio?.uf?.sigla}
          label='UF'
          required
          autoComplete={autoComplete}
        />
      </Cell>
      <Cell size={3}>
        <TextField
          name={name.numero}
          label='Número da residência'
          maxLength={7}
          required
          autoComplete={autoComplete}
          disabled={!values.municipio}
        />
      </Cell>
      {isCadastroCidadao && (
        <Cell size={3}>
          <TextField name={name.numeroFamilia} label='Número da família' maxLength={7} required />
        </Cell>
      )}
    </Grid>
  )
}

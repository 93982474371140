import { HeadingSection } from 'bold-ui'
import { Breadcrumb } from 'components/breadcrumb'
import { PageContent } from 'components/layout/PageContent'
import React, { Fragment } from 'react'

export const AtividadeColetivaSaudeView = () => {
  return (
    <Fragment>
      <Breadcrumb title='Registro de atividade coletiva em saúde' />
      <PageContent type='filled'>
        <HeadingSection level={1} title='Registro de atividade coletiva em saúde' />
      </PageContent>
    </Fragment>
  )
}

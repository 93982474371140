import { Omit } from 'bold-ui'
import { SelectField, SelectFieldProps } from 'components/form/final-form'
import { TipoAbastecimentoAguaEnum } from 'graphql/types.generated'
import React from 'react'

import { tipoAbastecimentoAguaEnumRecord } from './condicoes-moradia-model'

export interface TipoAbastecimentoAguaSelectFieldProps
  extends Omit<SelectFieldProps<TipoAbastecimentoAguaEnum>, 'items' | 'itemToString'> {}

export function TipoAbastecimentoAguaSelectField(props: TipoAbastecimentoAguaSelectFieldProps) {
  return <SelectField<TipoAbastecimentoAguaEnum> items={items} itemToString={itemToString} {...props} />
}

const items = Object.values(TipoAbastecimentoAguaEnum)
const itemToString = (item: TipoAbastecimentoAguaEnum) => item && tipoAbastecimentoAguaEnumRecord[item]

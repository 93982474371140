/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, HFlow, Icon, Text, Theme, Tooltip, useTheme, VFlow } from 'bold-ui'
import { blue } from 'bold-ui/lib/styles/colors'
import { useFirebase } from 'hooks/firebase/useFirebase'
import { AlertaSuporteDecisaoClinica } from 'hooks/sdc/model-sdc'

import { ReactComponent as NotificationCircle } from '../../../images/novidades/icons/notification_circle.svg'

interface SdcPopperAlert {
  alerta: AlertaSuporteDecisaoClinica
  onAlertaClick?(alertaId: ID): void
}

export const SdcPopperAlert = (props: SdcPopperAlert) => {
  const { alerta, onAlertaClick } = props

  const theme = useTheme()
  const styles = createStyles(theme)

  const { analytics } = useFirebase()

  const handleAlertaClick = () => {
    analytics.logEvent('lupa_SDC')
    onAlertaClick?.(alerta.id)
  }

  return (
    <HFlow hSpacing={0.5} style={styles.alert}>
      <Text style={alerta.visualizado && styles.alertDisabled}>
        <NotificationCircle css={styles.notificationCircle} />
      </Text>
      <VFlow vSpacing={0}>
        <Text fontSize={0.813} fontWeight='bold'>
          {alerta.titulo}
        </Text>
        <Text fontSize={0.813} style={styles.descriptionText}>
          {alerta.descricao}
        </Text>
      </VFlow>
      <Cell alignSelf='center'>
        <Button size='small' skin='ghost' onClick={handleAlertaClick}>
          <Tooltip text='Ler toda orientação'>
            <Icon icon='zoomOutline' />
          </Tooltip>
        </Button>
      </Cell>
    </HFlow>
  )
}

const createStyles = (theme: Theme) => ({
  alert: css`
    padding: 0.5rem;
    border-bottom: 1px solid ${theme.pallete.divider};
    grid-template-columns: min-content 1fr min-content;
  `,
  alertDisabled: css`
    visibility: hidden;
  `,
  notificationCircle: css`
    width: 0.5rem;
    height: 0.5rem;
    * {
      fill: ${blue.c40};
    }
  `,
  descriptionText: css`
    color: ${theme.pallete.gray.c10};
  `,
})

import { Breadcrumb } from 'components/breadcrumb'
import { PecSwitch, PrivateRoute } from 'components/route'
import React, { Fragment } from 'react'
import { useRouteMatch } from 'react-router-dom'
import Permissions from 'types/Permissions'

import { AtividadeColetivaView } from './AtividadeColetivaView'
import { AtividadeColetivaReuniaoView } from './reuniao/AtividadeColetivaReuniaoView'
import { AtividadeColetivaVisualizarReuniaoView } from './reuniao/AtividadeColetivaVisualizarReuniaoView'
import { AtividadeColetivaSaudeView } from './saude/AtividadeColetivaSaudeView'

export function AtividadeColetivaRootView() {
  const match = useRouteMatch()

  return (
    <Fragment>
      <Breadcrumb title='Atividade coletiva' />

      <PecSwitch>
        <PrivateRoute
          path={`${match.url}/registrar-reuniao`}
          component={AtividadeColetivaReuniaoView}
          permission={Permissions.cds.fichaDeAtividadeColetiva.cadastrarEditarEExcluir}
        />
        <PrivateRoute
          path={`${match.url}/registrar-atividade-saude`}
          component={AtividadeColetivaSaudeView}
          permission={Permissions.cds.fichaDeAtividadeColetiva.cadastrarEditarEExcluir}
        />
        <PrivateRoute
          path={`${match.url}/visualizar-reuniao/:atividadeColetivaId`}
          component={AtividadeColetivaVisualizarReuniaoView}
          permission={Permissions.cds.fichaDeAtividadeColetiva}
        />
        <PrivateRoute
          exact
          path={`${match.url}`}
          component={AtividadeColetivaView}
          permission={Permissions.cds.fichaDeAtividadeColetiva}
        />
      </PecSwitch>
    </Fragment>
  )
}

import { DropdownItem } from 'bold-ui'
import { DropdownButton } from 'components/dropdown'
import React from 'react'

import { RegistroObitoCidadaoFormModel } from '../model-cadastroImovel'

interface CidadaoFamiliaDropdownButton {
  isResponsavel: boolean
  isFamiliaMudouSe: boolean
  isMudouSe: boolean
  isNovoCadastroNaFamilia: boolean
  hasPermission: boolean
  registroObito: RegistroObitoCidadaoFormModel
  onDefinirNovoResponsavel: () => void
  onClickMudouSe: () => void
  onAtualizarCidadao: () => void
  onDesfazerMudouSe: () => void
  onClickCidadaoObito: () => void
  onRemoverCidadao: () => void
}

export const CidadaoFamiliaDropdownButton = (props: CidadaoFamiliaDropdownButton) => {
  const {
    isFamiliaMudouSe,
    isMudouSe,
    registroObito,
    isResponsavel,
    isNovoCadastroNaFamilia,
    hasPermission,
    onDefinirNovoResponsavel,
    onClickMudouSe,
    onDesfazerMudouSe,
    onAtualizarCidadao,
    onClickCidadaoObito,
    onRemoverCidadao,
  } = props

  return (
    <DropdownButton>
      {!isResponsavel && (
        <DropdownItem disabled={isFamiliaMudouSe || isMudouSe || !!registroObito} onClick={onDefinirNovoResponsavel}>
          Definir como responsável familiar
        </DropdownItem>
      )}
      {!isNovoCadastroNaFamilia && (
        <DropdownItem
          disabled={isFamiliaMudouSe || !!registroObito}
          onClick={isMudouSe ? onDesfazerMudouSe : onClickMudouSe}
        >
          {isMudouSe ? 'Desfazer mudou-se' : 'Mudou-se'}
        </DropdownItem>
      )}
      {hasPermission && <DropdownItem onClick={onAtualizarCidadao}>Atualizar cidadão</DropdownItem>}
      {!isNovoCadastroNaFamilia && (
        <DropdownItem disabled={isFamiliaMudouSe || isMudouSe} onClick={onClickCidadaoObito}>
          {registroObito ? 'Desfazer óbito' : 'Óbito'}
        </DropdownItem>
      )}
      {isNovoCadastroNaFamilia && <DropdownItem onClick={onRemoverCidadao}>Remover cidadão</DropdownItem>}
    </DropdownButton>
  )
}

/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, Text, Theme, useTheme, VFlow } from 'bold-ui'
import { useMemo } from 'react'

import { IVCF_GRUPOS, IvcfGrupoEnum } from '../model-ivcf'

interface IvcfGroupHeaderProps {
  grupo: IvcfGrupoEnum
  isEditing: boolean
}

export function IvcfGroupHeader(props: IvcfGroupHeaderProps) {
  const { grupo, isEditing } = props

  const theme = useTheme()
  const styles = useMemo(() => createStyles(theme, isEditing), [isEditing, theme])
  const { title, subtitle, index } = IVCF_GRUPOS[grupo]

  return (
    <VFlow vSpacing={0}>
      <HFlow hSpacing={0.25} alignItems='center' style={styles.groupHeader}>
        <Text fontWeight='bold'>{title}</Text>
        <Text>|</Text>
        <Text>Grupo {index} de 8</Text>
      </HFlow>
      {subtitle && (
        <HFlow alignItems='center' style={styles.groupSubtitle}>
          <Text fontWeight='bold'>{subtitle}</Text>
        </HFlow>
      )}
    </VFlow>
  )
}

const createStyles = (theme: Theme, isEditing: boolean) => ({
  groupHeader: css`
    height: 2rem;
    padding: 0 1rem;
    background: ${theme.pallete.gray.c90};
    border-bottom: 1px solid ${theme.pallete.gray.c80};
  `,
  groupSubtitle: css`
    font-weight: bold;
    height: 2rem;
    padding: 0 1rem;
    background: ${isEditing ? theme.pallete.primary.c90 : theme.pallete.surface.main};
    border-bottom: 1px solid ${theme.pallete.gray.c80};
  `,
})

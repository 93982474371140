import { colors } from 'bold-ui'
import { AgeRangeValue } from 'components/date/AgeRange'
import { EquipeSelectModel, LocalDateRange } from 'components/form'
import { CiapCidSelectModel } from 'components/form/field/select/CiapCidSelectField/CiapCidSelectField'
import { GrupoCondicaoSelectModel } from 'components/form/field/select/GrupoCondicaoSelectField/GrupoCondicaoSelectField'
import {
  AgeRangeType,
  FaixaEtariaInput,
  GrupoCondicaoEnum,
  IdentidadeGeneroEnum,
  PageParams,
  PeriodoDesfechoEnum,
  RiscoGravidez,
  SexoEnum,
  TipoRelatorioAcompanhamentoEnum,
} from 'graphql/types.generated'

import { ExamesPreNatalSelectModel } from './components/ExamesPreNatalSelectField'

export const ACOMPANHAMENTO_CONDICAO_SAUDE_ATMOSPHERE_PATH = 'acompanhamento-condicao-saude'

export enum AgeRangeFilter {
  TODAS_FAIXAS = 'TODAS_FAIXAS',
  CRIANCA = 'CRIANÇA',
  ADOLESCENTE = 'ADOLESCENTE',
  ADULTO = 'ADULTO',
  IDOSO = 'IDOSO',
  OUTRA = 'OUTRA',
}

interface AcompanhamentoCondicaoSaudeGeralFormModel {
  grupoCondicao?: GrupoCondicaoSelectModel[]
  problemasCondicoes?: CiapCidSelectModel[]
  ativoListaProblema?: boolean
  sexo?: SexoEnum[]
  identidadeGenero?: IdentidadeGeneroEnum[]
  periodoUltimoAtendimento?: LocalDateRange
  // controle dos grupos rápidos
  isDiabetesCheck?: boolean
  isHipertensaoCheck?: boolean
  isObesidadeCheck?: boolean
  isGravidezCheck?: boolean
}

interface AcompanhamentoGestantesPuerperasFormModel {
  tipoRelatorio?: TipoRelatorioAcompanhamentoEnum
  riscoGestacional?: RiscoGravidez
  idadeGestacional?: AgeRangeValue
  examesNaoSolicitados?: ExamesPreNatalSelectModel[]
  periodoDesfecho?: PeriodoDesfechoEnum
  periodoUltimaConsulta?: AgeRangeValue
}

export interface AcompanhamentoCondicaoSaudeFormModel {
  unidadeResponsavelId?: ID
  equipeResponsavelId?: ID
  equipeResponsavel?: EquipeSelectModel
  faixaEtaria?: AgeRangeValue
  faixaEtariaFilter?: AgeRangeFilter
  faixaEtariaInicio?: AgeRangeValue['firstValue']
  faixaEtariaFim?: AgeRangeValue['secondValue']
  isCoordenador?: boolean
  microareas?: string[]
  foraarea?: boolean
  naoinformada?: boolean
  geral?: AcompanhamentoCondicaoSaudeGeralFormModel
  gestantesPuerperas?: AcompanhamentoGestantesPuerperasFormModel
  pageParams?: PageParams
}

export const condicaoToFormRecord: Partial<Record<
  GrupoCondicaoEnum,
  keyof AcompanhamentoCondicaoSaudeFormModel['geral']
>> = {
  [GrupoCondicaoEnum.DIABETES]: 'isDiabetesCheck',
  [GrupoCondicaoEnum.HIPERTENSAO_ARTERIAL]: 'isHipertensaoCheck',
  [GrupoCondicaoEnum.OBESIDADE]: 'isObesidadeCheck',
  [GrupoCondicaoEnum.GRAVIDEZ]: 'isGravidezCheck',
}

export interface RiscoGravidezCor {
  descricao: string
  cor: string
}

export const riscoGravidezRecord: Record<RiscoGravidez, RiscoGravidezCor> = {
  [RiscoGravidez.ALTO]: { descricao: RiscoGravidez.ALTO.capitalize(), cor: colors.red.c40 },
  [RiscoGravidez.HABITUAL]: { descricao: RiscoGravidez.HABITUAL.capitalize(), cor: colors.blue.c40 },
}

export interface AcompanhamentoCondicaoSaudeDto {
  idCidadao: ID
  nomeCidadao?: string
  nomeSocialCidadao?: string
  cpfCidadao?: string
  cnsCidadao?: string
  telefoneResidencial?: string
  telefoneCelular?: string
  telefoneContato?: string
  endereco?: string
  dataUltimoAtendimento?: Date
}

export const faixaEtariaRecord: Partial<Record<AgeRangeFilter, FaixaEtariaInput>> = {
  [AgeRangeFilter.CRIANCA]: { idadeMaxima: 10, tipoFaixaEtaria: AgeRangeType.ANOS },
  [AgeRangeFilter.ADOLESCENTE]: { idadeMinima: 11, idadeMaxima: 19, tipoFaixaEtaria: AgeRangeType.ANOS },
  [AgeRangeFilter.ADULTO]: { idadeMinima: 20, idadeMaxima: 59, tipoFaixaEtaria: AgeRangeType.ANOS },
  [AgeRangeFilter.IDOSO]: { idadeMinima: 60, tipoFaixaEtaria: AgeRangeType.ANOS },
}

/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Heading, HFlow, Theme, useTheme } from 'bold-ui'
import { HideOnScreenSize } from 'components/responsive'
import { StreamPlayer } from 'components/stream-player'
import { useEffect, useState } from 'react'

import { AcaoVideochamadaButton } from '../componentes/AcaoVideochamadaButton'
import { useConfiguracoesVideochamada } from '../hooks/useConfiguracoesVideochamada'
import { useMediaDevicesConfiguration } from '../hooks/useMediaDevicesConfiguration'
import { useUserMedia } from '../hooks/useUserMedia'
import { ConfiguracoesVideochamadaModel, MediaStreamTrackKind } from '../model-videochamada'
import { ConfiguracoesVideochamadaFormModal } from './ConfiguracoesVideochamadaFormModal'
import { MicrophoneFeedback } from './MicrophoneFeedback'

interface CameraPreviewProps {
  audioEnabled: boolean
  videoEnabled: boolean
  setAudioEnabled(value: boolean): void
  setVideoEnabled(value: boolean): void
  isMobile?: boolean
}

export function CameraPreview(props: CameraPreviewProps) {
  const theme = useTheme()

  const { audioEnabled, videoEnabled, setAudioEnabled, setVideoEnabled, isMobile } = props

  const [isConfiguracoesModalOpen, setConfiguracoesModalOpen] = useState(false)
  const [configuracoesVideochamada, setConfiguracoesVideochamada] = useConfiguracoesVideochamada()

  const { stream: localStream, audioDeviceAvailable, videoDeviceAvailable, mediaDevices } = useUserMedia({
    video: videoEnabled,
    audio: audioEnabled,
    videoDeviceId: configuracoesVideochamada ? configuracoesVideochamada.videoInput?.id : 'default',
    audioDeviceId: configuracoesVideochamada ? configuracoesVideochamada.audioInput?.id : 'default',
  })

  const styles = createStyles(theme)

  const [videoTrackEnabled, setVideoTrackEnabled] = useState(false)
  const [audioTrackEnabled, setAudioTrackEnabled] = useState(false)

  useEffect(() => {
    const handleChangeTracks = (e: CustomEvent | MediaStreamTrack) => {
      const track = e instanceof CustomEvent ? e.detail?.track : (e as MediaStreamTrack)
      if (!track) return

      if (track.kind === MediaStreamTrackKind.AUDIO) setAudioTrackEnabled(track.enabled)
      else setVideoTrackEnabled(track.enabled)
    }

    if (localStream) {
      localStream.addEventListener('enable', (e: CustomEvent) => handleChangeTracks(e))
      localStream.getTracks().forEach((track) => handleChangeTracks(track))
    } else {
      setVideoTrackEnabled(false)
      setAudioTrackEnabled(false)
    }

    return () => {
      if (localStream) {
        localStream.removeEventListener('enable', (e: CustomEvent) => handleChangeTracks(e))
      }
    }
  }, [localStream, setAudioEnabled, setVideoEnabled])

  const { mediaDevicesConfiguration, setStoredAudioDevice, setStoredVideoDevice } = useMediaDevicesConfiguration({
    mediaDevices,
    audioEnabled,
    videoEnabled,
  })

  const handleSetConfiguracoesVideochamada = (config: ConfiguracoesVideochamadaModel) => {
    setConfiguracoesVideochamada(config)
    setStoredAudioDevice(config.audioInput)
    setStoredVideoDevice(config.videoInput)
  }

  return (
    <div css={[styles.container, isMobile && styles.containerMobile]}>
      <ConfiguracoesVideochamadaFormModal
        open={isConfiguracoesModalOpen}
        onClose={() => setConfiguracoesModalOpen(false)}
        initialConfigurations={mediaDevicesConfiguration}
        onSubmit={handleSetConfiguracoesVideochamada}
      />
      <div css={styles.cameraAndButtons}>
        <div css={styles.camera}>
          {!videoTrackEnabled ? (
            <Heading
              level={1}
              style={css`
                color: white;
              `}
            >
              Sem câmera
            </Heading>
          ) : (
            <StreamPlayer
              style={[styles.video, isMobile && styles.videoMobile]}
              stream={localStream}
              playsInline
              muted
              autoPlay
            />
          )}
        </div>

        <MicrophoneFeedback
          audioTrack={localStream?.getAudioTracks()[0]}
          audioEnabled={audioTrackEnabled}
          styles={styles.micFeedback}
        />
        <HFlow style={styles.buttons}>
          <AcaoVideochamadaButton
            unavailable={!audioDeviceAvailable}
            state={audioDeviceAvailable && audioEnabled ? 'on' : 'off'}
            onClick={() => setAudioEnabled(!audioEnabled)}
            icon='mic'
          />
          <AcaoVideochamadaButton
            unavailable={!videoDeviceAvailable}
            state={videoDeviceAvailable && videoEnabled ? 'on' : 'off'}
            onClick={() => setVideoEnabled(!videoEnabled)}
            icon='video'
          />
          <HideOnScreenSize down='sm'>
            <AcaoVideochamadaButton state='on' onClick={() => setConfiguracoesModalOpen(true)} icon='config' />
          </HideOnScreenSize>
        </HFlow>
      </div>
    </div>
  )
}

const createStyles = (theme: Theme) => ({
  container: css`
    padding-top: 90%;
    position: relative;
    height: 100%;
    max-height: 19.4rem;
  `,
  cameraAndButtons: css`
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
  `,
  video: css`
    height: 100%;
    width: 100%;
    object-fit: cover;
  `,
  camera: css`
    height: 100%;
    background-color: ${theme.pallete.gray.c20};
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  buttons: css`
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    padding: 1rem;
  `,
  micFeedback: css`
    position: absolute;
    top: 1rem;
    left: 1rem;
  `,
  containerMobile: css`
    padding-top: 0;
    max-height: none;
    min-height: calc(8.375rem - (100vh - ${window.innerHeight}px));
  `,
  videoMobile: css`
    object-fit: contain;
  `,
})

import { msg } from '..'

const ONLY_LETTERS = /^([a-zA-ZáéíóúÁÉÍÓÚçÇâêôÂÊÔõãÕÃäöüÄÖÜ '\s])+$/

export function nome(minLength: number = 5) {
  return (inputValue: string) => {
    if (!inputValue) {
      return null
    }

    if (!ONLY_LETTERS.test(inputValue)) {
      return msg('simpleName', inputValue)
    }

    // Deve possuir ao menos 5 caracteres
    if (inputValue.length < minLength) {
      return msg('minLength', inputValue, minLength)
    }

    if (inputValue.length > 100) {
      return msg('maxLength', inputValue, 100)
    }

    const nomeEspacos = inputValue && inputValue.trim().replace(/\s+/g, ' ')
    const nomes: string[] = nomeEspacos && nomeEspacos.split(' ')

    if (nomes.length < 2) {
      return msg('nome', inputValue)
    }

    if (nomes[0].length === 1) {
      return msg('nomeOneLetter', inputValue)
    }

    if (nomes.length === 2 && nomes[0].length === 2 && nomes[1].length === 2) {
      return msg('nomeTwoLetters', inputValue)
    }

    return null
  }
}

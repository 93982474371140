import { Cell, Tabs } from 'bold-ui'
import { PecSwitch, TabLink } from 'components/route'
import { useFlags } from 'config/useFlagsContext'
import React, { Fragment } from 'react'
import { useRouteMatch } from 'react-router'
import { Route } from 'react-router-dom'
import { MetaRoot } from 'util/metaPath'

import { AcompanhamentoCondicaoSaudeGeralTabContent } from './AcompanhamentoCondicaoSaudeGeralTabContent'
import { AcompanhamentoGestantesPuerperasTabContent } from './AcompanhamentoGestantesPuerperasTabContent'
import { AcompanhamentoCondicaoSaudeFormModel } from './model-acompanhamentoCondicaoSaude'

export interface AcompanhamentoCondicaoSaudeTabsProps {
  path: MetaRoot<AcompanhamentoCondicaoSaudeFormModel>
  formValues?: AcompanhamentoCondicaoSaudeFormModel
  onChange: (name: string, value?: any) => void
  onClick?: () => void
}

export function AcompanhamentoCondicaoSaudeTabs(props: AcompanhamentoCondicaoSaudeTabsProps) {
  const { path, formValues, onChange, onClick } = props
  const { ACOMPANHAMENTO_GESTANTES_PUERPERAS_ENABLED } = useFlags()
  const match = useRouteMatch()

  return (
    <Fragment>
      {ACOMPANHAMENTO_GESTANTES_PUERPERAS_ENABLED ? (
        <Fragment>
          <Cell size={12}>
            <Tabs>
              <TabLink exact to={match.url} onClick={onClick}>
                Geral
              </TabLink>
              <TabLink to={`${match.url}/gestantes-e-puerperas`} onClick={onClick}>
                Gestantes/Puérperas
              </TabLink>
            </Tabs>
          </Cell>
          <Cell size={12}>
            <PecSwitch>
              <Route exact path={match.url}>
                <AcompanhamentoCondicaoSaudeGeralTabContent path={path} formValues={formValues} onChange={onChange} />
              </Route>
              <Route path={`${match.url}/gestantes-e-puerperas`}>
                <AcompanhamentoGestantesPuerperasTabContent path={path} formValues={formValues} onChange={onChange} />
              </Route>
            </PecSwitch>
          </Cell>
        </Fragment>
      ) : (
        <Cell size={12}>
          <AcompanhamentoCondicaoSaudeGeralTabContent path={path} formValues={formValues} onChange={onChange} />
        </Cell>
      )}
    </Fragment>
  )
}

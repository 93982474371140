/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Heading, HFlow, Modal, ModalBody, ModalProps, Text } from 'bold-ui'
import { Cns, Cpf } from 'components/label'
import { AssinaturaProvider } from 'hooks/assinatura-eletronica-context/useAssinaturaEletronicaContext'
import HistoricoDetalhesPanel from 'view/atendimentos/detail/historico/components/accordion/detail/HistoricoDetalhesPanel'
import { convertIdadeOrigemAtendimento } from 'view/atendimentos/detail/historico/converter'
import { HistoricoDetalhesModel } from 'view/atendimentos/detail/historico/model/model-historico'

import { DadosCidadaoVisualizarAtendimentoModel } from './model'
interface VisualizarAtendimentoModalProps extends ModalProps {
  historicoAtendimento: HistoricoDetalhesModel
  title: string
  titleColor: string
  cidadao: DadosCidadaoVisualizarAtendimentoModel
  isAtendimentoAssinado?: boolean
}

export function VisualizarAtendimentoModal(props: VisualizarAtendimentoModalProps) {
  const { historicoAtendimento, title, cidadao, titleColor, isAtendimentoAssinado = false, ...modalProps } = props
  const idadeOrigemAtendimento = convertIdadeOrigemAtendimento(
    cidadao.dataNascimento,
    historicoAtendimento.dataAtendimento,
    historicoAtendimento.origemAtendimento
  )

  const isAssinaturaEletronica = !!cidadao.nome

  return (
    <Modal {...modalProps}>
      <ModalBody>
        <Heading
          level={1}
          style={css`
            color: ${titleColor};
          `}
        >
          <HFlow>
            {title}
            {!isAtendimentoAssinado && isAssinaturaEletronica && (
              // TODO (@Legacy): Ação do botão será realizada na #13407
              <Button size='small' kind='primary' style={styles.button}>
                Assinar atendimento
              </Button>
            )}
          </HFlow>
        </Heading>

        {isAssinaturaEletronica && (
          <Heading level={2}>
            <Text fontSize={1} fontWeight='bold'>
              {!cidadao.nomeSocial ? `${cidadao.nome} - ` : `${cidadao.nomeSocial} (${cidadao.nome}) - `}
            </Text>
            <Text fontSize={1} fontWeight='bold'>
              {cidadao.cpf ? 'CPF ' : cidadao.cns ? 'CNS ' : ''}
            </Text>
            <Text fontSize={1} fontWeight='normal'>
              {cidadao.cpf ? (
                <Cpf value={cidadao?.cpf} />
              ) : cidadao.cns ? (
                <Cns value={cidadao?.cns} />
              ) : (
                'Cidadão sem CPF/CNS'
              )}
            </Text>
          </Heading>
        )}
        <AssinaturaProvider isAtendimentoAssinado={isAtendimentoAssinado}>
          <HistoricoDetalhesPanel
            historicoAtendimento={historicoAtendimento}
            idadeOrigemAtendimento={idadeOrigemAtendimento}
            dataNascimento={cidadao.dataNascimento}
          />
        </AssinaturaProvider>
      </ModalBody>
    </Modal>
  )
}

const styles = {
  button: css`
    align-items: center;
  `,
}

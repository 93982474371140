/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Heading, HFlow, Modal, ModalBody, ModalFooter, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { useErrorHandler } from 'components/error'
import { CidadaoAtendimentoSelectField, CidadaoSelectField, Form, FormRenderProps, SubmitButton } from 'components/form'
import { FormApi } from 'final-form'
import { useProcessarUltimaFichaCidadaosTerritorioMutation } from 'graphql/hooks.generated'
import { useCallback } from 'react'
import { isUndefinedOrNull } from 'util/checks'
import { metaPath } from 'util/metaPath'
import { CadastrarCidadaoFamiliaFormModel, CidadaoTerritorioFormModel } from 'view/cadastro-imovel/model-cadastroImovel'
import { createCadastrarCidadaoFamiliaValidator } from 'view/cadastro-imovel/validator-cadastroImovel'

interface CadastrarCidadaoFamiliaModalProps {
  open: boolean
  cadastroImovelPath: string
  imovelId?: ID
  initialValues: CadastrarCidadaoFamiliaFormModel
  hasPermission: boolean
  onClose(): void
  onSubmit(formValues: CidadaoTerritorioFormModel, formApi: FormApi<object>): void
  onSaveCadastroCidadaoFamiliaCache(form: CadastrarCidadaoFamiliaFormModel): void
  onScrollAfterRedirect: () => void
  isCidadaoPresenteFamilias(cidadaoId: ID): boolean
}

const meta = metaPath<CadastrarCidadaoFamiliaFormModel>()

export function CadastrarCidadaoFamiliaModal(props: CadastrarCidadaoFamiliaModalProps) {
  const {
    open,
    cadastroImovelPath,
    initialValues,
    hasPermission,
    onClose,
    onSubmit,
    onSaveCadastroCidadaoFamiliaCache,
    onScrollAfterRedirect,
    isCidadaoPresenteFamilias,
  } = props

  const [processarCidadaosTerritorio] = useProcessarUltimaFichaCidadaosTerritorioMutation()
  const alert = useAlert()
  const handleRejection = useErrorHandler()

  const handleCloseModal = (resetApi: () => void) => {
    setTimeout(resetApi)
    onClose()
    onScrollAfterRedirect()
  }

  const handleSubmit = ({ cidadao }: CadastrarCidadaoFamiliaFormModel, formApi: FormApi) => {
    processarCidadaosTerritorio({
      variables: {
        input: [cidadao?.id],
      },
    })
      .then(({ data }) => {
        if (data?.processarCidadaosTerritorio) {
          alert('info', 'Cidadão automaticamente atualizado com ficha recente.')
        }
      })
      .catch(handleRejection)

    onSubmit(
      {
        _id: null,
        id: cidadao.id,
        mudouSe: false,
        registroObito: null,
        isNovoCadastroNaFamilia: true,
      },
      formApi
    )
    handleCloseModal(formApi.reset)
  }

  const isButtonDisabled = useCallback(
    (cidadaoId: ID) => isUndefinedOrNull(cidadaoId) || isCidadaoPresenteFamilias(cidadaoId),
    [isCidadaoPresenteFamilias]
  )

  const renderForm = ({ values, form }: FormRenderProps<CadastrarCidadaoFamiliaFormModel>) => {
    return (
      <Modal open={open} onClose={() => handleCloseModal(form.reset)} size='auto' closeOnBackdropClick={false}>
        <ModalBody
          style={css`
            width: 37rem;
            margin-bottom: 5rem;
          `}
        >
          <VFlow vSpacing={1}>
            <Heading level={1}>Adicionar cidadão à familia</Heading>
            {hasPermission ? (
              <CidadaoAtendimentoSelectField
                name={meta.cidadao}
                ativo
                obito={false}
                label='Cidadão'
                onBeforeRedirect={() => onSaveCadastroCidadaoFamiliaCache(values)}
                verificarPresenteNaListaAtendimento={false}
                addCidadaoCallbackUrl={cadastroImovelPath}
                required
              />
            ) : (
              <CidadaoSelectField name={meta.cidadao} label='Cidadão' required ativo />
            )}
          </VFlow>
        </ModalBody>
        <ModalFooter>
          <HFlow justifyContent='flex-end'>
            <Button onClick={() => handleCloseModal(form.reset)} size='medium'>
              Cancelar
            </Button>
            <SubmitButton
              kind='primary'
              size='medium'
              handleSubmit={() => handleSubmit(values, form)}
              disabled={isButtonDisabled(values?.cidadao?.id)}
            >
              Salvar
            </SubmitButton>
          </HFlow>
        </ModalFooter>
      </Modal>
    )
  }

  return (
    <Form<CadastrarCidadaoFamiliaFormModel>
      render={renderForm}
      onSubmit={handleSubmit}
      validate={createCadastrarCidadaoFamiliaValidator(isCidadaoPresenteFamilias)}
      initialValues={initialValues}
    />
  )
}

import { css } from '@emotion/core'
import { HFlow, Theme, useTheme, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { PageContainer } from 'components/layout/PageContainer'
import { PageContent } from 'components/layout/PageContent'
import { TitleGroup } from 'components/TitleGroup'
import { useUnificacaoCidadaosListingLazyQuery } from 'graphql/hooks.generated'
import { ArquivoIdentificadorEnum } from 'graphql/types.generated'
import qs from 'qs'
import React, { useCallback, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { CidadaoPesquisarForm } from 'view/cidadao/list/CidadaoPesquisarForm'
import { CidadaoUrlParams } from 'view/cidadao/types/CidadaoUrlParams'
import CidadaoPesquisarFormModel, { CidadaoListingFormModel } from 'view/cidadao/types/model-pesquisarCidadao'

import { ExportarCadastrosDuplicadosButton } from './components/ExportarCadastrosDuplicadosButton'
import { UnificacaoCadastroListing } from './UnificacaoCadastroListing'

export function UnificacaoCadastroView() {
  const alert = useAlert()
  const location = useLocation()
  const history = useHistory()
  const theme = useTheme()
  const classes = createStyles(theme)
  const [cidadaosEncontrados, setCidadaosEncontrados] = useState<CidadaoListingFormModel[]>()
  const [hasMoreResults, setHasMoreResults] = useState<boolean>(false)

  const [cidadaoUnificavelUrlParamsState] = useState<CidadaoUrlParams>(
    qs.parse(location.search, {
      ignoreQueryPrefix: true,
    })
  )

  const [executeQuery] = useUnificacaoCidadaosListingLazyQuery({
    onCompleted: (dataCidadaos) => {
      if (dataCidadaos?.listagemCidadaos?.content.length === 0) {
        alert('warning', 'Nenhum resultado encontrado.')
      }
      if (dataCidadaos?.listagemCidadaos?.content.length > 50) {
        alert(
          'info',
          'Foram encontrados muitos cadastros com os dados informados. Refine a busca incluindo mais informações.'
        )
      }
      setCidadaosEncontrados(dataCidadaos?.listagemCidadaos?.content)
      setHasMoreResults(dataCidadaos?.listagemCidadaos?.content.length > 50)
    },
  })

  const handleSubmitPesquisa = async (form: CidadaoPesquisarFormModel) => {
    if (!form.nomeCpfCns && !form.dataNascimento && !form.nomeMae && !form.municipio) {
      alert('info', 'Insira algum filtro para realizar a busca.')
    } else {
      exeQuery(form)
      history.push({
        search: qs.stringify({
          form: form,
        }),
      })
    }
  }

  const exeQuery = useCallback(
    (form: CidadaoPesquisarFormModel) => {
      executeQuery({
        variables: {
          input: {
            pageParams: {
              size: 51,
              fetchPageInfo: false,
            },
            query: form.nomeCpfCns,
            idCidadaoUnificacao: form.idCidadaoUnificacao,
            dataNascimento: form.dataNascimento,
            nomeMae: form.nomeMae,
            municipioNascimento: form.municipio?.id,
          },
        },
      })
    },
    [executeQuery]
  )

  useEffect(() => {
    cidadaoUnificavelUrlParamsState.form && exeQuery(cidadaoUnificavelUrlParamsState.form)
  }, [cidadaoUnificavelUrlParamsState, exeQuery])

  const handleClearCidadaosEncontrados = () => {
    setCidadaosEncontrados(null)
  }

  return (
    <>
      <VFlow style={classes.contentDivider}>
        <PageContent type='filled'>
          <HFlow justifyContent='space-between' style={classes.titleContent}>
            <TitleGroup title='Unificação de cadastros' />
            <ExportarCadastrosDuplicadosButton
              identificadorModuloArquivo={ArquivoIdentificadorEnum.RELATORIO_CADASTROS_DUPLICADOS}
            />
          </HFlow>
        </PageContent>
      </VFlow>
      <VFlow style={classes.borderBottom}>
        <PageContainer>
          <CidadaoPesquisarForm handleSubmitPesquisa={handleSubmitPesquisa} />
        </PageContainer>
      </VFlow>
      <PageContent type='transparent' style={classes.textAlign}>
        <UnificacaoCadastroListing
          cadastrosEncontrados={cidadaosEncontrados}
          hasMoreResults={hasMoreResults}
          clearCidadaosEncontrados={handleClearCidadaosEncontrados}
        />
      </PageContent>
    </>
  )
}

const createStyles = (theme: Theme) => ({
  contentDivider: css`
    padding-bottom: 1.25rem;
  `,
  titleContent: css`
    margin: 1rem 0;
  `,
  borderBottom: css`
    border-bottom: 1px solid ${theme.pallete.divider};
  `,
  textAlign: css`
    text-align: center;
  `,
})

import { Omit } from 'bold-ui'
import { SelectField, SelectFieldProps } from 'components/form/final-form'
import { TipoImovelEnum } from 'graphql/types.generated'
import React from 'react'

import { tipoImovelEnumRecord } from './condicoes-moradia-model'

export interface TipoImovelSelectFieldProps extends Omit<SelectFieldProps<TipoImovelEnum>, 'items' | 'itemToString'> {}

export function TipoImovelSelectField(props: TipoImovelSelectFieldProps) {
  return <SelectField<TipoImovelEnum> items={items} itemToString={itemToString} {...props} />
}

const items = Object.values(TipoImovelEnum)
const itemToString = (item: TipoImovelEnum) => item && tipoImovelEnumRecord[item]
